import React, { useState } from 'react';
import ConnectedLayout from 'business/user/layout/connected';
import { useTranslation } from 'react-i18next';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { Theme, useStrategyMetricsQuery } from 'generated/graphql';
import Loader from 'ui/loader';
import CardWithStatistic from 'ui/cardWithStatistic';
import RadarInCard from 'ui/radarInCard';
import { useAppContext, useLoggedInAppContext } from 'business/AppBootstrapper';
import { CountStakes } from 'business/summary/types';
import Radio from 'ui/radio';
import { RadioChangeEvent } from 'antd';
import styles from './index.module.scss';

const SummaryPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const [selectedTheme, setSelectedTheme] = useState<Theme>();

  const { data } = useStrategyMetricsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (!data) {
    return (
      <ConnectedLayout>
        <Loader />
      </ConnectedLayout>
    );
  }

  const indicatorsByStakes = data.indicators_by_stakes as CountStakes[];
  const actionsByStakes = data.actions_by_stakes as CountStakes[];

  const options = data.theme.map((theme) => theme.label);
  const stakes = data.stake;
  const defaultSelectedTheme = data.theme[0];

  const indicatorRadarData = stakes
    .filter(
      (stake) => stake.theme.id === (selectedTheme || defaultSelectedTheme).id,
    )
    .map((stake) => {
      const radarData = indicatorsByStakes.find(
        (indicator) =>
          indicator.entityId === entityId && indicator.stakeId === stake.id,
      );
      if (!radarData) {
        return {
          count: 0,
          entityId,
          stakeId: stake.id,
          stakeLabel: stake.label,
          themeId: stake.theme.id,
          themeLabel: stake.theme.label,
        };
      }
      return radarData;
    });

  const actionsRadarData = stakes
    .filter(
      (stake) => stake.theme.id === (selectedTheme || defaultSelectedTheme).id,
    )
    .map((stake) => {
      const radarData = actionsByStakes.find(
        (action) => action.entityId === entityId && action.stakeId === stake.id,
      );
      if (!radarData) {
        return {
          count: 0,
          entityId,
          stakeId: stake.id,
          stakeLabel: stake.label,
          themeId: stake.theme.id,
          themeLabel: stake.theme.label,
        };
      }
      return radarData;
    });

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedTheme(
      data.theme.find((theme) => theme.label === e.target.value),
    );
  };

  return (
    <ConnectedLayout showEntityBreadcrumbs>
      <Space direction="vertical" size="large" className={styles.summary}>
        <Space justify="space-between">
          <Space align="center">
            <Typography.Title level={3}>{t('summary.title')}</Typography.Title>
          </Space>
        </Space>
        <Space justify="space-between" size="large">
          <CardWithStatistic
            label={t('summary.followedIndicators')}
            value={data.indicator_aggregate.aggregate?.count || 0}
            isLoading={
              data.indicator_aggregate.aggregate?.count === undefined ||
              data.indicator_aggregate.aggregate?.count === null
            }
          />
          <CardWithStatistic
            label={t('summary.inProgressOrIncomingActions')}
            value={data.action_aggregate.aggregate?.count || 0}
            isLoading={
              data.action_aggregate.aggregate?.count === undefined ||
              data.action_aggregate.aggregate?.count === null
            }
          />
          <CardWithStatistic
            label={t('summary.stakesWithoutIndicators', {
              total: stakes.length,
            })}
            value={
              data.total_stakes.aggregate?.count === null ||
              data.stakes_with_indicators.aggregate?.count === null ||
              data.total_stakes.aggregate?.count === undefined ||
              data.stakes_with_indicators.aggregate?.count === undefined
                ? 0
                : data.total_stakes.aggregate?.count -
                  data.stakes_with_indicators.aggregate?.count
            }
            isLoading={
              data.total_stakes.aggregate?.count === null ||
              data.stakes_with_indicators.aggregate?.count === null ||
              data.total_stakes.aggregate?.count === undefined ||
              data.stakes_with_indicators.aggregate?.count === undefined
            }
          />
          <CardWithStatistic
            label={t('summary.stakesWithoutActions', {
              total: stakes.length,
            })}
            value={
              data.total_stakes.aggregate?.count === null ||
              data.stakes_with_actions.aggregate?.count === null ||
              data.total_stakes.aggregate?.count === undefined ||
              data.stakes_with_actions.aggregate?.count === undefined
                ? 0
                : data.total_stakes.aggregate?.count -
                  data.stakes_with_actions.aggregate?.count
            }
            isLoading={
              data.total_stakes.aggregate?.count === null ||
              data.stakes_with_actions.aggregate?.count === null ||
              data.total_stakes.aggregate?.count === undefined ||
              data.stakes_with_actions.aggregate?.count === undefined
            }
          />
        </Space>
        <Radio
          optionType="button"
          buttonStyle="solid"
          direction="horizontal"
          options={options}
          onChange={handleRadioChange}
          value={(selectedTheme || defaultSelectedTheme).label}
        />
        <Space
          justify="space-around"
          size="large"
          className={styles.radarCards}
        >
          <RadarInCard
            title={t('summary.indicatorsByStakes')}
            data={indicatorRadarData}
            color={(selectedTheme || defaultSelectedTheme).color}
            tooltipLabel={t('summary.tooltips.indicators')}
          />
          <RadarInCard
            title={t('summary.actionsByStakes')}
            data={actionsRadarData}
            color={(selectedTheme || defaultSelectedTheme).color}
            tooltipLabel={t('summary.tooltips.actions')}
          />
        </Space>
      </Space>
    </ConnectedLayout>
  );
};

export default SummaryPage;
