import { Tag } from 'antd';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import variables from 'ui/variables.module.scss';
import { CampaignStatus } from '../../services/types';

interface CampaignStatusTagProps {
  status: string;
  className?: string;
}

const resolveTagPayload = (
  status: string,
  t: TFunction,
): { color: string; label: string } => {
  switch (status) {
    case CampaignStatus.Finished:
      return {
        color: variables.green,
        label: t(`campaigns.status.${status}`),
      };
    case CampaignStatus.InProgress:
      return {
        color: variables.purpleLight,
        label: t(`campaigns.status.${status}`),
      };
    default:
      return { color: variables.blue, label: status };
  }
};

const CampaignStatusTag: React.FC<CampaignStatusTagProps> = ({
  status,
  className,
}) => {
  const { t } = useTranslation();
  const { color, label } = resolveTagPayload(status, t);
  return (
    <Tag data-test-id="campaign-status" className={className} color={color}>
      {label}
    </Tag>
  );
};

export default CampaignStatusTag;
