import {
  ThemeFragment,
  IndicatorTemplateDetailsFragment,
  StakeFragment,
  IndicatorDetailsFragment,
  IndicatorFragment,
  ParameterFragment,
  ParameterDetailsFragment,
  PlotData,
  SimplePlotData,
  KeyValues,
  RepartitionPlotData,
  AggregatedPlotData,
  DataSource_Enum,
} from 'generated/graphql';

export type AssociatedActionTemplate =
  IndicatorTemplateDetailsFragment['actionTemplateIndicatorTemplates'][number]['actionTemplate'];
export type AssociatedAction =
  IndicatorDetailsFragment['actionIndicators'][number]['action'];
export type LinkedIndicatorTemplate =
  IndicatorTemplateDetailsFragment['indicatorTemplateLinks'][number]['indicatorTemplate'];
export type LinkedIndicator = Pick<
  IndicatorDetailsFragment['indicatorLinks'][number]['indicator'],
  'id' | 'indicatorTemplate'
>;
export type AssociatedCampaignTemplate =
  IndicatorTemplateDetailsFragment['indicatorTemplateCampaignTemplates'][number]['campaignTemplate'];
export type AssociatedCampaign =
  IndicatorDetailsFragment['campaignIndicators'][number]['campaign'];

export interface IndicatorTemplateRow {
  key: string;
  label: string;
  themes: ThemeFragment[];
  stakes: StakeFragment[];
  nbAssociatedActionTemplates: number;
  isConnected: boolean;
}

export interface LinkedIndicatorTemplateRow {
  key: string;
  label: string;
}

export type LinkedIndicatorRow = LinkedIndicatorTemplateRow;

export interface IndicatorValueRow {
  key: string;
  date: string;
  value: number | undefined;
  unit: IndicatorUnit;
  type: IndicatorType;
  source: DataSource | undefined;
  isTemporary: boolean;
}

export interface MissingIndicator {
  label: string;
  id: string;
  linkedIndicators?: MissingIndicator[];
}

export enum IndicatorType {
  Photo = 'photo',
  Stock = 'stock',
  Repartition = 'repartition',
  Select = 'select',
}

export enum IndicatorUnit {
  Km = 'km',
  L = 'L',
  Kg = 'kg',
  m2 = 'm2',
  m3 = 'm3',
  Percentage = '%',
  kW = 'kW',
  MW = 'MW',
  kWh = 'kWh',
  MWh = 'MWh',
  kWhPerCA = 'kWh/k€CA',
  Integer = 'integer',
  Float = 'float',
  Year = 'year',
  Day = 'day',
  Hour = 'hours',
  Min = 'min',
  Boolean = 'boolean',
  Euro = '€',
  KiloEuro = 'k€',
  MegaEuro = 'M€',
  KgEqCO2 = 'kgeqCO2',
  TEqCO2 = 'teqCO2',
  KgEqCO2PerkEuroCA = 'kgeqCO2/k€CA',
  KgEqCO2PerCollab = 'kgeqCO2/collab',
  KmPerCollab = 'km/collab',
  LPerCollab = 'L/collab',
  KgPerCollab = 'kg/collab',
  Tonne = 't',
  KgPerKiloEuroCA = 'kg/k€CA',
  Go = 'Go',
  To = 'To',
  Po = 'Po',
  ClasseDPE = 'classeDPE',
  ClasseSign = 'classeSign',
  ScoreEnglish = 'scoreEnglish',
  ScoreCDP = 'scoreCDP',
  Level = 'niveau',
  PoidsRSE = 'poidsRSE',
  Mark = 'mark',
}

export enum IndicatorAggregationMethod {
  Sum = 'sum',
  Average = 'average',
}

export const isInstanceOfIndicator = (
  object: IndicatorFragment | ParameterFragment,
): object is IndicatorFragment => {
  return !!(object as IndicatorFragment).indicatorTemplate
    .indicatorTemplateStakes;
};

export const isInstanceOfIndicatorDetails = (
  object: IndicatorDetailsFragment | ParameterDetailsFragment,
): object is IndicatorDetailsFragment => {
  return !!(object as IndicatorDetailsFragment).indicatorTemplate
    .indicatorTemplateStakes;
};

export enum IndicatorStockGraphMode {
  Aggregated = 'aggregated',
  Monthly = 'monthly',
}

export enum IndicatorGraphMode {
  Yearly = 'yearly',
  AllYears = 'allYears',
}

export const ensurePlotDataIsSimplePlotData = (
  plotData: PlotData,
): plotData is SimplePlotData => {
  // eslint-disable-next-line no-underscore-dangle
  return (plotData as SimplePlotData).__typename === 'SimplePlotData';
};

export const ensurePlotDataIsAggregatedPlotData = (
  plotData: PlotData,
): plotData is AggregatedPlotData => {
  // eslint-disable-next-line no-underscore-dangle
  return (plotData as AggregatedPlotData).__typename === 'AggregatedPlotData';
};

export const ensurePlotDataIsRepartitionPlotData = (
  plotData: PlotData,
): plotData is RepartitionPlotData => {
  // eslint-disable-next-line no-underscore-dangle
  return (plotData as RepartitionPlotData).__typename === 'RepartitionPlotData';
};

export const ensureKeyValuesIsSimplePlotData = (
  keyValues: KeyValues,
): keyValues is { currentYear: SimplePlotData; lastYear: SimplePlotData } => {
  return (
    !!keyValues.currentYear &&
    ensurePlotDataIsSimplePlotData(keyValues.currentYear) &&
    !!keyValues.lastYear &&
    ensurePlotDataIsSimplePlotData(keyValues.lastYear)
  );
};

export const ensureArrayPlotDataIsArraySimplePlotData = (
  plotData: Array<PlotData | null>,
): plotData is SimplePlotData[] => {
  return plotData.some((data) => !data || ensurePlotDataIsSimplePlotData(data));
};

export const ensureArrayPlotDataIsArrayRepartitionPlotData = (
  plotData: Array<PlotData | null>,
): plotData is RepartitionPlotData[] => {
  return plotData.some(
    (data) => data && ensurePlotDataIsRepartitionPlotData(data),
  );
};

export const ensureArrayPlotDataIsArrayAggregatedPlotData = (
  plotData: Array<PlotData | null>,
): plotData is AggregatedPlotData[] => {
  return plotData.some(
    (data) => !data || ensurePlotDataIsAggregatedPlotData(data),
  );
};

// DataSource is an alias for the generated DataSource_Enum, So it equals DataSource_Enum value and type
// cf https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944
export type DataSource = DataSource_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataSource = DataSource_Enum;
