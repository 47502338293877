import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TrendIndicator from 'business/indicators/components/trendIndicator';
import {
  IndicatorStrategyDetailsTabs,
  Mode,
  isIndicatorStrategyDetailsTabs,
} from 'business/indicators/services/type';
import { getUnitName } from 'business/indicators/services/units';
import { IndicatorType, IndicatorUnit } from 'business/indicators/types';
import ParameterTabs from 'business/myEntity/components/ParameterTabs';
import Routes, { parameterDetailsRoute } from 'business/router/routes';
import SubPageLayout from 'business/user/layout/subPage';
import {
  KeyValues,
  useIndicatorValuesQuery,
  useParameterQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isNotNullOrUndefined } from 'technical/type';
import Dropdown from 'ui/dropdown';
import Loader from 'ui/loader';
import Space from 'ui/space';
import Typography from 'ui/typography';

interface ParametersDetailsRouteParams {
  id: string;
  tab?: string;
}

const ParameterDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { id, tab } = useParams<ParametersDetailsRouteParams>();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);

  const history = useHistory();

  const [activeTabKey, setActiveTabKey] =
    useState<IndicatorStrategyDetailsTabs>(
      tab && isIndicatorStrategyDetailsTabs(tab) ? tab : 'description',
    );

  const { data, refetch } = useParameterQuery({
    variables: {
      id,
    },
  });

  const handleTabKey = (tabKey: string) => {
    if (isIndicatorStrategyDetailsTabs(tabKey)) {
      setActiveTabKey(tabKey);
      history.replace(parameterDetailsRoute(id, tabKey));
    }
  };

  const parameter = data?.indicator_by_pk;

  let defaultSelectedUnit = '';
  if (parameter) {
    defaultSelectedUnit = parameter.indicatorTemplate.unit;
  }

  const { data: parameterValues } = useIndicatorValuesQuery({
    variables: {
      entityId,
      indicatorTemplateId: parameter?.indicatorTemplate.id,
      unit: selectedUnit || defaultSelectedUnit,
    },
  });

  if (!parameter || !parameterValues) {
    return (
      <SubPageLayout goBack={Routes.IndicatorsStrategy}>
        <Loader />
      </SubPageLayout>
    );
  }

  const keyValues = parameterValues?.getKeyValues as KeyValues;

  const handleSelectChange = (value: string) => {
    setSelectedUnit(value);
  };

  const units = (['unit', 'unit2', 'unit3', 'unit4'] as const).reduce(
    (acc, unitField) => {
      const unit = parameter.indicatorTemplate[unitField];
      const value = unit ? getUnitName(unit) : unit;
      if (isNotNullOrUndefined(value)) {
        return [...acc, { value }];
      }
      return acc;
    },
    [] as { value: string }[],
  );

  return (
    <SubPageLayout goBack={Routes.IndicatorsStrategy}>
      <Space direction="vertical" size="large">
        <Space justify="space-between">
          <Typography.Title data-test-id="indicator-name" level={3}>
            {t(`parameters.${parameter.indicatorTemplate.reference}`)}
          </Typography.Title>
        </Space>
        {units.length > 1 ? (
          <Dropdown
            value={selectedUnit || defaultSelectedUnit}
            onChange={handleSelectChange}
            options={units}
          />
        ) : null}
        <TrendIndicator
          positiveTrend={parameter.indicatorTemplate.positiveTrend}
          unit={selectedUnit || defaultSelectedUnit}
          keyValues={keyValues}
          mode={Mode.Horizontal}
          indicatorType={parameter.indicatorTemplate.type as IndicatorType}
        />
        <ParameterTabs
          activeTabKey={activeTabKey}
          setActiveTabKey={handleTabKey}
          parameter={parameter}
          refetch={refetch}
          selectedUnit={(selectedUnit || defaultSelectedUnit) as IndicatorUnit}
          defaultUnit={defaultSelectedUnit as IndicatorUnit}
        />
      </Space>
    </SubPageLayout>
  );
};

export default ParameterDetailsPage;
