import React from 'react';
import Space from 'ui/space';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface TagProps {
  color?: string;
}

const Tag: React.FC<TagProps> = ({ color, children }) => {
  return (
    <Space size="extra-small" align="center" className={styles.tag}>
      {color ? (
        <div className={styles.circle} style={{ backgroundColor: color }} />
      ) : null}
      <Typography.Text className={styles.text}>{children}</Typography.Text>
    </Space>
  );
};

export default Tag;
