import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { EntityTypes } from 'business/user/types/user';
import { FormikProps } from 'formik';
import {
  Address,
  BuildingPayload,
  CompanyPayload,
  ContractPayload,
} from 'generated/graphql';
import React from 'react';
import { TFunction } from 'react-i18next';
import { formikErrorMessage, formikStatus } from 'technical/form';
import Space from 'ui/space';
import Form from 'ui/form';
import { IdentitySheetRow } from '../types';

export const identitySheetDataSource = (
  payload:
    | Pick<CompanyPayload, 'sector'>
    | Pick<
        BuildingPayload,
        'numberOfFloors' | 'yearOfConstruction' | 'mainUsage'
      >
    | Pick<
        ContractPayload,
        | 'contractNature'
        | 'clientName'
        | 'numberOfSites'
        | 'providerName'
        | 'totalSurface'
      >
    | null,
  address:
    | Pick<Address, 'city' | 'country' | 'streetName' | 'zipCode'>
    | undefined,
  entityName: string,
  parentEntityName: string | undefined,
  numberOfChildrenEntities: string | undefined,
  entityType: string,
  t: TFunction,
): IdentitySheetRow[] => {
  let specificRowsValues: {
    key: string;
    label: string;
    value: string | number | null | undefined;
    editable: boolean;
    type: string;
    options?: Array<{ value: string; label: string }>;
  }[] = [];
  let adressValues: Array<string> = [];
  switch (entityType) {
    case EntityTypes.Company: {
      const companyPayload = payload as Pick<CompanyPayload, 'sector'> | null;
      specificRowsValues = [
        {
          key: 'sector',
          label: t(`my-entity.identity-card.table.row.sector.label`),
          value: companyPayload ? companyPayload.sector : undefined,
          editable: true,
          type: 'string',
          options: [
            {
              value: 'construction',
              label: t(
                'my-entity.identity-card.table.row.sector.options.construction',
              ),
            },
            {
              value: 'industry',
              label: t(
                'my-entity.identity-card.table.row.sector.options.industry',
              ),
            },
            {
              value: 'consulting',
              label: t(
                'my-entity.identity-card.table.row.sector.options.consulting',
              ),
            },
            {
              value: 'engineering',
              label: t(
                'my-entity.identity-card.table.row.sector.options.engineering',
              ),
            },
            {
              value: 'realEstate',
              label: t(
                'my-entity.identity-card.table.row.sector.options.realEstate',
              ),
            },
            {
              value: 'administration',
              label: t(
                'my-entity.identity-card.table.row.sector.options.administration',
              ),
            },
            {
              value: 'transport',
              label: t(
                'my-entity.identity-card.table.row.sector.options.transport',
              ),
            },
          ],
        },
      ];
      adressValues = ['city', 'country', 'streetName', 'zipCode'];
      break;
    }
    case EntityTypes.Building: {
      const buildingPayload = payload as Pick<
        BuildingPayload,
        'numberOfFloors' | 'yearOfConstruction' | 'mainUsage'
      > | null;
      specificRowsValues = [
        {
          key: 'yearOfConstruction',
          label: t(`my-entity.identity-card.table.row.yearOfConstruction`),
          value: buildingPayload
            ? buildingPayload.yearOfConstruction
            : undefined,
          editable: true,
          type: 'number',
        },
        {
          key: 'numberOfFloors',
          label: t(`my-entity.identity-card.table.row.numberOfFloors`),
          value: buildingPayload ? buildingPayload.numberOfFloors : undefined,
          editable: true,
          type: 'number',
        },
        {
          key: 'mainUsage',
          label: t(`my-entity.identity-card.table.row.mainUsage.label`),
          value: buildingPayload ? buildingPayload.mainUsage : undefined,
          editable: true,
          type: 'string',
          options: [
            {
              value: 'housing',
              label: t(
                'my-entity.identity-card.table.row.mainUsage.options.housing',
              ),
            },
            {
              value: 'offices',
              label: t(
                'my-entity.identity-card.table.row.mainUsage.options.offices',
              ),
            },
            {
              value: 'hotels',
              label: t(
                'my-entity.identity-card.table.row.mainUsage.options.hotels',
              ),
            },
            {
              value: 'business',
              label: t(
                'my-entity.identity-card.table.row.mainUsage.options.business',
              ),
            },
            {
              value: 'industry',
              label: t(
                'my-entity.identity-card.table.row.mainUsage.options.industry',
              ),
            },
            {
              value: 'logistic',
              label: t(
                'my-entity.identity-card.table.row.mainUsage.options.logistic',
              ),
            },
          ],
        },
      ];
      adressValues = ['city', 'country', 'streetName', 'zipCode'];
      break;
    }
    case EntityTypes.Contract: {
      const contractPayload = payload as Pick<
        ContractPayload,
        | 'contractNature'
        | 'clientName'
        | 'numberOfSites'
        | 'providerName'
        | 'totalSurface'
      > | null;
      specificRowsValues = [
        {
          key: 'clientName',
          label: t(`my-entity.identity-card.table.row.clientName`),
          value: contractPayload ? contractPayload.clientName : undefined,
          editable: true,
          type: 'string',
        },
        {
          key: 'providerName',
          label: t(`my-entity.identity-card.table.row.providerName`),
          value: contractPayload ? contractPayload.providerName : undefined,
          editable: true,
          type: 'string',
        },
        {
          key: 'contractNature',
          label: t(`my-entity.identity-card.table.row.contractNature.label`),
          value: contractPayload ? contractPayload.contractNature : undefined,
          editable: true,
          type: 'string',
          options: [
            {
              value: 'operation',
              label: t(
                'my-entity.identity-card.table.row.contractNature.options.operation',
              ),
            },
            {
              value: 'works',
              label: t(
                'my-entity.identity-card.table.row.contractNature.options.works',
              ),
            },
            {
              value: 'services',
              label: t(
                'my-entity.identity-card.table.row.contractNature.options.services',
              ),
            },
          ],
        },
        {
          key: 'numberOfSites',
          label: t(`my-entity.identity-card.table.row.numberOfSites`),
          value: contractPayload ? contractPayload.numberOfSites : undefined,
          editable: true,
          type: 'string',
        },
        {
          key: 'totalSurface',
          label: t(`my-entity.identity-card.table.row.totalSurface`),
          value: contractPayload ? contractPayload.totalSurface : undefined,
          editable: true,
          type: 'number',
        },
      ];
      break;
    }
    default:
      throw new Error(`${entityType} is an unhandled entity type`);
  }

  const adressRowsValues = adressValues.map((row) => ({
    key: row,
    label: t(`my-entity.identity-card.table.row.${row}`),
    value: address ? address[row as keyof typeof payload] : undefined,
    editable: true,
    type: 'string',
  }));

  const commonRowsValues = [
    {
      key: 'entityName',
      label: t('my-entity.identity-card.table.row.entityName'),
      value: entityName,
      editable: false,
      type: 'string',
    },
    {
      key: 'parentEntity',
      label: t('my-entity.identity-card.table.row.parentEntity'),
      value: parentEntityName,
      editable: false,
      type: 'string',
    },
    {
      key: 'numberOfChildrens',
      label: t('my-entity.identity-card.table.row.numberOfChildrens'),
      value: numberOfChildrenEntities,
      editable: false,
      type: 'string',
    },
  ];
  return [...commonRowsValues, ...adressRowsValues, ...specificRowsValues];
};

export const identitySheetColumns = ({
  canEdit,
  isEditing,
  save,
  cancel,
  edit,
  remove,
  formik,
  t,
}: {
  canEdit: boolean;
  isEditing: (record: IdentitySheetRow) => boolean;
  save: () => void;
  cancel: () => void;
  edit: (record: IdentitySheetRow) => void;
  remove: (record: IdentitySheetRow) => void;
  formik: FormikProps<{ value: number | string | null | undefined }>;
  t: TFunction;
}) => [
  {
    title: t('my-entity.identity-card.table.column.label'),
    dataIndex: 'label',
    width: '45%',
    render: (_: any, record: IdentitySheetRow) => {
      return record.label;
    },
  },
  {
    title: t('my-entity.identity-card.table.column.value'),
    dataIndex: 'value',
    width: '45%',
    render: (_: any, record: IdentitySheetRow) => {
      const editing = isEditing(record);

      if (editing) {
        return (
          <Form.Item
            style={{ margin: 0 }}
            validateStatus={formikStatus(formik, 'value')}
          >
            {record.options ? (
              <Form.Select
                onChange={(value) => formik.setFieldValue('value', value)}
                onBlur={formik.handleBlur}
                value={(formik.values.value as string) ?? undefined}
                options={record.options}
              />
            ) : (
              <Form.Input
                name="value"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.value ?? undefined}
                type={record.type}
                min={0}
              />
            )}
          </Form.Item>
        );
      }
      return record.options
        ? t(
            `my-entity.identity-card.table.row.${record.key}.options.${record.value}`,
          )
        : record.value ?? '-';
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    render: (_: any, record: IdentitySheetRow) => {
      if (canEdit && record.editable) {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <CheckOutlined data-test-id="save-action" onClick={save} />
            <CloseOutlined data-test-id="cancel-action" onClick={cancel} />
          </Space>
        ) : (
          <Space>
            <EditOutlined
              data-test-id="edit-action"
              onClick={() => edit(record)}
            />
            <Popconfirm
              title={t('indicators.strategy.indicatorValues.confirmDelete')}
              placement="leftBottom"
              okButtonProps={{
                id: 'delete-action-ok',
              }}
              onConfirm={() => remove(record)}
              onCancel={() => null}
              okText={t('common.yes')}
              cancelText={t('common.no')}
            >
              <DeleteOutlined data-test-id="delete-action" />
            </Popconfirm>
          </Space>
        );
      }
      return null;
    },
  },
];
