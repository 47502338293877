import InformationDetails from 'business/common/components/informationDetails';
import { getPartnerStakes } from 'business/partners/services';
import Routes from 'business/router/routes';
import { getThemes } from 'business/stake/services';
import ThemeTag from 'business/theme/components/themeTag';
import SubPageLayout from 'business/user/layout/subPage';
import { usePartnerDetailsQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Button from 'ui/button';
import Loader from 'ui/loader';
import RichTextContainer from 'ui/richTextContainer';
import Space from 'ui/space';
import Typography from 'ui/typography';

interface PartnerCatalogDetailsRouteParams {
  id: string;
}

const PartnerCatalogDetailsPage: React.FC = () => {
  const { id } = useParams<PartnerCatalogDetailsRouteParams>();
  const { t } = useTranslation();

  const { data } = usePartnerDetailsQuery({
    variables: {
      id,
    },
  });

  const partner = data?.partner_by_pk;

  if (!partner) {
    return (
      <SubPageLayout goBack={Routes.PartnersCatalog}>
        <Loader />
      </SubPageLayout>
    );
  }

  const stakes = getPartnerStakes(partner);
  const themes = getThemes(stakes);

  return (
    <SubPageLayout goBack={Routes.PartnersCatalog}>
      <Space direction="vertical" size="large">
        <Space justify="space-between">
          <Typography.Title data-test-id="partner-name" level={3}>
            {partner.name}
          </Typography.Title>
          {partner.link && (
            <a target="_blank" rel="noreferrer" href={partner.link}>
              <Button type="primary">
                {t('partners.details.goToWebsite')}
              </Button>
            </a>
          )}
        </Space>
        <InformationDetails title={t('partners.details.description')}>
          <RichTextContainer content={partner.description} />
        </InformationDetails>
        <InformationDetails title={t('partners.details.themesAndStakes')}>
          <Space size="extra-small">
            {themes.map((theme) => (
              <ThemeTag key={theme.id} theme={theme} />
            ))}
          </Space>
          <Space>{stakes.map((stake) => stake.label).join(', ')}</Space>
        </InformationDetails>
        <InformationDetails title={t('partners.details.contact')}>
          <a href={`mailto:${partner.contact}`}>{partner.contact}</a>
        </InformationDetails>
      </Space>
    </SubPageLayout>
  );
};

export default PartnerCatalogDetailsPage;
