import React from 'react';
import Sprinkle from 'ui/sprinkle';
import Card, { CardProps } from '../card';
import styles from './index.module.scss';

const CardWithSprinkles: React.FC<CardProps> = ({ children, ...rest }) => {
  return (
    <Card {...rest} className={styles.cardWithSprinkles}>
      <div className={styles.sprinklesContainer}>
        <Sprinkle color="blue" className={styles.bluePosition} />
        <Sprinkle color="pink" className={styles.pinkTopPosition} />
        <Sprinkle color="green" className={styles.greenPosition} />
        <Sprinkle color="pink" className={styles.pinkBottomPosition} />
      </div>
      {children}
    </Card>
  );
};

export default CardWithSprinkles;
