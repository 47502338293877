import React from 'react';
import { Permission } from 'business/user/types/user';
import { useLoggedInAppContext } from 'business/AppBootstrapper';

interface RestrictedProps {
  permission: Permission;
}

const Restricted: React.FC<RestrictedProps> = ({ permission, children }) => {
  const { isAllowedTo } = useLoggedInAppContext();
  if (isAllowedTo(permission)) {
    return <>{children}</>;
  }
  return null;
};

export default Restricted;
