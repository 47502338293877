import { useAppContext } from 'business/AppBootstrapper';
import React from 'react';
import { Redirect } from 'react-router';

const DEFAULT_NOT_CONNECTED_ROUTE = '/signin';
const DEFAULT_CONNECTED_ROUTE = '/';
/**
 * Redirect the user to the default not-connected route when the user
 * is not-connected and intend to access connected route
 */
export function ConnectedContainer({
  children,
}: {
  children: React.ReactElement;
}) {
  const { isConnected } = useAppContext();
  return isConnected ? children : <Redirect to={DEFAULT_NOT_CONNECTED_ROUTE} />;
}

/**
 * Redirect the user to the default connected route when the user is
 * connected and intend to access not connected route
 */
export function NotConnectedContainer({
  children,
}: {
  children: React.ReactElement;
}) {
  const { isConnected } = useAppContext();
  return isConnected ? <Redirect to={DEFAULT_CONNECTED_ROUTE} /> : children;
}

export function ConnectedHoc<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => (
    <ConnectedContainer>
      <Component {...props} />
    </ConnectedContainer>
  );
}

export function NotConnectedHoc<Props>(
  Component: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return ({ ...props }) => (
    <NotConnectedContainer>
      <Component {...props} />
    </NotConnectedContainer>
  );
}
