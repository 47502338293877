import React, { Key } from 'react';
import { i18n } from 'translations';
import ThemeTag from 'business/theme/components/themeTag';
import {
  IndicatorTemplateFragment,
  ThemeFragment,
  StakeFragment,
  Order_By,
  IndicatorTemplate_Order_By,
  IndicatorDetailsFragment,
  ParameterDetailsFragment,
} from 'generated/graphql';
import { getThemes } from 'business/stake/services';
import Space from 'ui/space';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { FormikProps } from 'formik';
import Form from 'ui/form';
import DatePicker from 'ui/datePicker';
import { formikStatus } from 'technical/form';
import { Popconfirm } from 'antd';
import { TFunction } from 'react-i18next';
import { dateRange } from 'technical/date';
import { formatDate } from 'technical/date/formatter';
import { useFilters } from 'business/common/components/tableFilters/services/useFiltersHook';
import {
  stringFilter,
  pageFilter,
  booleanFilter,
} from 'business/common/components/tableFilters/services/filters';
import {
  IndicatorTemplateRow,
  IndicatorType,
  IndicatorUnit,
  IndicatorValueRow,
  LinkedIndicatorRow,
  LinkedIndicatorTemplate,
  LinkedIndicatorTemplateRow,
} from '../types';
import { getIndicatorTemplateStakes, isConnectedIndicator } from '.';
import { getUnitComponent, getUnitDisplay } from './units';
import IndicatorTemplateConnectorMarker from '../components/indicatorTemplateConnectorMarker';

// Indicator templates table (catalog)

export const buildIndicatorTemplateOrderBy = (
  columnKey: Key | undefined,
  order: Order_By,
): IndicatorTemplate_Order_By | undefined => {
  switch (columnKey as keyof IndicatorTemplateRow) {
    case 'label':
      return { label: order };
    case 'nbAssociatedActionTemplates':
      return { actionTemplateIndicatorTemplates_aggregate: { count: order } };
    default:
      return undefined;
  }
};

export const indicatorTemplatesDataSource = (
  indicatorTemplates: IndicatorTemplateFragment[],
): IndicatorTemplateRow[] => {
  return indicatorTemplates.map((indicatorTemplate) => {
    const { id, label } = indicatorTemplate;

    const stakes = getIndicatorTemplateStakes(indicatorTemplate);
    const themes = getThemes(stakes);

    const nbAssociatedActionTemplates =
      indicatorTemplate.actionTemplateIndicatorTemplates_aggregate.aggregate
        ?.count || 0;

    const isConnected = isConnectedIndicator({ indicatorTemplate });

    return {
      key: id,
      label,
      themes,
      stakes,
      nbAssociatedActionTemplates,
      isConnected,
    };
  });
};

export const indicatorTemplatesColumns = () => [
  {
    title: i18n.t('indicators.catalog.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: true,
    width: '60%',
    render: (label: string, row: IndicatorTemplateRow) => {
      return (
        <>
          {label}
          &nbsp;
          {row.isConnected ? <IndicatorTemplateConnectorMarker /> : null}
        </>
      );
    },
  },
  {
    title: i18n.t('indicators.catalog.table.themes'),
    dataIndex: 'themes',
    key: 'themes',
    render: (themes: ThemeFragment[]) => {
      // Forced by eslint to spread themes (bug)
      return (
        <Space direction="vertical" size="extra-small">
          {[...themes].map((theme) => (
            <ThemeTag key={theme.id} theme={theme} />
          ))}
        </Space>
      );
    },
    width: '10%',
  },
  {
    title: i18n.t('indicators.catalog.table.stakes'),
    dataIndex: 'stakes',
    key: 'stakes',
    render: (stakes: StakeFragment[]) => {
      return stakes.map((stake) => stake.label).join(', ');
    },
    width: '15%',
  },
  {
    title: i18n.t('indicators.catalog.table.nbAssociatedActionTemplates'),
    dataIndex: 'nbAssociatedActionTemplates',
    key: 'nbAssociatedActionTemplates',
    sorter: true,
    width: '15%',
  },
];

// Linked indicator templates table (catalog)

export const linkedIndicatorTemplatesDataSource = (
  indicatorTemplates: LinkedIndicatorTemplate[],
): LinkedIndicatorTemplateRow[] => {
  return indicatorTemplates.map((indicatorTemplate) => {
    return {
      key: indicatorTemplate.id,
      label: indicatorTemplate.label,
    };
  });
};

export const linkedIndicatorTemplatesColumns = () => [
  {
    title: i18n.t('indicators.catalog.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: (a: LinkedIndicatorTemplateRow, b: LinkedIndicatorTemplateRow) =>
      a.label.localeCompare(b.label),
    width: '100%',
  },
];

// Linked indicators table (catalog)

export const linkedIndicatorsDataSource = <
  Indicator extends { id: string; indicatorTemplate: { label: string } },
>(
  indicators: Indicator[],
): LinkedIndicatorRow[] => {
  return indicators.map((indicator) => {
    return {
      key: indicator.id,
      label: indicator.indicatorTemplate.label,
    };
  });
};

export const linkedIndicatorsColumns = () => [
  {
    title: i18n.t('indicators.strategy.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: (a: LinkedIndicatorRow, b: LinkedIndicatorRow) =>
      a.label.localeCompare(b.label),
    width: '100%',
  },
];

// Indicator values table (strategy)

export const indicatorValuesDataSource = (
  indicator: IndicatorDetailsFragment | ParameterDetailsFragment,
): IndicatorValueRow[] => {
  const indicatorValuesDates: { [key: string]: IndicatorValueRow } = {};

  // In case of a stock indicator we want to fill the gaps with empty values to be displayed for the final user.
  if (
    indicator.indicatorTemplate.type === IndicatorType.Stock &&
    indicator.indicatorValues.length > 0
  ) {
    const firstDate = new Date(indicator.indicatorValues[0].startDate);
    const lastDate = new Date(
      indicator.indicatorValues[indicator.indicatorValues.length - 1].startDate,
    );
    const dates = dateRange(lastDate, firstDate);

    dates.forEach((date) => {
      const formattedDate = formatDate(date, 'y-MM-dd');
      indicatorValuesDates[formattedDate] = {
        key: formattedDate,
        date: formattedDate,
        value: undefined,
        type: indicator.indicatorTemplate.type as IndicatorType,
        unit: indicator.indicatorTemplate.unit as IndicatorUnit,
        source: undefined,
        isTemporary: true,
      };
    });
  }
  indicator.indicatorValues.forEach((indicatorValue) => {
    indicatorValuesDates[indicatorValue.startDate] = {
      key: indicatorValue.id,
      date: indicatorValue.startDate,
      value: indicatorValue.rawValue,
      type: indicator.indicatorTemplate.type as IndicatorType,
      unit: indicator.indicatorTemplate.unit as IndicatorUnit,
      source: indicatorValue.source,
      isTemporary: false,
    };
  });

  return Object.values(indicatorValuesDates);
};

export const indicatorValuesColumns = ({
  canEdit,
  isEditing,
  save,
  cancel,
  edit,
  remove,
  formik,
  t,
}: {
  canEdit: boolean;
  isEditing: (record: IndicatorValueRow) => boolean;
  save: () => void;
  cancel: () => void;
  edit: (record: IndicatorValueRow) => void;
  remove: (record: IndicatorValueRow) => void;
  formik: FormikProps<{ value: number | undefined; date: Date | undefined }>;
  t: TFunction;
}) => [
  {
    title: i18n.t('indicators.strategy.table.fields.date'),
    dataIndex: 'date',
    width: '30%',
    render: (_: any, record: IndicatorValueRow) => {
      const editing = isEditing(record);
      if (editing && record.type === IndicatorType.Photo) {
        return (
          <Form.Item
            style={{ margin: 0 }}
            validateStatus={formikStatus(formik, 'date')}
          >
            <DatePicker
              data-test-id="indicator-value-input-date"
              size="middle"
              name="date"
              format="dd/MM/y"
              // disabledDate={(date) => date > new Date()} (disabled temporary)
              onChange={(date) => {
                formik.setValues({
                  ...formik.values,
                  date: date ?? undefined,
                });
              }}
              onBlur={formik.handleBlur}
              value={formik.values.date}
              style={{ width: '100%' }}
            />
          </Form.Item>
        );
      }
      const date = format(
        new Date(record.date),
        record.type === IndicatorType.Stock ? 'LLLL y' : 'dd/MM/y',
        { locale: fr },
      );
      return `${date[0].toUpperCase()}${date.slice(1)}`;
    },
  },
  {
    title: i18n.t('indicators.strategy.table.fields.value'),
    dataIndex: 'value',
    width: '30%',
    render: (_: any, record: IndicatorValueRow) => {
      const editing = isEditing(record);
      const Component = getUnitComponent(record.unit);
      const display = getUnitDisplay(record.unit);

      if (editing && Component) {
        return (
          <Form.Item
            style={{ margin: 0 }}
            validateStatus={formikStatus(formik, 'value')}
          >
            <Component
              data-test-id="edit-indicator-value-input-value"
              formik={formik}
              size="middle"
            />
          </Form.Item>
        );
      }
      return record.value !== undefined ? display(record.value) : '-';
    },
  },
  {
    title: i18n.t('indicators.strategy.table.fields.source.label'),
    dataIndex: 'source',
    width: '30%',
    render: (_: any, record: IndicatorValueRow) => {
      return record.source
        ? i18n.t(
            `indicators.strategy.table.fields.source.values.${record.source}`,
          )
        : null;
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    render: (_: any, record: IndicatorValueRow) => {
      if (canEdit) {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <CheckOutlined data-test-id="save-action" onClick={save} />
            <CloseOutlined data-test-id="cancel-action" onClick={cancel} />
          </Space>
        ) : (
          <Space>
            <EditOutlined
              data-test-id="edit-action"
              onClick={() => edit(record)}
            />
            {record.isTemporary ? null : (
              <Popconfirm
                title={t('indicators.strategy.indicatorValues.confirmDelete')}
                placement="leftBottom"
                okButtonProps={{
                  id: 'delete-action-ok',
                }}
                onConfirm={() => remove(record)}
                onCancel={() => null}
                okText={t('common.yes')}
                cancelText={t('common.no')}
              >
                <DeleteOutlined data-test-id="delete-action" />
              </Popconfirm>
            )}
          </Space>
        );
      }
      return null;
    },
  },
];

export const useCatalogIndicatorListFilters = () =>
  useFilters({
    search: stringFilter,
    stakeId: stringFilter,
    themeId: stringFilter,
    page: pageFilter,
  });

export const useStrategyIndicatorListFilters = () =>
  useFilters({
    search: stringFilter,
    stakeId: stringFilter,
    themeId: stringFilter,
    page: pageFilter,
    showHidden: booleanFilter,
  });
