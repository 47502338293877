import React, { useState } from 'react';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import BreadcrumbsSelect, { Node } from 'ui/breadcrumbSelect';
import { Entity } from 'business/user/entitySelection/useEntitySelection';
import Button from 'ui/button';
import Space from 'ui/space';
import { Spin } from 'antd';
import Loader from 'ui/loader';
import styles from './index.module.scss';

const format = (rootEntity: Entity): Node<Entity> => ({
  key: rootEntity.entityId,
  children: rootEntity.children.map(format),
  title: rootEntity.entityName,
  data: rootEntity,
});

const EntityBreadcrumbs: React.FC = () => {
  const {
    entitySelection: { selectEntity, rootEntity, loading },
    selectedEntity,
  } = useLoggedInAppContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectEntity = async (entity: Entity) => {
    setIsLoading(true);
    await selectEntity(entity);
    setIsLoading(false);
  };

  if (!rootEntity || loading) {
    return (
      <Spin>
        <Space
          justify="space-between"
          align="center"
          className={styles.breadcrumbs}
        >
          <Button size="small">{selectedEntity.entityName}</Button>
        </Space>
      </Spin>
    );
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <BreadcrumbsSelect<Entity>
        root={format(rootEntity)}
        selectedNode={selectedEntity.entityId}
        onChange={(entity) => handleSelectEntity(entity)}
      />
    </>
  );
};

export default EntityBreadcrumbs;
