import { Tabs } from 'antd';
import AssociatedActionsTable from 'business/actions/components/associatedActionsTable';
import AssociatedCampaignsTable from 'business/campaigns/components/associatedCampaigns';
import {
  IndicatorDetailsFragment,
  ParameterDetailsFragment,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RichTextContainer from 'ui/richTextContainer';
import Typography from 'ui/typography';
import {
  IndicatorUnit,
  isInstanceOfIndicatorDetails,
} from 'business/indicators/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import { isComputedIndicator } from 'business/indicators/services';
import AssociatedPartnersTable from 'business/partners/components/associatedPartnersTable';
import { IndicatorStrategyDetailsTabs } from 'business/indicators/services/type';
import LinkedIndicatorsTable from '../linkedIndicatorsTable';
import IndicatorValuesTable from '../indicatorValuesTable';

const { TabPane } = Tabs;

interface IndicatorTabsProps {
  indicator: IndicatorDetailsFragment | ParameterDetailsFragment;
  refetch: () => void;
  selectedUnit: IndicatorUnit;
  defaultUnit: IndicatorUnit;
  refetchValues?: () => void;
  activeTabKey: IndicatorStrategyDetailsTabs;
  setActiveTabKey: (s: string) => void;
}

const IndicatorTabs: React.FC<IndicatorTabsProps> = ({
  indicator,
  refetch,
  refetchValues,
  selectedUnit,
  defaultUnit,
  activeTabKey,
  setActiveTabKey,
}) => {
  const { t } = useTranslation();
  const { isAllowedTo } = useLoggedInAppContext();

  return isInstanceOfIndicatorDetails(indicator) ? (
    <Tabs
      activeKey={activeTabKey}
      onTabClick={(newActiveTabKey) => setActiveTabKey(newActiveTabKey)}
    >
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-description">
            {t('indicators.tabs.description')}
          </Typography.Text>
        }
        key="description"
      >
        <RichTextContainer
          content={indicator.indicatorTemplate.description}
          data-test-id="indicator-description"
        />
      </TabPane>
      {isAllowedTo(Permission.IndicatorsDataRead) && // can't use Restricted component here because it adds a blank tab
        !isComputedIndicator(indicator) && (
          <TabPane
            tab={
              <Typography.Text data-test-id="indicator-tab-data">
                {t('indicators.tabs.data')}
              </Typography.Text>
            }
            key="data"
          >
            <IndicatorValuesTable
              indicator={indicator}
              refetch={refetch}
              refetchValues={refetchValues}
              selectedUnit={selectedUnit}
              defaultUnit={defaultUnit}
            />
          </TabPane>
        )}

      {isAllowedTo(Permission.ActionRead) && (
        <TabPane
          tab={
            <Typography.Text data-test-id="indicator-tab-actions">
              {t('indicators.tabs.actions')}
            </Typography.Text>
          }
          key="actions"
        >
          <AssociatedActionsTable
            indicatorId={indicator.id}
            actions={indicator.actionIndicators.map(
              (actionIndicator) => actionIndicator.action,
            )}
          />
        </TabPane>
      )}
      {isAllowedTo(Permission.CampaignRead) && (
        <TabPane
          tab={
            <Typography.Text data-test-id="indicator-tab-campaigns">
              {t('indicators.tabs.campaigns')}
            </Typography.Text>
          }
          key="campaigns"
        >
          <AssociatedCampaignsTable
            campaigns={Array.from(
              new Map(
                indicator.campaignIndicators.map((campaignIndicator) => [
                  campaignIndicator.campaign.id,
                  campaignIndicator.campaign,
                ]),
              ).values(),
            )}
          />
        </TabPane>
      )}
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-indicator-template-links">
            {t('indicators.tabs.indicatorTemplateLinks')}
          </Typography.Text>
        }
        key="indicator-template-links"
      >
        <LinkedIndicatorsTable
          indicators={indicator.indicatorLinks.map(
            (indicatorLink) => indicatorLink.indicator,
          )}
        />
      </TabPane>
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-partners">
            {t('indicators.tabs.catalog.partners')}
          </Typography.Text>
        }
        key="partners"
      >
        <AssociatedPartnersTable
          partners={indicator.indicatorTemplate.partnerIndicatorTemplates.map(
            (partnerIndicatorTemplate) => partnerIndicatorTemplate.partner,
          )}
        />
      </TabPane>
    </Tabs>
  ) : (
    <Tabs
      activeKey={activeTabKey}
      onTabClick={(newActiveTabKey) => setActiveTabKey(newActiveTabKey)}
    >
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-description">
            {t('indicators.tabs.description')}
          </Typography.Text>
        }
        key="description"
      >
        <RichTextContainer
          content={indicator.indicatorTemplate.description}
          data-test-id="indicator-description"
        />
      </TabPane>
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-data">
            {t('indicators.tabs.data')}
          </Typography.Text>
        }
        key="data"
      >
        <IndicatorValuesTable
          indicator={indicator}
          refetch={refetch}
          refetchValues={refetchValues}
          selectedUnit={selectedUnit}
          defaultUnit={defaultUnit}
        />
      </TabPane>
    </Tabs>
  );
};

export default IndicatorTabs;
