import React from 'react';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import Layout from 'ui/layout';
import EntityBreadcrumb from 'business/user/components/entityBreadcrumb';
import Space from 'ui/space';
import { Permission } from 'business/user/types/user';
import Footer from '../../components/navFooter';
import Menu from '../../components/menu';

export interface ConnectedLayoutProps {
  isPaddingActive?: boolean;
  showEntityBreadcrumbs?: boolean;
}

const ConnectedLayout: React.FC<ConnectedLayoutProps> = ({
  children,
  isPaddingActive = true,
  showEntityBreadcrumbs,
}) => {
  const { isConnected, isAllowedTo } = useLoggedInAppContext();

  return (
    <Layout
      isPaddingActive={isPaddingActive}
      menuFooter={<Footer />}
      menuContent={<Menu />}
      isMenuVisible={isConnected}
    >
      <Space direction="vertical">
        {showEntityBreadcrumbs &&
          isAllowedTo(Permission.ChildrenStrategyRead) && <EntityBreadcrumb />}
        {children}
      </Space>
    </Layout>
  );
};

export default ConnectedLayout;
