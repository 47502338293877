/**
 * Binds the DatePicker to date-fns in order to use simple Date object
 * and reduce bundle size.
 *
 * More infos: https://ant.design/docs/react/replace-moment
 */
import dateFnsGenerateConfig from 'rc-picker/es/generate/dateFns';
import { DatePicker } from 'antd';

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

export default MyDatePicker;
