import React from 'react';
import { List } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Space from 'ui/space';
import Button from 'ui/button';
import Typography from 'ui/typography';
import getEntityIcon from 'business/user/services/getEntityIcon';
import { EntityTypes } from 'business/user/types/user';
import { useTranslation } from 'react-i18next';
import { useConnectedUserContext } from 'business/AppBootstrapper';
import styles from './index.module.scss';

interface AccountsListProps {
  entityTypes: EntityTypes[];
}

const EntitiesList: React.FC<AccountsListProps> = ({ entityTypes }) => {
  const { t } = useTranslation();
  const { chooseEntityType } = useConnectedUserContext();

  return (
    <List
      dataSource={entityTypes}
      renderItem={(entityType) => (
        <List.Item>
          <Button
            type="text"
            data-test-id="entities-selection-button"
            onClick={() => {
              chooseEntityType(entityType);
            }}
            block
            loading={false}
          >
            <Space align="center">
              {getEntityIcon(entityType)}
              <Typography.Text
                data-test-id="entities-item-type-label"
                className={styles.text}
              >
                {t(`entityTypes.${entityType}`)}
              </Typography.Text>
              <RightOutlined className={styles.arrowIcon} />
            </Space>
          </Button>
        </List.Item>
      )}
    />
  );
};

export default EntitiesList;
