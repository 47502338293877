import { xlsxMimeType } from './types';

export function convertBinarytoBlob(content: string, contentType?: string) {
  const sliceSize = 512;
  const byteArrays = [];
  for (let offset = 0; offset < content.length; offset += sliceSize) {
    const slice = content.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
}

export const downloadFile = (blob: Blob, fileName: string) => {
  const fileLink = document.createElement('a');
  fileLink.href = URL.createObjectURL(blob);
  fileLink.download = fileName;
  fileLink.click();
};

export const convertToBinaryAndDownloadFile = (
  content: string,
  contentType: string,
  fileName: string,
) => {
  return downloadFile(convertBinarytoBlob(content, contentType), fileName);
};

export const downloadXlsxFileFromBinaryString = (
  fileName: string,
  document?: string | null,
) => {
  if (!document) {
    throw new Error('Cannot convert: document not defined');
  }
  return convertToBinaryAndDownloadFile(document, xlsxMimeType, fileName);
};
