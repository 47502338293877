import { Typography } from 'antd';
import { generateColorShades } from 'business/indicators/services';
import {
  ensureArrayPlotDataIsArrayAggregatedPlotData,
  ensureArrayPlotDataIsArrayRepartitionPlotData,
  ensureArrayPlotDataIsArraySimplePlotData,
  ensurePlotDataIsAggregatedPlotData,
  ensurePlotDataIsRepartitionPlotData,
  IndicatorGraphMode,
  IndicatorType,
  IndicatorUnit,
} from 'business/indicators/types';
import {
  AggregatedPlotData,
  PlotData,
  RepartitionPlotData,
} from 'generated/graphql';
import React from 'react';
import {
  BarChart as ReChartBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Area,
  AreaChart as ReChartAreaChart,
  ComposedChart as ReChartComposedChart,
  Line,
  LineChart as ReChartLineChart,
  LabelList,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { formatDate } from 'technical/date/formatter';
import { formatNumber } from 'technical/number/format';
import { isNotNullOrUndefined } from 'technical/type';
import { i18n } from 'translations';
import ResponsiveContainer from 'ui/responsiveContainer';
import Space from 'ui/space';
import variables from 'ui/variables.module.scss';
import colors from 'config/colors';
import CustomTooltip, { CustomPercentChartTooltip } from './customTooltip';
import PercentPieChartCustomTooltip from './customTooltip/percentPieChartCustomTooltip';
import { getAllKeysInArray, toPercent, getPercent } from './helpers';

const TOTAL_KEY = 'total';

export const standardComputation = (
  plotData: PlotData[],
  unit: IndicatorUnit,
  objective: number,
) => {
  if (ensureArrayPlotDataIsArraySimplePlotData(plotData)) {
    return plotData
      .filter((data) => !!data)
      .map((data) => ({
        date: data!.date,
        value: data!.value,
        objective,
        unit,
      }));
  }
  if (ensureArrayPlotDataIsArrayAggregatedPlotData(plotData)) {
    return plotData
      .filter((data) => !!data)
      .map((data) => ({
        date: data!.date,
        total: data!.total,
        unit,
      }));
  }
  throw new Error('[Standard Computation] Wrong type for plot data');
};

export const stackComputation = (
  plotData: PlotData[],
  unit: IndicatorUnit,
  objective: number,
) => {
  if (ensureArrayPlotDataIsArraySimplePlotData(plotData)) {
    return plotData
      .filter((data) => !!data)
      .reduce(
        (acc, curr) => {
          if (acc.length === 0) {
            return [
              {
                date: curr!.date,
                value: curr!.value || 0,
                objective,
                unit,
              },
            ];
          }
          return [
            ...acc,
            {
              date: curr!.date,
              value: acc[acc.length - 1].value + (curr!.value || 0),
              objective,
              unit,
            },
          ];
        },
        [] as Array<{
          date: number;
          value: number;
          unit: IndicatorUnit;
          objective: number;
        }>,
      );
  }
  if (ensureArrayPlotDataIsArrayAggregatedPlotData(plotData)) {
    return plotData
      .filter((data) => !!data)
      .reduce(
        (acc, curr) => {
          if (acc.length === 0) {
            return [
              {
                date: curr!.date,
                total: curr!.total || 0,
                unit,
              },
            ];
          }
          return [
            ...acc,
            {
              date: curr!.date,
              total: acc[acc.length - 1].total + (curr!.total || 0),
              unit,
            },
          ];
        },
        [] as Array<{ date: number; total: number; unit: IndicatorUnit }>,
      );
  }

  throw new Error('[Standard Computation] Wrong type for plot data');
};

export const percentComputation = (
  plotData: PlotData[],
  unit: IndicatorUnit,
) => {
  if (
    !ensureArrayPlotDataIsArrayRepartitionPlotData(plotData) &&
    !ensureArrayPlotDataIsArrayAggregatedPlotData(plotData)
  ) {
    throw new Error('[Percent Computation] wrong type for plotData');
  }

  return (plotData as (AggregatedPlotData | RepartitionPlotData)[]) // 'as' is needed there because typescript doesn't manage well union of arrays
    .filter((data) => !!data && data.values?.length)
    .map((data) => {
      const values: Record<string, number | string> = {
        date: data!.date,
        unit,
      };
      data.values
        .filter((plot) => plot.value !== undefined)
        .forEach((plot) => {
          if (plot.label !== TOTAL_KEY) {
            values[plot.label] = plot.value!;
          }
        });
      return values;
    });
};

export const percentStackedComputation = (
  plotData: PlotData[],
  unit: IndicatorUnit,
) => {
  if (!ensureArrayPlotDataIsArrayRepartitionPlotData(plotData)) {
    throw new Error('[Percent Staked Computation] wrong type for plotData');
  }

  const returnValue = plotData
    .filter((data) => !!data && data.values?.length)
    .reduce(
      (acc, curr) => {
        const values: Record<string, number | string | null> = {
          date: curr!.date,
          unit,
        };
        if (acc.length === 0) {
          curr.values
            .filter((plot) => plot.value !== undefined)
            .forEach((plot) => {
              if (plot.label !== TOTAL_KEY) {
                values[plot.label] = plot.value!;
              }
            });
          return [values];
        }

        Object.keys(acc[acc.length - 1]).forEach((key) => {
          if (key !== 'date' && key !== 'unit') {
            values[key] = acc[acc.length - 1][key];
          }
        });

        curr.values
          .filter((plot) => plot.value !== undefined)
          .forEach((plot) => {
            if (plot.label !== TOTAL_KEY) {
              const accValue = acc[acc.length - 1][plot.label] as number;
              if (isNotNullOrUndefined(accValue) && plot.value !== null) {
                values[plot.label] = accValue + plot.value!;
              } else if (isNotNullOrUndefined(accValue)) {
                values[plot.label] = accValue;
              } else if (plot.value !== null) {
                values[plot.label] = plot.value!;
              } else {
                values[plot.label] = null;
              }
            }
          });
        return [...acc, values];
      },
      [] as Array<Record<string, number | string | null>>,
    );
  return returnValue;
};

export const singlePercentComputation = (plotData: PlotData[]) => {
  if (
    !ensurePlotDataIsRepartitionPlotData(plotData[0]) &&
    !ensurePlotDataIsAggregatedPlotData(plotData[0])
  ) {
    throw new Error('[Single Percent Computation] wrong type for plotData');
  }
  const values = plotData[0].values
    .filter((plot) => plot.label !== TOTAL_KEY && plot.value)
    .map((plot) => {
      return { name: plot.label, value: plot.value };
    });
  return values;
};

export const aggregatedComputation = (
  plotData: PlotData[],
  unit: IndicatorUnit,
) => {
  if (!ensureArrayPlotDataIsArrayAggregatedPlotData(plotData)) {
    throw new Error('[Aggregated Computation] Wrong type for plotData');
  }
  return plotData
    .filter((data) => !!data)
    .map((data) => {
      const values: Record<string, number | string> = {
        date: data!.date,
        unit,
      };
      data.values.forEach((plot) => {
        values[plot.label] = plot.value || 0;
      });
      if (isNotNullOrUndefined(data.total)) {
        values.total = data.total;
      }
      return values;
    });
};

export const stackedAggregatedComputation = (
  plotData: PlotData[],
  unit: IndicatorUnit,
) => {
  if (!ensureArrayPlotDataIsArrayAggregatedPlotData(plotData)) {
    throw new Error('[Aggregated Computation] Wrong type for plotData');
  }
  return plotData
    .filter((data) => !!data)
    .reduce(
      (acc, curr) => {
        const values: Record<string, number | string> = {
          date: curr!.date,
          unit,
        };
        if (acc.length === 0) {
          curr.values.forEach((plot) => {
            values[plot.label] = plot.value || 0;
          });
          return [
            {
              date: curr.date,
              ...values,
              unit,
            },
          ];
        }
        Object.keys(acc[acc.length - 1])
          .filter((key) => key !== 'unit' && key !== 'date')
          .forEach((key) => {
            values[key] =
              (acc[acc.length - 1][key] as number) +
              (curr.values.find((value) => value.label === key)?.value || 0);
          });
        curr.values.forEach((plot) => {
          if (values[plot.label] === null || values[plot.label] === undefined) {
            values[plot.label] = plot.value || 0;
          }
        });
        return [
          ...acc,
          {
            date: curr.date,
            unit,
            ...values,
          },
        ];
      },
      [] as Array<Record<string, unknown>>,
    );
};

export const ComposedChart: React.FC<{
  data: any;
  type: IndicatorType;
  color: string;
}> = ({ data, type, color }) => {
  // debounce is used to avoid ResizeObserver Errors
  return (
    <ResponsiveContainer>
      <ReChartComposedChart
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          scale="time"
          type="number"
          domain={['dataMin', 'dataMax']}
          dataKey="date"
          tickFormatter={(v) =>
            type === IndicatorType.Photo
              ? formatDate(new Date(v), 'dd/MM/yyyy')
              : formatDate(new Date(v), 'MMMM yyyy')
          }
          allowDataOverflow
        />
        <YAxis />
        <Tooltip content={<CustomTooltip type={type} />} />
        <Area
          type="monotone"
          dataKey={
            getAllKeysInArray(data).includes(TOTAL_KEY) ? TOTAL_KEY : 'value'
          }
          name={
            getAllKeysInArray(data).includes(TOTAL_KEY)
              ? i18n.t('indicators.details.graph.total')
              : i18n.t('indicators.details.graph.value')
          }
          stroke={color}
          fill={color}
          isAnimationActive={false} // temporary workaround for label disappearing on refresh until the bug is fixed by recharts
        >
          <LabelList
            position="top"
            formatter={(value: number) => formatNumber(value)}
          />
        </Area>
        <Line
          type="monotone"
          dataKey="objective"
          stroke={variables.middleGrey}
          name={i18n.t('indicators.details.graph.objective')}
        />
      </ReChartComposedChart>
    </ResponsiveContainer>
  );
};

export const BooleanAreaChart: React.FC<{ data: any; color: string }> = ({
  data,
  color,
}) => {
  return (
    <ResponsiveContainer>
      <ReChartAreaChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid />
        <XAxis
          scale="time"
          type="number"
          domain={['auto', 'auto']}
          dataKey="date"
          tickFormatter={(v) => formatDate(new Date(v))}
        />
        <YAxis
          dataKey={
            getAllKeysInArray(data).includes(TOTAL_KEY) ? TOTAL_KEY : 'value'
          }
          ticks={[0, 1]}
          interval="preserveStartEnd"
          tickFormatter={(v) =>
            v ? i18n.t('common.yes') : i18n.t('common.no')
          }
        />
        <Tooltip
          labelFormatter={() => ''}
          formatter={(_value: any, _name: any, props: any) => {
            const { date, value } = props.payload;
            return [
              value ? i18n.t('common.yes') : i18n.t('common.no'),
              formatDate(new Date(date)),
            ];
          }}
        />
        <Area
          type="step"
          dataKey={
            getAllKeysInArray(data).includes(TOTAL_KEY) ? TOTAL_KEY : 'value'
          }
          stroke={color}
          fill={color}
          label={{ position: 'top' }}
        />
      </ReChartAreaChart>
    </ResponsiveContainer>
  );
};

export const PercentAreaChart: React.FC<{
  data: Record<string, unknown>[];
  type: IndicatorType;
  color: string;
}> = ({ data, type, color }) => {
  const colorPalette = generateColorShades(
    color,
    getAllKeysInArray(data).filter((key) => key !== 'date' && key !== 'unit')
      .length,
  );

  const colorAssociatedWithKeys = getAllKeysInArray(data)
    .filter((key) => key !== 'unit' && key !== 'date')
    .sort((a, b) => b.localeCompare(a)) // workaround to sync tooltip and areas order
    .map((key, index) => ({
      key,
      color: colorPalette[index],
    }));

  // transform null values into 0 for graph data, in order to have a better graph display.
  const graphData = data.map((valuesForDate) => {
    const filteredValues = { ...valuesForDate };
    Object.keys(valuesForDate).forEach((key) => {
      if (valuesForDate[key] === null) {
        filteredValues[key] = 0;
      }
    });
    return filteredValues;
  });

  return (
    <ResponsiveContainer>
      <ReChartAreaChart
        data={graphData}
        stackOffset="expand"
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          scale="time"
          type="number"
          domain={['auto', 'auto']}
          tickFormatter={(v) =>
            type === IndicatorType.Stock
              ? formatDate(new Date(v), 'MMMM yyyy')
              : formatDate(new Date(v), 'dd/MM/yyyy')
          }
        />
        <YAxis tickFormatter={toPercent} />
        <Tooltip
          content={
            <CustomPercentChartTooltip // we use a different tooltip for repartition indicators because, null values needs to be filtered
              type={type}
              data={data}
              colorPalette={colorAssociatedWithKeys}
            />
          }
        />
        {getAllKeysInArray(graphData)
          .filter((key) => key !== 'date' && key !== 'unit')
          .sort((a, b) => b.localeCompare(a)) // workaround to sync tooltip and areas order
          .map((key) => (
            <Area
              key={key}
              type="monotone"
              dataKey={key}
              stroke={
                colorAssociatedWithKeys.find((keyColor) => keyColor.key === key)
                  ?.color ?? ''
              }
              fill={
                colorAssociatedWithKeys.find((keyColor) => keyColor.key === key)
                  ?.color ?? ''
              }
              stackId="1"
            />
          ))}
      </ReChartAreaChart>
    </ResponsiveContainer>
  );
};

export const BarChart: React.FC<{
  data: Array<any>;
  color: string;
  dateMode?: IndicatorGraphMode;
}> = ({ data, color, dateMode }) => {
  const dateFormat =
    dateMode === IndicatorGraphMode.AllYears ? 'yyyy' : 'MMMM yyyy';

  return (
    <ResponsiveContainer>
      <ReChartBarChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(v) => formatDate(new Date(v), dateFormat)}
        />
        <YAxis
          dataKey={
            getAllKeysInArray(data).includes(TOTAL_KEY) ? TOTAL_KEY : 'value'
          }
        />
        <Tooltip
          content={
            <CustomTooltip type={IndicatorType.Stock} dateMode={dateMode} />
          }
        />
        <Bar
          dataKey={
            getAllKeysInArray(data).includes(TOTAL_KEY) ? TOTAL_KEY : 'value'
          }
          name={
            getAllKeysInArray(data).includes(TOTAL_KEY)
              ? i18n.t('indicators.details.graph.total')
              : i18n.t('indicators.details.graph.value')
          }
          fill={color}
          isAnimationActive={false} // temporary workaround for label disappearing on refresh until the bug is fixed by recharts
        >
          <LabelList
            position="top"
            formatter={(value: number) => formatNumber(value)}
          />
        </Bar>
      </ReChartBarChart>
    </ResponsiveContainer>
  );
};

export const StackedBarChart: React.FC<{
  data: Record<string, unknown>[];
  color: string;
  dateMode?: IndicatorGraphMode;
}> = ({ data, color, dateMode }) => {
  const dateFormat =
    dateMode === IndicatorGraphMode.AllYears ? 'yyyy' : 'MMMM yyyy';

  const colorPalette = generateColorShades(
    color,
    getAllKeysInArray(data).filter((key) => {
      return key !== 'date' && key !== 'unit' && key !== TOTAL_KEY;
    }).length,
  );

  return (
    <ResponsiveContainer>
      <ReChartBarChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(v) => formatDate(new Date(v), dateFormat)}
        />
        <YAxis />
        <Tooltip
          content={
            <CustomTooltip
              type={IndicatorType.Stock}
              hasTotal
              dateMode={dateMode}
            />
          }
        />
        {getAllKeysInArray(data)
          .filter((key) => {
            return key !== 'date' && key !== 'unit' && key !== TOTAL_KEY;
          })
          .sort((a, b) => b.localeCompare(a)) // workaround to sync tooltip and areas order
          .map((key, index) => (
            <Bar
              key={key}
              dataKey={key}
              fill={colorPalette[index]}
              stackId="1"
            />
          ))}
      </ReChartBarChart>
    </ResponsiveContainer>
  );
};

export const StackedAreaChart: React.FC<{
  data: Record<string, unknown>[];
  type: IndicatorType;
  color: string;
}> = ({ data, type, color }) => {
  const colorPalette = generateColorShades(
    color,
    getAllKeysInArray(data).filter(
      (key) => key !== 'date' && key !== 'unit' && key !== TOTAL_KEY,
    ).length,
  );

  return (
    <ResponsiveContainer>
      <ReChartAreaChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          scale="time"
          type="number"
          domain={['dataMin', 'dataMax']}
          dataKey="date"
          tickFormatter={(v) =>
            type === IndicatorType.Photo
              ? formatDate(new Date(v), 'dd/MM/yyyy')
              : formatDate(new Date(v), 'MMMM yyyy')
          }
        />
        <YAxis />
        <Tooltip content={<CustomTooltip type={type} hasTotal />} />
        {getAllKeysInArray(data)
          .filter(
            (key) => key !== 'date' && key !== 'unit' && key !== TOTAL_KEY,
          )
          .sort((a, b) => b.localeCompare(a)) // workaround to sync tooltip and areas order
          .map((key, index) => (
            <Area
              type="monotone"
              key={key}
              dataKey={key}
              stroke={colorPalette[index]}
              fill={colorPalette[index]}
              stackId="1"
            />
          ))}
      </ReChartAreaChart>
    </ResponsiveContainer>
  );
};

export const MultiLineChart: React.FC<{
  data: Record<string, unknown>[];
  type: IndicatorType;
  color: string;
}> = ({ data, type, color }) => {
  const colorPalette = generateColorShades(
    color,
    getAllKeysInArray(data).filter(
      (key) => key !== 'date' && key !== 'unit' && key !== TOTAL_KEY,
    ).length,
  );

  return (
    <ResponsiveContainer>
      <ReChartLineChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          scale="time"
          type="number"
          domain={['dataMin', 'dataMax']}
          dataKey="date"
          tickFormatter={(v) =>
            type === IndicatorType.Stock
              ? formatDate(new Date(v), 'MMMM yyyy')
              : formatDate(new Date(v), 'dd/MM/yyyy')
          }
        />
        <YAxis />
        <Tooltip content={<CustomTooltip type={type} />} />
        {getAllKeysInArray(data)
          .filter((key) => key !== 'date' && key !== 'unit')
          .map((key, index) => (
            <Line
              type="monotone"
              key={key}
              dataKey={key}
              isAnimationActive={false} // temporary workaround for label disappearing on refresh until the bug is fixed by recharts
              {...(key === TOTAL_KEY
                ? {
                    strokeWidth: 3,
                    stroke: colors.totalGraphColor,
                  }
                : {
                    strokeWidth: 2,
                    stroke: colorPalette[index],
                    strokeDasharray: 4,
                  })}
            >
              {/* 
                We removed the label to make the graph more readable, but resulting in a loss of information
                We keep the label to reverse the change at any time and avoid a waste of time
               */}
              {/* <LabelList
                position="top"
                formatter={(value: number) => formatNumber(value)}
              /> */}
            </Line>
          ))}
      </ReChartLineChart>
    </ResponsiveContainer>
  );
};

export const PercentPieChart: React.FC<{
  data: Record<string, unknown>[];
  type: IndicatorType;
  color: string;
  plotData: PlotData[];
  unit: IndicatorUnit;
}> = ({ data, color, type, plotData, unit }) => {
  let total = 0;
  if (ensurePlotDataIsRepartitionPlotData(plotData[0])) {
    total =
      plotData[0].values.find((value) => value.label === TOTAL_KEY)?.value || 0;
  }
  if (ensurePlotDataIsAggregatedPlotData(plotData[0])) {
    total = plotData[0].total || 0;
  }
  const { date } = plotData[0];
  const colorPalette = generateColorShades(color, data.length);
  const renderLabel = (entry: Record<string, number>) => {
    return getPercent(entry.value, total);
  };
  return (
    <Space direction="vertical" align="center">
      <ResponsiveContainer>
        <PieChart data={data}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill={color}
            label={renderLabel}
            isAnimationActive={false} // temporary workaround for label disappearing on refresh until the bug is fixed by recharts
          >
            {data.map((entry, index) => (
              <Cell key={entry.name as string} fill={colorPalette[index]} />
            ))}
          </Pie>
          <Tooltip
            content={
              <PercentPieChartCustomTooltip
                total={total}
                type={type}
                unit={unit}
              />
            }
          />
        </PieChart>
      </ResponsiveContainer>
      <Typography.Title level={5}>
        {type === IndicatorType.Stock
          ? formatDate(new Date(date), 'MMMM yyyy')
          : formatDate(new Date(date), 'dd MMMM yyyy')}
      </Typography.Title>
    </Space>
  );
};
