import React from 'react';
import { ReactDiv } from 'technical/type';
import Space from 'ui/space';
import Typography from 'ui/typography';

const InformationDetails: React.FC<
  { title: string; orientation?: 'vertical' | 'horizontal' } & ReactDiv
> = ({ title, children, orientation = 'vertical', ...rest }) => (
  <Space {...rest} direction={orientation} size="small" justify="start">
    <Typography.Title level={5}>{title}</Typography.Title>
    {children}
  </Space>
);

export default InformationDetails;
