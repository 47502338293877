import authService from 'business/user/services/authentication';
import Routes from 'business/router/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResetPasswordCard, {
  Values,
} from 'business/user/components/resetPasswordCard';
import ActionCard from 'business/user/components/actionCard';

import { Link } from 'react-router-dom';
import AuthLayout from '../../layout/auth';

function ForgotPasswordPage() {
  const [errorForgotPassword, setErrorForgotPassword] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();

  const handleSubmitClick = async (values: Values) => {
    try {
      setErrorForgotPassword('');
      await authService.requestPasswordReset(values.login);
      setShowSuccess(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrorForgotPassword(error.message);
      }
    }
  };

  if (showSuccess) {
    return (
      <AuthLayout>
        <ActionCard
          data-test-id="success-forgot-password"
          title={t('user.forgotPassword.success.title')}
          hint={t('user.forgotPassword.success.description')}
          btnText={t('user.common.goToHome')}
        />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <ResetPasswordCard
        submitButton={{
          label: t('user.forgotPassword.submit'),
          onClick: handleSubmitClick,
        }}
        errorResetPassword={errorForgotPassword}
        title={t('user.forgotPassword.title_question')}
        description={t('user.forgotPassword.description')}
        inputType="email"
        bottomHint={
          <Link to={Routes.SignIn}>
            {t('user.forgotPassword.backToSignIn')}
          </Link>
        }
      />
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
