import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import Button from 'ui/button';

interface CampaignMenuProps {
  handleDeleteCta: () => void;
}

const CampaignMenu: React.FC<CampaignMenuProps> = ({ handleDeleteCta }) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          type="link"
          data-test-id="delete-action"
          onClick={handleDeleteCta}
        >
          {t('campaigns.strategy.details.menu.delete')}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button data-test-id="action-menu-ellipsis" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default CampaignMenu;
