import {
  BarChartOutlined,
  QuestionCircleOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import Space from 'ui/space';
import { useTranslation } from 'react-i18next';
import Info from '../info';

interface InfoBarProps {
  numberOfAssociatedIndicators: number;
  numberOfQuestions: number;
  isExternal: boolean;
}

const InfoBar: React.FC<InfoBarProps> = ({
  numberOfAssociatedIndicators,
  numberOfQuestions,
  isExternal,
}) => {
  const { t } = useTranslation();
  return (
    <Space justify="start">
      <Info icon={<BarChartOutlined />}>
        <Typography.Text>
          {t('campaigns.catalog.card.associatedIndicators', {
            count: numberOfAssociatedIndicators,
          })}
        </Typography.Text>
      </Info>
      <Info icon={<QuestionCircleOutlined />}>
        <Typography.Text>
          {t('campaigns.catalog.card.numberOfQuestions', {
            count: numberOfQuestions,
          })}
        </Typography.Text>
      </Info>
      <Info icon={<ExportOutlined />}>
        <Typography.Text>
          {t('campaigns.catalog.card.external', {
            context: isExternal ? 'external' : 'internal',
          })}
        </Typography.Text>
      </Info>
    </Space>
  );
};

export default InfoBar;
