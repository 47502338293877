import React from 'react';
import ContextSwitchLayout from 'business/user/layout/contextSwitch';
import EntitySelectionCard from 'business/user/components/entitySelectionCard';
import { getDistinctEntityTypesForUsers } from 'business/user/services/user';
import { useConnectedUserContext } from 'business/AppBootstrapper';

const EntitySelectionPage: React.FC = () => {
  const { user } = useConnectedUserContext();
  const entityTypes = getDistinctEntityTypesForUsers(user.userPermissions);

  return (
    <ContextSwitchLayout>
      <EntitySelectionCard entityTypes={entityTypes} />
    </ContextSwitchLayout>
  );
};

export default EntitySelectionPage;
