import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { Tree } from 'antd';
import { MissingIndicator } from 'business/indicators/types';
import { DataNode } from 'antd/lib/tree';
import styles from './index.module.scss';

export enum AddMissingIndicatorsModalContext {
  Indicators = 'indicators',
  Campaigns = 'campaigns',
  Actions = 'actions',
}

interface AddMissingIndicatorsModalProps {
  isVisible: boolean;
  handleClose: () => void;
  missingIndicators: MissingIndicator[];
  alreadyInstanciatedIndicators?: MissingIndicator[];
  handleSubmit:
    | ((missingIndicatorsToAdd: MissingIndicator[]) => Promise<void>)
    | (() => Promise<void>);
  editable: boolean;
  context: AddMissingIndicatorsModalContext;
}

const recursivelyGenerateTree = (
  missingIndicators: MissingIndicator[],
  level = 0,
  prefix = '',
): DataNode[] =>
  missingIndicators.map((missingIndicator) => ({
    title:
      level === 0 ? (
        missingIndicator.label
      ) : (
        <Typography.Text className={styles.leaf} soften>
          {missingIndicator.label}
        </Typography.Text>
      ),
    key: `${prefix}${missingIndicator.id}`,
    checkable: level === 0,
    children: recursivelyGenerateTree(
      missingIndicator.linkedIndicators || [],
      level + 1,
      `${prefix}${missingIndicator.id}`,
    ),
  }));

const AddMissingIndicatorsModal: React.FC<AddMissingIndicatorsModalProps> = ({
  isVisible,
  missingIndicators,
  alreadyInstanciatedIndicators,
  handleClose,
  handleSubmit,
  editable,
  context,
}) => {
  const { t } = useTranslation();
  const [indicatorsToAdd, setIndicatorsToAdd] = useState<MissingIndicator[]>(
    [],
  );

  const optional = alreadyInstanciatedIndicators
    ? alreadyInstanciatedIndicators.length > 0
    : false;

  return (
    <Modal
      open={isVisible}
      onCancel={handleClose}
      title={t(`${context}.catalog.addMissingIndicators.title`, {
        count: missingIndicators.length,
        context: optional ? 'optional' : '',
      })}
      okText={t('common.validate')}
      cancelText={t('common.cancel')}
      okButtonProps={{
        id: 'add-missing-indicators-cta',
      }}
      onOk={() =>
        handleSubmit(
          indicatorsToAdd.concat(alreadyInstanciatedIndicators || []),
        )
      }
    >
      <Space direction="vertical" data-test-id="add-missing-indicators">
        <Typography.Paragraph>
          {t(`${context}.catalog.addMissingIndicators.description`, {
            count: missingIndicators.length,
            context: optional ? 'optional' : '',
          })}
        </Typography.Paragraph>
        <Tree
          checkable={editable}
          checkedKeys={indicatorsToAdd.map(
            (indicatorToAdd) => indicatorToAdd.id,
          )}
          autoExpandParent
          defaultExpandAll
          switcherIcon={<></>}
          onCheck={(_, info) => {
            const newIndicatorsToAdd = info.checkedNodes.reduce((acc, curr) => {
              const missingIndicator = missingIndicators.find(
                (mi) => mi.id === String(curr.key),
              );
              return missingIndicator ? [...acc, missingIndicator] : acc;
            }, [] as MissingIndicator[]);
            setIndicatorsToAdd(newIndicatorsToAdd);
          }}
          expandedKeys={missingIndicators.reduce(
            (acc, curr) => [
              ...acc,
              curr.id,
              ...(curr.linkedIndicators?.map(
                (linkedIndicator) => `${curr.id}${linkedIndicator.id}`,
              ) || []),
            ],
            [] as string[],
          )}
          selectable={false}
          treeData={recursivelyGenerateTree(missingIndicators)}
        />
      </Space>
    </Modal>
  );
};

export default AddMissingIndicatorsModal;
