import React from 'react';
import { List } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Space from 'ui/space';
import Button from 'ui/button';
import Typography from 'ui/typography';
import { UserPermission } from 'generated/graphql';
import apolloClient from 'technical/graphql/client';
import { useConnectedUserContext } from 'business/AppBootstrapper';
import styles from './index.module.scss';
import classNames from 'classnames';

interface AccountsListProps {
  userPermissions: UserPermission[];
}

const AccountsList: React.FC<AccountsListProps> = ({ userPermissions }) => {
  const { t } = useTranslation();
  const { choosePermission } = useConnectedUserContext();

  return (
    <List
      dataSource={userPermissions}
      renderItem={(userPermission) => (
        <List.Item data-test-id="account-selection-list-item">
          <Button
            type="text"
            data-test-id={`account-selection-button-${userPermission.role}`}
            onClick={() => {
              choosePermission(userPermission.entityId, userPermission.role);
              apolloClient.cache.reset();
            }}
            block
            loading={false}
          >
            <Space className={styles.account} align="center">
              <Typography.Text
                data-test-id="account-item-company"
                className={classNames(styles.companyName, styles.text)}
              >
                {userPermission.entity.name}
              </Typography.Text>
              <Typography.Text
                data-test-id="account-item-role"
                className={styles.text}
              >
                {t(`roles.${userPermission.role}`)}
              </Typography.Text>
              <RightOutlined className={styles.arrowIcon} />
            </Space>
          </Button>
        </List.Item>
      )}
    />
  );
};

export default AccountsList;
