import Routes from 'business/router/routes';
import React, { useEffect, useState } from 'react';
import logger from 'technical/logger';
import { i18n } from 'translations';
import history from 'technical/history/index';
import { mapEntityToLanguage } from 'technical/i18n/mapEntityToLanguage';
import {
  AppContext,
  AppContextAttributes,
  useAppContext,
} from 'business/AppBootstrapper';
import {
  addPermissionOnStorage,
  removePermissionFromStorage,
  getSelectedEntityTypeFromLocalStorage,
  getSelectedPermissionsFromLocalstorage,
  findPermission,
} from '../services/permission';
import {
  formatPermission,
  getDistinctEntityTypesForUsers,
} from '../services/user';
import {
  EntityTypes,
  FormattedUserPermission,
  UserWithPrivateInfosAndPermissions,
} from '../types/user';
import { SelectedPermissionContextProvider } from './selectedPermission';

interface ConnectedUserProps {
  user: UserWithPrivateInfosAndPermissions;
}

const useConnectedUser = ({
  user,
}: ConnectedUserProps): Pick<
  AppContextAttributes,
  | 'selectedPermission'
  | 'selectedEntityType'
  | 'deletePermission'
  | 'deleteEntityType'
  | 'choosePermission'
  | 'chooseEntityType'
> => {
  const [selectedEntityType, setSelectedEntityType] =
    useState<EntityTypes | null>(getSelectedEntityTypeFromLocalStorage(user));

  const [selectedPermission, setSelectedPermission] =
    useState<FormattedUserPermission | null>(
      selectedEntityType ? getSelectedPermissionsFromLocalstorage(user) : null,
    );

  const handleEntityTypeChange = (entityType: EntityTypes) => {
    setSelectedEntityType(entityType);
    i18n.changeLanguage(mapEntityToLanguage(entityType));
  };

  const choosePermission = (entityId: string, role: string) => {
    const permission = findPermission(entityId, role, user.userPermissions);
    if (permission) {
      setSelectedPermission(formatPermission(permission));
      addPermissionOnStorage(entityId, role);
      history.push(Routes.Home);
    } else {
      logger.error(
        `We did not found the permission you were looking for... EntityId : ${entityId}, Role : ${role}`,
      );
    }
  };

  const chooseEntityType = (entityType: EntityTypes) => {
    handleEntityTypeChange(entityType);
    localStorage.setItem('userEntityTypes', entityType);
    if (
      user.userPermissions.filter(
        (userPermission) => userPermission.entity.type === entityType,
      ).length === 1
    ) {
      choosePermission(
        user.userPermissions[0].entityId,
        user.userPermissions[0].role,
      );
    } else {
      history.push(Routes.AccountSelection);
    }
  };

  const deletePermission = () => {
    removePermissionFromStorage();
    setSelectedPermission(null);
    history.push(Routes.AccountSelection);
  };

  const deleteEntityType = () => {
    removePermissionFromStorage();
    localStorage.removeItem('userEntityTypes');
    setSelectedEntityType(null);
    setSelectedPermission(null);
    history.push(Routes.EntitySelection);
  };

  // user sync with providers
  useEffect(() => {
    const entityTypes = getDistinctEntityTypesForUsers(user.userPermissions);
    if (entityTypes.length === 1) {
      handleEntityTypeChange(entityTypes[0]);
      localStorage.setItem('userEntityTypes', entityTypes[0]);
    } else {
      const entityTypeFromStorage = getSelectedEntityTypeFromLocalStorage(user);
      if (entityTypeFromStorage) {
        handleEntityTypeChange(entityTypeFromStorage);
      }
    }
  }, [user]);

  useEffect(() => {
    const permissions = user.userPermissions.filter(
      (userPermission) => userPermission.entity.type === selectedEntityType,
    );
    if (permissions.length !== 1) {
      const permission = getSelectedPermissionsFromLocalstorage(user);

      if (permission && selectedEntityType) {
        setSelectedPermission(permission);
      }
    } else {
      const permission = permissions[0];
      setSelectedPermission(formatPermission(permission));
      addPermissionOnStorage(permission.entityId, permission.role);
    }
  }, [user, selectedEntityType]);

  return {
    selectedPermission,
    selectedEntityType,
    choosePermission,
    chooseEntityType,
    deletePermission,
    deleteEntityType,
  };
};

const ConnectedUserContextProvider: React.FC<
  { children: React.ReactElement } & ConnectedUserProps
> = ({ children, ...conntectedContextProps }) => {
  const appContext = useAppContext();
  const connectedContextValue = useConnectedUser(conntectedContextProps);
  const { selectedPermission, selectedEntityType } = connectedContextValue;

  return (
    <AppContext.Provider value={{ ...appContext, ...connectedContextValue }}>
      {selectedPermission && selectedEntityType ? (
        <SelectedPermissionContextProvider
          user={conntectedContextProps.user}
          selectedPermission={selectedPermission}
        >
          {children}
        </SelectedPermissionContextProvider>
      ) : (
        children
      )}
    </AppContext.Provider>
  );
};

export default ConnectedUserContextProvider;
