import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockFilled,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { CampaignStatus } from 'business/campaigns/services/types';
import InformationDetails from 'business/common/components/informationDetails';
import DeleteIndicatorModal from 'business/indicators/components/deleteIndicatorModal';
import DeleteIndicatorStatusModal from 'business/indicators/components/deleteIndicatorSuccessModal';
import IndicatorGoal from 'business/indicators/components/indicatorGoal';
import IndicatorTabs from 'business/indicators/components/indicatorTabs';
import ThemeAndStakesDetails from 'business/common/components/themeAndStakesDetails';
import TrendIndicator from 'business/indicators/components/trendIndicator';
import {
  getIndicatorStakes,
  isConnectedIndicator,
  isMandatory,
  isParameterIndicator,
} from 'business/indicators/services';
import {
  getUnitName,
  isUnitDiscreet,
} from 'business/indicators/services/units';
import {
  IndicatorGraphMode,
  IndicatorType,
  IndicatorUnit,
} from 'business/indicators/types';
import Routes, {
  actionCatalogForIndicatorRoute,
  indicatorStrategyDetailsRoute,
  parameterDetailsRoute,
} from 'business/router/routes';
import { getThemes } from 'business/stake/services';
import SubPageLayout from 'business/user/layout/subPage';
import { Permission } from 'business/user/types/user';
import {
  HiddenIndicatorCountDocument,
  IndicatorDocument,
  KeyValues,
  PlotData,
  useCloseCampaignMutation,
  useHideActionsMutation,
  useHideIndicatorMutation,
  useIndicatorQuery,
  useIndicatorValuesQuery,
  useShowIndicatorMutation,
  useSoftDeleteIndicatorMutation,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import logger from 'technical/logger';
import Button from 'ui/button';
import ButtonToggle from 'ui/buttonToggle';
import Loader from 'ui/loader';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';
import Restricted from 'business/user/components/Restricted';
import IndicatorTemplateConnectorMarker from 'business/indicators/components/indicatorTemplateConnectorMarker';
import IndicatorGraphTabs from 'business/indicators/components/indicatorGraphTabs';
import {
  IndicatorStrategyDetailsTabs,
  Mode,
  isIndicatorStrategyDetailsTabs,
} from 'business/indicators/services/type';
import styles from './index.module.scss';

interface IndicatorStrategyDetailsRouteParams {
  id: string;
  tab?: string;
}

const IndicatorStrategyDetailsPage: React.FC = () => {
  const { id, tab } = useParams<IndicatorStrategyDetailsRouteParams>();
  const [activeTabKey, setActiveTabKey] =
    useState<IndicatorStrategyDetailsTabs>(
      tab && isIndicatorStrategyDetailsTabs(tab) ? tab : 'description',
    );
  const history = useHistory();

  const { t } = useTranslation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();

  const [dateMode, setDateMode] = useState<IndicatorGraphMode>(
    IndicatorGraphMode.Yearly,
  );
  const [softDeleteIndicator] = useSoftDeleteIndicatorMutation();

  const refetchHiddentIndicatorCountQuery = {
    refetchQueries: [
      {
        query: HiddenIndicatorCountDocument,
        variables: { condition: { entityId: { _eq: entityId } } },
      },
    ],
  };
  const [showIndicator] = useShowIndicatorMutation(
    refetchHiddentIndicatorCountQuery,
  );
  const [hideIndicator] = useHideIndicatorMutation(
    refetchHiddentIndicatorCountQuery,
  );

  const [
    isDeleteIndicatorStatusModalVisible,
    setIsDeleteIndicatorStatusModalVisible,
  ] = useState(false);
  const [modalMessage, setModalMessage] = useState<JSX.Element>();
  const [isDeleteIndicatorModalVisible, setIsDeleteIndicatorModalVisible] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);

  const [actionsToHide, setActionsToHide] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [campaignsToHide, setCampaignsToHide] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  const [hideActions] = useHideActionsMutation();
  const [closeCampaign] = useCloseCampaignMutation();

  const { data, refetch } = useIndicatorQuery({
    variables: {
      id,
      entityId,
    },
  });

  const indicator = data?.indicator_by_pk;

  let distinctYears: number[] = [];
  let defaultSelectedUnit = '';
  let defaultSelectedYear = '';
  if (indicator) {
    distinctYears = indicator.distinctYears ? indicator.distinctYears : [];
    defaultSelectedUnit = indicator.indicatorTemplate.unit;
    defaultSelectedYear = Math.max(...distinctYears).toString();
  }

  const { data: indicatorComputedValues, refetch: refetchValues } =
    useIndicatorValuesQuery({
      variables: {
        entityId,
        indicatorTemplateId: indicator?.indicatorTemplate.id,
        unit: selectedUnit || defaultSelectedUnit,
        year: parseInt(selectedYear || defaultSelectedYear, 10),
        allYears: dateMode === IndicatorGraphMode.AllYears,
      },
      skip: !selectedUnit && defaultSelectedUnit === '',
    });

  useEffect(() => {
    setSelectedYear(
      distinctYears.length ? Math.max(...distinctYears).toString() : null,
    );
  }, [dateMode]);

  if (!indicator || !indicatorComputedValues) {
    return (
      <SubPageLayout goBack={Routes.IndicatorsStrategy}>
        <Loader />
      </SubPageLayout>
    );
  }

  const plotData = indicatorComputedValues.getPlotData as (PlotData | null)[];
  const keyValues = indicatorComputedValues.getKeyValues as KeyValues;

  const stakes = getIndicatorStakes(indicator);
  const themes = getThemes(stakes);

  if (isParameterIndicator(indicator)) {
    return <Redirect to={parameterDetailsRoute(id)} />;
  }

  const isIndicatorDeletable = indicator?.parentIndicatorLinks.length === 0;

  const displayWhyIndicatorIsNotDeletable = () => {
    Modal.info({
      title: t('indicators.details.delete.cannot.title'),
      content: (
        <Space direction="vertical">
          {indicator.parentIndicatorLinks.map((indicatorLink) => (
            <Typography.Paragraph key={indicatorLink.parentIndicator.id}>
              {indicatorLink.parentIndicator.indicatorTemplate.label}
            </Typography.Paragraph>
          ))}
        </Space>
      ),
      okText: t('indicators.details.delete.cannot.ok'),
      onOk: () => {},
      okButtonProps: {
        id: 'ok-i-cannot-delete-indicator',
      },
    });
  };

  const handleDeleteIndicator = async () => {
    const actions = indicator.actionIndicators.reduce(
      (acc, curr) => {
        if (curr.action.actionIndicators_aggregate.aggregate?.count === 1) {
          acc.push({ id: curr.action.id, name: curr.action.label });
        }
        return acc;
      },
      [] as { id: string; name: string }[],
    );
    const campaigns = indicator.campaignIndicators.reduce(
      (acc, curr) => {
        if (
          curr.campaign.campaignIndicators_aggregate.aggregate?.count === 1 &&
          curr.campaign.status !== CampaignStatus.Finished
        ) {
          acc.push({ id: curr.campaign.id, name: curr.campaign.name });
        }
        return acc;
      },
      [] as { id: string; name: string }[],
    );
    setActionsToHide(actions);
    setCampaignsToHide(campaigns);

    setIsDeleteIndicatorModalVisible(true);
  };

  const deleteIndicator = async () => {
    try {
      if (!isIndicatorDeletable) {
        displayWhyIndicatorIsNotDeletable();
        return;
      }
      await softDeleteIndicator({
        variables: {
          id,
        },
      });
      hideActions({
        variables: {
          ids: actionsToHide.map((action) => action.id),
        },
      });
      campaignsToHide.map((campaign) =>
        closeCampaign({
          variables: {
            campaignId: campaign.id,
            entityId,
          },
        }),
      );
      setIsError(false);
    } catch (err) {
      logger.error(err);
      setIsError(true);
      setModalMessage(t('indicators.details.statusModal.error.message'));
    }
    setIsDeleteIndicatorStatusModalVisible(true);
  };

  const handleVisibilityToggle = () => {
    const toggleVisibility =
      indicator.hiddenAt === null ? hideIndicator : showIndicator;
    toggleVisibility({
      variables: { id },
      refetchQueries: [
        {
          query: IndicatorDocument,
          variables: {
            id: indicator.id,
            entityId,
          },
        },
      ],
    });
  };

  const handleTabKey = (tabKey: string) => {
    if (isIndicatorStrategyDetailsTabs(tabKey)) {
      setActiveTabKey(tabKey);
      history.replace(indicatorStrategyDetailsRoute(id, tabKey));
    }
  };
  return (
    <SubPageLayout goBack={Routes.IndicatorsStrategy}>
      <Space direction="vertical" size="large">
        <Space justify="space-between">
          <Typography.Title data-test-id="indicator-name" level={3}>
            {indicator.indicatorTemplate.label}
            &nbsp;
            {isConnectedIndicator(indicator) ? (
              <IndicatorTemplateConnectorMarker />
            ) : null}
          </Typography.Title>
          <Restricted permission={Permission.ActionAdd}>
            <Space>
              <ButtonToggle
                checked={indicator.hiddenAt === null}
                data-test-id="toggle-visibility-cta"
                onClick={handleVisibilityToggle}
                checkedChildren={<EyeOutlined />}
                uncheckedChildren={<EyeInvisibleOutlined />}
                tooltip={{
                  placement: 'bottom',
                  checkedLabel: t('indicators.details.visibility.hide'),
                  uncheckedLabel: t('indicators.details.visibility.show'),
                }}
              />
              <Link to={actionCatalogForIndicatorRoute(indicator.id)}>
                <Button type="primary">
                  {t('indicators.details.addAction')}
                </Button>
              </Link>
              {isMandatory(indicator) ? (
                <Tooltip
                  title={t('indicators.details.delete.disabled')}
                  placement="bottomLeft"
                >
                  <LockFilled className={styles.mandatoryIcon} />
                </Tooltip>
              ) : (
                <Button
                  onClick={() => {
                    handleDeleteIndicator();
                  }}
                  data-test-id="indicator-delete-button"
                  type="default"
                >
                  {t('indicators.details.delete.label')}
                </Button>
              )}
            </Space>
          </Restricted>
        </Space>

        <IndicatorGraphTabs
          activeTab={dateMode}
          setActiveTab={(newDateMode) => setDateMode(newDateMode)}
          selectedYear={selectedYear ?? defaultSelectedYear}
          distinctYears={distinctYears}
          setSelectedYear={(y) => setSelectedYear(y)}
          selectedUnit={(selectedUnit ?? defaultSelectedUnit) as IndicatorUnit}
          setSelectedUnit={(u) => setSelectedUnit(u)}
          indicator={indicator}
          plotData={plotData}
          keyValues={keyValues}
        />
        <ThemeAndStakesDetails themes={themes} stakes={stakes} />
        {indicator.indicatorTemplate.type !== IndicatorType.Repartition && (
          <InformationDetails
            title={`${t('indicators.details.goal')} ${
              !isUnitDiscreet(defaultSelectedUnit)
                ? `(${getUnitName(defaultSelectedUnit)}) `
                : ''
            }:`}
          >
            {/** TODO : when adding unit selection feature, update props accordingly. We are using main unit by default */}
            <IndicatorGoal
              indicatorUnit={indicator.indicatorTemplate.unit}
              indicatorId={indicator.id}
              indicatorGoal={indicator.indicatorGoals.find(
                (indicatorGoal) =>
                  indicatorGoal.unit === indicator.indicatorTemplate.unit,
              )}
              defaultUnit={defaultSelectedUnit as IndicatorUnit}
              indicatorType={indicator.indicatorTemplate.type as IndicatorType}
              entityId={entityId}
            />
          </InformationDetails>
        )}
        <IndicatorTabs
          activeTabKey={activeTabKey}
          setActiveTabKey={handleTabKey}
          indicator={indicator}
          refetch={refetch}
          refetchValues={refetchValues}
          selectedUnit={(selectedUnit || defaultSelectedUnit) as IndicatorUnit}
          defaultUnit={defaultSelectedUnit as IndicatorUnit}
        />
      </Space>
      <DeleteIndicatorStatusModal
        isVisible={isDeleteIndicatorStatusModalVisible}
        redirect={Routes.IndicatorsStrategy}
        isError={isError}
        message={modalMessage}
      />
      <DeleteIndicatorModal
        isVisible={isDeleteIndicatorModalVisible}
        actionsToHide={actionsToHide}
        campaignsToHide={campaignsToHide}
        deleteIndicator={deleteIndicator}
        handleClose={() => setIsDeleteIndicatorModalVisible(false)}
      />
    </SubPageLayout>
  );
};

export default IndicatorStrategyDetailsPage;
