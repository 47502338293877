import { useGetForbiddenPermissionQuery } from 'generated/graphql';
import { useCallback } from 'react';
import {
  FormattedEntity,
  FormattedUserPermission,
  Permission,
} from '../types/user';
import { permissions } from './permissions';

interface IsAllowedToHookProps {
  selectedPermission: FormattedUserPermission;
  selectedEntity: FormattedEntity;
}

const useIsAllowedTo = ({
  selectedEntity: { entityId: selectedEntityId },
  selectedPermission: {
    role,
    providers: { entityProviderId },
    entityType,
    entityId: selectedPermissionEntityId,
  },
}: IsAllowedToHookProps) => {
  const isChildEntitySelected = selectedEntityId !== selectedPermissionEntityId;

  const { data } = useGetForbiddenPermissionQuery({
    variables: {
      condition: {
        role: { _eq: role },
        entityType: { _eq: entityType },
        entityProviderId: entityProviderId
          ? { _eq: entityProviderId }
          : { _is_null: true },
      },
    },
  });

  const blacklistedPermissions =
    data?.permissionBlacklist.map((e) => e.permission) ?? [];

  const isAllowedTo = useCallback(
    (permission: Permission) => {
      const perms = isChildEntitySelected
        ? permissions[role].onChildrenEntities ?? []
        : permissions[role].onOwnEntities;

      return perms
        .filter((p) => !blacklistedPermissions.includes(p))
        .includes(permission);
    },
    [isChildEntitySelected, blacklistedPermissions],
  );

  return { isAllowedTo };
};

export default useIsAllowedTo;
