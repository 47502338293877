import Typography from 'ui/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardWithSprinkles from 'ui/cardWithSprinkles';
import Space from 'ui/space';
import Button from 'ui/button';
import Routes, { actionStrategyCreateRoute } from 'business/router/routes';
import history from 'technical/history';

interface CustomActionCtaProps {
  from?: string;
}
const CustomActionCta: React.FC<CustomActionCtaProps> = ({ from }) => {
  const { t } = useTranslation();
  return (
    <CardWithSprinkles>
      <Space align="center" direction="vertical">
        <Typography.Text emphase>
          {t('actions.strategy.customAction.purpose')}
        </Typography.Text>
        <Button
          data-test-id="create-action-cta"
          type="primary"
          onClick={() =>
            history.replace(
              from
                ? actionStrategyCreateRoute(from)
                : Routes.ActionStrategyCreate,
            )
          }
        >
          {t('actions.strategy.customAction.cta')}
        </Button>
      </Space>
    </CardWithSprinkles>
  );
};

export default CustomActionCta;
