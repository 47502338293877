import { Permission, Roles } from '../types/user';

export const permissions: Record<
  Roles,
  { onOwnEntities: Permission[]; onChildrenEntities?: Permission[] }
> = {
  [Roles.CsrManager]: {
    onOwnEntities: [
      Permission.IndicatorsRead,
      Permission.CampaignRead,
      Permission.ActionRead,
      Permission.IndicatorsAdd,
      Permission.ActionAdd,
      Permission.CampaignAdd,
      Permission.CampaignUpdate,
      Permission.CampaignExport,
      Permission.IndicatorsUpdate,
      Permission.ActionUpdate,
      Permission.ActionDelete,
      Permission.IndicatorsDataRead,
      Permission.DocumentationRead,
      Permission.ParametersRead,
      Permission.SummaryRead,
      Permission.OnboardingAccess,
      Permission.EntityUpdate,
    ],
  },
  [Roles.Supervisor]: {
    onChildrenEntities: [
      Permission.IndicatorsRead,
      Permission.ActionRead,
      Permission.ChildrenStrategyRead,
      Permission.IndicatorsAggregate,
    ],
    onOwnEntities: [
      Permission.IndicatorsRead,
      Permission.IndicatorsAggregate,
      Permission.ActionRead,
      Permission.UsersPermissionsRead,
      Permission.UsersPermissionsUpdate,
      Permission.UsersInvite,
      Permission.ChildrenStrategyRead,
    ],
  },
  [Roles.CompanyDirector]: {
    onOwnEntities: [
      Permission.IndicatorsRead,
      Permission.CampaignRead,
      Permission.ActionRead,
      Permission.IndicatorsDataRead,
      Permission.DocumentationRead,
      Permission.ParametersRead,
      Permission.SummaryRead,
      Permission.OnboardingAccess,
      Permission.UsersPermissionsRead,
      Permission.UsersPermissionsUpdate,
      Permission.UsersInvite,
    ],
  },
  [Roles.Collaborator]: {
    onOwnEntities: [
      Permission.IndicatorsRead,
      Permission.ActionRead,
      Permission.IndicatorsDataRead,
      Permission.DocumentationRead,
      Permission.ParametersRead,
      Permission.SummaryRead,
      Permission.OnboardingAccess,
    ],
  },
  [Roles.User]: {
    onOwnEntities: [],
  },
};

export const isAllowedToSelectChild = (role: Roles) =>
  permissions[role].onOwnEntities.includes(Permission.ChildrenStrategyRead);
