import React from 'react';
import Table from 'ui/table';
import { CampaignTemplateFragment } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import InformationDetails from 'business/common/components/informationDetails';
import {
  campaignTemplateQuestionsColumn,
  campaignTemplateQuestionsData,
} from '../../services/table';

interface CampaignTemplateQuestionsProps {
  questions: CampaignTemplateFragment['questions'];
}

const CampaignTemplateQuestions: React.FC<CampaignTemplateQuestionsProps> = ({
  questions,
}) => {
  const { t } = useTranslation();

  return (
    <InformationDetails title={t('campaigns.catalog.details.questions.title')}>
      <Table
        columns={campaignTemplateQuestionsColumn()}
        dataSource={campaignTemplateQuestionsData(questions)}
      />
    </InformationDetails>
  );
};

export default CampaignTemplateQuestions;
