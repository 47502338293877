import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import TableFilters from 'business/common/components/tableFilters';
import { IndicatorTemplateRow } from 'business/indicators/types';
import { indicatorCatalogDetailsRoute } from 'business/router/routes';
import ConnectedLayout from 'business/user/layout/connected';
import {
  IndicatorTemplate_Order_By,
  Order_By,
  useIndicatorTemplatesListQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { handleSortChange } from 'technical/table/sort';
import Space from 'ui/space';
import Typography from 'ui/typography';
import Table from 'ui/table';
import EmptyState from 'ui/emptyState';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import {
  buildIndicatorTemplateOrderBy,
  indicatorTemplatesColumns,
  indicatorTemplatesDataSource,
  useCatalogIndicatorListFilters,
} from '../../../services/table';

const IndicatorsCatalogPage: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectedEntity: { entityTags },
  } = useLoggedInAppContext();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<
    IndicatorTemplate_Order_By | IndicatorTemplate_Order_By[]
  >({ createdAt: Order_By.Desc, id: Order_By.Asc });
  const history = useHistory();

  const { filtersValues, debouncedFiltersValues, setFiltersValues } =
    useCatalogIndicatorListFilters();

  const { data, previousData, loading } = useIndicatorTemplatesListQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      sort: orderBy,
      condition: {
        isVisible: { _eq: true },
        _or: [
          {
            label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
          {
            description: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
        ],
        indicatorTemplateStakes: {
          stake: {
            id: filtersValues.stakeId ? { _eq: filtersValues.stakeId } : {},
            themeId: filtersValues.themeId
              ? { _eq: filtersValues.themeId }
              : {},
          },
        },
        _not: {
          indicators: { deletedAt: { _is_null: true } },
        },
        indicatorTemplateTags: {
          tag: {
            label: {
              _in: entityTags,
            },
          },
        },
      },
    },
  });

  const indicatorTemplates =
    data?.indicatorTemplate || previousData?.indicatorTemplate || [];

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current ?? 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSortChange = (sorter: SorterResult<IndicatorTemplateRow>) => {
    setOrderBy(
      handleSortChange<IndicatorTemplateRow, IndicatorTemplate_Order_By>(
        sorter,
        buildIndicatorTemplateOrderBy,
      ),
    );
  };

  return (
    <ConnectedLayout>
      <Space direction="vertical" size="middle">
        <Typography.Title level={3}>
          {t('indicators.catalog.title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('indicators.catalog.info')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.indicators')}
        />
        {indicatorTemplates.length === 0 && !loading ? (
          <EmptyState
            title={t('indicators.catalog.empty.title')}
            description={t('indicators.catalog.empty.description')}
          />
        ) : (
          <Table
            loading={loading}
            data-test-id="indicator-templates-table"
            data-onboarding-id="catalog-indicators-table"
            dataSource={indicatorTemplatesDataSource(indicatorTemplates)}
            columns={indicatorTemplatesColumns()}
            pagination={{
              total: data?.indicatorTemplate_aggregate.aggregate?.count || 0,
              pageSize: resultsPerPage,
              current: filtersValues.page,
            }}
            onChange={(pagination, _filters, sorter) => {
              onPaginationChange(pagination);
              onSortChange(sorter as SorterResult<IndicatorTemplateRow>);
            }}
            onRow={(record) => ({
              onClick: () =>
                history.push(indicatorCatalogDetailsRoute(record.key)),
            })}
          />
        )}
      </Space>
    </ConnectedLayout>
  );
};

export default IndicatorsCatalogPage;
