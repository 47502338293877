import {
  getUnitComponent,
  getUnitName,
  getUnitValidationSchema,
  isUnitDiscreet,
} from 'business/indicators/services/units';
import { IndicatorType, IndicatorUnit } from 'business/indicators/types';
import { endOfMonth, isFuture } from 'date-fns';
import { useFormik } from 'formik';
import {
  IndicatorDetailsFragment,
  IndicatorDocument,
  IndicatorValuesDocument,
  IndicatorValue_Constraint,
  ParameterDetailsFragment,
  useInsertIndicatorValueMutation,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui/modal';
import Space from 'ui/space';
import * as yup from 'yup';
import Form from 'ui/form';
import DatePicker from 'ui/datePicker';
import { formikStatus, formikErrorMessage } from 'technical/form';
import { ApolloError } from '@apollo/client';
import { formatDate } from 'technical/date/formatter';
import { useLoggedInAppContext } from 'business/AppBootstrapper';

interface AddIndicatorValueModalProps {
  isVisible: boolean;
  handleClose: () => void;
  indicator: IndicatorDetailsFragment | ParameterDetailsFragment;
  selectedUnit: IndicatorUnit;
  defaultUnit: IndicatorUnit;
}

const AddIndicatorValueModal: React.FC<AddIndicatorValueModalProps> = ({
  isVisible,
  handleClose,
  indicator,
  selectedUnit,
  defaultUnit,
}) => {
  const { t } = useTranslation();
  const [insertIndicatorValue] = useInsertIndicatorValueMutation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const formik = useFormik<{
    value: number | undefined;
    date: Date | undefined;
  }>({
    initialValues: { value: undefined, date: undefined },
    validateOnMount: true,
    validationSchema: yup
      .object({
        value: getUnitValidationSchema(indicator.indicatorTemplate.unit),
        date: yup.date().required(t('errors.required')),
      })
      .required(),
    onSubmit: async (values) => {
      try {
        await insertIndicatorValue({
          refetchQueries: [
            {
              query: IndicatorValuesDocument,
              variables: {
                entityId,
                indicatorTemplateId: indicator?.indicatorTemplate.id,
                unit: selectedUnit,
              },
            },
            {
              query: IndicatorDocument,
              variables: {
                id: indicator.id,
                entityId,
              },
            },
          ],
          variables: {
            indicatorValue: {
              indicatorId: indicator.id,
              startDate: formatDate(values.date!, 'y-MM-dd'),
              endDate:
                indicator.indicatorTemplate.type === IndicatorType.Stock
                  ? formatDate(endOfMonth(values.date!), 'y-MM-dd')
                  : formatDate(values.date!, 'y-MM-dd'),
              rawValue: values.value,
            },
            onConflict: {
              constraint: IndicatorValue_Constraint.IndicatorValuePkey,
              update_columns: [],
            },
          },
        });
        formik.resetForm();
        handleClose();
      } catch (err) {
        if (
          err instanceof ApolloError &&
          err.graphQLErrors.some(
            (gqlErr) => gqlErr.extensions?.code === 'constraint-violation',
          )
        ) {
          formik.setFieldError(
            'date',
            t('errors.indicatorValues.dateAlreadyExists'),
          );
        }
      }
    },
  });

  const Component = getUnitComponent(indicator.indicatorTemplate.unit);

  return (
    <Modal
      open={isVisible}
      onCancel={handleClose}
      title={t('indicators.strategy.indicatorValues.add.title')}
      okText={t('common.validate')}
      cancelText={t('common.cancel')}
      okButtonProps={{
        disabled: !formik.isValid,
        id: 'add-indicator-value-ok',
      }}
      cancelButtonProps={{
        id: 'add-indicator-value-cancel',
      }}
      onOk={() => formik.handleSubmit()}
    >
      <Space direction="vertical" size="small">
        <Form.Item
          data-test-id="indicator-value-item-date"
          required
          label={t('indicators.strategy.indicatorValues.date.label')}
          validateStatus={formikStatus(formik, 'date')}
          help={formikErrorMessage(formik, 'date')}
        >
          <DatePicker
            name="date"
            data-test-id="indicator-value-input-date"
            format={
              indicator.indicatorTemplate.type === IndicatorType.Stock
                ? 'MM/y'
                : 'dd/MM/y'
            }
            picker={
              indicator.indicatorTemplate.type === IndicatorType.Stock
                ? 'month'
                : 'date'
            }
            // disabledDate={(date) => isFuture(date)} (disabled temporary)
            onChange={(date) => {
              // When selecting a month, the date contains current day, we must to take first day of the month.
              let dateToStore;
              if (date) {
                dateToStore =
                  indicator.indicatorTemplate.type === IndicatorType.Stock
                    ? new Date(formatDate(date, 'y-MM'))
                    : date;
              }
              formik.setValues({
                ...formik.values,
                date: dateToStore,
              });
            }}
            onBlur={formik.handleBlur}
            value={formik.values.date}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          required
          label={
            t('indicators.strategy.indicatorValues.value.label') +
            (!isUnitDiscreet(defaultUnit)
              ? ` (${getUnitName(defaultUnit)})`
              : '')
          }
          validateStatus={formikStatus(formik, 'value')}
          help={formikErrorMessage(formik, 'value')}
        >
          <Component
            data-test-id="add-indicator-value-input-value"
            formik={formik}
            size="large"
          />
        </Form.Item>
      </Space>
    </Modal>
  );
};

export default AddIndicatorValueModal;
