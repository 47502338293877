import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export function formatDate(
  date: string | number | Date,
  dateFormat = 'dd/MM/yyyy',
) {
  return format(date instanceof Date ? date : new Date(date), dateFormat, {
    locale: fr,
  });
}
