import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  ExportCampaignDocument,
  ExportCampaignQuery,
  ExportCampaignQueryVariables,
  useCloseCampaignMutation,
  useGetCampaignQuery,
  useHideCampaignsMutation,
} from 'generated/graphql';
import SubPageLayout from 'business/user/layout/subPage';
import Space from 'ui/space';
import Routes, { campaignCatalogDetailsRoute } from 'business/router/routes';
import Loader from 'ui/loader';
import ModalAddCampaignSuccess from 'business/campaigns/components/modalAddCampaignSuccess';
import { Button, Popconfirm, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import CampaignStatusTag from 'business/campaigns/components/campaignStatusTag';
import { CampaignStatus } from 'business/campaigns/services/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import { Link } from 'react-router-dom';
import CampaignLinkedIndicators from 'business/campaigns/components/campaignLinkedIndicators';
import Restricted from 'business/user/components/Restricted';
import CampaignMenu from 'business/campaigns/components/campaignMenu';
import Typography from 'ui/typography';
import Modal from 'ui/modal';
import { DownloadOutlined } from '@ant-design/icons';
import apolloClient from 'technical/graphql/client';
import { downloadCsv } from 'technical/download';
import slug from 'slug';
import { downloadXlsxFileFromBinaryString } from 'technical/file';
import CampaignGlobalStats from '../../../components/campaignGlobalStats';
import CampaignResponse from '../../../components/campaignResponse';
import CampaignName from '../../../components/campaignName';
import styles from './index.module.scss';

interface CampaignStrategyDetailsPageParams {
  id: string;
}

const CampaignStrategyDetailsPage: React.FC = () => {
  const { id } = useParams<CampaignStrategyDetailsPageParams>();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const { t } = useTranslation();
  const history = useHistory();
  const [hideCampaign] = useHideCampaignsMutation();

  const { data, refetch } = useGetCampaignQuery({
    variables: {
      campaignId: id,
    },
  });

  const campaign = data?.campaign_by_pk;

  const [closeCampaign, { loading }] = useCloseCampaignMutation({
    variables: {
      campaignId: campaign?.id,
      entityId,
    },
  });

  const handleCloseCampaign = async () => {
    await closeCampaign();
    refetch();
  };

  const [
    isModalAddCampaignSuccessVisible,
    setIsModalAddCampaignSuccessVisible,
  ] = useState(false);

  if (!campaign) {
    return (
      <SubPageLayout goBack={Routes.CampaignsStrategy}>
        <Loader />
      </SubPageLayout>
    );
  }

  const removeCampaign = async () => {
    // Delete campaign
    await hideCampaign({
      variables: {
        ids: [campaign.id],
      },
    });
    // Redirect to my campaigns
    history.push(Routes.CampaignsStrategy);
  };

  const handleDeleteCta = () => {
    Modal.confirm({
      title: t('campaigns.strategy.delete.confirmTitle'),
      content: (
        <Typography.Paragraph>
          {t('campaigns.strategy.delete.confirmContent')}
        </Typography.Paragraph>
      ),
      okText: t('common.delete'),
      cancelText: t('common.cancel'),
      onOk: removeCampaign,
      okButtonProps: {
        id: 'delete-action',
      },
    });
  };

  const handleExportCampaign = async () => {
    setIsExportLoading(true);
    const { data: exportCampaignData, error } = await apolloClient.query<
      ExportCampaignQuery,
      ExportCampaignQueryVariables
    >({
      query: ExportCampaignDocument,
      variables: {
        id: campaign.id,
      },
    });

    const xlsxCampaignData = exportCampaignData.exportCampaign?.data;

    if (!error && xlsxCampaignData) {
      downloadXlsxFileFromBinaryString(
        `${slug(campaign.name)}.xlsx`,
        xlsxCampaignData,
      );
    }
    setIsExportLoading(false);
  };

  return (
    <SubPageLayout goBack={Routes.CampaignsStrategy}>
      {isExportLoading ? <Loader /> : null}
      <Space direction="vertical">
        <Space justify="space-between">
          <Space align="center" className={styles.flexGrow}>
            <CampaignName campaign={campaign} />
            <CampaignStatusTag status={campaign.status} />
          </Space>
          {campaign.status === CampaignStatus.Finished ? (
            <Restricted permission={Permission.CampaignExport}>
              <Button
                onClick={handleExportCampaign}
                data-test-id="campaign-export"
                type="primary"
                icon={<DownloadOutlined />}
              >
                {t('campaigns.strategy.exportCta')}
              </Button>
            </Restricted>
          ) : null}
          <Restricted permission={Permission.CampaignUpdate}>
            <Space>
              {campaign.status === CampaignStatus.InProgress ? (
                <Popconfirm
                  title={t('campaigns.strategy.card.confirmMarkAsDone')}
                  onConfirm={() => {
                    handleCloseCampaign();
                  }}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  okButtonProps={{ id: 'campaign-finish-popconfirm' }}
                >
                  <Button
                    data-test-id="campaign-finish-acquisition"
                    type="default"
                  >
                    {t('campaigns.strategy.card.markAsDone')}
                  </Button>
                </Popconfirm>
              ) : (
                <Link
                  to={campaignCatalogDetailsRoute(campaign.campaignTemplateId)}
                >
                  <Button type="default">
                    {t('campaigns.strategy.card.restart')}
                  </Button>
                </Link>
              )}

              <Button
                type="primary"
                onClick={() => setIsModalAddCampaignSuccessVisible(true)}
              >
                {t('common.share')}
              </Button>
              {campaign.status === CampaignStatus.Finished && (
                <CampaignMenu handleDeleteCta={handleDeleteCta} />
              )}
            </Space>
          </Restricted>
        </Space>
        <Spin spinning={loading}>
          <Space direction="vertical">
            <CampaignGlobalStats insights={campaign.insights} />
            <CampaignLinkedIndicators
              campaignIndicators={campaign.campaignIndicators}
            />
            <CampaignResponse
              responses={campaign.responses}
              questions={campaign.questions}
            />
          </Space>
        </Spin>
      </Space>
      <ModalAddCampaignSuccess
        isVisible={isModalAddCampaignSuccessVisible}
        campaignUrl={campaign.url}
        handleCancel={() => {
          setIsModalAddCampaignSuccessVisible(false);
        }}
        handleClose={() => {
          setIsModalAddCampaignSuccessVisible(false);
        }}
      />
    </SubPageLayout>
  );
};

export default CampaignStrategyDetailsPage;
