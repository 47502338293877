import { IndicatorUnit } from 'business/indicators/types';
import {
  IndicatorTemplateFragment,
  IndicatorTemplateDetailsFragment,
  IndicatorFragment,
  IndicatorTemplateQuery,
  StakeFragment,
  IndicatorDetailsFragment,
  Indicator_Constraint,
  Indicator_Update_Column,
  ActionIndicator_Insert_Input,
  Indicator,
  IndicatorLink_Constraint,
  IndicatorLink_Update_Column,
  PlotData,
  ExportIndicatorsXlsxQuery,
  ExportIndicatorsXlsxQueryVariables,
  ExportIndicatorsXlsxDocument,
} from 'generated/graphql';
import chroma from 'chroma-js';
import logger from 'technical/logger';
import { isNotNullOrUndefined } from 'technical/type';
import { downloadXlsxFileFromBinaryString } from 'technical/file';
import client from 'technical/graphql/client';
import {
  AssociatedActionTemplate,
  ensureKeyValuesIsSimplePlotData,
  IndicatorType,
  MissingIndicator,
} from '../types';

export const getIndicatorTemplateStakes = (
  indicatorTemplate:
    | IndicatorTemplateFragment
    | IndicatorTemplateDetailsFragment,
): StakeFragment[] => {
  return [
    ...new Map(
      indicatorTemplate.indicatorTemplateStakes.map(({ stake }) => [
        stake.id,
        stake,
      ]),
    ).values(),
  ];
};

export const getIndicatorStakes = (
  indicator: IndicatorDetailsFragment,
): StakeFragment[] => {
  return [
    ...new Map(
      indicator.indicatorTemplate.indicatorTemplateStakes.map(({ stake }) => [
        stake.id,
        stake,
      ]),
    ).values(),
  ];
};

export const getAssociatedActionTemplates = (
  indicatorTemplate: IndicatorTemplateDetailsFragment,
): AssociatedActionTemplate[] => {
  return indicatorTemplate.actionTemplateIndicatorTemplates.map(
    (actionTemplateIndicatorTemplate) =>
      actionTemplateIndicatorTemplate.actionTemplate,
  );
};

export const getNbAssociatedActionTemplates = (
  indicatorTemplate: IndicatorTemplateDetailsFragment,
) => {
  return getAssociatedActionTemplates(indicatorTemplate).length;
};

export const computeRelativePercentage = (
  currentValue?: number | null,
  previousValue?: number | null,
) => {
  if (
    isNotNullOrUndefined(currentValue) &&
    isNotNullOrUndefined(previousValue) &&
    previousValue !== 0
  ) {
    return Math.round(((currentValue - previousValue) / previousValue) * 100);
  }
  return null;
};

export const computeKeyValuesRelativePercentage = (
  keyValues: IndicatorFragment['keyValues'],
) => {
  if (
    !keyValues ||
    !keyValues.currentYear ||
    !keyValues.lastYear ||
    !ensureKeyValuesIsSimplePlotData(keyValues)
  ) {
    return null;
  }
  return computeRelativePercentage(
    keyValues.currentYear.value ?? 0,
    keyValues.lastYear.value ?? 0,
  );
};

export const recursivelyGenerateIndicator = (
  missingIndicators: MissingIndicator[],
  entityId: string,
  actionId?: string,
): ActionIndicator_Insert_Input[] => {
  return missingIndicators.map((missingIndicator) => ({
    actionId,
    indicator: {
      data: {
        indicatorTemplateId: missingIndicator.id,
        entityId,
        indicatorLinks: {
          data: recursivelyGenerateIndicator(
            missingIndicator.linkedIndicators ?? [],
            entityId,
          ),
          on_conflict: {
            constraint: IndicatorLink_Constraint.IndicatorLinkPkey,
            update_columns: [IndicatorLink_Update_Column.UpdatedAt],
          },
        },
      },
      on_conflict: {
        constraint:
          Indicator_Constraint.IndicatorCompanyIdIndicatorTemplateIdKey,
        update_columns: [
          Indicator_Update_Column.DeletedAt,
          Indicator_Update_Column.UpdatedAt,
        ],
      },
    },
  }));
};

export const getLinkedIndicatorTemplatesNotInMyStrategy = (
  indicatorTemplate: IndicatorTemplateQuery['indicatorTemplate_by_pk'],
): MissingIndicator[] => {
  return (
    indicatorTemplate?.indicatorTemplateLinks
      .filter(
        (indicatorTemplateLink) =>
          indicatorTemplateLink.indicatorTemplate.indicators.length === 0,
      )
      .map((indicatorTemplateLink) => ({
        id: indicatorTemplateLink.indicatorTemplate.id,
        label: indicatorTemplateLink.indicatorTemplate.label,
        linkedIndicators:
          indicatorTemplateLink.indicatorTemplate.indicatorTemplateLinks.map(
            (indicatorTemplateLinkedLink) => ({
              id: indicatorTemplateLinkedLink.indicatorTemplate.id,
              label: indicatorTemplateLinkedLink.indicatorTemplate.label,
            }),
          ),
      })) || []
  );
};

export const whichTypeIsThisRepartition = (
  indicator: Indicator,
): IndicatorType.Stock | IndicatorType.Photo => {
  if (!indicator.indicatorLinks || indicator.indicatorLinks.length === 0) {
    throw new Error(
      `[GET AGGREGATED PLOT DATA] ERROR FOR INDICATOR REPARTITION ${indicator.id}, no indicator links were provided`,
    );
  }
  const temporaryType =
    indicator.indicatorLinks[0].indicator.indicatorTemplate.type;

  if (
    !indicator.indicatorLinks.every(
      (indicatorLink) =>
        indicatorLink.indicator.indicatorTemplate.type === temporaryType,
    )
  ) {
    throw new Error(
      `[GET AGGREGATED PLOT DATA] ERROR FOR INDICATOR REPARTITION ${indicator.id}, not every indicator linked are the same type`,
    );
  }
  if (
    temporaryType === IndicatorType.Stock ||
    temporaryType === IndicatorType.Photo
  ) {
    return temporaryType;
  }

  throw new Error(
    `[GET AGGREGATED PLOT DATA] ERROR FOR INDICATOR REPARTITION ${indicator.id}, INDICATOR TYPE : ${temporaryType} IS NOT AUTHORIZED`,
  );
};

export const whichUnitIsThisRepartition = (
  indicator: Indicator,
): IndicatorUnit => {
  if (!indicator.indicatorLinks || indicator.indicatorLinks.length === 0) {
    throw new Error(
      `[GET AGGREGATED PLOT DATA] ERROR FOR INDICATOR REPARTITION ${indicator.id}, no indicator links were provided`,
    );
  }
  const temporaryUnit =
    indicator.indicatorLinks[0].indicator.indicatorTemplate.unit;

  if (
    !indicator.indicatorLinks.every(
      (indicatorLink) =>
        indicatorLink.indicator.indicatorTemplate.unit === temporaryUnit,
    )
  ) {
    logger.warn(
      `[GET AGGREGATED PLOT DATA] WARNING FOR INDICATOR REPARTITION ${indicator.id}, not every indicator linked have the same unit`,
    );
    // The only significant case when we have reparititon with different unit is that it's a repartition with FE
    return IndicatorUnit.KgEqCO2;
  }

  return temporaryUnit as IndicatorUnit;
};

export const generateColorShades = (color: string, N: number, delta = 4) => {
  const colorRGB = chroma(color).rgb();
  const max = Math.max(
    Math.max(colorRGB[0], Math.max(colorRGB[1], colorRGB[2])),
    1,
  );
  const step = 255 / (max * (N + delta));

  const shadeArray = [...Array(N)].map((_value, i) => {
    return chroma([
      colorRGB[0] * (i + delta) * step,
      colorRGB[1] * (i + delta) * step,
      colorRGB[2] * (i + delta) * step,
    ]).hex();
  });

  return shadeArray;
};

export const notEmptyPlotData = (value: PlotData | null): value is PlotData => {
  return value !== null;
};

export const isComputedIndicator = (indicator: IndicatorDetailsFragment) => {
  return !!indicator.indicatorLinks.filter(
    (indicatorLink) =>
      indicatorLink.indicator.indicatorTemplate.indicatorTemplateStakes.length,
  ).length;
};

export const isParameterIndicator = (indicator: IndicatorDetailsFragment) => {
  return indicator.indicatorTemplate.indicatorTemplateStakes.length === 0;
};

export const isMandatory = (indicator: {
  indicatorTemplate: {
    indicatorTemplateTags: { tag: { isMandatory: boolean } }[];
  };
}) => {
  return indicator.indicatorTemplate.indicatorTemplateTags.some(
    (indicatorTemplateTag) => indicatorTemplateTag.tag.isMandatory,
  );
};

export const isConnectedIndicator = (indicator: {
  indicatorTemplate: {
    connectorIndicatorTemplates: Array<{
      connectorId: string;
    }>;
  };
}) => {
  return indicator.indicatorTemplate.connectorIndicatorTemplates.length > 0;
};

export const exportIndicatorsXlsx = async (
  entityId: string,
  entityName: string,
) => {
  const { data } = await client.query<
    ExportIndicatorsXlsxQuery,
    ExportIndicatorsXlsxQueryVariables
  >({
    query: ExportIndicatorsXlsxDocument,
    variables: { entityId },
  });

  return downloadXlsxFileFromBinaryString(
    `${entityName}-indicators.xlsx`,
    data.exportIndicators?.data,
  );
};
