import classnames from 'classnames';
import React from 'react';
import Card from 'ui/card';
import Sprinkle from 'ui/sprinkle';
import styles from './index.module.scss';

interface CampaignBaseCardProps {
  onClick: () => void;
  'data-test-id': string;
  color: string;
}

const CampaignBaseCard: React.FC<CampaignBaseCardProps> = ({
  color,
  children,
  ...rest
}) => {
  const themeBorderWidth = 3; // pixels

  return (
    <Card
      className={styles.campaignCard}
      style={{
        borderLeft: `${themeBorderWidth}px solid ${color}`,
      }}
      {...rest}
    >
      {children}
      <div className={styles.sprinkleContainer}>
        <Sprinkle color="blue" className={styles.topRight} />
        <Sprinkle color="pink" className={styles.bottomRight} />
        <Sprinkle color="green" className={styles.topLeft} />
      </div>
    </Card>
  );
};

export default CampaignBaseCard;
