import React from 'react';
import { Typography } from 'antd';
import { getUnitDisplay } from 'business/indicators/services/units';
import { IndicatorGraphMode, IndicatorType } from 'business/indicators/types';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { formatDate } from 'technical/date/formatter';
import Card from 'ui/card';
import styles from './index.module.scss';
import { getDateFormat, getPercent } from '../helpers';

type CustomTooltipProps = TooltipProps<number, string> & {
  type: IndicatorType;
  showPercentages?: boolean;
  hasTotal?: boolean;
  dateMode?: IndicatorGraphMode;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  type,
  showPercentages,
  hasTotal = false,
  dateMode,
}: CustomTooltipProps) => {
  if (!active || !payload || !payload.length) {
    return null;
  }
  const data = payload
    .map((e) => ({ ...e, value: e.value ?? 0, name: e.name ?? '' }))
    .sort((a, b) => a.name.localeCompare(b.name)); // sorting of the data to be in the same order than the graph

  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const { unit } = payload[0].payload;

  const dateFormat = getDateFormat(type, dateMode);

  return (
    <Card className={styles.tooltipCard}>
      <Typography.Title level={5}>
        {formatDate(new Date(label), dateFormat)}
      </Typography.Title>
      <table>
        <tbody>
          {data.map((e) => (
            <tr style={{ color: e.color }} key={e.dataKey}>
              <td>{e.name}</td>
              <td>{getUnitDisplay(unit)(e.value)}</td>
              {showPercentages && <td>{getPercent(e.value, total)}</td>}
            </tr>
          ))}
          {data.length > 1 && hasTotal && (
            <tr className={styles.totalRow}>
              <td>Total</td>
              <td>{getUnitDisplay(unit)(total)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
};

type CustomPercentChartTooltipProps = TooltipProps<number, string> & {
  type: IndicatorType;
  data: any[];
  colorPalette: { key: string; color: string }[];
};
export const CustomPercentChartTooltip = ({
  active,
  data,
  label,
  type,
  colorPalette,
}: CustomPercentChartTooltipProps) => {
  const dataForDate = data.find(
    (valuesForDate) => valuesForDate.date === label,
  );
  if (!active || dataForDate === undefined || dataForDate === null) {
    return null;
  }
  const { unit } = dataForDate;
  const total = Object.keys(dataForDate)
    .filter((key) => key !== 'date' && key !== 'unit')
    .reduce((acc, curr) => acc + dataForDate[curr], 0);

  return (
    <Card className={styles.tooltipCard}>
      <Typography.Title level={5}>
        {type === IndicatorType.Stock
          ? formatDate(new Date(label), 'MMMM yyyy')
          : formatDate(new Date(label), 'dd MMMM yyyy')}
      </Typography.Title>
      <table>
        <tbody>
          {Object.keys(dataForDate)
            .sort((a, b) => a.localeCompare(b)) // workaround to sync tooltip and areas order
            .filter(
              (key) =>
                key !== 'date' && key !== 'unit' && dataForDate[key] !== null,
            )
            .map((key) => (
              <tr
                style={{
                  color:
                    colorPalette.find((color) => color.key === key)?.color ??
                    '',
                }}
                key={key}
              >
                {type === IndicatorType.Select ? (
                  <>
                    <td>{getUnitDisplay(unit)(parseInt(key, 10))}</td>
                    <td>{dataForDate[key]}</td>
                  </>
                ) : (
                  <>
                    <td>{key}</td>
                    <td>{getUnitDisplay(unit)(dataForDate[key])}</td>
                  </>
                )}

                <td>{getPercent(dataForDate[key], total)}</td>
              </tr>
            ))}
          {data.length > 1 && (
            <tr className={styles.totalRow}>
              <td>Total</td>
              <td>
                {type === IndicatorType.Select
                  ? total
                  : getUnitDisplay(unit)(total)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
};

export default CustomTooltip;
