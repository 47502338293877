import React from 'react';
import {
  CheckboxOptionType,
  Radio as AntdRadio,
  RadioChangeEvent,
  Space as AntdSpace,
} from 'antd';
import Space from 'ui/space';
import styles from './index.module.scss';

interface RadioProps {
  direction?: 'vertical' | 'horizontal';
  options?: (CheckboxOptionType | string)[];
  buttonStyle?: 'outline' | 'solid';
  optionType?: 'default' | 'button';
  onChange?: (e: RadioChangeEvent) => void;
  value: string;
}

const isCheckBoxOptionType = (
  options: CheckboxOptionType | string,
): options is CheckboxOptionType => {
  return (options as CheckboxOptionType).value !== undefined;
};

const Radio: React.FC<RadioProps> = ({
  direction,
  options,
  buttonStyle,
  optionType,
  onChange,
  value,
}) => {
  return (
    <AntdRadio.Group
      buttonStyle={buttonStyle}
      className={styles.radio}
      onChange={onChange}
      value={value}
    >
      <Space align="center" justify="center" className={styles.space}>
        <AntdSpace size="large" direction={direction}>
          {/* AntdSpace is needed to have vertical aligned radio buttons */}
          {options &&
            (optionType === 'default'
              ? options.map((option) => (
                  <AntdRadio
                    key={
                      isCheckBoxOptionType(option)
                        ? option.value.toString()
                        : option
                    }
                    value={isCheckBoxOptionType(option) ? option.value : option}
                  >
                    {isCheckBoxOptionType(option) ? option.label : option}
                  </AntdRadio>
                ))
              : options.map((option) => (
                  <AntdRadio.Button
                    key={
                      isCheckBoxOptionType(option)
                        ? option.value.toString()
                        : option
                    }
                    value={isCheckBoxOptionType(option) ? option.value : option}
                    className={styles.radioButton}
                  >
                    {isCheckBoxOptionType(option) ? option.label : option}
                  </AntdRadio.Button>
                )))}
        </AntdSpace>
      </Space>
    </AntdRadio.Group>
  );
};

export default Radio;
