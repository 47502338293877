import React from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import classnames from 'classnames';
import styles from './index.module.scss';

const Table: React.FC<TableProps<any>> = (rest) => {
  const { onRow, pagination, dataSource } = rest;
  return (
    <AntdTable
      {...rest}
      rowClassName={classnames(styles.row, { [styles.clickable]: !!onRow })}
      showSorterTooltip={false}
      showHeader={!!dataSource?.length}
      pagination={
        pagination !== false
          ? {
              hideOnSinglePage: true,
              ...pagination,
            }
          : false
      }
    />
  );
};

export default Table;
