import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import CustomActionCta from 'business/actions/components/customActionCta';
import {
  actionTemplateWithRelevanceColumns,
  actionTemplateWithRelevanceData,
  buildActionOrderBy,
  useActionTableFilters,
} from 'business/actions/services/table';
import { ActionTemplateWithRelevanceRow } from 'business/actions/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TableFilters from 'business/common/components/tableFilters';
import Routes, { actionCatalogDetailsRoute } from 'business/router/routes';
import SubPageLayout from 'business/user/layout/subPage';
import {
  ActionTemplate_Order_By,
  Action_Order_By,
  Order_By,
  useListActionWithRelevanceQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { handleSortChange } from 'technical/table/sort';
import Space from 'ui/space';
import Table from 'ui/table';
import Typography from 'ui/typography';

const AddActionPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    selectedEntity: { entityTags },
  } = useLoggedInAppContext();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<
    ActionTemplate_Order_By | ActionTemplate_Order_By[]
  >({
    actionTemplateRelevanceForCompanies_aggregate: {
      max: {
        relevance: Order_By.DescNullsLast,
      },
    },
    id: Order_By.Asc,
  });

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useActionTableFilters();

  const { data, previousData } = useListActionWithRelevanceQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      orderBy,
      condition: {
        label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
        actionTemplateIndicatorTemplates: {
          indicatorTemplate: {
            indicatorTemplateStakes: {
              stake: {
                id: filtersValues.stakeId ? { _eq: filtersValues.stakeId } : {},
                themeId: filtersValues.themeId
                  ? { _eq: filtersValues.themeId }
                  : {},
              },
            },
            indicatorTemplateTags: {
              tag: {
                label: {
                  _in: entityTags,
                },
              },
            },
          },
        },
      },
    },
  });

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current || 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSortChange = (
    sorter: SorterResult<ActionTemplateWithRelevanceRow>,
  ) => {
    setOrderBy(
      handleSortChange<ActionTemplateWithRelevanceRow, Action_Order_By>(
        sorter,
        buildActionOrderBy,
      ),
    );
  };

  const actionTemplates =
    data?.actionTemplate || previousData?.actionTemplate || [];

  return (
    <SubPageLayout goBack={Routes.ActionsStrategy}>
      <Space direction="vertical" size="middle">
        <Typography.Title level={2}>
          {t('actions.strategy.add.title')}
        </Typography.Title>
        <Typography.Paragraph soften>
          {t('actions.strategy.add.description')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.actions')}
        />
        <Table
          data-test-id="action-with-relevance-table"
          columns={actionTemplateWithRelevanceColumns()}
          dataSource={actionTemplateWithRelevanceData(actionTemplates)}
          pagination={{
            total: data?.actionTemplate_aggregate.aggregate?.count || 0,
            pageSize: resultsPerPage,
            current: filtersValues.page,
          }}
          onChange={(pagination, _filters, sorter) => {
            onPaginationChange(pagination);
            onSortChange(
              sorter as SorterResult<ActionTemplateWithRelevanceRow>,
            );
          }}
          onRow={(row: ActionTemplateWithRelevanceRow) => ({
            onClick: () => {
              history.push(actionCatalogDetailsRoute(row.key));
            },
          })}
        />
        <CustomActionCta />
      </Space>
    </SubPageLayout>
  );
};

export default AddActionPage;
