import React from 'react';
import { useAppContext } from 'business/AppBootstrapper';
import AppNavigationHistory from 'business/AppNavigationHistory';
import config from 'config';
import { Redirect, Route, Router as ReactDomRouter } from 'react-router-dom';
import history from 'technical/history';
import SwitchNotFoundHandler from 'technical/router/switch';
import { useZendesk } from 'technical/hooks/useZendesk';
import { commonRoutesProps, routesPropsListBuilder } from './services';
import { RouteProps } from './types';

const routesListBuilder = (routesProps: RouteProps[]) =>
  routesProps.map(({ path, component, exact = true, redirectTo }) => {
    if (redirectTo) {
      return <Redirect key={path} from={path} to={redirectTo} />;
    }
    return <Route key={path} path={path} exact={exact} component={component} />;
  });

// WARNING: don't bump history version until this is fixed: https://github.com/ReactTraining/history/issues/804
// In any case test all navigation use cases before merging

const Router = () => {
  const appContext = useAppContext();
  const routesProps = routesPropsListBuilder(appContext);

  if (appContext.isConnected && config.zendesk.enabled) {
    useZendesk();
  }

  return (
    <ReactDomRouter history={history}>
      <AppNavigationHistory>
        <SwitchNotFoundHandler>
          {routesListBuilder([...commonRoutesProps, ...routesProps])}
        </SwitchNotFoundHandler>
      </AppNavigationHistory>
    </ReactDomRouter>
  );
};

export default Router;
