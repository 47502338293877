import React from 'react';
import { Dropdown, Menu } from 'antd';
import { ActionStatus, AllActionStatus } from 'business/actions/types';
import {
  ChangeActionStatusDocument,
  ChangeActionStatusMutation,
  ChangeActionStatusMutationVariables,
} from 'generated/graphql';
import client from 'technical/graphql/client';
import classnames from 'classnames';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import StatusTag from '../statusTag';
import styles from './index.module.scss';

interface StatusTagSelectProps {
  action: { id: string; status: ActionStatus };
  onChange?: () => void;
}

const StatusTagSelect: React.FC<StatusTagSelectProps> = ({
  action: { status, id },
  onChange,
}) => {
  const { isAllowedTo } = useLoggedInAppContext();

  async function handleChange(newStatus: ActionStatus) {
    if (newStatus === status) {
      return;
    }
    await client.mutate<
      ChangeActionStatusMutation,
      ChangeActionStatusMutationVariables
    >({
      mutation: ChangeActionStatusDocument,
      variables: {
        actionId: id,
        status: newStatus,
      },
    });
    if (onChange) {
      onChange();
    }
  }

  if (!isAllowedTo(Permission.ActionUpdate)) {
    return <StatusTag status={status} />;
  }

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu
          onClick={(e) => {
            switch (e.key) {
              case '1':
                handleChange(ActionStatus.Incoming);
                break;
              case '2':
                handleChange(ActionStatus.InProgress);
                break;
              case '3':
                handleChange(ActionStatus.Finished);
                break;
              default:
                throw Error('Impossible Status');
            }
            e.domEvent.stopPropagation();
          }}
        >
          {AllActionStatus.map((option) => (
            <Menu.Item key={option} className={styles.option}>
              <StatusTag
                status={option}
                className={classnames(styles.statusTag, {
                  [styles.selectedTag]: option === status,
                })}
              />
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <StatusTag
        status={status}
        onClick={(e) => e.stopPropagation()}
        className={styles.clickable}
      />
    </Dropdown>
  );
};

export default StatusTagSelect;
