import React from 'react';
import { Link } from 'react-router-dom';
import Routes from 'business/router/routes';
import images from 'ui/images';
import classnames from 'classnames';
import styles from './index.module.scss';

interface AppLogoProps {
  height?: string;
  path?: string;
  connected?: boolean;
  clickable?: boolean;
  mode?: 'light' | 'dark';
  className?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({
  clickable,
  className,
  mode = 'light',
}) => {
  return clickable ? (
    <Link to={Routes.Home}>
      <img
        className={classnames(styles.appLogo, className)}
        src={images.logo[mode]}
        alt="App logo"
      />
    </Link>
  ) : (
    <img
      className={classnames(styles.appLogo, className)}
      src={images.logo[mode]}
      alt="App logo"
    />
  );
};

export default AppLogo;
