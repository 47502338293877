import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  getUnitComponent,
  getUnitDisplay,
  getUnitValidationSchema,
} from 'business/indicators/services/units';
import { IndicatorType, IndicatorUnit } from 'business/indicators/types';
import { useFormik } from 'formik';
import {
  IndicatorDetailsFragment,
  IndicatorDocument,
  IndicatorGoal_Constraint,
  IndicatorGoal_Update_Column,
  useDeleteIndicatorGoalMutation,
  useInsertIndicatorGoalMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import Space from 'ui/space';
import Typography from 'ui/typography';
import Form from 'ui/form';
import * as yup from 'yup';
import DatePicker from 'ui/datePicker';
import { formikStatus } from 'technical/form';
import { formatDate } from 'technical/date/formatter';
import { endOfMonth, isPast } from 'date-fns';
import { Permission } from 'business/user/types/user';
import { Popconfirm } from 'antd';
import Restricted from 'business/user/components/Restricted';
import styles from './index.module.scss';

interface IndicatorGoalProps {
  indicatorGoal: IndicatorDetailsFragment['indicatorGoals'][number] | undefined;
  indicatorId: IndicatorDetailsFragment['id'];
  indicatorUnit: string;
  defaultUnit: IndicatorUnit;
  indicatorType: IndicatorType;
  entityId: string;
}

const IndicatorGoal: React.FC<IndicatorGoalProps> = ({
  indicatorGoal,
  indicatorId,
  indicatorUnit,
  indicatorType,
  entityId,
}) => {
  const { t } = useTranslation();
  const [insertIndicatorGoal] = useInsertIndicatorGoalMutation();
  const [deleteIndicatorGoal] = useDeleteIndicatorGoalMutation();
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik<{
    value: number | undefined;
    date: Date | undefined;
  }>({
    initialValues: {
      value: indicatorGoal?.value,
      date: indicatorGoal ? new Date(indicatorGoal.date) : undefined,
    },
    validationSchema: yup
      .object({
        value: getUnitValidationSchema(indicatorUnit),
        date: yup.date().required(t('errors.required')),
      })
      .required(),
    onSubmit: async (values) => {
      const date =
        indicatorType === IndicatorType.Stock
          ? endOfMonth(values.date!)
          : values.date!;

      await insertIndicatorGoal({
        refetchQueries: [
          {
            query: IndicatorDocument,
            variables: {
              id: indicatorId,
              entityId,
            },
          },
        ],
        variables: {
          indicatorGoal: {
            indicatorId,
            value: values.value,
            date: formatDate(date, 'y-MM-dd'),
            unit: indicatorUnit,
          },
          onConflict: {
            constraint:
              IndicatorGoal_Constraint.IndicatorGoalIndicatorIdUnitKey,
            update_columns: [
              IndicatorGoal_Update_Column.UpdatedAt,
              IndicatorGoal_Update_Column.Date,
              IndicatorGoal_Update_Column.Value,
            ],
          },
        },
      });
      setIsEditing(false);
    },
  });

  const Component = getUnitComponent(indicatorUnit);

  return (
    <Space data-test-id="indicator-goal" size="small" align="center">
      {isEditing ? (
        <>
          <Form.Item
            style={{ margin: 0 }}
            className={styles.item}
            validateStatus={formikStatus(formik, 'value')}
          >
            <Component
              data-test-id="indicator-goal-input-value"
              size="middle"
              formik={formik}
            />
          </Form.Item>
          <Typography.Text>
            {t('indicators.strategy.indicatorGoal.at')}
          </Typography.Text>
          <Form.Item
            style={{ margin: 0 }}
            className={styles.item}
            validateStatus={formikStatus(formik, 'date')}
          >
            <DatePicker
              data-test-id="indicator-goal-input-date"
              size="middle"
              style={{ width: '100%' }}
              name="date"
              format={
                indicatorType === IndicatorType.Stock ? 'MM/y' : 'dd/MM/y'
              }
              picker={indicatorType === IndicatorType.Stock ? 'month' : 'date'}
              disabledDate={(date) => isPast(date)}
              onChange={(date) => {
                formik.setValues({
                  ...formik.values,
                  date: date ?? undefined,
                });
              }}
              onBlur={formik.handleBlur}
              value={formik.values.date}
            />
          </Form.Item>
          <Button
            data-test-id="confirm-indicator-goal-cta"
            className={styles.cta}
            onClick={() => formik.handleSubmit()}
          >
            <CheckOutlined />
          </Button>
          <Button
            data-test-id="cancel-indicator-goal-cta"
            className={styles.cta}
            onClick={() => setIsEditing(false)}
          >
            <CloseOutlined />
          </Button>
        </>
      ) : (
        <>
          <Typography.Text>
            {indicatorGoal
              ? t('indicators.strategy.indicatorGoal.display', {
                  value: getUnitDisplay(indicatorGoal.unit)(
                    indicatorGoal.value,
                  ),
                  date: formatDate(indicatorGoal.date, 'dd/MM/y'),
                })
              : t('indicators.strategy.indicatorGoal.empty')}
          </Typography.Text>
          <Restricted permission={Permission.IndicatorsUpdate}>
            <Button
              data-test-id="edit-indicator-goal-cta"
              onClick={() => setIsEditing(true)}
              className={styles.cta}
            >
              <EditOutlined />
            </Button>
            {indicatorGoal && (
              <Popconfirm
                title={t('indicators.strategy.indicatorGoal.confirmDelete')}
                placement="leftBottom"
                okButtonProps={{
                  id: 'delete-action-ok',
                }}
                onConfirm={() => {
                  deleteIndicatorGoal({
                    refetchQueries: [
                      {
                        query: IndicatorDocument,
                        variables: {
                          id: indicatorId,
                          entityId,
                        },
                      },
                    ],
                    variables: { id: indicatorGoal.id },
                  });
                  setIsEditing(false);
                }}
                onCancel={() => null}
                okText={t('common.yes')}
                cancelText={t('common.no')}
              >
                {' '}
                <Button className={styles.cta}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </Restricted>
        </>
      )}
    </Space>
  );
};

export default IndicatorGoal;
