import React from 'react';
import { useThemeListQuery, useStakeListQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Form from 'ui/form';
import { CloseCircleFilled } from '@ant-design/icons';
import { FilterComponentProps } from './services/type';

const StakeAndThemeFilter: React.FC<
  FilterComponentProps<{
    themeId: string | undefined;
    stakeId: string | undefined;
  }>
> = ({ filterValue, setFilterValue }) => {
  const { t } = useTranslation();
  const { data: possibleThemesValue } = useThemeListQuery();
  const { data: possibleStakesValue } = useStakeListQuery({
    variables: {
      themeCondition: filterValue.themeId
        ? {
            themeId: { _eq: filterValue.themeId },
          }
        : undefined,
    },
  });

  const stakes = possibleStakesValue?.stake || [];
  const themes = possibleThemesValue?.theme || [];

  if (
    filterValue.stakeId &&
    stakes.length !== 0 &&
    !stakes.map((stake) => stake.id).includes(filterValue.stakeId)
  ) {
    setFilterValue({ ...filterValue, stakeId: undefined });
  }

  return (
    <>
      <Form.Item noStyle>
        <Form.Select
          data-test-id="table-filters-theme"
          allowClear
          clearIcon={
            <CloseCircleFilled data-test-id="table-filters-theme-null" />
          }
          placeholder={t('tableFilters.theme.placeholder')}
          value={filterValue.themeId}
          options={themes.map((theme) => ({
            value: theme.id,
            label: theme.label,
            key: theme.id,
            'data-test-id': `table-filters-theme-${theme.label}`,
          }))}
          onChange={(themeId) =>
            setFilterValue({ ...filterValue, themeId: themeId as string })
          }
        />
      </Form.Item>
      <Form.Item noStyle>
        <Form.Select
          showSearch
          data-test-id="table-filters-stake"
          allowClear
          clearIcon={
            <CloseCircleFilled data-test-id="table-filters-stake-null" />
          }
          placeholder={t('tableFilters.stake.placeholder')}
          filterOption={(input, option) => {
            if (!option || !option.label) {
              return false;
            }
            return (
              (option.label as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) !== -1
            );
          }}
          value={filterValue.stakeId}
          options={stakes.map((stake) => ({
            value: stake.id,
            label: stake.label,
            key: stake.id,
            'data-test-id': `table-filters-stake-${stake.label}`,
          }))}
          onChange={(stakeId) =>
            setFilterValue({ ...filterValue, stakeId: stakeId as string })
          }
        />
      </Form.Item>
    </>
  );
};

export default StakeAndThemeFilter;
