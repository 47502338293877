import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserPermission } from 'generated/graphql';
import Typography from 'ui/typography';
import Routes from 'business/router/routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import history from 'technical/history';
import { useConnectedUserContext } from 'business/AppBootstrapper';
import AccountsList from '../accountsList';
import styles from './index.module.scss';

interface AccountSelectionCardProps {
  userPermissions: UserPermission[];
}

const AccountSelectionCard: React.FC<AccountSelectionCardProps> = ({
  userPermissions,
}) => {
  const { t } = useTranslation();
  const { deleteEntityType } = useConnectedUserContext();

  return (
    <Card className={styles.accountSelectionCard}>
      <ArrowLeftOutlined
        onClick={() => {
          deleteEntityType();
          history.push(Routes.EntitySelection);
        }}
        className={styles.leftArrow}
      />
      {userPermissions.length ? (
        <AccountsList userPermissions={userPermissions} />
      ) : (
        <Typography.Paragraph
          center
          data-test-id="account-selection-no-role-message"
        >
          {t('account.selection.noRole')}
        </Typography.Paragraph>
      )}
    </Card>
  );
};

export default AccountSelectionCard;
