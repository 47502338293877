import * as yup from 'yup';
import { i18n } from 'translations';
import {
  ActionCost,
  ActionImpact,
  ActionStatus,
  AllActionCosts,
  AllActionImpacts,
  AllActionStatus,
} from '../types';

export const label = yup.string().required(i18n.t('errors.required'));

export const description = yup.string().required(i18n.t('errors.required'));

export const comment = yup.string();

export const status = yup
  .mixed<ActionStatus>()
  .required(i18n.t('errors.required'))
  .oneOf(
    AllActionStatus,
    i18n.t('errors.actions.status', { context: 'oneof' }),
  );

export const cost = yup
  .mixed<ActionCost>()
  .oneOf(AllActionCosts, i18n.t('errors.actions.cost', { context: 'oneof' }));

export const impact = yup
  .mixed<ActionImpact>()
  .oneOf(
    AllActionImpacts,
    i18n.t('errors.actions.impact', { context: 'oneof' }),
  );

export const dueDate = yup.date().nullable(true);

export const linkedIndicators = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string(),
      label: yup.string(),
      indicators: yup.array().of(
        yup.object().shape({
          id: yup.string(),
          label: yup.string(),
        }),
      ),
    }),
  )
  .min(
    1,
    i18n.t(
      'errors.actions.linkedIndicators',
      i18n.t('errors.actions.linkedIndicators', { context: 'min' }),
    ),
  );

export const responsibleId = yup.string();
