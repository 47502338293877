import { Tabs } from 'antd';
import AssociatedActionTemplatesTable from 'business/actions/components/associatedActionTemplatesTable';
import AssociatedCampaignTemplatesTable from 'business/campaigns/components/associatedCampaignTemplatesTable';
import { getAssociatedActionTemplates } from 'business/indicators/services';
import { IndicatorTemplateDetailsFragment } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RichTextContainer from 'ui/richTextContainer';
import Typography from 'ui/typography';
import {
  ensureSlugIsValid,
  IndicatorCatalogDetailsRouteSlug,
} from 'business/indicators/pages/catalog/details/type';
import AssociatedPartnersTable from 'business/partners/components/associatedPartnersTable';
import LinkedIndicatorTemplatesTable from '../linkedIndicatorTemplatesTable';

const { TabPane } = Tabs;

interface IndicatorTemplateTabsProps {
  indicatorTemplate: IndicatorTemplateDetailsFragment;
  activeTab: IndicatorCatalogDetailsRouteSlug;
  setActiveTab: (newTab: IndicatorCatalogDetailsRouteSlug) => void;
}

const IndicatorTemplateTabs: React.FC<IndicatorTemplateTabsProps> = ({
  indicatorTemplate,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();

  const associatedActionTemplates =
    getAssociatedActionTemplates(indicatorTemplate);

  return (
    <Tabs
      defaultActiveKey="description"
      data-onboarding-id="indicator-template-tabs"
      activeKey={activeTab}
      onChange={(activeKey) =>
        setActiveTab(ensureSlugIsValid(activeKey) ? activeKey : 'description')
      }
    >
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-description">
            {t('indicators.tabs.description')}
          </Typography.Text>
        }
        key="description"
      >
        <RichTextContainer
          content={indicatorTemplate.description}
          data-test-id="indicator-description"
        />
      </TabPane>
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-actions">
            {t('indicators.tabs.catalog.actions')}
          </Typography.Text>
        }
        key="actions"
      >
        <AssociatedActionTemplatesTable
          actionTemplates={associatedActionTemplates}
        />
      </TabPane>
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-campaigns">
            {t('indicators.tabs.catalog.campaigns')}
          </Typography.Text>
        }
        key="campaigns"
      >
        <AssociatedCampaignTemplatesTable
          campaignTemplates={Array.from(
            new Map(
              indicatorTemplate.indicatorTemplateCampaignTemplates.map(
                (indicatorTemplateCampaignTemplate) => [
                  indicatorTemplateCampaignTemplate.campaignTemplate.id,
                  indicatorTemplateCampaignTemplate.campaignTemplate,
                ],
              ),
            ).values(),
          )}
        />
      </TabPane>

      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-indicator-template-links">
            {t('indicators.tabs.indicatorTemplateLinks')}
          </Typography.Text>
        }
        key="linked-indicators"
      >
        <LinkedIndicatorTemplatesTable
          indicatorTemplates={indicatorTemplate.indicatorTemplateLinks.map(
            (it) => it.indicatorTemplate,
          )}
        />
      </TabPane>
      <TabPane
        tab={
          <Typography.Text data-test-id="indicator-tab-partners">
            {t('indicators.tabs.catalog.partners')}
          </Typography.Text>
        }
        key="partners"
      >
        <AssociatedPartnersTable
          partners={indicatorTemplate.partnerIndicatorTemplates.map(
            (partnerIndicatorTemplate) => partnerIndicatorTemplate.partner,
          )}
        />
      </TabPane>
    </Tabs>
  );
};

export default IndicatorTemplateTabs;
