type KeyType = string | number;

type Node<Key extends string, Children extends string, T> = {
  [_ in Key]: KeyType;
} & { [_ in Children]?: Node<Key, Children, T>[] } & T;

/**
 * Generator function to find a given node in the tree structure by its key
 * @param keyField the name of the key field to uniquely identify any node
 * @param childrenField the name of the children field, that contains the list of the children of the node
 * @returns a function that to find a node in a tree structure by its key
 *
 * For example, with a tree structure like
 * ```
 * type Node = {key: string, children: Node[]}
 *
 * // Create the function with the correct fields
 * const findInTree = createFindInTreeFunction('key', 'children')
 * ```
 */
export function createFindInTreeFunction<K extends string, C extends string>(
  keyField: K,
  childrenField: C,
) {
  return function findInTree<T>(
    key: KeyType,
    root: Node<K, C, T>,
  ): Node<K, C, T> | null {
    if (root[keyField] === key) {
      return root;
    }

    if (!root[childrenField]) {
      return null;
    }

    let result: Node<K, C, T> | null = null;
    for (let i = 0; i < root[childrenField].length && !result; i += 1) {
      result = findInTree<T>(key, root[childrenField][i]);
    }
    return result;
  };
}

export default createFindInTreeFunction;
