import { LinkedIndicatorTemplate } from 'business/indicators/types';
import { indicatorCatalogDetailsRoute } from 'business/router/routes';
import React from 'react';
import { useHistory } from 'react-router';
import Table from 'ui/table';
import {
  linkedIndicatorTemplatesDataSource,
  linkedIndicatorTemplatesColumns,
} from '../../services/table';

interface LinkedIndicatorTemplatesTableProps {
  indicatorTemplates: LinkedIndicatorTemplate[];
}

const LinkedIndicatorTemplatesTable: React.FC<
  LinkedIndicatorTemplatesTableProps
> = ({ indicatorTemplates }) => {
  const history = useHistory();
  return (
    <Table
      data-test-id="linked-indicator-templates-table"
      dataSource={linkedIndicatorTemplatesDataSource(indicatorTemplates)}
      columns={linkedIndicatorTemplatesColumns()}
      onRow={(record) => ({
        onClick: () => history.push(indicatorCatalogDetailsRoute(record.key)),
      })}
    />
  );
};

export default LinkedIndicatorTemplatesTable;
