import Routes from 'business/router/routes';
import React from 'react';
import history from 'technical/history';
import { ReactDiv } from 'technical/type';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import SuccessIllustration from 'ui/illustrations/success';
import Space from 'ui/space';
import Typography from 'ui/typography';
import NotConnectedCard from '../notConnectedCard';

interface ActionProps extends ReactDiv {
  title: string;
  hint: string;
  handleSubmit?: () => void;
  btnText?: string;
  children?: React.ReactElement;
}

const ActionCard: React.FC<ActionProps> = ({
  title,
  hint,
  btnText,
  children,
  handleSubmit = () => history.push(Routes.Home),
  ...rest
}) => {
  return (
    <NotConnectedCard {...rest}>
      <Space direction="vertical" align="center">
        <AppLogo />
        <Typography.Title level={5}>{title}</Typography.Title>
        <Typography.Paragraph>{hint}</Typography.Paragraph>
        <Space justify="center">{children || <SuccessIllustration />}</Space>
        <Button
          id="submit-button"
          data-test-id="submit-button"
          type="primary"
          onClick={handleSubmit}
        >
          {btnText}
        </Button>
      </Space>
    </NotConnectedCard>
  );
};

export default ActionCard;
