import React from 'react';
import 'antd/dist/reset.css';
import './App.scss';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, Empty } from 'antd';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';
import CookieConsentBanner from 'ui/cookie/consentBanner';
import { useTranslation } from 'react-i18next';
import locale from 'config/locale';
import Router from './router';
import OnBoarding from './onboarding/components/tour';
import AppProvider from './AppBootstrapper';
import defaultTheme from 'config/theme';

const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <ConfigProvider
        locale={locale}
        renderEmpty={() => (
          <Empty
            description={t('common.noDataAlt')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
        componentSize="large"
        theme={defaultTheme}
      >
        <ApolloProvider client={apolloClient}>
          <AppProvider>
            <>
              <base target="_blank" />
              <CookieConsentBanner />
              <OnBoarding />
              <Router />
            </>
          </AppProvider>
        </ApolloProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
