import React from 'react';
import Space from 'ui/space';
import styles from './index.module.scss';

const Info: React.FC<{ icon: React.ReactElement }> = ({ icon, children }) => (
  <Space className={styles.info} align="center" size="extra-small">
    {icon}
    {children}
  </Space>
);

export default Info;
