import {
  CommonUserFieldsFragment,
  EntityType_Enum,
  Permission_Enum,
  Role_Enum,
  UserPermission,
} from 'generated/graphql';

export interface User {
  id: any;
  firstName: string | null;
  lastName: string | null;
  onboardingDoneAt: Date | null;
}

type ISOString = string;

// Roles is an alias for the generated Role_Enum, So it equals Role_Enum value and type
// cf https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944
export type Roles = Role_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Roles = Role_Enum;

export interface UserWithPrivateInfos extends User {
  privateInfos: {
    identityId: string | null;
    email: string;
    createdAt: ISOString;
    updatedAt: ISOString;
  };
}

export interface FormattedEntity {
  entityId: UserPermission['entityId'];
  entityName: UserPermission['entity']['name'];
  entityType: EntityTypes;
  entityTags: string[];
  providers: {
    entityProviderId: string | null;
    userProviderId: string | null;
  };
}

export interface FormattedUserPermission extends FormattedEntity {
  userPermissionId: UserPermission['id'];
  role: UserPermission['role'];
}

export interface UserWithPrivateInfosAndPermissions
  extends UserWithPrivateInfos {
  userPermissions: UserPermission[];
}

export function isUserWithPrivateInfosAndPermissions(
  user: CommonUserFieldsFragment,
): user is UserWithPrivateInfosAndPermissions {
  if (!user || user.privateInfos == null) {
    return false;
  }
  if (user.userPermissions == null) {
    return false;
  }
  return true;
}

export interface HasuraContext {
  headers: {
    'X-Hasura-Role': string;
    'X-Hasura-Entity-Id': string;
  };
}

// EntityTypes is an alias for the generated EntityType_Enum, So it equals EntityType_Enum value and type
// cf https://github.com/microsoft/TypeScript/issues/17592#issuecomment-449440944
export type EntityTypes = EntityType_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityTypes = EntityType_Enum;

export type Permission = Permission_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Permission = Permission_Enum;
