import { Space, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import CustomActionCta from 'business/actions/components/customActionCta';
import {
  actionTemplatesColumns,
  actionTemplatesDataSource,
  buildActionTemplateOrderBy,
  useActionTableFilters,
} from 'business/actions/services/table';
import { ActionTemplateRow } from 'business/actions/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TableFilters from 'business/common/components/tableFilters';
import {
  actionCatalogDetailsRoute,
  indicatorStrategyDetailsRoute,
} from 'business/router/routes';
import SubPageLayout from 'business/user/layout/subPage';
import {
  ActionTemplate_Order_By,
  Order_By,
  useGetIndicatorNameQuery,
  useListActionTemplateForIndicatorQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { handleSortChange } from 'technical/table/sort';
import EmptyState from 'ui/emptyState';
import Table from 'ui/table';
import Typography from 'ui/typography';

interface ActionCatalogForIndicatorPageParams {
  id: string;
}

const ActionCatalogForIndicatorPage: React.FC = () => {
  const { id } = useParams<ActionCatalogForIndicatorPageParams>();
  const { t } = useTranslation();
  const {
    selectedEntity: { entityTags },
  } = useLoggedInAppContext();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<
    ActionTemplate_Order_By | ActionTemplate_Order_By[]
  >({ createdAt: Order_By.Desc, id: Order_By.Asc });
  const history = useHistory();

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useActionTableFilters();

  const { data, loading, previousData } =
    useListActionTemplateForIndicatorQuery({
      variables: {
        limit: resultsPerPage,
        offset: resultsPerPage * (filtersValues.page - 1),
        sort: orderBy,
        indicatorId: id,
        condition: {
          _or: [
            { label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` } },
            {
              description: {
                _ilike: `%${debouncedFiltersValues.search ?? ''}%`,
              },
            },
          ],
          actionTemplateIndicatorTemplates: {
            indicatorTemplate: {
              indicatorTemplateStakes: {
                stake: {
                  id: filtersValues.stakeId
                    ? { _eq: filtersValues.stakeId }
                    : {},
                  themeId: filtersValues.themeId
                    ? { _eq: filtersValues.themeId }
                    : {},
                },
              },
              indicatorTemplateTags: {
                tag: {
                  label: {
                    _in: entityTags,
                  },
                },
              },
            },
          },
        },
      },
    });

  const { data: indicatorData } = useGetIndicatorNameQuery({
    variables: {
      indicatorId: id,
    },
  });

  const indicatorName =
    indicatorData?.indicator_by_pk?.indicatorTemplate.label || '';

  const actionTemplates =
    data?.actionTemplate || previousData?.actionTemplate || [];

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current || 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSortChange = (sorter: SorterResult<ActionTemplateRow>) => {
    setOrderBy(
      handleSortChange<ActionTemplateRow, ActionTemplate_Order_By>(
        sorter,
        buildActionTemplateOrderBy,
      ),
    );
  };

  const noLinkedActions = noFilters && actionTemplates.length === 0;

  return (
    <SubPageLayout goBack={indicatorStrategyDetailsRoute(id)}>
      <Space direction="vertical" size="large">
        <Typography.Title level={2}>
          {t('actions.listForIndicator.title', { name: indicatorName })}
        </Typography.Title>
        {!noLinkedActions && (
          <TableFilters
            filtersValue={filtersValues}
            setFilters={(newFilters) =>
              setFiltersValues({ ...newFilters, page: 1 })
            }
            searchPlaceholder={t('tableFilters.search.actions')}
          />
        )}
        {actionTemplates.length === 0 && !loading ? (
          <EmptyState
            title={
              noLinkedActions
                ? t('actions.catalog.empty.altTitle')
                : t('actions.catalog.empty.title')
            }
            description={t('actions.catalog.empty.description')}
          />
        ) : (
          <Table
            loading={loading}
            data-test-id="action-templates-table-for-indicator"
            dataSource={actionTemplatesDataSource(actionTemplates)}
            columns={actionTemplatesColumns().splice(0, 5)}
            pagination={{
              total: data?.actionTemplate_aggregate.aggregate?.count || 0,
              pageSize: resultsPerPage,
              current: filtersValues.page,
            }}
            onChange={(pagination, _filters, sorter) => {
              onPaginationChange(pagination);
              onSortChange(sorter as SorterResult<ActionTemplateRow>);
            }}
            onRow={(record) => ({
              onClick: () =>
                history.push(
                  `${actionCatalogDetailsRoute(record.key)}?indicator=${id}`,
                ),
            })}
          />
        )}
      </Space>
      <CustomActionCta
        from={indicatorData?.indicator_by_pk?.indicatorTemplate.id}
      />
    </SubPageLayout>
  );
};

export default ActionCatalogForIndicatorPage;
