import React from 'react';
import DisconnectedLayout from '../disconnected';
import styles from './index.module.scss';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <DisconnectedLayout>
      <div className={styles.container}>{children}</div>
    </DisconnectedLayout>
  );
};

export default AuthLayout;
