import React from 'react';
import variables from 'ui/variables.module.scss';

import { Spin, Statistic as AntdStatistic } from 'antd';

interface StatisticProps {
  label: string;
  value: number | null;
  isLoading: boolean;
}

const Statistic: React.FC<StatisticProps> = ({ label, value, isLoading }) => {
  if (isLoading) {
    return <Spin />;
  }
  return (
    <AntdStatistic
      title={label}
      value={value === null ? '---' : value}
      valueStyle={{ color: variables.purple, fontSize: 30 }}
    />
  );
};

export default Statistic;
