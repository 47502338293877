import React from 'react';
import Space from 'ui/space';
import { Form, FormItemProps } from 'antd';
import classnames from 'classnames';
import styles from './index.module.scss';

const Item: React.FC<FormItemProps> = ({ className, ...rest }) => {
  return (
    // We encapsulate the Form.Item to let it manage his padding
    // allowing it to display without glitches the error
    <Space className={classnames(styles.container, className)}>
      <Form.Item labelCol={{ span: 24 }} labelAlign="left" {...rest} />
    </Space>
  );
};

export default Item;
