import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Space from 'ui/space';
import Typography from 'ui/typography';
import images from 'ui/images';
import FancyTable from 'business/documentation/component/fancyTable';
import variables from 'ui/variables.module.scss';
import Hat from 'business/documentation/component/hat';
import ExternalLink from 'ui/externalLink';
import DocumentationLayout from 'business/documentation/layout/documentationLayout';

const CsrCertificationAndLabelsDocumentationPage: React.FC = () => {
  const { t } = useTranslation();

  const context = 'documentation.labels.table';

  const columnDefs = [
    {
      label: t(`${context}.columns.ecovadis`),
      color: variables.purpleLight,
      key: 0,
    },
    {
      label: t(`${context}.columns.bcorp`),
      color: variables.purpleLight,
      key: 1,
    },
    {
      label: t(`${context}.columns.lucie`),
      color: variables.purpleLight,
      key: 2,
    },
    {
      label: t(`${context}.columns.rse`),
      color: variables.purpleLight,
      key: 3,
    },
  ];

  const rows = [
    {
      title: t(`${context}.rows.description.title`),
      data: {
        0: [
          t(`${context}.rows.description.ecovadis.leader`),
          t(`${context}.rows.description.ecovadis.offer`),
          t(`${context}.rows.description.ecovadis.questions`),
        ],
        1: [
          t(`${context}.rows.description.bcorp.young`),
          t(`${context}.rows.description.bcorp.labels`),
          t(`${context}.rows.description.bcorp.enterprise-specific`),
          t(`${context}.rows.description.bcorp.actions`),
          t(`${context}.rows.description.bcorp.from-afar`),
          t(`${context}.rows.description.bcorp.no-iso`),
          t(`${context}.rows.description.bcorp.international`),
        ],
        2: [
          t(`${context}.rows.description.lucie.expert`),
          t(`${context}.rows.description.lucie.motor`),
          t(`${context}.rows.description.lucie.sector`),
          t(`${context}.rows.description.lucie.results`),
          t(`${context}.rows.description.lucie.trust`),
          t(`${context}.rows.description.lucie.french`),
        ],
        3: [
          t(`${context}.rows.description.rse.specialist`),
          t(`${context}.rows.description.rse.labels`),
          t(`${context}.rows.description.rse.enterprise`),
          t(`${context}.rows.description.rse.sectors`),
          t(`${context}.rows.description.rse.distance`),
          t(`${context}.rows.description.rse.french`),
        ],
      },
    },
    {
      title: t(`${context}.rows.createdAt.title`),
      data: {
        0: t(`${context}.rows.createdAt.ecovadis`),
        1: t(`${context}.rows.createdAt.bcorp`),
        2: t(`${context}.rows.createdAt.lucie`),
        3: t(`${context}.rows.createdAt.rse`),
      },
    },
    {
      title: t(`${context}.rows.popularity.title`),
      data: {
        0: t(`${context}.rows.popularity.ecovadis`),
        1: t(`${context}.rows.popularity.bcorp`),
        2: t(`${context}.rows.popularity.lucie`),
        3: t(`${context}.rows.popularity.rse`),
      },
    },
    {
      title: t(`${context}.rows.sectors.title`),
      data: {
        0: t(`${context}.rows.sectors.ecovadis`),
        1: t(`${context}.rows.sectors.bcorp`),
        2: t(`${context}.rows.sectors.lucie`),
        3: t(`${context}.rows.sectors.rse`),
      },
    },
    {
      title: t(`${context}.rows.referentiel.title`),
      data: {
        0: t(`${context}.rows.referentiel.ecovadis`),
        1: t(`${context}.rows.referentiel.bcorp`),
        2: t(`${context}.rows.referentiel.lucie`),
        3: t(`${context}.rows.referentiel.rse`),
      },
    },
    {
      title: t(`${context}.rows.structure.title`),
      data: {
        0: t(`${context}.rows.structure.ecovadis`),
        1: t(`${context}.rows.structure.bcorp`),
        2: t(`${context}.rows.structure.lucie`),
        3: t(`${context}.rows.structure.rse`),
      },
    },
    {
      title: t(`${context}.rows.systemPreEval.title`),
      data: {
        0: t(`${context}.rows.systemPreEval.ecovadis`),
        1: t(`${context}.rows.systemPreEval.bcorp`),
        2: t(`${context}.rows.systemPreEval.lucie`),
        3: t(`${context}.rows.systemPreEval.rse`),
      },
    },
    {
      title: t(`${context}.rows.audit.title`),
      data: {
        0: t(`${context}.rows.audit.ecovadis`),
        1: t(`${context}.rows.audit.bcorp`),
        2: t(`${context}.rows.audit.lucie`),
        3: t(`${context}.rows.audit.rse`),
      },
    },
    {
      title: t(`${context}.rows.renewing.title`),
      data: {
        0: t(`${context}.rows.renewing.ecovadis`),
        1: t(`${context}.rows.renewing.bcorp`),
        2: t(`${context}.rows.renewing.lucie`),
        3: t(`${context}.rows.renewing.rse`),
      },
    },
    {
      title: t(`${context}.rows.eligibility.title`),
      data: {
        0: t(`${context}.rows.eligibility.ecovadis`),
        1: t(`${context}.rows.eligibility.bcorp`),
        2: t(`${context}.rows.eligibility.lucie`),
        3: t(`${context}.rows.eligibility.rse`),
      },
    },
    {
      title: t(`${context}.rows.cost.title`),
      data: {
        0: t(`${context}.rows.cost.ecovadis`),
        1: t(`${context}.rows.cost.bcorp`),
        2: t(`${context}.rows.cost.lucie`),
        3: t(`${context}.rows.cost.rse`),
      },
    },
  ];

  return (
    <DocumentationLayout>
      <Space direction="vertical">
        <Space align="center" justify="center">
          <Typography.Title level={1}>
            {t('documentation.labels.title')}
          </Typography.Title>
        </Space>
        <Hat>{t('documentation.labels.introduction')}</Hat>
        <Space direction="vertical">
          <Typography.Title level={4}>
            {t('documentation.labels.role.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.role.text')}
          </Typography.Paragraph>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={4}>
            {t('documentation.labels.list.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.list.text')}
          </Typography.Paragraph>
          <img
            src={images.documentation.labelsListe}
            alt={t('documentation.labels.list.alt')}
          />
        </Space>
        <Space direction="vertical">
          <Typography.Title level={4}>
            {t('documentation.labels.table.title')}
          </Typography.Title>
          <FancyTable
            rows={rows}
            columnDefs={columnDefs}
            legend={t('documentation.labels.table.legend')}
            isOddRowColorized
          />
        </Space>
        <Space size="small" direction="vertical">
          <Typography.Title level={4}>
            <Trans t={t} i18nKey="documentation.labels.ecovadis.title">
              a<ExternalLink to="https://ecovadis.com/fr/">b</ExternalLink>c
            </Trans>
          </Typography.Title>
          <Typography.Title level={5}>
            {t('documentation.labels.ecovadis.introduction.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.ecovadis.introduction.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.ecovadis.fonctioning.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.ecovadis.fonctioning.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.ecovadis.cost.title')}
          </Typography.Title>
          <Typography.Paragraph>
            <Trans t={t} i18nKey="documentation.labels.ecovadis.cost.text">
              a
              <ExternalLink to="https://ecovadis.com/fr/plans-pricing/">
                b
              </ExternalLink>
            </Trans>
          </Typography.Paragraph>
        </Space>
        <Space size="small" direction="vertical">
          <Typography.Title level={4}>
            <Trans t={t} i18nKey="documentation.labels.bcorp.title">
              a<ExternalLink to="https://www.bcorporation.fr/">b</ExternalLink>c
            </Trans>
          </Typography.Title>
          <Typography.Title level={5}>
            {t('documentation.labels.bcorp.introduction.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.bcorp.introduction.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.bcorp.fonctioning.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.bcorp.fonctioning.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.bcorp.cost.title')}
          </Typography.Title>
          <Typography.Paragraph>
            <Trans t={t} i18nKey="documentation.labels.bcorp.cost.text">
              a
              <ExternalLink to="https://bcorporation.eu/update-b-corp-certification-fees">
                b
              </ExternalLink>
            </Trans>
          </Typography.Paragraph>
        </Space>
        <Space size="small" direction="vertical">
          <Typography.Title level={4}>
            <Trans t={t} i18nKey="documentation.labels.lucie.title">
              a<ExternalLink to="https://www.labellucie.com/">b</ExternalLink>c
            </Trans>
          </Typography.Title>
          <Typography.Title level={5}>
            {t('documentation.labels.lucie.introduction.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.lucie.introduction.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.lucie.fonctioning.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.lucie.fonctioning.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.lucie.cost.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.lucie.cost.text')}
          </Typography.Paragraph>
        </Space>
        <Space size="small" direction="vertical">
          <Typography.Title level={4}>
            <Trans t={t} i18nKey="documentation.labels.afnor.title">
              a
              <ExternalLink to="https://certification.afnor.org/developpement-durable-rse/label-engage-rse">
                b
              </ExternalLink>
              c
            </Trans>
          </Typography.Title>
          <Typography.Title level={5}>
            {t('documentation.labels.afnor.introduction.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.afnor.introduction.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.afnor.fonctioning.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.afnor.fonctioning.text')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.labels.afnor.cost.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.labels.afnor.cost.text')}
          </Typography.Paragraph>
        </Space>
      </Space>
    </DocumentationLayout>
  );
};

export default CsrCertificationAndLabelsDocumentationPage;
