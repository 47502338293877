import {
  CampaignTemplateFragment,
  IndicatorTemplate,
  Indicator,
} from 'generated/graphql';
import { MissingIndicator } from 'business/indicators/types';

export const getNotInstanciatedIndicatorTemplateFromCampaign = (
  indicatorTemplates: Array<
    Pick<IndicatorTemplate, 'id' | 'label'> & {
      indicators: Pick<Indicator, 'id'>[];
      indicatorTemplateLinks: Array<{
        indicatorTemplate: Pick<IndicatorTemplate, 'id' | 'label'> & {
          indicatorTemplateLinks?: Array<{
            indicatorTemplate: Pick<IndicatorTemplate, 'id' | 'label'>;
          }>;
        };
      }>;
    }
  >,
): MissingIndicator[] => {
  return indicatorTemplates
    .filter((indicatorTemplate) => indicatorTemplate.indicators.length === 0)
    .map((indicatorTemplate) => ({
      id: indicatorTemplate.id,
      label: indicatorTemplate.label,
      linkedIndicators: indicatorTemplate.indicatorTemplateLinks.map(
        (indicatorTemplateLink) => ({
          id: indicatorTemplateLink.indicatorTemplate.id,
          label: indicatorTemplateLink.indicatorTemplate.label,
          linkedIndicators:
            indicatorTemplateLink.indicatorTemplate.indicatorTemplateLinks?.map(
              (indicatorTemplateLinkedLink) => ({
                id: indicatorTemplateLinkedLink.indicatorTemplate.id,
                label: indicatorTemplateLinkedLink.indicatorTemplate.label,
              }),
            ),
        }),
      ),
    }));
};

export const getAllIndicatorTemplateFromCampaign = (
  indicatorTemplates: Array<
    Pick<IndicatorTemplate, 'id' | 'label'> & {
      indicators: Pick<Indicator, 'id'>[];
      indicatorTemplateLinks: Array<{
        indicatorTemplate: Pick<IndicatorTemplate, 'id' | 'label'> & {
          indicatorTemplateLinks?: Array<{
            indicatorTemplate: Pick<IndicatorTemplate, 'id' | 'label'>;
          }>;
        };
      }>;
    }
  >,
) => {
  return indicatorTemplates.map((indicatorTemplate) => ({
    id: indicatorTemplate.id,
    label: indicatorTemplate.label,
    linkedIndicators: indicatorTemplate.indicatorTemplateLinks.map(
      (indicatorTemplateLink) => ({
        id: indicatorTemplateLink.indicatorTemplate.id,
        label: indicatorTemplateLink.indicatorTemplate.label,
        linkedIndicators:
          indicatorTemplateLink.indicatorTemplate.indicatorTemplateLinks?.map(
            (indicatorTemplateLinkedLink) => ({
              id: indicatorTemplateLinkedLink.indicatorTemplate.id,
              label: indicatorTemplateLinkedLink.indicatorTemplate.label,
            }),
          ),
      }),
    ),
  }));
};

export const getLinkedIndicatorTemplates = (
  campaignTemplate: CampaignTemplateFragment,
) =>
  Array.from(
    new Map(
      campaignTemplate.indicatorTemplateCampaignTemplates.map(
        (indicatorTemplateCampaignTemplates) => [
          indicatorTemplateCampaignTemplates.indicatorTemplate.id,
          indicatorTemplateCampaignTemplates.indicatorTemplate,
        ],
      ),
    ).values(),
  );
