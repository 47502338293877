import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { ActionRow } from 'business/actions/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TableFilters from 'business/common/components/tableFilters';
import Routes, { actionStrategyDetailsRoute } from 'business/router/routes';
import Restricted from 'business/user/components/Restricted';
import ConnectedLayout from 'business/user/layout/connected';
import { Permission } from 'business/user/types/user';
import {
  Action_Order_By,
  Order_By,
  useActionsListQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { handleSortChange } from 'technical/table/sort';
import Button from 'ui/button';
import EmptyState from 'ui/emptyState';
import images from 'ui/images';
import Space from 'ui/space';
import Table from 'ui/table';
import Typography from 'ui/typography';
import ActionsListMenu from 'business/actions/components/actionsListMenu';
import { exportActionsXlsx } from 'business/actions/services';
import notifyPromise from 'business/common/services/notifyPromise';
import config from 'config';
import {
  actionsColumns,
  actionsDataSource,
  buildActionOrderBy,
  useActionTableFilters,
} from '../../../services/table';

const ActionsStrategyPage: React.FC = () => {
  const { t } = useTranslation();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<Action_Order_By | Action_Order_By[]>({
    createdAt: Order_By.Desc,
    id: Order_By.Asc,
  });
  const {
    isAllowedTo,
    selectedEntity: { entityId, entityName },
  } = useLoggedInAppContext();
  const history = useHistory();

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useActionTableFilters();

  const { data, previousData, loading, refetch } = useActionsListQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      sort: orderBy,
      condition: {
        label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
        actionIndicators: {
          indicator: {
            entityId: { _eq: entityId },
            indicatorTemplate: {
              indicatorTemplateStakes: {
                stake: {
                  id: filtersValues.stakeId
                    ? { _eq: filtersValues.stakeId }
                    : {},
                  themeId: filtersValues.themeId
                    ? { _eq: filtersValues.themeId }
                    : {},
                },
              },
            },
          },
        },
      },
    },
  });

  const actions = data?.action || previousData?.action || [];

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current || 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSortChange = (sorter: SorterResult<ActionRow>) => {
    setOrderBy(
      handleSortChange<ActionRow, Action_Order_By>(sorter, buildActionOrderBy),
    );
  };

  return (
    <ConnectedLayout showEntityBreadcrumbs>
      <Space direction="vertical" size="middle">
        <Space justify="space-between">
          <Typography.Title level={3}>
            {t('actions.strategy.title')}
          </Typography.Title>
          <Space justify="center" align="center">
            <Restricted permission={Permission.ActionAdd}>
              <Link to={Routes.AddActionStrategy}>
                <Button data-test-id="add-action" type="primary">
                  {t('actions.strategy.add.cta')}
                </Button>
              </Link>
            </Restricted>
            {config.featureFlag.isExportActionsEnabled && actions.length ? (
              <Restricted permission={Permission.ActionRead}>
                <ActionsListMenu
                  exportActions={() => {
                    notifyPromise(
                      t,
                      () => exportActionsXlsx(entityId, entityName),
                      {
                        errorNotification: t(
                          'actions.strategy.export.notification.error',
                        ),
                        reportError: 'error',
                        successNotification: t(
                          'actions.strategy.export.notification.success',
                        ),
                      },
                    );
                  }}
                />
              </Restricted>
            ) : null}
          </Space>
        </Space>
        <Typography.Paragraph>
          {t('actions.strategy.info')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.actions')}
        />
        {actions.length === 0 && !loading ? (
          <EmptyState
            title={t(
              noFilters
                ? 'actions.strategy.empty.titleNoData'
                : 'actions.strategy.empty.titleNoResult',
            )}
            description={t('actions.strategy.empty.description')}
            picture={images.actionPerson}
            cta={
              isAllowedTo(Permission.ActionAdd)
                ? {
                    label: t('actions.strategy.empty.ctaLabel'),
                    to: Routes.AddActionStrategy,
                  }
                : undefined
            }
          />
        ) : (
          <Table
            loading={loading}
            data-test-id="actions-table"
            dataSource={actionsDataSource(actions)}
            columns={actionsColumns(refetch)}
            pagination={{
              total: data?.action_aggregate.aggregate?.count || 0,
              pageSize: resultsPerPage,
              current: filtersValues.page,
            }}
            onChange={(pagination, _filters, sorter) => {
              onPaginationChange(pagination);
              onSortChange(sorter as SorterResult<ActionRow>);
            }}
            onRow={(record) => ({
              onClick: () =>
                history.push(actionStrategyDetailsRoute(record.key)),
            })}
          />
        )}
      </Space>
    </ConnectedLayout>
  );
};

export default ActionsStrategyPage;
