import { Form as AntdForm, Input, Popover } from 'antd';
import {
  formikStatus,
  formikErrorMessage,
  getAllPasswordRules,
} from 'technical/form';
import { email, password } from 'business/user/validations/string';
import classnames from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Space from 'ui/space';
import * as yup from 'yup';
import Form from 'ui/form';
import Typography from 'ui/typography';
import { InfoCircleTwoTone } from '@ant-design/icons';
import styles from './index.module.scss';
import NotConnectedCard from '../notConnectedCard';
import variables from '../../../../ui/variables.module.scss';

export interface Values {
  login: string;
  password: string;
}

interface Props {
  submitButton: {
    label: string;
    onClick: (
      values: Values,
      formikHelpers: FormikHelpers<Values>,
    ) => void | Promise<any>;
  };
  errorResetPassword: string;
  title?: string;
  description?: string;
  bottomHint?: React.ReactNode;
  className?: string;
  inputType: 'email' | 'password';
}

const initialValues: Values = { login: '', password: '' };

export default function ResetPasswordCard({
  submitButton,
  errorResetPassword,
  title,
  description,
  bottomHint,
  className,
  inputType,
}: Props) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: yup.object().shape(
      inputType === 'email'
        ? {
            login: email(t),
          }
        : {
            password: password(t),
          },
    ),
    onSubmit: submitButton.onClick,
  });

  return (
    <NotConnectedCard className={classnames(className)}>
      <AntdForm onFinish={formik.handleSubmit}>
        <Space direction="vertical" align="center">
          <AppLogo />

          {title ? (
            <Typography.Title level={5}>{title}</Typography.Title>
          ) : null}
          <Typography.Paragraph>{description}</Typography.Paragraph>
          <Space direction="vertical" size="small">
            {inputType === 'email' && (
              <Form.Item
                label={t('notConnected.login.email')}
                help={formikErrorMessage(formik, 'login')}
                validateStatus={formikStatus(formik, 'login')}
              >
                <Input
                  data-test-id="email-input"
                  id="login"
                  onChange={(e) => {
                    formik.setTouched({ ...formik.touched, login: undefined });
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            )}
            {inputType === 'password' && (
              <Form.Item
                label={
                  <Space size="extra-small" align="center">
                    <span> {t('notConnected.login.resetPassword')} </span>
                    <Popover
                      placement="top"
                      title={t('errors.rules-title')}
                      content={getAllPasswordRules(t).map((rule) => (
                        <div className={styles.popoverRules}>{rule}</div>
                      ))}
                      trigger="hover"
                    >
                      <InfoCircleTwoTone twoToneColor={variables.purpleLight} />
                    </Popover>
                  </Space>
                }
                help={formikErrorMessage(formik, 'password')}
                validateStatus={formikStatus(formik, 'password')}
                required
              >
                <Form.PasswordInput
                  id="password"
                  data-test-id="password-input"
                  required
                  onChange={(e) => {
                    formik.setTouched({
                      ...formik.touched,
                      password: true,
                    });
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            )}
          </Space>
          <Form.Error message={errorResetPassword} />
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            htmlType="submit"
          >
            {submitButton.label}
          </Button>

          {bottomHint && <p>{bottomHint}</p>}
        </Space>
      </AntdForm>
    </NotConnectedCard>
  );
}
