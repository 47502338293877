import {
  campaignAnswersData,
  campaignAnswersColumn,
} from 'business/campaigns/services/table';
import InformationDetails from 'business/common/components/informationDetails';
import { CampaignFragment } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';

interface CampaignResponseProps {
  responses: CampaignFragment['responses'];
  questions: CampaignFragment['questions'];
}

const CampaignResponse: React.FC<CampaignResponseProps> = ({
  responses,
  questions,
}) => {
  const { t } = useTranslation();

  return (
    <InformationDetails title={t('campaigns.strategy.details.responses.title')}>
      <Table
        scroll={{ x: 'max-content' }}
        data-test-id="campaigns-answers"
        columns={campaignAnswersColumn(questions)}
        dataSource={campaignAnswersData(responses)}
        sortDirections={[]}
      />
    </InformationDetails>
  );
};

export default CampaignResponse;
