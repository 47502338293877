import { useEffect, useState } from 'react';
import apolloClient from 'technical/graphql/client';
import {
  addSelectedEntityIdInStorage,
  PermissionType,
} from 'business/user/services/permission';
import {
  CommonEntityFieldsFragment,
  GetEntityQuery,
  GetEntityQueryVariables,
  GetEntityDocument,
} from 'generated/graphql';
import createFindInTreeFunction from 'technical/tree';
import { FormattedEntity, FormattedUserPermission } from '../types/user';
import { isAllowedToSelectChild } from '../permissions/permissions';
import {
  impersonateEntity,
  removeImpersonateToken,
} from '../services/impersonate';

export interface Entity extends FormattedEntity {
  children: Entity[];
}

export type RawEntity = CommonEntityFieldsFragment & {
  childrenEntities?: RawEntity[];
};

const formatEntity = (rawEntity: RawEntity): Entity => ({
  entityId: rawEntity.id,
  entityName: rawEntity.name,
  entityType: rawEntity.type,
  entityTags: rawEntity.entityTags.map((entityTag) => entityTag.tag.label),
  providers: {
    entityProviderId: rawEntity.entityProviderId ?? null,
    userProviderId: rawEntity.userProviderId ?? null,
  },
  children:
    rawEntity.childrenEntities?.map((childEntity) =>
      formatEntity(childEntity),
    ) ?? [],
});

async function getEntity(id: string) {
  const res = await apolloClient.query<GetEntityQuery, GetEntityQueryVariables>(
    {
      query: GetEntityDocument,
      variables: { id },
      context: {
        getRootEntity: true,
      },
    },
  );
  if (!res.data?.entity_by_pk) {
    throw new Error(`[GET ENTITY] Entity with id '${id}' not found`);
  }
  return formatEntity(res.data.entity_by_pk);
}

export interface EntitySelection {
  rootEntity: Entity;
  selectedEntity: FormattedEntity | null;
  selectEntity: (entity: Entity) => Promise<void>;
  loading: boolean;
}

function useEntitySelection(
  selectedPermission: FormattedUserPermission,
): EntitySelection {
  const [rootEntity, setRootEntity] = useState<Entity>({
    ...selectedPermission,
    children: [],
  });
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);

  useEffect(() => {
    setRootEntity({
      ...selectedPermission,
      children: [],
    });
    setSelectedEntity(null);
    removeImpersonateToken();
    if (!isAllowedToSelectChild(selectedPermission.role)) {
      return;
    }

    getEntity(selectedPermission.entityId).then((entity) => {
      // Set the selected entity to the one already in localstorage if it exists
      const entityId = localStorage.getItem(
        PermissionType.SUPERVISOR_IMPERSONATE_PERMISSION,
      );
      const storedRootEntity = entityId
        ? createFindInTreeFunction('entityId', 'children')(entityId, entity)
        : null;

      const entityToUse = storedRootEntity ?? entity;
      setRootEntity(entity);
      if (entityToUse.entityId !== entity.entityId) {
        impersonateEntity(entityToUse.entityId).then(() => {
          apolloClient.cache.reset();
          setSelectedEntity(entityToUse);
        });
      } else {
        setSelectedEntity(entityToUse);
      }
      addSelectedEntityIdInStorage(entityToUse.entityId);
    });
  }, [selectedPermission]);

  const selectEntity = async (entity: Entity) => {
    addSelectedEntityIdInStorage(entity.entityId);
    await impersonateEntity(entity.entityId);
    apolloClient.cache.reset();
    setSelectedEntity(entity);
  };

  return {
    rootEntity,
    selectedEntity,
    selectEntity,
    loading: !rootEntity || !selectedEntity,
  };
}

export default useEntitySelection;
