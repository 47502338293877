import { CampaignListQuery } from 'generated/graphql';
import React from 'react';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { useHistory } from 'react-router';
import { campaignStrategyDetailsRoute } from 'business/router/routes';
import styles from './index.module.scss';
import CampaignStatusTag from '../campaignStatusTag';
import InfoBar from '../infoBar';
import CampaignBaseCard from '../campaignBaseCard';

interface CampaignCardProps {
  campaign: CampaignListQuery['campaign'][number];
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  const history = useHistory();

  return (
    <CampaignBaseCard
      onClick={() => history.push(campaignStrategyDetailsRoute(campaign.id))}
      data-test-id="card-campaign"
      color={
        campaign.campaignIndicators[0]?.indicator.indicatorTemplate
          .indicatorTemplateStakes[0].stake.theme.color || 'transparent'
      }
    >
      <CampaignStatusTag
        className={styles.campaignStatus}
        status={campaign.status}
      />
      <Space align="start" direction="vertical">
        <Typography.Text data-test-id="campaign-name" emphase>
          {campaign.name}
        </Typography.Text>
        <InfoBar
          numberOfAssociatedIndicators={campaign.campaignIndicators.length}
          numberOfQuestions={campaign.numberOfQuestions}
          isExternal={campaign.isExternal}
        />
      </Space>
    </CampaignBaseCard>
  );
};

export default CampaignCard;
