import React from 'react';
import Tag from 'ui/tag';
import { ThemeFragment } from 'generated/graphql';

interface ThemeTagProps {
  theme: ThemeFragment;
}

const ThemeTag: React.FC<ThemeTagProps> = ({ theme }) => {
  return <Tag color={theme.color}>{theme.label}</Tag>;
};

export default ThemeTag;
