import errorReporting from 'technical/error-reporting';
import {
  LOGOUT_USER,
  RENEW_TOKEN,
  SIGN_IN_USER,
  SIGN_UP_USER,
  ASK_FOR_PASSWORD_RESET,
  RESET_PASSWORD,
} from 'business/user/services/queries';
import client from 'technical/graphql/client';
import history from 'technical/history';
import { i18n } from 'translations';
import jwt from 'jsonwebtoken';
import { setUserId } from 'technical/analytics';
import Routes from 'business/router/routes';
import logger from 'technical/logger';

interface CustomAuthToken {
  'https://hasura.io/jwt/claims': {
    'x-hasura-default-role': string;
    'x-hasura-allowed-roles': string[];
    'x-hasura-user-identity-id': string;
    'x-hasura-user-email': string;
  };
  exp: number; // timestamp d’expiration(15 minutes)
}

let authResult: CustomAuthToken | null = null;
let accessToken: string | null = null;

export const goToLogin = () => {
  history.push(Routes.SignIn);
};

export const goToSignUp = () => {
  // history.push(Routes.SignUp);
  logger.warn('[Auth Error] There is no signup in this app');
};

export function isAuthenticated() {
  return !!authResult;
}

export function getAccessToken() {
  return accessToken;
}

export function persistAuth(token: string): void {
  accessToken = token;
  authResult = jwt.decode(token) as CustomAuthToken;
}

export function unpersistAuth(): void {
  authResult = null;
  accessToken = null;
  setUserId(undefined);
  errorReporting.removeUser();
}

export async function renewToken() {
  const { data, errors } = await client.mutate({
    mutation: RENEW_TOKEN,
    fetchPolicy: 'no-cache',
    context: {
      sendWithoutJwt: true,
      getRootEntity: true,
    },
  });

  if (errors) {
    throw new Error('Token renewal have failed');
  }

  if (data.queryRefreshToken.success) {
    persistAuth(data.queryRefreshToken.idToken);
  } else {
    throw new Error(data.queryRefreshToken.message);
  }
}

export const initAuthentication = async () => {
  if (authResult && authResult.exp > new Date().getTime() / 1000) {
    return;
  }
  try {
    await renewToken();
  } catch (error) {
    if (error instanceof Error && error.message !== 'login-required') {
      errorReporting.error(error);
    }
  }
};

export const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string,
) => {
  const { data } = await client.mutate({
    mutation: SIGN_UP_USER,
    variables: {
      email,
      password,
      passwordConfirmation,
    },
  });

  if (!data?.signUp?.success) {
    throw new Error(data.signUp.message);
  }
};

export const signIn = async (email: string, password: string) => {
  const { data } = await client.mutate({
    mutation: SIGN_IN_USER,
    variables: {
      email,
      password,
    },
  });

  if (data?.signIn?.success) {
    // 1. Store Token
    persistAuth(data.signIn.idToken);
    // 2. Go To LoginCallBack so the app can "requestReboostrap" and handle the user as logged-in
    history.push(Routes.LoginCallback);
  } else {
    throw new Error(data.signIn.message);
  }
};

export const requestLoginCallback = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    if (authResult) {
      return resolve();
    }

    return reject(new Error('An error occured during authentication'));
  });

export const getAuthResult = () => authResult;

export const getAuthId = () =>
  authResult?.['https://hasura.io/jwt/claims']['x-hasura-user-identity-id'];

export const getAuthEmail = () =>
  authResult?.['https://hasura.io/jwt/claims']['x-hasura-user-email'];

export const logout = async () => {
  localStorage.removeItem('userPermission');
  localStorage.removeItem('userEntityType');
  unpersistAuth();
  await client.mutate({
    mutation: LOGOUT_USER,
  });
  // Instead of using History, we use window.location so
  // The app is re-bootstrap and informations concerning our user
  // are up to date: ie he's not here anymore
  window.location.href = Routes.Home;
};

export const forgotPassword = async (email: string) => {
  const { data, errors } = await client.mutate({
    mutation: ASK_FOR_PASSWORD_RESET,
    variables: {
      email,
    },
  });
  if (errors || !data?.askForPasswordReset?.success) {
    throw new Error(i18n.t('errors.generic'));
  }
};

// Token can be null because if the user is logged in he does not require
// a token to reset his password
export const resetPassword = async (
  token: string | null,
  password: string,
  passwordConfirmation: string,
) => {
  const { data, errors } = await client.mutate({
    mutation: RESET_PASSWORD,
    variables: {
      resetPasswordToken: token,
      password,
      passwordConfirmation,
    },
  });

  if (errors) {
    throw new Error(i18n.t('errors.generic'));
  }
  if (!data.resetPassword.success) {
    throw new Error(
      i18n.t('errors.resetPassword', { context: data.resetPassword.message }),
    );
  }

  if (isAuthenticated()) {
    unpersistAuth();
    window.location.href = Routes.SignIn;
  }
};
