import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AppLogo from 'ui/appLogo';
import Space from 'ui/space';
import Typography from 'ui/typography';
import FancyTable from 'business/documentation/component/fancyTable';
import List, { Item } from 'ui/list';
import { useThemeListQuery } from 'generated/graphql';
import Button from 'ui/button';
import Routes from 'business/router/routes';
import { Link } from 'react-router-dom';
import DocumentationLayout from 'business/documentation/layout/documentationLayout';
import Hat from '../../component/hat';
import styles from './index.module.scss';

const CaliDocumentationPage: React.FC = () => {
  const { t } = useTranslation();

  const { data } = useThemeListQuery();

  const themes = data?.theme || [];

  const columnDefs = themes.map((theme, index) => ({
    label: theme.label,
    color: theme.color,
    key: index,
  }));

  const context = 'documentation.cali.howItWorks.table.rows';

  const rowDefs = [
    {
      title: t(`${context}.stake.title`),
      data: {
        0: [
          t(`${context}.stake.environnment.carbon`),
          t(`${context}.stake.environnment.mobility`),
          t(`${context}.stake.environnment.energy`),
          t(`${context}.stake.environnment.water`),
          t(`${context}.stake.environnment.waste`),
          t(`${context}.stake.environnment.biodiversity`),
          t(`${context}.stake.environnment.resilience`),
          t(`${context}.stake.environnment.polution`),
        ],
        1: [
          t(`${context}.stake.social.security`),
          t(`${context}.stake.social.diversity`),
          t(`${context}.stake.social.feminisation`),
          t(`${context}.stake.social.teaching`),
          t(`${context}.stake.social.youthJobs`),
          t(`${context}.stake.social.health`),
          t(`${context}.stake.social.workQuality`),
          t(`${context}.stake.social.hrValorisation`),
          t(`${context}.stake.social.positiveTerritorialImpact`),
        ],
        2: [
          t(`${context}.stake.governance.buyAndInvests`),
          t(`${context}.stake.governance.clientRelationship`),
          t(`${context}.stake.governance.ethique`),
          t(`${context}.stake.governance.governanceCsr`),
          t(`${context}.stake.governance.normsLabelsCertifications`),
          t(`${context}.stake.governance.numericAndGovernanceSI`),
        ],
      },
    },
    {
      title: t(`${context}.indicators.title`),
      data: {
        0: [
          t(`${context}.indicators.environnment.carbon`),
          t(`${context}.indicators.environnment.water`),
          t(`${context}.indicators.environnment.waste`),
          t(`${context}.indicators.environnment.kms`),
          t(`${context}.indicators.environnment.climat`),
        ],
        1: [
          t(`${context}.indicators.social.accident`),
          t(`${context}.indicators.social.turnover`),
          t(`${context}.indicators.social.qvt`),
          t(`${context}.indicators.social.teaching`),
          t(`${context}.indicators.social.equality`),
        ],
        2: [
          t(`${context}.indicators.governance.r&d`),
          t(`${context}.indicators.governance.outsourcing`),
          t(`${context}.indicators.governance.clientSatisfactionRate`),
          t(`${context}.indicators.governance.itGreen`),
        ],
      },
    },
    {
      title: t(`${context}.actions.title`),
      data: {
        0: [
          t(`${context}.actions.environnment.cars`),
          t(`${context}.actions.environnment.plane`),
          t(`${context}.actions.environnment.collaborators`),
          t(`${context}.actions.environnment.qualityOfProducts`),
        ],
        1: [
          t(`${context}.actions.social.manager`),
          t(`${context}.actions.social.forums`),
          t(`${context}.actions.social.qvt`),
          t(`${context}.actions.social.sport`),
        ],
        2: [
          t(`${context}.actions.governance.esg`),
          t(`${context}.actions.governance.digitalWaste`),
          t(`${context}.actions.governance.ecovadis`),
          t(`${context}.actions.governance.client`),
        ],
      },
    },
  ];

  return (
    <DocumentationLayout>
      <Space direction="vertical">
        <Space size="small" align="center" justify="center">
          <Typography.Title className={styles.titleWithLogo} level={1}>
            {t('documentation.cali.title')}
          </Typography.Title>
          <AppLogo />
        </Space>
        <Hat>{t('documentation.cali.introduction')}</Hat>
        <Space direction="vertical">
          <Typography.Title level={2}>
            {t('documentation.cali.whereDoCaliComeFrom.title')}
          </Typography.Title>
          <Typography.Paragraph>
            <Trans t={t} i18nKey="documentation.cali.whereDoCaliComeFrom.text">
              a<Typography.Text strong>b</Typography.Text>c
              <Typography.Text strong>d</Typography.Text>e
            </Trans>
          </Typography.Paragraph>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={2}>
            {t('documentation.cali.howItWorks.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.cali.howItWorks.introduction')}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t('documentation.cali.howItWorks.tree.title')}
          </Typography.Title>
          <List>
            <Item>
              <Trans t={t} i18nKey="documentation.cali.howItWorks.tree.theme">
                <Typography.Text strong>a</Typography.Text>b
              </Trans>
            </Item>
            <Item>
              <Trans t={t} i18nKey="documentation.cali.howItWorks.tree.stake">
                <Typography.Text strong>a</Typography.Text>b
              </Trans>
            </Item>
            <Item>
              <Trans
                t={t}
                i18nKey="documentation.cali.howItWorks.tree.indicator"
              >
                <Typography.Text strong>a</Typography.Text>b
              </Trans>
            </Item>
            <Item>
              <Trans t={t} i18nKey="documentation.cali.howItWorks.tree.goal">
                <Typography.Text strong>a</Typography.Text>b
              </Trans>
            </Item>
            <Item>
              <Trans t={t} i18nKey="documentation.cali.howItWorks.tree.action">
                <Typography.Text strong>a</Typography.Text>b
              </Trans>
            </Item>
          </List>
        </Space>
        <FancyTable
          columnDefs={columnDefs}
          rows={rowDefs}
          legend={t('documentation.cali.howItWorks.table.legend')}
        />
        <Typography.Title level={5}>
          {t('documentation.cali.howItWorks.resources.title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('documentation.cali.howItWorks.resources.text')}
        </Typography.Paragraph>
        <List>
          <Item>
            <Trans
              t={t}
              i18nKey="documentation.cali.howItWorks.resources.list.item1"
            >
              a<Typography.Text strong>b</Typography.Text>c
              <Typography.Text strong>d</Typography.Text>e
            </Trans>
          </Item>
          <Item>
            <Trans
              t={t}
              i18nKey="documentation.cali.howItWorks.resources.list.item2"
            >
              a<Typography.Text strong>b</Typography.Text>c
              <Typography.Text strong>d</Typography.Text>e
            </Trans>
          </Item>
        </List>
        <Typography.Paragraph>
          {t('documentation.cali.howItWorks.resources.next')}
        </Typography.Paragraph>
        <Space justify="center">
          <Link to={Routes.Home}>
            <Button type="primary">
              {t('documentation.cali.goToStrategy')}
            </Button>
          </Link>
        </Space>
      </Space>
    </DocumentationLayout>
  );
};

export default CaliDocumentationPage;
