import React from 'react';
import {
  SearchOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Form from 'ui/form';
import Space from 'ui/space';
import { CampaignStatus } from 'business/campaigns/services/types';
import ButtonToggle from 'ui/buttonToggle';
import { Roles } from 'business/user/types/user';
import { AllRoles } from 'business/myEntity/types';
import { getCategoryValuesFromEntityTags } from 'business/partners/services/categories';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { PossibleFilters } from './services/type';
import StakeAndThemeFilter from './stakeAndThemeFilter';
import styles from './index.module.scss';

type TableFiltersProps<T> = {
  filtersValue: T;
  setFilters: (filters: T) => void;
  searchPlaceholder?: string;
  showVisibilityToggle?: boolean;
};

const TableFilters = <T extends PossibleFilters>({
  filtersValue,
  setFilters,
  ...filtersInfo
}: TableFiltersProps<T>) => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityTags },
  } = useLoggedInAppContext();

  if ('showHidden' in filtersValue) {
    if (!filtersInfo.showVisibilityToggle && filtersValue.showHidden) {
      setFilters({ ...filtersValue, showHidden: false });
    }
  }

  const categoryValues = getCategoryValuesFromEntityTags(entityTags);

  return (
    <Space align="center">
      {'search' in filtersValue ? (
        <Form.Item noStyle>
          <Form.Input
            data-test-id="table-filters-search"
            suffix={<SearchOutlined />}
            placeholder={filtersInfo.searchPlaceholder}
            value={filtersValue.search}
            onChange={(e) =>
              setFilters({ ...filtersValue, search: e.target.value })
            }
          />
        </Form.Item>
      ) : null}

      {'stakeId' in filtersValue && (
        <StakeAndThemeFilter
          filterValue={filtersValue}
          setFilterValue={(value) => setFilters({ ...filtersValue, ...value })}
        />
      )}

      {'campaignStatus' in filtersValue && (
        <Form.Item noStyle>
          <Form.Select
            data-test-id="table-filters-status"
            allowClear
            clearIcon={
              <CloseCircleFilled data-test-id="table-filters-status-null" />
            }
            placeholder={t('tableFilters.campaignStatus.placeholder')}
            value={filtersValue.campaignStatus}
            options={Object.values(CampaignStatus).map((status: string) => ({
              label: t(`tableFilters.campaignStatus.${status}`),
              value: status,
              key: status,
              'data-test-id': `table-filters-status-${status}`,
            }))}
            onChange={(campaignStatus) =>
              setFilters({
                ...filtersValue,
                campaignStatus: campaignStatus as CampaignStatus,
              })
            }
          />
        </Form.Item>
      )}

      {'showHidden' in filtersValue && filtersInfo.showVisibilityToggle && (
        <ButtonToggle
          checked={filtersValue.showHidden}
          onChange={(checked) => {
            setFilters({ ...filtersValue, showHidden: checked });
          }}
          checkedChildren={<EyeOutlined />}
          uncheckedChildren={<EyeInvisibleOutlined />}
          tooltip={{
            checkedLabel: t('tableFilters.indicatorVisibility.hideHidden'),
            uncheckedLabel: t('tableFilters.indicatorVisibility.seeHidden'),
            placement: 'topLeft',
          }}
        />
      )}

      {'category' in filtersValue && (
        <Form.Item noStyle>
          <Form.Select
            showSearch
            data-test-id="table-filters-category"
            allowClear
            clearIcon={
              <CloseCircleFilled data-test-id="table-filters-category-null" />
            }
            placeholder={t('tableFilters.category.placeholder')}
            value={filtersValue.category}
            options={categoryValues.map((category) => ({
              value: category,
              label: t(`partners.category.${category}`),
              key: category,
              'data-test-id': `table-filters-category-${category}`,
            }))}
            onChange={(category) =>
              setFilters({
                ...filtersValue,
                category: category as string,
              })
            }
          />
        </Form.Item>
      )}

      {'role' in filtersValue && (
        <Form.Item noStyle className={styles.roleFilter}>
          <Form.Select
            showSearch
            data-test-id="table-filters-role"
            allowClear
            clearIcon={
              <CloseCircleFilled data-test-id="table-filters-role-null" />
            }
            placeholder={t('tableFilters.role.placeholder')}
            value={filtersValue.role}
            options={AllRoles.map((role) => ({
              value: role,
              label: t(`roles.${role}`),
              key: role,
              'data-test-id': `table-filters-role-${role}`,
            }))}
            onChange={(role) =>
              setFilters({
                ...filtersValue,
                role: role as Roles,
              })
            }
          />
        </Form.Item>
      )}
    </Space>
  );
};

export default TableFilters;
