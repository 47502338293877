import React, { useState } from 'react';
import { Switch, Tabs, Tooltip } from 'antd';
import {
  IndicatorGraphMode,
  IndicatorStockGraphMode,
  IndicatorType,
  IndicatorUnit,
} from 'business/indicators/types';
import { useTranslation } from 'react-i18next';
import Typography from 'ui/typography';
import Space from 'ui/space';
import Dropdown from 'ui/dropdown';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import { IndicatorQuery, KeyValues, PlotData } from 'generated/graphql';
import {
  getIndicatorStakes,
  notEmptyPlotData,
} from 'business/indicators/services';
import { getThemes } from 'business/stake/services';
import { getUnitName } from 'business/indicators/services/units';
import { parameterDetailsRoute } from 'business/router/routes';
import { Link } from 'react-router-dom';
import { Mode } from 'business/indicators/services/type';
import styles from './index.module.scss';
import GraphIndicator from '../indicatorGraph';
import TrendIndicator from '../trendIndicator';

const { TabPane } = Tabs;

interface IndicatorGraphTabsProps {
  activeTab: IndicatorGraphMode;
  setActiveTab: (s: IndicatorGraphMode) => void;
  selectedYear: string;
  setSelectedYear: (s: string) => void;
  distinctYears: number[];
  indicator: IndicatorQuery['indicator_by_pk'];
  plotData: (PlotData | null)[];
  keyValues: KeyValues;
  selectedUnit: IndicatorUnit;
  setSelectedUnit: (s: IndicatorUnit) => void;
}

const IndicatorGraphTabs: React.FC<IndicatorGraphTabsProps> = ({
  activeTab,
  setActiveTab,
  selectedYear,
  distinctYears,
  setSelectedYear,
  indicator,
  plotData,
  keyValues,
  selectedUnit,
  setSelectedUnit,
}) => {
  const { t } = useTranslation();
  const { isAllowedTo } = useLoggedInAppContext();

  const [hasChildren, setHasChildren] = useState(false);
  const [graphMode, setGraphMode] = useState<IndicatorStockGraphMode>(
    IndicatorStockGraphMode.Monthly,
  );

  if (!indicator) {
    return null;
  }

  const stakes = getIndicatorStakes(indicator);
  const themes = getThemes(stakes);

  const units = indicator.computableUnits.map((computableUnit) => ({
    disabled: !!computableUnit.incompleteIndicator,
    value: computableUnit.unit,
    label: getUnitName(computableUnit.unit),
    disabledTooltip: computableUnit.incompleteIndicator ? (
      <Space direction="vertical" align="center" size="extra-small">
        <Typography.Text>
          {t('indicators.details.unavailableUnit')}
        </Typography.Text>
        <Link to={parameterDetailsRoute(computableUnit.incompleteIndicator.id)}>
          <Typography.Text>
            {t(`parameters.${computableUnit.incompleteIndicator.reference}`)}
          </Typography.Text>
        </Link>
      </Space>
    ) : null,
  }));

  const indicatorIsStockOrPhoto = [
    IndicatorType.Photo,
    IndicatorType.Stock,
  ].includes(indicator.indicatorTemplate.type as IndicatorType);

  const displayMultiYearGraphSwitch =
    distinctYears.length > 1 && indicatorIsStockOrPhoto;

  return (
    <Tabs
      activeKey={activeTab}
      onTabClick={(newActiveTab) =>
        setActiveTab(newActiveTab as IndicatorGraphMode)
      }
    >
      <TabPane
        tab={
          <Typography.Text>
            {t('indicators.details.graph.tabs.yearly')}
          </Typography.Text>
        }
        key={IndicatorGraphMode.Yearly}
      >
        <Space direction="vertical">
          <Space direction="horizontal" align="center" justify="space-between">
            {indicatorIsStockOrPhoto &&
              isAllowedTo(Permission.IndicatorsAggregate) && (
                <Switch
                  checkedChildren={t('indicators.details.graph.hasChildren')}
                  unCheckedChildren={t('indicators.details.graph.hasChildren')}
                  checked={hasChildren}
                  onChange={(checked) => setHasChildren(checked)}
                />
              )}
            <Space direction="horizontal" align="center" justify="end">
              {indicator.indicatorTemplate.type === IndicatorType.Stock && (
                <Switch
                  checkedChildren={t('indicators.details.graph.aggregated')}
                  unCheckedChildren={t('indicators.details.graph.aggregated')}
                  checked={graphMode === IndicatorStockGraphMode.Aggregated}
                  onChange={(checked) =>
                    setGraphMode(
                      checked
                        ? IndicatorStockGraphMode.Aggregated
                        : IndicatorStockGraphMode.Monthly,
                    )
                  }
                />
              )}
              <Dropdown
                options={distinctYears.map((year) => ({
                  value: year.toString(),
                }))}
                value={selectedYear}
                onChange={setSelectedYear}
              />
            </Space>
          </Space>
          <GraphIndicator
            graphMode={graphMode}
            dateMode={IndicatorGraphMode.Yearly}
            indicator={indicator}
            hasChildren={hasChildren}
            plotData={plotData.filter(notEmptyPlotData)}
            selectedUnit={selectedUnit}
            themes={themes}
          />
          <Dropdown
            options={units}
            value={selectedUnit}
            onChange={(newSelectedUnit) =>
              setSelectedUnit(newSelectedUnit as IndicatorUnit)
            }
          />
          <TrendIndicator
            positiveTrend={indicator.indicatorTemplate.positiveTrend}
            unit={selectedUnit}
            keyValues={keyValues}
            mode={Mode.Horizontal}
            indicatorType={indicator.indicatorTemplate.type as IndicatorType}
          />
        </Space>
      </TabPane>
      {displayMultiYearGraphSwitch ? (
        <TabPane
          tab={
            <Tooltip
              placement="top"
              title={t('indicators.details.graph.yearly', {
                context: indicator.indicatorTemplate.type,
              })}
            >
              <Typography.Text>
                {t('indicators.details.graph.tabs.allYears')}
              </Typography.Text>
            </Tooltip>
          }
          key={IndicatorGraphMode.AllYears}
        >
          <Space direction="vertical" align="start">
            {indicatorIsStockOrPhoto &&
              isAllowedTo(Permission.IndicatorsAggregate) && (
                <Switch
                  checkedChildren={t('indicators.details.graph.hasChildren')}
                  unCheckedChildren={t('indicators.details.graph.hasChildren')}
                  checked={hasChildren}
                  onChange={(checked) => setHasChildren(checked)}
                />
              )}
            <GraphIndicator
              graphMode={graphMode}
              dateMode={IndicatorGraphMode.AllYears}
              indicator={indicator}
              hasChildren={hasChildren}
              plotData={plotData.filter(notEmptyPlotData)}
              selectedUnit={selectedUnit}
              themes={themes}
            />
            <Dropdown
              options={units}
              value={selectedUnit}
              onChange={(newSelectedUnit) =>
                setSelectedUnit(newSelectedUnit as IndicatorUnit)
              }
            />
          </Space>
        </TabPane>
      ) : null}
    </Tabs>
  );
};

export default IndicatorGraphTabs;
