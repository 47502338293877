import { Modal as AntdModal, ModalFuncProps, ModalProps } from 'antd';
import { modalGlobalConfig, ModalStaticFunctions } from 'antd/es/modal/confirm';
import useModal from 'antd/es/modal/useModal';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/button';
import Space from 'ui/space';
import Typography from 'ui/typography';
import variables from 'ui/variables.module.scss';
import styles from './index.module.scss';

type ModalType = typeof CustomModal &
  ModalStaticFunctions & {
    useModal: typeof useModal;
    destroyAll: () => void;
    config: typeof modalGlobalConfig;
  };

const CustomModal: React.FC<ModalProps> = ({
  title,
  onOk,
  okText,
  onCancel,
  cancelText,
  width,
  okButtonProps,
  cancelButtonProps,
  centered,
  footer,
  ...rest
}) => {
  return (
    <AntdModal
      onCancel={onCancel}
      {...rest}
      title={
        <Typography.Paragraph center emphase>
          {title}
        </Typography.Paragraph>
      }
      classNames={{
        body: styles.body,
        header: styles.header,
        footer: styles.footer,
      }}
      width={width ?? 600}
      centered={centered ?? true}
      footer={
        footer || (
          <Space size="extra-small" justify="space-around">
            {onCancel && cancelText && (
              <Button {...cancelButtonProps} onClick={onCancel}>
                {cancelText}
              </Button>
            )}
            {onOk && okText && (
              <Button {...okButtonProps} onClick={onOk} type="primary">
                {okText}
              </Button>
            )}
          </Space>
        )
      }
    />
  );
};

const Modal = CustomModal as ModalType;

Modal.info = ({ width, title, icon, centered, ...rest }: ModalFuncProps) => {
  return AntdModal.info({
    ...rest,
    title: <Typography.Paragraph emphase>{title}</Typography.Paragraph>,
    width: width ?? 600,
    centered: centered ?? true,
    icon: icon ?? null,
  });
};

Modal.confirm = ({ width, title, icon, centered, ...rest }: ModalFuncProps) => {
  return AntdModal.confirm({
    ...rest,
    title: <Typography.Paragraph emphase>{title}</Typography.Paragraph>,
    width: width ?? 600,
    centered: centered ?? true,
    icon: icon ?? null,
  });
};

export default Modal;
