import { useLoggedInAppContext } from 'business/AppBootstrapper';
import {
  whichTypeIsThisRepartition,
  whichUnitIsThisRepartition,
} from 'business/indicators/services';
import {
  ensureArrayPlotDataIsArraySimplePlotData,
  IndicatorAggregationMethod,
  IndicatorGraphMode,
  IndicatorStockGraphMode,
  IndicatorType,
  IndicatorUnit,
} from 'business/indicators/types';
import { Indicator, IndicatorQuery, PlotData, Theme } from 'generated/graphql';
import React from 'react';
import variables from 'ui/variables.module.scss';
import getGraphStrategyForGraphIndicator from './getStrategyForGraphIndicator';

interface GraphIndicatorProps {
  indicator: IndicatorQuery['indicator_by_pk'];
  graphMode?: IndicatorStockGraphMode;
  dateMode?: IndicatorGraphMode;
  hasChildren: boolean;
  plotData: PlotData[];
  selectedUnit: IndicatorUnit;
  themes: Theme[];
}

const GraphIndicator: React.FC<GraphIndicatorProps> = ({
  indicator,
  graphMode,
  dateMode,
  hasChildren,
  plotData,
  selectedUnit,
  themes,
}) => {
  const context = useLoggedInAppContext();

  if (!indicator?.indicatorTemplate || plotData.length === 0) {
    return null;
  }

  const type = indicator.indicatorTemplate.type as IndicatorType;

  if (type === IndicatorType.Photo && plotData.length < 2) {
    return null;
  }

  if (type === IndicatorType.Repartition && plotData.length === 0) {
    return null;
  }

  if (
    ensureArrayPlotDataIsArraySimplePlotData(plotData) &&
    plotData.filter((plot) => plot.value !== null).length === 0
  ) {
    return null;
  }

  if (
    type === IndicatorType.Stock &&
    graphMode === IndicatorStockGraphMode.Aggregated &&
    plotData.length < 2
  ) {
    return null;
  }

  const unit =
    indicator.indicatorTemplate.type === IndicatorType.Repartition
      ? whichUnitIsThisRepartition(indicator as Indicator)
      : selectedUnit;
  const aggregationMethod = indicator.indicatorTemplate
    .aggregationMethod as IndicatorAggregationMethod;
  const goals = indicator.indicatorGoals;
  let objective = null;
  if (selectedUnit === unit) {
    objective = goals.length
      ? goals[indicator.indicatorGoals.length - 1].value
      : null;
  }

  const result = getGraphStrategyForGraphIndicator(
    indicator as Indicator,
    type,
    unit,
    context,
    hasChildren,
    aggregationMethod,
    plotData.length,
    graphMode,
    dateMode,
  );

  if (!result) {
    return null;
  }

  const { component, computation } = result;

  return component({
    data: computation(plotData as PlotData[], unit, objective),
    type:
      type === IndicatorType.Repartition
        ? whichTypeIsThisRepartition(indicator as Indicator)
        : type,
    color: themes.length ? themes[0].color : variables.purpleLight,
    plotData,
    unit,
    dateMode,
  });
};

export default GraphIndicator;
