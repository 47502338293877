/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/browser';
import config, { ReactAppEnv } from 'config';

interface IErrorReporting {
  error(error: Error, errorInfo?: unknown): void;
  info(error: Error, errorInfo?: unknown): void;
  warning(error: Error, errorInfo?: unknown): void;
  setUser(user: { id: string; username?: string; email?: string }): void;
  removeUser(): void;
}

/**
 * Main class
 */
class ErrorReporting implements IErrorReporting {
  constructor(key: string) {
    this.init(key);
  }

  private init(key: string): void {
    return Sentry.init({
      dsn: key,
      environment: config.env,
    });
  }

  private log(level: Sentry.Severity, error: Error, errorInfo?: object) {
    return Sentry.withScope((scope) => {
      if (errorInfo) {
        scope.setExtras(errorInfo as Record<string, unknown>);
      }
      scope.setLevel(level);
      Sentry.captureException(error);
    });
  }

  public setUser(user: Sentry.User) {
    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }

  public removeUser() {
    Sentry.configureScope((scope) => {
      scope.setUser({ username: '', id: '', email: '' });
    });
  }

  public error(error: Error, errorInfo?: object) {
    this.log(Sentry.Severity.Error, error, errorInfo);
  }

  public info(error: Error, errorInfo?: object) {
    if (config.env === ReactAppEnv.Development) {
      this.log(Sentry.Severity.Info, error, errorInfo);
    }
  }

  public warning(error: Error, errorInfo?: object) {
    this.log(Sentry.Severity.Warning, error, errorInfo);
  }
}

/**
 * Mocks class
 */
class ErrorReportingMocks implements IErrorReporting {
  setUser() {}

  error() {}

  info() {}

  warning() {}

  removeUser() {}
}

const errorReporting: IErrorReporting = config.errorReporting.secret
  ? new ErrorReporting(config.errorReporting.secret)
  : new ErrorReportingMocks();

export default errorReporting;
