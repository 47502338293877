import React from 'react';
import { Tag, TagProps } from 'antd';
import { ActionStatus } from 'business/actions/types';
import colors from 'config/colors';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './index.module.scss';

interface StatusTagProps extends TagProps {
  status: ActionStatus;
}

const StatusTag: React.FC<StatusTagProps> = ({
  status,
  className,
  ...rest
}) => {
  const { t } = useTranslation();
  let color;
  switch (status) {
    case ActionStatus.Incoming:
      color = colors.emphasisColor;
      break;
    case ActionStatus.InProgress:
      color = colors.primary;
      break;
    case ActionStatus.Finished:
      color = colors.success;
      break;
    default:
      color = colors.neutral;
  }

  return (
    <Tag className={classnames(styles.tag, className)} color={color} {...rest}>
      {t(`actions.status.${status}`)}
    </Tag>
  );
};

export default StatusTag;
