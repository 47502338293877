import React from 'react';
import { Spin, SpinProps } from 'antd';
import Space from 'ui/space';
import styles from './index.module.scss';

const Loader: React.FC<SpinProps> = (props) => (
  <Space
    className={styles.loader}
    direction="vertical"
    align="center"
    justify="center"
  >
    <Spin {...props} />
  </Space>
);

export default Loader;
