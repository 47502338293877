import React, { useState } from 'react';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { useEditCampaignNameMutation } from 'generated/graphql';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import styles from './index.module.scss';

interface CampaignNameProps {
  campaign: {
    id: string;
    name: string;
  };
}

function CampaignName({ campaign }: CampaignNameProps) {
  const { isAllowedTo } = useLoggedInAppContext();

  const [campaignName, setCampaignName] = useState(campaign.name);
  const [editCampaignName] = useEditCampaignNameMutation();

  return (
    <Space direction="horizontal" className={styles.title}>
      <Typography.Title
        data-test-id="campaign-name"
        level={3}
        editable={
          isAllowedTo(Permission.CampaignUpdate) && {
            onChange: async (name) => {
              const trimedName = name.trim();
              if (trimedName.length === 0) {
                return;
              }
              setCampaignName(trimedName);
              await editCampaignName({
                variables: {
                  campaignId: campaign.id,
                  name: trimedName,
                },
              });
            },
            onCancel: () => setCampaignName(campaign.name),
            autoSize: { maxRows: 1 },
          }
        }
      >
        {campaignName}
      </Typography.Title>
    </Space>
  );
}

export default CampaignName;
