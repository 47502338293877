import Routes from 'business/router/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'ui/button';
import images from 'ui/images';
import Space from 'ui/space';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface EmptyStateProps {
  title?: string;
  description?: string;
  picture?: string;
  dispose?: string;
  cta?: {
    label: string;
    to: Routes | string;
  };
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  description,
  picture,
  cta,
  dispose,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Space
      direction="vertical"
      align="center"
      size="large"
      data-test-id="empty-state"
    >
      {title && <Typography.Text emphase>{title}</Typography.Text>}
      {description && (
        <Typography.Paragraph className={styles.description} center>
          {description}
        </Typography.Paragraph>
      )}
      <img
        src={picture || images.lostPerson}
        alt={t('common.noDataAlt')}
        className={styles.img}
      />
      {dispose && <Typography.Paragraph center>{dispose}</Typography.Paragraph>}
      {cta ? (
        <Link to={cta.to}>
          <Button type="primary">{cta.label}</Button>
        </Link>
      ) : null}
      {children}
    </Space>
  );
};

export default EmptyState;
