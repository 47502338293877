import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import React from 'react';

const PasswordInput: React.FC<InputProps> = (props) => {
  return (
    <Input.Password
      {...props}
      iconRender={(visible) =>
        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
      }
    />
  );
};

export default PasswordInput;
