import React from 'react';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { CampaignTemplateRowFragment } from 'generated/graphql';
import { useHistory } from 'react-router';
import { campaignCatalogDetailsRoute } from 'business/router/routes';
import InfoBar from '../infoBar';
import CampaignBaseCard from '../campaignBaseCard';

interface CampaignCardProps {
  campaignTemplate: CampaignTemplateRowFragment;
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaignTemplate }) => {
  const history = useHistory();
  return (
    <CampaignBaseCard
      color={
        campaignTemplate.indicatorTemplateCampaignTemplates[0].indicatorTemplate
          .indicatorTemplateStakes[0].stake.theme.color
      }
      data-test-id="card-campaign-template"
      onClick={() =>
        history.push(campaignCatalogDetailsRoute(campaignTemplate.id))
      }
    >
      <Space>
        <Space direction="vertical" size="small">
          <Typography.Title data-test-id="campaign-template-name" level={5}>
            {campaignTemplate.name}
          </Typography.Title>
          <InfoBar
            numberOfAssociatedIndicators={
              campaignTemplate.indicatorTemplateCampaignTemplates.length
            }
            numberOfQuestions={campaignTemplate.numberOfQuestions}
            isExternal={campaignTemplate.isExternal}
          />
        </Space>
      </Space>
    </CampaignBaseCard>
  );
};

export default CampaignCard;
