import { useLoggedInAppContext } from 'business/AppBootstrapper';
import AddMissingIndicatorsModal, {
  AddMissingIndicatorsModalContext,
} from 'business/indicators/components/addMissingIndicatorsModal';
import IndicatorTemplateTabs from 'business/indicators/components/indicatorTemplateTabs';
import ThemeAndStakesDetails from 'business/common/components/themeAndStakesDetails';
import {
  getIndicatorTemplateStakes,
  getLinkedIndicatorTemplatesNotInMyStrategy,
  isConnectedIndicator,
} from 'business/indicators/services';
import Routes, {
  indicatorCatalogDetailsRoute,
  indicatorStrategyDetailsRoute,
} from 'business/router/routes';
import { getThemes } from 'business/stake/services';
import SubPageLayout from 'business/user/layout/subPage';
import { Permission } from 'business/user/types/user';
import {
  useAddIndicatorMutation,
  useIndicatorTemplateQuery,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import Button from 'ui/button';
import Loader from 'ui/loader';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';
import IndicatorTemplateConnectorMarker from 'business/indicators/components/indicatorTemplateConnectorMarker';
import { IndicatorCatalogDetailsRouteSlug, ensureSlugIsValid } from './type';

interface IndicatorCatalogDetailsRouteParams {
  id: string;
  slug?: string;
}
const IndicatorCatalogDetailsPage: React.FC = () => {
  const { id, slug } = useParams<IndicatorCatalogDetailsRouteParams>();
  const { t } = useTranslation();
  const {
    isAllowedTo,
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const history = useHistory();
  const [
    isModalAddMissingIndicatorsVisible,
    setIsModalAddMissingIndicatorsVisible,
  ] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addIndicator] = useAddIndicatorMutation();

  const { data } = useIndicatorTemplateQuery({
    variables: {
      id,
    },
  });

  const [activeTab, setActiveTab] = useState<IndicatorCatalogDetailsRouteSlug>(
    ensureSlugIsValid(slug) ? slug : 'description',
  );

  useEffect(() => {
    if (slug !== activeTab) {
      history.replace(indicatorCatalogDetailsRoute(id, activeTab));
    }
  }, [activeTab]);

  useEffect(() => {
    if (slug !== activeTab && ensureSlugIsValid(slug)) {
      setActiveTab(slug);
      history.replace(indicatorCatalogDetailsRoute(id, slug));
    }
  }, [slug]);

  const indicatorTemplate = data?.indicatorTemplate_by_pk;

  if (!indicatorTemplate) {
    return (
      <SubPageLayout goBack={Routes.IndicatorsCatalog}>
        <Loader />
      </SubPageLayout>
    );
  }

  const stakes = getIndicatorTemplateStakes(indicatorTemplate);
  const themes = getThemes(stakes);
  const linkedIndicatorTemplatesNotInMyStrategy =
    getLinkedIndicatorTemplatesNotInMyStrategy(indicatorTemplate);

  const addIndicatorToStrategy = async () => {
    setLoading(true);

    // 1. Add indicator & linkedIndicators & links to actions and campaigns
    const indicatorId = await addIndicator({
      variables: {
        entityId,
        indicatorTemplateId: indicatorTemplate.id,
      },
    }).then((e) => e.data?.addIndicatorToEntity.indicatorId);

    if (indicatorId === undefined) {
      throw new Error(
        `Error while adding indicator of id ${indicatorTemplate.id}`,
      );
    }

    // 2. Show success modal
    Modal.confirm({
      title: t('indicators.catalog.add.successTitle'),
      content: (
        <Typography.Paragraph>
          {t('indicators.catalog.add.successContent')}
        </Typography.Paragraph>
      ),
      okText: t('indicators.catalog.add.successOkCta'),
      cancelText: t('indicators.catalog.add.successCancelCta'),
      onOk: () => history.push(indicatorStrategyDetailsRoute(indicatorId)),
      onCancel: () =>
        history.length > 1
          ? history.goBack()
          : history.push(Routes.IndicatorsCatalog),
      okButtonProps: {
        id: 'see-my-indicator',
      },
    });

    setLoading(false);
  };

  const handleAddIndicatorCta = async () => {
    if (linkedIndicatorTemplatesNotInMyStrategy.length === 0) {
      await addIndicatorToStrategy();
    } else {
      setIsModalAddMissingIndicatorsVisible(true);
    }
  };

  return (
    <SubPageLayout goBack={Routes.IndicatorsCatalog}>
      <Space direction="vertical" size="large">
        <Space justify="space-between">
          <Typography.Title data-test-id="indicator-name" level={3}>
            {indicatorTemplate.label}
            &nbsp;
            {isConnectedIndicator({ indicatorTemplate }) ? (
              <IndicatorTemplateConnectorMarker />
            ) : null}
          </Typography.Title>
          {isAllowedTo(Permission.IndicatorsAdd) &&
          indicatorTemplate.indicators.length === 0 ? (
            <Button
              data-test-id="add-indicator"
              data-onboarding-id="add-indicator"
              type="primary"
              onClick={handleAddIndicatorCta}
              loading={loading}
            >
              {t('indicators.details.addIndicator')}
            </Button>
          ) : null}
        </Space>
        <ThemeAndStakesDetails themes={themes} stakes={stakes} />
        <IndicatorTemplateTabs
          activeTab={activeTab}
          setActiveTab={(newTag: IndicatorCatalogDetailsRouteSlug) =>
            setActiveTab(newTag)
          }
          indicatorTemplate={indicatorTemplate}
        />
      </Space>
      <AddMissingIndicatorsModal
        editable={false}
        context={AddMissingIndicatorsModalContext.Indicators}
        isVisible={isModalAddMissingIndicatorsVisible}
        missingIndicators={linkedIndicatorTemplatesNotInMyStrategy}
        handleSubmit={addIndicatorToStrategy}
        handleClose={() => setIsModalAddMissingIndicatorsVisible(false)}
      />
    </SubPageLayout>
  );
};

export default IndicatorCatalogDetailsPage;
