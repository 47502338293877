import React from 'react';
import { Permission, Roles } from 'business/user/types/user';
import { FormikProps } from 'formik';
import { formatNumber } from 'technical/number/format';
import { i18n } from 'translations';
import Form from 'ui/form';
import * as yup from 'yup';
import { IndicatorUnit } from '../types';

// Validation
const positiveDecimalNumber = yup
  .number()
  .min(0, i18n.t('errors.indicatorValues.min'))
  .required(i18n.t('errors.required'));
const positiveIntegerNumber = yup
  .number()
  .integer(i18n.t('errors.integer'))
  .min(0, i18n.t('errors.indicatorValues.min'))
  .required(i18n.t('errors.required'));
const booleanNumber = yup
  .number()
  .oneOf([0, 1])
  .required(i18n.t('errors.required'));
const classeDPENumber = yup
  .number()
  .oneOf([0, 1, 2, 3, 4, 5, 6])
  .required(i18n.t('errors.required'));

const classeSignNumber = yup
  .number()
  .oneOf([0, 1, 2, 3, 4, 5])
  .required(i18n.t('errors.required'));

const scoreEnglishNumber = yup
  .number()
  .oneOf([0, 1, 2, 3])
  .required(i18n.t('errors.required'));

const levelNumber = yup
  .number()
  .oneOf([0, 1, 2])
  .required(i18n.t('errors.required'));

const scoreCDPNumber = yup
  .number()
  .oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  .required(i18n.t('errors.required'));

const poidsRSENumber = yup
  .number()
  .oneOf([0, 1, 2, 3])
  .required(i18n.t('errors.required'));

const markNumber = yup
  .number()
  .min(0)
  .max(10)
  .required(i18n.t('errors.required'));

// Input
interface ComponentProps {
  formik: FormikProps<{ value: number | undefined; date: Date | undefined }>;
  size: 'small' | 'middle' | 'large';
  placeholder?: string;
  className?: string;
}
const numberInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Input
    {...rest}
    name="value"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.value}
    type="number"
    min={0}
  />
);

const markInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Input
    {...rest}
    name="value"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.value}
    type="number"
    min={0}
    max={10}
  />
);

const booleanSelectInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('common.yes'),
        value: '1',
      },
      {
        label: i18n.t('common.no'),
        value: '0',
      },
    ]}
  />
);

const scoreEnglishInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreEnglish.3'),
        value: '3',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreEnglish.2'),
        value: '2',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreEnglish.1'),
        value: '1',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreEnglish.0'),
        value: '0',
      },
    ]}
  />
);

const classeDPEInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.6'),
        value: '6',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.5'),
        value: '5',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.4'),
        value: '4',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.3'),
        value: '3',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.2'),
        value: '2',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.1'),
        value: '1',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeDPE.0'),
        value: '0',
      },
    ]}
  />
);

const classeSignInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeSign.5'),
        value: '5',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeSign.4'),
        value: '4',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeSign.3'),
        value: '3',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeSign.2'),
        value: '2',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeSign.1'),
        value: '1',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.classeSign.0'),
        value: '0',
      },
    ]}
  />
);

const levelInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('indicators.strategy.indicatorValues.level.2'),
        value: '2',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.level.1'),
        value: '1',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.level.0'),
        value: '0',
      },
    ]}
  />
);

const scoreCDP = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.10'),
        value: '10',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.9'),
        value: '9',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.8'),
        value: '8',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.7'),
        value: '7',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.6'),
        value: '6',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.5'),
        value: '5',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.4'),
        value: '4',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.3'),
        value: '3',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.2'),
        value: '2',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.1'),
        value: '1',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.scoreCDP.0'),
        value: '0',
      },
    ]}
  />
);

const poidsRSEInput = ({ formik, ...rest }: ComponentProps) => (
  <Form.Select
    {...rest}
    onBlur={formik.handleBlur}
    value={
      formik.values.value !== undefined ? `${formik.values.value}` : undefined
    }
    onChange={(value) => formik.setFieldValue('value', value)}
    options={[
      {
        label: i18n.t('indicators.strategy.indicatorValues.poidsRSE.3'),
        value: '3',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.poidsRSE.2'),
        value: '2',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.poidsRSE.1'),
        value: '1',
      },
      {
        label: i18n.t('indicators.strategy.indicatorValues.poidsRSE.0'),
        value: '0',
      },
    ]}
  />
);

// Display
const defaultDisplay =
  (unit: string, maximumFractionDigits = 2) =>
  (value: number) =>
    `${formatNumber(value, maximumFractionDigits)} ${unit}`;

const units = {
  [IndicatorUnit.Km]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Km),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Km),
  },
  [IndicatorUnit.L]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.L),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.L),
  },
  [IndicatorUnit.Kg]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Kg),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Kg),
  },
  [IndicatorUnit.m2]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.m2),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.m2),
  },

  [IndicatorUnit.m3]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.m3),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.m3),
  },
  [IndicatorUnit.Percentage]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Percentage, 1),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Percentage, 1),
  },
  [IndicatorUnit.kW]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.kW),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.kW),
  },
  [IndicatorUnit.MW]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.MW),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.MW),
  },
  [IndicatorUnit.kWh]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.kWh),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.kWh),
  },
  [IndicatorUnit.MWh]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.MWh),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.MWh),
  },
  [IndicatorUnit.kWhPerCA]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.kWhPerCA),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.kWhPerCA),
  },
  [IndicatorUnit.Integer]: {
    component: numberInput,
    validationSchema: positiveIntegerNumber,
    display: (value: number) => `${formatNumber(value)}`,
    aggregatedDisplay: (value: number) => `${formatNumber(value)}`,
  },
  [IndicatorUnit.Float]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: (value: number) => `${formatNumber(value)}`,
    aggregatedDisplay: (value: number) => `${formatNumber(value)}`,
  },
  [IndicatorUnit.Year]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(i18n.t(`indicators.unit.year`)),
    aggregatedDisplay: defaultDisplay(i18n.t(`indicators.unit.year`)),
  },
  [IndicatorUnit.Day]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(i18n.t(`indicators.unit.day`)),
    aggregatedDisplay: defaultDisplay(i18n.t(`indicators.unit.day`)),
  },
  [IndicatorUnit.Hour]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(i18n.t(`indicators.unit.hours`)),
    aggregatedDisplay: defaultDisplay(i18n.t(`indicators.unit.hours`)),
  },
  [IndicatorUnit.Min]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(i18n.t(`indicators.unit.min`)),
    aggregatedDisplay: defaultDisplay(i18n.t(`indicators.unit.min`)),
  },
  [IndicatorUnit.Boolean]: {
    component: booleanSelectInput,
    validationSchema: booleanNumber,
    display: (value: number) =>
      value === 1 ? i18n.t('common.yes') : i18n.t('common.no'),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Percentage),
  },
  [IndicatorUnit.Euro]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Euro),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Euro),
  },
  [IndicatorUnit.KiloEuro]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KiloEuro),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KiloEuro),
  },
  [IndicatorUnit.MegaEuro]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.MegaEuro),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.MegaEuro),
  },
  [IndicatorUnit.KgEqCO2]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KgEqCO2),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KgEqCO2),
  },
  [IndicatorUnit.TEqCO2]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.TEqCO2),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.TEqCO2),
  },
  [IndicatorUnit.KgEqCO2PerkEuroCA]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KgEqCO2PerkEuroCA),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KgEqCO2PerkEuroCA),
  },
  [IndicatorUnit.KgEqCO2PerCollab]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KgEqCO2PerCollab),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KgEqCO2PerCollab),
  },
  [IndicatorUnit.KmPerCollab]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KmPerCollab),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KmPerCollab),
  },
  [IndicatorUnit.LPerCollab]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.LPerCollab),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.LPerCollab),
  },
  [IndicatorUnit.KgPerCollab]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KgPerCollab),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KgPerCollab),
  },
  [IndicatorUnit.Tonne]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Tonne),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Tonne),
  },
  [IndicatorUnit.KgPerKiloEuroCA]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.KgPerKiloEuroCA),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.KgPerKiloEuroCA),
  },
  [IndicatorUnit.Go]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Go),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Go),
  },
  [IndicatorUnit.To]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.To),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.To),
  },
  [IndicatorUnit.Po]: {
    component: numberInput,
    validationSchema: positiveDecimalNumber,
    display: defaultDisplay(IndicatorUnit.Po),
    aggregatedDisplay: defaultDisplay(IndicatorUnit.Po),
  },
  [IndicatorUnit.ClasseDPE]: {
    component: classeDPEInput,
    validationSchema: classeDPENumber,
    display: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.classeDPE.${value}`);
    },
    aggregatedDisplay: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.classeDPE.${value}`);
    },
  },
  [IndicatorUnit.ClasseSign]: {
    component: classeSignInput,
    validationSchema: classeSignNumber,
    display: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.classeSign.${value}`);
    },
    aggregatedDisplay: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.classeSign.${value}`);
    },
  },
  [IndicatorUnit.ScoreEnglish]: {
    component: scoreEnglishInput,
    validationSchema: scoreEnglishNumber,
    display: (value: number) => {
      return i18n.t(
        `indicators.strategy.indicatorValues.scoreEnglish.${value}`,
      );
    },
    aggregatedDisplay: (value: number) => {
      return i18n.t(
        `indicators.strategy.indicatorValues.scoreEnglish.${value}`,
      );
    },
  },
  [IndicatorUnit.ScoreCDP]: {
    component: scoreCDP,
    validationSchema: scoreCDPNumber,
    display: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.scoreCDP.${value}`);
    },
    aggregatedDisplay: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.scoreCDP.${value}`);
    },
  },
  [IndicatorUnit.Level]: {
    component: levelInput,
    validationSchema: levelNumber,
    display: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.level.${value}`);
    },
    aggregatedDisplay: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.level.${value}`);
    },
  },
  [IndicatorUnit.PoidsRSE]: {
    component: poidsRSEInput,
    validationSchema: poidsRSENumber,
    display: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.poidsRSE.${value}`);
    },
    aggregatedDisplay: (value: number) => {
      return i18n.t(`indicators.strategy.indicatorValues.poidsRSE.${value}`);
    },
  },
  [IndicatorUnit.Mark]: {
    component: markInput,
    validationSchema: markNumber,
    display: (value: number) => `${value} / 10`,
    aggregatedDisplay: (value: number) => `${value} / 10`,
  },
};

function isKnownUnit(unit: string | IndicatorUnit): unit is IndicatorUnit {
  return Object.values(IndicatorUnit).some((value) => unit === value);
}

export const getUnitValidationSchema = (unit: string) => {
  if (isKnownUnit(unit)) {
    return units[unit].validationSchema;
  }
  // If unit is unknown we return a default validation
  return positiveDecimalNumber;
};

export const getUnitComponent = (unit: string) => {
  if (isKnownUnit(unit)) {
    return units[unit].component;
  }
  // If unit is unknown we return a default component
  return numberInput;
};

export const getUnitDisplay = (unit: string, aggregated?: boolean) => {
  if (isKnownUnit(unit)) {
    return aggregated ? units[unit].aggregatedDisplay : units[unit].display;
  }
  // If unit is unknown we return default display
  return defaultDisplay(unit);
};

export const getUnitName = (unit: string) => {
  switch (unit) {
    case IndicatorUnit.Boolean:
      return `${i18n.t('common.yes')}/${i18n.t('common.no')}`;
    case IndicatorUnit.Integer:
      return i18n.t('indicators.unit.integer');
    case IndicatorUnit.Day:
      return i18n.t('indicators.unit.day');
    case IndicatorUnit.Year:
      return i18n.t('indicators.unit.year');
    case IndicatorUnit.Hour:
      return i18n.t(`indicators.unit.hours`);
    case IndicatorUnit.Min:
      return i18n.t(`indicators.unit.min`);
    case IndicatorUnit.Float:
      return i18n.t(`indicators.unit.float`);
    case IndicatorUnit.Level:
      return i18n.t(`indicators.unit.level`);
    case IndicatorUnit.ClasseDPE:
      return i18n.t(`indicators.unit.classeDPE`);
    case IndicatorUnit.ScoreCDP:
      return i18n.t(`indicators.unit.scoreCDP`);
    case IndicatorUnit.ScoreEnglish:
      return i18n.t(`indicators.unit.scoreEnglish`);
    case IndicatorUnit.PoidsRSE:
      return i18n.t(`indicators.unit.poidsRSE`);
    case IndicatorUnit.Mark:
      return i18n.t('indicators.unit.mark');
    default:
      return unit;
  }
};

/**
 * To test if the field to enter a value of this unit is a select or a not
 */
export const isUnitDiscreet = (unit: string) => {
  return (
    unit === IndicatorUnit.Boolean ||
    unit === IndicatorUnit.ClasseDPE ||
    unit === IndicatorUnit.ScoreCDP ||
    unit === IndicatorUnit.ScoreEnglish ||
    unit === IndicatorUnit.Level ||
    unit === IndicatorUnit.PoidsRSE
  );
};

export default units;
