import React from 'react';
import Space from 'ui/space';
import { Avatar, Dropdown, Menu } from 'antd';
import Typography from 'ui/typography';
import {
  GlobalOutlined,
  LogoutOutlined,
  RightOutlined,
  SearchOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { getDistinctEntityTypesForUsers } from 'business/user/services/user';
import { Permission } from 'business/user/types/user';
import { getInitial } from '../../format';
import authService from '../../services/authentication';
import styles from './index.module.scss';

const ContextMenu: React.FC = () => {
  const { t } = useTranslation();
  const {
    user,
    deletePermission,
    deleteEntityType,
    setShouldDisplayOnBoarding,
    selectedEntityType,
    isAllowedTo,
  } = useLoggedInAppContext();

  return (
    <Menu mode="vertical" className={styles.profileMenu}>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => authService.logout()}
      >
        {t('menu.profile.logout')}
      </Menu.Item>
      {user.userPermissions.filter(
        (userPermission) => userPermission.entity.type === selectedEntityType,
      ).length > 1 ? (
        <Menu.Item
          key="changeRole"
          icon={<UserSwitchOutlined />}
          onClick={() => deletePermission()}
        >
          {t('menu.profile.changeAccount')}
        </Menu.Item>
      ) : null}
      {getDistinctEntityTypesForUsers(user.userPermissions).length > 1 ? (
        <Menu.Item
          key="changeContext"
          icon={<GlobalOutlined />}
          onClick={() => deleteEntityType()}
        >
          {t('menu.profile.changeContext')}
        </Menu.Item>
      ) : null}
      {isAllowedTo(Permission.OnboardingAccess) ? (
        <Menu.Item
          key="onboarding"
          icon={<SearchOutlined />}
          onClick={() => {
            setShouldDisplayOnBoarding(true);
            return true;
          }}
        >
          {t('menu.profile.onboarding')}
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

const Footer: React.FC = () => {
  const {
    user,
    selectedPermission: { role, entityName, entityId },
    selectedEntity,
  } = useLoggedInAppContext();
  const { t } = useTranslation();
  if (!user) {
    return null;
  }

  const info = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
  };

  return (
    <Space className={styles.dropdownContainer}>
      <Dropdown
        trigger={['click']}
        overlay={<ContextMenu />}
        placement="topLeft"
      >
        <Space
          className={styles.dropdown}
          align="center"
          justify="space-between"
        >
          <Space align="center" size="small">
            <Avatar className={styles.avatar} size="large">
              {getInitial(info)}
            </Avatar>
            <Space direction="vertical" size="extra-small">
              <Typography.Text>{info.firstName}</Typography.Text>
              <Typography.Text className={styles.role}>
                {`${t(`roles.${role}`)} - ${entityName}`}
              </Typography.Text>
            </Space>
          </Space>
          <RightOutlined />
        </Space>
      </Dropdown>
    </Space>
  );
};

export default Footer;
