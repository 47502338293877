import React from 'react';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';
import { InputProps } from 'antd';
import styles from './index.module.scss';

require('trix');

const toolbarActions = [
  'bold',
  'italic',
  'strike',
  'link',
  'heading1',
  'quote',
  'code',
  'bullet',
  'number',
  'outdent',
  'indent',
  'undo',
  'redo',
];

type RichTextEditorProps = InputProps & { 'data-test-id': string };

export default function RichTextEditor({
  value,
  ...rest
}: RichTextEditorProps) {
  return (
    <div className={styles.richTextEditor}>
      <ReactTrixRTEToolbar
        toolbarId="react-trix-rte-editor"
        toolbarActions={toolbarActions}
      />
      <ReactTrixRTEInput
        id={rest['data-test-id']}
        className="ant-input-lg ant-input"
        toolbarId="react-trix-rte-editor"
        {...rest}
        defaultValue={value}
      />
    </div>
  );
}
