import React, { useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { partnerCatalogDetailsRoute } from 'business/router/routes';
import ConnectedLayout from 'business/user/layout/connected';
import {
  Order_By,
  Partner_Order_By,
  usePartnerListQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Space from 'ui/space';
import Typography from 'ui/typography';
import Table from 'ui/table';
import TableFilters from 'business/common/components/tableFilters';
import { SorterResult } from 'antd/es/table/interface';
import { PartnerRow } from 'business/partners/types';
import { handleSortChange } from 'technical/table/sort';
import EmptyState from 'ui/emptyState';
import {
  partnersColumns,
  partnersDataSource,
  buildPartnerOrderBy,
  usePartnersListFilters,
} from '../../../services/table';

const PartnersCatalogPage: React.FC = () => {
  const { t } = useTranslation();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<Partner_Order_By | Partner_Order_By[]>(
    {
      createdAt: Order_By.Desc,
      id: Order_By.Asc,
    },
  );
  const history = useHistory();

  const { filtersValues, debouncedFiltersValues, setFiltersValues } =
    usePartnersListFilters();

  const { data, previousData, loading, called } = usePartnerListQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      sort: orderBy,
      condition: {
        _or: [
          { name: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` } },
          {
            description: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
        ],
        category: filtersValues.category ? { _eq: filtersValues.category } : {},
        partnerIndicatorTemplates: {
          indicatorTemplate: {
            indicatorTemplateStakes: {
              stake: {
                id: filtersValues.stakeId ? { _eq: filtersValues.stakeId } : {},
                themeId: filtersValues.themeId
                  ? { _eq: filtersValues.themeId }
                  : {},
              },
            },
          },
        },
      },
    },
  });

  const partners = data?.partner || previousData?.partner || [];

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current ?? 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSorterChange = (sorter: SorterResult<PartnerRow>) => {
    setOrderBy(
      handleSortChange<PartnerRow, Partner_Order_By>(
        sorter,
        buildPartnerOrderBy,
      ),
    );
  };

  return (
    <ConnectedLayout>
      <Space direction="vertical" size="middle">
        <Typography.Title level={3}>
          {t('partners.catalog.title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('partners.catalog.info')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) => {
            setFiltersValues({
              ...newFilters,
              page: 1,
            });
          }}
          searchPlaceholder={t('tableFilters.search.partners')}
        />
        {partners.length === 0 && !loading ? (
          <EmptyState
            title={t('partners.catalog.empty.title')}
            description={t('partners.catalog.empty.description')}
          />
        ) : (
          <Table
            loading={loading || !called}
            data-test-id="partners-table"
            dataSource={partnersDataSource(partners)}
            columns={partnersColumns()}
            pagination={{
              total: data?.partner_aggregate.aggregate?.count || 0,
              pageSize: resultsPerPage,
              current: filtersValues.page,
            }}
            onChange={(pagination, _filters, sorter) => {
              onPaginationChange(pagination);
              onSorterChange(sorter as SorterResult<PartnerRow>);
            }}
            onRow={(record) => ({
              onClick: () =>
                history.push(partnerCatalogDetailsRoute(record.key)),
            })}
          />
        )}
      </Space>
    </ConnectedLayout>
  );
};

export default PartnersCatalogPage;
