import authService from 'business/user/services/authentication';
import Routes from 'business/router/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import ResetPasswordCard, {
  Values,
} from 'business/user/components/resetPasswordCard';

import history from 'technical/history';
import AuthLayout from '../../layout/auth';

interface ResetPasswordRouteProps {
  token?: string; // Generated by the back when user request a change of password
}

function ResetPasswordPage() {
  const [errorResetPassword, setErrorResetPassword] = useState<string>('');

  const { params } = useRouteMatch<ResetPasswordRouteProps>();
  const { t } = useTranslation();

  const token = params.token || null;

  const handleSubmitClick = async (values: Values) => {
    setErrorResetPassword('');
    try {
      await authService.resetPassword(token, values.password, values.password);
      history.push(Routes.SignIn);
    } catch (error) {
      if (error instanceof Error) {
        setErrorResetPassword(error.message);
      }
    }
  };

  return (
    <AuthLayout>
      <ResetPasswordCard
        submitButton={{
          label: t('user.resetPassword.submit'),
          onClick: handleSubmitClick,
        }}
        errorResetPassword={errorResetPassword}
        description={t('user.resetPassword.description')}
        inputType="password"
      />
    </AuthLayout>
  );
}

export default ResetPasswordPage;
