import { Input } from 'antd';
import Error from './error';
import PasswordInput from './passwordInput';
import Item from './item';
import Select from './selectInput';
import Checkbox from './checkbox';

export default {
  Error,
  PasswordInput,
  Item,
  Input,
  Select,
  Checkbox,
};
