import { LoggedInAppContextAttributes } from 'business/AppBootstrapper';
import { whichTypeIsThisRepartition } from 'business/indicators/services';
import {
  IndicatorAggregationMethod,
  IndicatorGraphMode,
  IndicatorStockGraphMode,
  IndicatorType,
  IndicatorUnit,
} from 'business/indicators/types';
import { Permission } from 'business/user/types/user';
import { PlotData } from 'generated/graphql';
import { Indicator } from '../../../../generated/graphql';

import {
  BarChart,
  ComposedChart,
  standardComputation,
  stackComputation,
  PercentAreaChart,
  percentComputation,
  StackedBarChart,
  aggregatedComputation,
  StackedAreaChart,
  stackedAggregatedComputation,
  percentStackedComputation,
  PercentPieChart,
  singlePercentComputation,
  MultiLineChart,
} from './strategies';

const getGraphStrategyForSimpleGraphIndicator = (
  indicator: Indicator,
  type: IndicatorType,
  plotDataLength: number,
  graphMode?: IndicatorStockGraphMode,
  dateMode?: IndicatorGraphMode,
): {
  component: React.FC<{
    data: any;
    type: IndicatorType;
    color: string;
    plotData: PlotData[];
    unit: IndicatorUnit;
  }>;
  computation: (
    plotData: PlotData[],
    unit: IndicatorUnit,
    objective: number,
  ) => any;
} | null => {
  switch (true) {
    case dateMode === IndicatorGraphMode.AllYears:
      return {
        component: BarChart,
        computation: standardComputation,
      };
    case type === IndicatorType.Stock &&
      graphMode === IndicatorStockGraphMode.Monthly:
      return {
        component: BarChart,
        computation: standardComputation,
      };
    case type === IndicatorType.Stock &&
      graphMode === IndicatorStockGraphMode.Aggregated:
      return {
        component: ComposedChart,
        computation: stackComputation,
      };
    case type === IndicatorType.Photo:
      return {
        component: ComposedChart,
        computation: standardComputation,
      };
    case type === IndicatorType.Repartition && plotDataLength === 1:
      return {
        component: PercentPieChart,
        computation: singlePercentComputation,
      };
    case type === IndicatorType.Repartition &&
      whichTypeIsThisRepartition(indicator) === IndicatorType.Photo:
      return { component: PercentAreaChart, computation: percentComputation };
    case type === IndicatorType.Repartition &&
      whichTypeIsThisRepartition(indicator) === IndicatorType.Stock:
      return {
        component: PercentAreaChart,
        computation: percentStackedComputation,
      };
    default:
      return null;
  }
};

const getGraphStrategyForAggregatedGraphIndicator = (
  indicator: Indicator,
  type: IndicatorType,
  unit: IndicatorUnit,
  hasChildren: boolean,
  aggregationMethod: IndicatorAggregationMethod,
  plotDataLength: number,
  graphMode?: IndicatorStockGraphMode,
  dateMode?: IndicatorGraphMode,
): {
  component: React.FC<{
    data: any;
    type: IndicatorType;
    color: string;
    plotData: PlotData[];
    unit: IndicatorUnit;
  }>;
  computation: (
    plotData: PlotData[],
    unit: IndicatorUnit,
    objective: number,
  ) => any;
} | null => {
  switch (true) {
    case dateMode === IndicatorGraphMode.AllYears: {
      return hasChildren
        ? {
            component: StackedBarChart,
            computation: aggregatedComputation,
          }
        : {
            component: BarChart,
            computation: standardComputation,
          };
    }
    case type === IndicatorType.Stock &&
      graphMode === IndicatorStockGraphMode.Monthly &&
      hasChildren:
      return {
        component: StackedBarChart,
        computation: aggregatedComputation,
      };
    case type === IndicatorType.Stock &&
      graphMode === IndicatorStockGraphMode.Monthly:
      return {
        component: BarChart,
        computation: standardComputation,
      };
    case type === IndicatorType.Stock &&
      graphMode === IndicatorStockGraphMode.Aggregated &&
      hasChildren:
      return {
        component: StackedAreaChart,
        computation: stackedAggregatedComputation,
      };
    case type === IndicatorType.Stock &&
      graphMode === IndicatorStockGraphMode.Aggregated:
      return {
        component: ComposedChart,
        computation: stackComputation,
      };
    case type === IndicatorType.Photo &&
      hasChildren &&
      aggregationMethod === IndicatorAggregationMethod.Sum:
      return {
        component: StackedAreaChart,
        computation: aggregatedComputation,
      };
    case type === IndicatorType.Photo &&
      hasChildren &&
      aggregationMethod === IndicatorAggregationMethod.Average:
      return {
        component: MultiLineChart,
        computation: aggregatedComputation,
      };
    case type === IndicatorType.Photo &&
      aggregationMethod === IndicatorAggregationMethod.Average:
      return {
        component: ComposedChart,
        computation: standardComputation,
      };
    case type === IndicatorType.Photo &&
      aggregationMethod === IndicatorAggregationMethod.Sum:
      return {
        component: ComposedChart,
        computation: standardComputation,
      };
    case type === IndicatorType.Repartition &&
      whichTypeIsThisRepartition(indicator) === IndicatorType.Photo:
      return { component: PercentAreaChart, computation: percentComputation };
    case type === IndicatorType.Repartition &&
      whichTypeIsThisRepartition(indicator) === IndicatorType.Stock:
      return {
        component: PercentAreaChart,
        computation: percentStackedComputation,
      };
    case type === IndicatorType.Photo && unit === IndicatorUnit.Boolean:
      return null;
    case type === IndicatorType.Select && plotDataLength === 1:
      return {
        component: PercentPieChart,
        computation: singlePercentComputation,
      };
    case type === IndicatorType.Select:
      return {
        component: PercentAreaChart,
        computation: percentComputation,
      };
    default:
      return null;
  }
};

const getGraphStrategyForGraphIndicator = (
  indicator: Indicator,
  type: IndicatorType,
  // @todo, use unit for other indicators like photo boolean etc...
  unit: IndicatorUnit,
  context: LoggedInAppContextAttributes,
  hasChildren: boolean,
  aggregationMethod: IndicatorAggregationMethod,
  plotDataLength: number,
  graphMode?: IndicatorStockGraphMode,
  dateMode?: IndicatorGraphMode,
): {
  component: React.FC<{
    data: any;
    type: IndicatorType;
    color: string;
    plotData: PlotData[];
    unit: IndicatorUnit;
    dateMode?: IndicatorGraphMode;
  }>;
  computation: (
    plotData: PlotData[],
    unit: IndicatorUnit,
    objective: number,
  ) => any;
} | null => {
  if (context.isAllowedTo(Permission.IndicatorsAggregate)) {
    return getGraphStrategyForAggregatedGraphIndicator(
      indicator,
      type,
      unit,
      hasChildren,
      aggregationMethod,
      plotDataLength,
      graphMode,
      dateMode,
    );
  }
  if (context.isAllowedTo(Permission.IndicatorsDataRead)) {
    return getGraphStrategyForSimpleGraphIndicator(
      indicator,
      type,
      plotDataLength,
      graphMode,
      dateMode,
    );
  }
  throw new Error(
    `[GET GRAPH STRATEGY] Role ${context.selectedPermission.role} is not an expected role`,
  );
};

export default getGraphStrategyForGraphIndicator;
