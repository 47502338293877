const mapTagsLabelToCategories: { [key: string]: string[] } = {
  'ENTREPRISE / VEF': ['vinciPartner'],
};

const defaultCategory = [
  'tool',
  'stateAgency',
  'grfOffer',
  'assistanceAndGrants',
  'association',
  'ratingAgency',
];

export function getCategoryValuesFromEntityTags(tags: string[]) {
  return [
    ...defaultCategory,
    ...tags.flatMap((tag) => mapTagsLabelToCategories[tag] ?? []),
  ].sort();
}
