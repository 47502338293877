import React from 'react';
import Layout from 'ui/layout';

const DisconnectedLayout: React.FC = ({ children }) => {
  return (
    <Layout mode="dark" isMenuVisible={false}>
      {children}
    </Layout>
  );
};

export default DisconnectedLayout;
