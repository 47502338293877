import classnames from 'classnames';
import React from 'react';
import List, { Item } from 'ui/list';
import Space from 'ui/space';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface FancyTableProps {
  columnDefs: {
    label: string;
    color: string;
    key: number;
  }[];
  rows: {
    title: string;
    data: { [key: string]: string | string[] | React.ReactElement };
  }[];
  legend: string;
  isOddRowColorized?: boolean;
}

const FancyTable: React.FC<FancyTableProps> = ({
  columnDefs,
  rows,
  legend,
  isOddRowColorized = false,
}) => {
  return (
    <Space align="center" direction="vertical">
      <table
        className={classnames(styles.fancyTable, {
          [styles.oddRowColorized]: isOddRowColorized,
        })}
      >
        <thead>
          <tr>
            <td />
            {columnDefs.map((column) => (
              <th
                key={column.key}
                style={{ width: `calc(100% / ${columnDefs.length})` }}
              >
                <span
                  className={styles.border}
                  style={{ borderTopColor: column.color }}
                />
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.title}>
              <td>
                <Typography.Paragraph strong>{row.title}</Typography.Paragraph>
              </td>
              {columnDefs.map((column) => {
                const data = row.data[column.key];

                return (
                  <td key={column.key}>
                    {Array.isArray(data) ? (
                      <List color={column.color}>
                        {data.map((item) => (
                          <Item key={item}>{item}</Item>
                        ))}
                      </List>
                    ) : (
                      data
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <Typography.Paragraph strong>{legend}</Typography.Paragraph>
    </Space>
  );
};

export default FancyTable;
