import React, { ReactNode } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import Button, { ButtonProps } from 'ui/button';
import styles from './index.module.scss';

interface ButtonToggleProps extends Omit<ButtonProps, 'onChange'> {
  checked: boolean;

  tooltip?: {
    checkedLabel?: string;
    uncheckedLabel?: string;
    placement?: TooltipProps['placement'];
  };

  checkedChildren: ReactNode;
  uncheckedChildren: ReactNode;
  onChange?: (checked: boolean) => void;
}

function ButtonToggle({
  checked,
  checkedChildren: onChildren,
  uncheckedChildren: offChildren,
  onChange,
  tooltip,
  ...rest
}: ButtonToggleProps) {
  return (
    <Tooltip
      placement={tooltip?.placement ?? 'top'}
      title={checked ? tooltip?.checkedLabel : tooltip?.uncheckedLabel}
    >
      <Button
        type={checked ? 'primary' : 'default'}
        data-test-id="toggle-visibility-cta"
        className={styles.cta}
        onClick={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
        {...rest}
      >
        {checked ? onChildren : offChildren}
      </Button>
    </Tooltip>
  );
}

export default ButtonToggle;
