import { Select as AntdSelect, SelectProps, Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

const { Option } = AntdSelect;

interface DropdownProps extends Omit<SelectProps<string>, 'options' | 'size'> {
  options: {
    value: string | undefined | null;
    label?: string;
    disabled?: boolean;
    disabledTooltip?: ReactNode;
  }[];
  value: string;
  size?: 'small' | 'medium' | 'large' | 'auto';
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  size = 'medium',
  ...rest
}) => {
  if (options.filter((option) => option !== null).length <= 1) {
    return null;
  }

  return (
    <AntdSelect className={styles[size]} {...rest}>
      {options.map((option) =>
        option.value ? (
          <Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            <Tooltip title={option.disabledTooltip} placement="right">
              {option.label ?? option.value}
            </Tooltip>
          </Option>
        ) : null,
      )}
    </AntdSelect>
  );
};

export default Dropdown;
