import * as Yup from 'yup';
import yupPassword from 'yup-password';
import { TFunction } from 'i18next';

yupPassword(Yup);

const MAX_PASSWORD_LENGTH = 64;
const MIN_PASSPHRASE_LENGTH = 20;

export const email = (t: TFunction) =>
  Yup.string()
    .email(t('errors.wrong_email_address'))
    .required(t('errors.required'));

export const notEmail = (t: TFunction, domainName: string) =>
  Yup.string().matches(
    /^(?!.*@).*$/,
    t('errors.should_not_be_an_email', { domainName }),
  );

const simplePassword = (t: TFunction) =>
  Yup.string()
    .password()
    .minLowercase(
      1,
      t('user.resetPassword.errors.signUp', {
        context: 'password-doesnt-contain-lowercase',
      }),
    )
    .minUppercase(
      1,
      t('user.resetPassword.errors.signUp', {
        context: 'password-doesnt-contain-uppercase',
      }),
    )
    .minSymbols(
      1,
      t('user.resetPassword.errors.signUp', {
        context: 'password-doesnt-contain-symbol',
      }),
    )
    .minNumbers(
      1,
      t('user.resetPassword.errors.signUp', {
        context: 'password-doesnt-contain-number',
      }),
    )
    .min(
      8,
      t('user.resetPassword.errors.signUp', { context: 'password-too-short' }),
    )
    .max(MAX_PASSWORD_LENGTH, t('errors.too_long'))
    .required(t('errors.required'));

const passphrase = (t: TFunction) =>
  Yup.string()
    .min(MIN_PASSPHRASE_LENGTH, t('errors.too_short'))
    .max(MAX_PASSWORD_LENGTH, t('errors.too_long'))
    .required(t('errors.required'));

export const name = (t: TFunction) =>
  Yup.string()
    .min(2, t('errors.too_short'))
    .max(50, t('errors.too_long'))
    .required(t('errors.required'));

export const password = (t: TFunction) =>
  Yup.lazy((value) => {
    switch (true) {
      case value && value.length >= MIN_PASSPHRASE_LENGTH:
        return passphrase(t);
      default:
        return simplePassword(t);
    }
  });

export const passwordConfirmation = (t: TFunction) =>
  Yup.string()
    .required(t('errors.required'))
    .oneOf([Yup.ref('password')], t('errors.passwords_did_not_match'));
