import { TFunction, useTranslation } from 'react-i18next';
import React from 'react';
import Routes from 'business/router/routes';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  FundViewOutlined,
  PieChartOutlined,
  ReadOutlined,
  FileTextOutlined,
  IdcardOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';
import {
  AppContextAttributes,
  useLoggedInAppContext,
} from 'business/AppBootstrapper';
import { EntityTypes, Permission, Roles } from 'business/user/types/user';
import getEntityIcon from 'business/user/services/getEntityIcon';
import styles from './index.module.scss';

interface MenuItem {
  key: string;
  label: string;
  to?: Routes;
  icon?: React.ReactElement;
  children?: MenuItem[];
  'data-onboarding-id'?: string;
}

const menuData = (
  t: TFunction,
  isAllowedTo: AppContextAttributes['isAllowedTo'],
  entityType: EntityTypes,
): MenuItem[] => {
  return [
    {
      key: 'strategy',
      label: t('menu.strategy.label'),
      icon: <FundViewOutlined />,
      'data-onboarding-id': 'menu-strategy',
      children: [
        ...(isAllowedTo(Permission.SummaryRead)
          ? [
              {
                key: 'summary',
                label: t('menu.strategy.summary'),
                to: Routes.Summary,
              },
            ]
          : []),
        ...(isAllowedTo(Permission.IndicatorsRead)
          ? [
              {
                key: 'indicators',
                label: t('menu.strategy.indicators'),
                to: Routes.IndicatorsStrategy,
              },
            ]
          : []),
        ...(isAllowedTo(Permission.ActionRead)
          ? [
              {
                key: 'actions',
                label: t('menu.strategy.actions'),
                to: Routes.ActionsStrategy,
              },
            ]
          : []),
        ...(isAllowedTo(Permission.CampaignRead)
          ? [
              {
                key: 'campaigns',
                label: t('menu.strategy.campaigns'),
                to: Routes.CampaignsStrategy,
              },
            ]
          : []),
      ],
    },
    {
      key: 'catalog',
      label: t('menu.catalog.label'),
      icon: <ReadOutlined />,
      'data-onboarding-id': 'menu-catalog',
      children: [
        {
          key: 'indicators',
          label: t('menu.catalog.indicators'),
          to: Routes.IndicatorsCatalog,
        },
        {
          key: 'actions',
          label: t('menu.catalog.actions'),
          to: Routes.ActionsCatalog,
          'data-onboarding-id': 'menu-catalog-actions',
        },
        {
          key: 'campaigns',
          label: t('menu.catalog.campaigns'),
          to: Routes.CampaignsCatalog,
        },
        {
          key: 'partners',
          label: t('menu.catalog.partners'),
          to: Routes.PartnersCatalog,
        },
      ],
    },
    ...(isAllowedTo(Permission.DocumentationRead)
      ? [
          {
            key: 'documentation',
            label: t('menu.documentation.label'),
            icon: <FileTextOutlined />,
            children: [
              ...(entityType === EntityTypes.Company
                ? [
                    {
                      key: 'csr',
                      label: t('menu.documentation.csr'),
                      to: Routes.CsrDocumentation,
                    },
                  ]
                : []),
              {
                key: 'cali',
                label: t('menu.documentation.cali'),
                to: Routes.CaliDocumentation,
              },
              ...(entityType === EntityTypes.Company
                ? [
                    {
                      key: 'csrCertificationAndLabels',
                      label: t('menu.documentation.csrCertificationAndLabels'),
                      to: Routes.CsrCertificationAndLabelsDocumentation,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    {
      key: 'my-entity',
      label: t('menu.my-entity.label'),
      icon: getEntityIcon(entityType),
      children: [
        {
          key: 'identity-card',
          label: t('menu.my-entity.identity-card'),
          to: Routes.IdentitySheet,
          icon: <IdcardOutlined />,
        },
        ...(isAllowedTo(Permission.ParametersRead)
          ? [
              {
                key: 'parameters',
                label: t('menu.my-entity.parameters'),
                to: Routes.Parameters,
                icon: <PieChartOutlined />,
              },
            ]
          : []),
        ...(isAllowedTo(Permission.UsersPermissionsRead)
          ? [
              {
                key: 'users-permissions',
                label: t('menu.my-entity.users-permissions'),
                to: Routes.UsersPermissions,
                icon: <TeamOutlined />,
              },
            ]
          : []),
      ],
    },
  ];
};

const isActive = (item: MenuItem, path: string): boolean => {
  return (
    path.startsWith(item.to as string) ||
    (!!item.children &&
      item.children.some((children) => isActive(children, path)))
  );
};

const CustomMenu: React.FC = () => {
  const { t } = useTranslation();
  const {
    isAllowedTo,
    selectedEntity: { entityType },
  } = useLoggedInAppContext();
  const data = menuData(t, isAllowedTo, entityType);
  const { pathname } = useLocation();

  return (
    <Menu
      defaultOpenKeys={data
        .filter((item) => isActive(item, pathname))
        .map((item) => item.label)}
      className={styles.menu}
      mode="inline"
      theme="dark"
    >
      {data.map((item) => {
        return item.children ? (
          <Menu.SubMenu
            className={styles.menu}
            key={item.label}
            icon={item.icon}
            title={item.label}
            data-test-id={`menu-${item.key}`}
            data-onboarding-id={item['data-onboarding-id']}
          >
            {item.children.map((subItem) => (
              <Menu.Item
                key={subItem.label}
                icon={subItem.icon}
                data-test-id={`menu-${item.key}-${subItem.key}`}
                data-onboarding-id={subItem['data-onboarding-id']}
              >
                <Link
                  className={classnames({
                    [styles.activeLink]: isActive(subItem, pathname),
                  })}
                  to={subItem.to || Routes.Home}
                >
                  {subItem.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={item.label}
            icon={item.icon}
            data-test-id={`menu-${item.key}`}
          >
            <Link
              className={classnames({
                [styles.activeLink]: isActive(item, pathname),
              })}
              to={item.to || Routes.Home}
            >
              {item.label}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default CustomMenu;
