import React from 'react';
import { useHistory } from 'react-router';
import Table from 'ui/table';
import {
  actionStrategyDetailsRoute,
  actionCatalogForIndicatorRoute,
} from 'business/router/routes';
import { AssociatedAction } from 'business/indicators/types';
import { useTranslation } from 'react-i18next';
import EmptyState from 'ui/emptyState';
import {
  associatedActionsColumns,
  associatedActionsDataSource,
} from '../../services/table';

interface AssociatedActionsTableProps {
  actions: AssociatedAction[];
  indicatorId: string;
}

const AssociatedActionsTable: React.FC<AssociatedActionsTableProps> = ({
  actions,
  indicatorId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return actions.length === 0 ? (
    <EmptyState
      title={t('actions.catalog.empty.altTitle')}
      description={t('actions.catalog.empty.description')}
      cta={{
        to: actionCatalogForIndicatorRoute(indicatorId),
        label: t('indicators.details.addAction'),
      }}
    />
  ) : (
    <Table
      data-test-id="associated-actions-table"
      dataSource={associatedActionsDataSource(actions)}
      columns={associatedActionsColumns()}
      onRow={(record) => ({
        onClick: () => history.push(actionStrategyDetailsRoute(record.key)),
      })}
    />
  );
};

export default AssociatedActionsTable;
