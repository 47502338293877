import { CampaignStatus } from 'business/campaigns/services/types';
import { Roles } from 'business/user/types/user';
import * as yup from 'yup';
import { FilterInURL } from './type';

export const stringFilter: FilterInURL<string | undefined> = {
  fromSearchParam: (param) =>
    yup.string().validateSync(decodeURI(param ?? '') || undefined),
  toSearchParam: (value) => value,
};

export const pageFilter: FilterInURL<number> = {
  defaultValue: 1,
  fromSearchParam: (param) =>
    yup
      .number()
      .required()
      .validateSync(param ?? 1),
  toSearchParam: (value) => (value === 1 ? undefined : value.toString()),
};

export const booleanFilter: FilterInURL<boolean> = {
  defaultValue: false,
  fromSearchParam: (param) => param === 'true',
  toSearchParam: (value) => (value ? 'true' : undefined),
};

export const campaignStatusFilter: FilterInURL<CampaignStatus | undefined> = {
  fromSearchParam: (param) =>
    yup
      .mixed<CampaignStatus>()
      .oneOf(Object.values(CampaignStatus))
      .validateSync(param ?? undefined),
  toSearchParam: (value) => value,
};

export const roleFilter: FilterInURL<Roles | undefined> = {
  fromSearchParam: (param) =>
    yup
      .mixed<Roles>()
      .oneOf(Object.values(Roles))
      .validateSync(param ?? undefined),
  toSearchParam: (value) => value,
};
