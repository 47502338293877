import { EnsureDefined, isNotNullOrUndefined } from 'technical/type';

function ensureFieldsAreDefined<
  T extends { [keys: string]: any },
  Keys extends keyof T,
>(object: T, keys: Keys[]): EnsureDefined<T, Keys> {
  keys.forEach((key) => {
    if (!isNotNullOrUndefined(object[key])) {
      throw new Error(
        `[ENSURE DEFINED] Object has key '${String(key)}' that is not defined`,
      );
    }
  });
  return object;
}

export default ensureFieldsAreDefined;
