import { useFormik } from 'formik';
import { useEffect } from 'react';
import history from 'technical/history';
import useDebounce from 'technical/hooks/useDebounce';
import { FilterInURL } from './type';

/**
 * Custom hook to manage filters and automatically fetch (and update) from the URL
 * @param filters An object which keys correspond to each possible filter
 */
export const useFilters = <T extends { [key: string]: any }>(filters: {
  [name in keyof T]: FilterInURL<T[name]>;
}) => {
  // Get filters from the URL
  const filtersToUrl = (search: string): T => {
    const params = new URLSearchParams(search);

    return Object.keys(filters).reduce((res: T, key: keyof T) => {
      res[key] = filters[key].fromSearchParam(params.get(key as string));
      return res;
    }, {} as T);
  };

  const formik = useFormik({
    initialValues: filtersToUrl(history.location.search),
    onSubmit: () => {
      // We won't submit this form
    },
  });

  const debouncedFormikValues = useDebounce(formik.values, 500);

  // Write filters to the URL
  useEffect(() => {
    const params = new URLSearchParams();

    Object.keys(filters).forEach((key: keyof T) => {
      const value = filters[key].toSearchParam(formik.values[key]);
      if (value) {
        params.set(key as string, value);
      }
    });
    const newSearch = encodeURI(params.toString());

    // history.location.search add a "?" as first character
    if (history.location.search.substring(1) !== newSearch) {
      history.replace({ search: newSearch });
    }
  }, [debouncedFormikValues]);

  return {
    noFilters: Object.keys(filters).every(
      (key: keyof T) => filters[key].defaultValue === formik.values[key],
    ),
    filtersValues: formik.values,
    debouncedFiltersValues: debouncedFormikValues,
    setFiltersValues: formik.setValues,
  };
};
