import { Form as AntdForm, Input } from 'antd';
import Routes from 'business/router/routes';
import authService from 'business/user/services/authentication';
import { email } from 'business/user/validations/string';
import classnames from 'classnames';
import config from 'config';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { formikErrorMessage, formikStatus } from 'technical/form';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Form from 'ui/form';
import Space from 'ui/space';
import Typography from 'ui/typography';
import * as yup from 'yup';
import NotConnectedCard from '../notConnectedCard';

interface LoginCardProps {
  className?: string;
}

const LoginCard: React.FC<LoginCardProps> = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const isError = params.get('error') === 'true';

  const [signInError, setSignInError] = useState<string>();

  if (isError) {
    setSignInError(t('errors.signIn', { context: 'sso' }));
    history.replace({ search: '' });
  }

  const formik = useFormik({
    initialValues: { login: '', password: '' },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      login: email(t),
      password: yup.string(),
    }),
    onSubmit: async (values) => {
      setSignInError('');
      try {
        await authService.signIn(values.login.toLowerCase(), values.password);
      } catch (error) {
        if (error instanceof Error) {
          setSignInError(t('errors.signIn', { context: error.message }));
        }
      }
    },
  });

  return (
    <NotConnectedCard className={classnames(className)}>
      <AntdForm onFinish={formik.handleSubmit}>
        <Space direction="vertical" align="center">
          <AppLogo />
          <Typography.Title level={3}>{t('common.welcome')}</Typography.Title>
          <Space direction="vertical" size="no-space">
            <Form.Item
              label={t('notConnected.login.email')}
              validateStatus={formikStatus(formik, 'login')}
              help={formikErrorMessage(formik, 'login')}
            >
              <Input
                data-test-id="login-input"
                id="login"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
            <Form.Item
              label={t('notConnected.login.password')}
              help={formikErrorMessage(formik, 'password')}
              validateStatus={formikStatus(formik, 'password')}
            >
              <Form.PasswordInput
                id="password"
                data-test-id="password-input"
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
            <Space justify="center">
              <Link to={Routes.ForgotPassword}>
                {t('user.forgotPassword.title', {
                  context: 'question',
                })}
              </Link>
            </Space>
          </Space>
          <Form.Error message={signInError} />
          <Button
            id="submit-button"
            data-test-id="submit-button"
            type="primary"
            htmlType="submit"
          >
            {t('notConnected.login.submit')}
          </Button>
          {config.isSsoEnabled ? (
            <Link to={Routes.SsoSignIn}>
              <Button>{t('notConnected.login.goToSso')}</Button>
            </Link>
          ) : null}
        </Space>
      </AntdForm>
    </NotConnectedCard>
  );
};

export default LoginCard;
