import {
  stringFilter,
  pageFilter,
} from 'business/common/components/tableFilters/services/filters';
import { useFilters } from 'business/common/components/tableFilters/services/useFiltersHook';
import { getThemes } from 'business/stake/services';
import ThemeTag from 'business/theme/components/themeTag';
import {
  Order_By,
  Partner_Order_By,
  PartnerFragment,
  StakeFragment,
  ThemeFragment,
} from 'generated/graphql';
import React, { Key } from 'react';
import { i18n } from 'translations';
import Space from 'ui/space';
import { getPartnerStakes } from '.';
import { AssociatedPartners, PartnerRow } from '../types';

// Partners table (catalog)

export const buildPartnerOrderBy = (
  columnKey: Key | undefined,
  order: Order_By,
): Partner_Order_By | undefined => {
  switch (columnKey as keyof PartnerRow) {
    case 'name':
      return { name: order };
    case 'description':
      return { description: order };
    case 'category':
      return { category: order };
    default:
      return undefined;
  }
};

export const partnersDataSource = (
  partners: PartnerFragment[],
): PartnerRow[] => {
  return partners.map((partner) => {
    const stakes = getPartnerStakes(partner);
    const themes = getThemes(stakes);
    return {
      key: partner.id,
      name: partner.name,
      description: partner.shortDescription || '',
      category: partner.category || '',
      stakes,
      themes,
    };
  });
};

export const partnersColumns = () => [
  {
    title: i18n.t('partners.catalog.table.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: '30%',
  },
  {
    title: i18n.t('partners.catalog.table.description'),
    dataIndex: 'description',
    key: 'description',
    sorter: true,
    width: '40%',
  },
  {
    title: i18n.t('partners.catalog.table.themes'),
    dataIndex: 'themes',
    key: 'themes',
    render: (themes: ThemeFragment[]) => {
      // Forced by eslint to spread themes (bug)
      return (
        <Space size="extra-small">
          {[...themes].map((theme) => (
            <ThemeTag key={theme.id} theme={theme} />
          ))}
        </Space>
      );
    },
    width: '10%',
  },
  {
    title: i18n.t('partners.catalog.table.stakes'),
    dataIndex: 'stakes',
    key: 'stakes',
    render: (stakes: StakeFragment[]) => {
      return stakes.map((stake) => stake.label).join(', ');
    },
    width: '10%',
  },
  {
    title: i18n.t('partners.catalog.table.category'),
    dataIndex: 'category',
    key: 'category',
    sorter: true,
    render: (category: string) =>
      category ? i18n.t(`partners.category.${category}`) : null,
    width: '10%',
  },
];

// associated partners table

interface AssociatedPartnersRow {
  key: string;
  name: string;
  shortDescription: string;
}

export const associatedPartnersTemplatesDataSource = (
  partners: AssociatedPartners[],
): AssociatedPartnersRow[] => {
  return partners.map((partner) => {
    const { id, name, shortDescription } = partner;
    return {
      key: id,
      name,
      shortDescription: shortDescription ?? '',
    };
  });
};

export const associatedPartnersColumns = () => [
  {
    title: i18n.t('partners.catalog.table.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a: AssociatedPartnersRow, b: AssociatedPartnersRow) =>
      a.name.localeCompare(b.name),
    width: '30%',
  },
  {
    title: i18n.t('partners.catalog.table.description'),
    dataIndex: 'shortDescription',
    key: 'shortDescription',
    sorter: (a: AssociatedPartnersRow, b: AssociatedPartnersRow) =>
      a.shortDescription.localeCompare(b.shortDescription),
    width: '70%',
  },
];

export const usePartnersListFilters = () =>
  useFilters({
    search: stringFilter,
    stakeId: stringFilter,
    themeId: stringFilter,
    category: stringFilter,
    page: pageFilter,
  });
