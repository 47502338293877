import React from 'react';
import Card from 'ui/card';
import Space from 'ui/space';
import { useTranslation } from 'react-i18next';
import {
  IndicatorFragment,
  ParameterFragment,
  SimplePlotData,
} from 'generated/graphql';
import Typography from 'ui/typography';
import {
  CaretRightOutlined,
  EyeInvisibleOutlined,
  LockFilled,
  WarningOutlined,
} from '@ant-design/icons';
import colors from 'config/colors';
import { useHistory } from 'react-router-dom';
import {
  indicatorStrategyDetailsRoute,
  parameterDetailsRoute,
} from 'business/router/routes';
import {
  IndicatorType,
  isInstanceOfIndicator,
} from 'business/indicators/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import classnames from 'classnames';
import {
  isConnectedIndicator,
  isMandatory,
} from 'business/indicators/services';
import { Tooltip } from 'antd';
import { Mode } from 'business/indicators/services/type';
import TrendIndicator from '../trendIndicator';
import styles from './index.module.scss';
import IndicatorTemplateConnectorMarker from '../indicatorTemplateConnectorMarker';

interface IndicatorCardProps {
  indicator: IndicatorFragment | ParameterFragment;
}

const IndicatorCard: React.FC<IndicatorCardProps> = ({ indicator }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAllowedTo } = useLoggedInAppContext();

  const themeBorderWidth = 3; // pixels

  if (isInstanceOfIndicator(indicator)) {
    const activeActions = indicator.actionIndicators_aggregate.aggregate?.count;

    return (
      <Card
        data-test-id="indicator-card-list-item"
        style={{
          borderLeft: `${themeBorderWidth}px solid ${
            indicator.indicatorTemplate.indicatorTemplateStakes[0].stake.theme
              .color || 'transparent'
          }`,
        }}
        onClick={() =>
          history.push(indicatorStrategyDetailsRoute(indicator.id))
        }
        className={classnames(styles.indicatorCard, {
          [styles.hidden]: indicator.hiddenAt !== null,
        })}
      >
        <Space justify="space-between">
          <Space direction="vertical" size="extra-small">
            <Space direction="horizontal" align="center" size="small">
              {indicator.hiddenAt !== null && (
                <EyeInvisibleOutlined className={styles.indicatorIcon} />
              )}
              <Typography.Title level={5}>
                {indicator.indicatorTemplate.label}
              </Typography.Title>
              {isMandatory(indicator) ? (
                <Tooltip title={t('indicators.strategy.card.mandatory')}>
                  <LockFilled className={styles.indicatorIcon} />
                </Tooltip>
              ) : null}
              {isConnectedIndicator(indicator) ? (
                <IndicatorTemplateConnectorMarker
                  className={styles.indicatorIcon}
                />
              ) : null}
            </Space>
            {isAllowedTo(Permission.ActionRead) ? (
              <Space align="center" size="extra-small">
                {activeActions ? (
                  <CaretRightOutlined />
                ) : (
                  <WarningOutlined style={{ color: colors.danger }} />
                )}

                <Typography.Text type="secondary">
                  {t('indicators.strategy.card.action', {
                    count: activeActions,
                    context: activeActions,
                  })}
                </Typography.Text>
              </Space>
            ) : null}
          </Space>

          <TrendIndicator
            positiveTrend={indicator.indicatorTemplate.positiveTrend}
            unit={indicator.indicatorTemplate.unit}
            keyValues={indicator.keyValues}
            mode={Mode.Vertical}
            indicatorType={indicator.indicatorTemplate.type as IndicatorType}
          />
        </Space>
      </Card>
    );
  }
  return (
    <Card
      data-test-id="indicator-card-list-item"
      onClick={() => history.push(parameterDetailsRoute(indicator.id))}
    >
      <Space justify="space-between">
        <Space direction="vertical">
          <Typography.Title level={5}>
            {indicator.indicatorTemplate.label}
          </Typography.Title>
        </Space>
        <TrendIndicator
          positiveTrend={indicator.indicatorTemplate.positiveTrend}
          unit={indicator.indicatorTemplate.unit}
          indicatorType={indicator.indicatorTemplate.type as IndicatorType}
          keyValues={
            indicator.keyValues as {
              currentYear: SimplePlotData;
              lastYear: SimplePlotData;
            }
          }
          mode={Mode.Vertical}
        />
      </Space>
    </Card>
  );
};

export default IndicatorCard;
