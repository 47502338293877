import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';

interface DeleteIndicatorModalProps {
  isVisible: boolean;
  deleteIndicator: () => void;
  handleClose: () => void;
  actionsToHide: {
    id: string;
    name: string;
  }[];
  campaignsToHide: {
    id: string;
    name: string;
  }[];
}

const DeleteIndicatorModal: React.FC<DeleteIndicatorModalProps> = ({
  isVisible,
  actionsToHide,
  campaignsToHide,
  deleteIndicator,
  handleClose,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('indicators.details.deleteModal.title')}
      open={isVisible}
      okText={t('common.validate')}
      cancelText={t('common.cancel')}
      onOk={deleteIndicator}
      onCancel={handleClose}
      okButtonProps={{
        id: 'delete-indicator-modal-cta',
      }}
      cancelButtonProps={{
        id: 'cancel-delete-indicator-modal-cta',
      }}
    >
      <Space direction="vertical" align="center">
        <Typography.Text center>
          <Typography.Paragraph>
            {t('indicators.details.deleteModal.message')}
          </Typography.Paragraph>
          {actionsToHide.map((action) => (
            <Typography.Paragraph key={action.id}>
              {t('indicators.details.deleteModal.actionDeleted', {
                action: action.name,
              })}
            </Typography.Paragraph>
          ))}
          {campaignsToHide.map((campaign) => (
            <Typography.Paragraph key={campaign.id}>
              {t('indicators.details.deleteModal.campaignDeleted', {
                campaign: campaign.name,
              })}
            </Typography.Paragraph>
          ))}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default DeleteIndicatorModal;
