import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

interface AppHistoryContextAttributes {
  getLastUrl: () => string | undefined;
}

const AppNavigationHistoryContext = createContext<AppHistoryContextAttributes>({
  getLastUrl: () => undefined,
});

export const useHistoryContext = () => useContext(AppNavigationHistoryContext);

const AppNavigationHistory: React.FC = ({ children }) => {
  const [navigationStack, setNavigationStack] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    setNavigationStack([location.pathname, ...navigationStack]);
  }, [location]);

  const getLastUrl = () => {
    const [current, last, ...urls] = navigationStack;
    setNavigationStack(urls);
    return last;
  };

  return (
    <AppNavigationHistoryContext.Provider
      value={{
        getLastUrl,
      }}
    >
      {children}
    </AppNavigationHistoryContext.Provider>
  );
};

export default AppNavigationHistory;
