import React from 'react';
import { ParameterFragment } from 'generated/graphql';
import IndicatorCard from 'business/indicators/components/indicatorCard';

interface ParameterCardProps {
  parameter: ParameterFragment;
}

const ParameterCard: React.FC<ParameterCardProps> = ({ parameter }) => {
  return <IndicatorCard key={parameter.id} indicator={parameter} />;
};

export default ParameterCard;
