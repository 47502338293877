import { ApolloClient } from '@apollo/client';
import authService from 'business/user/services/authentication';
import {
  QueryMyUserQuery,
  QueryMyUserQueryVariables,
  UserPermission,
  QueryMyUserDocument,
} from 'generated/graphql';
import {
  UserWithPrivateInfosAndPermissions,
  isUserWithPrivateInfosAndPermissions,
  FormattedUserPermission,
  EntityTypes,
} from '../types/user';

async function fetchUser(
  client: ApolloClient<object>,
): Promise<UserWithPrivateInfosAndPermissions | undefined> {
  const id = authService.getAuthId();
  const email = authService.getAuthEmail();
  // try to get my user
  const data = await client.query<QueryMyUserQuery, QueryMyUserQueryVariables>({
    query: QueryMyUserDocument,
    variables: { id, email },
    fetchPolicy: 'network-only',
    context: {
      sendAsUser: true,
      getRootEntity: true,
    },
  });

  if (data) {
    const {
      data: {
        user: [finalUser],
      },
    } = data;
    if (!finalUser) {
      return undefined;
    }

    if (!isUserWithPrivateInfosAndPermissions(finalUser)) {
      throw new Error('cannot access private infos or permissions');
    }
    return finalUser;
  }

  return undefined;
}

export default async function getUser(client: ApolloClient<object>) {
  const user = await fetchUser(client);

  if (!user) {
    throw new Error('User does not exist');
  }

  return user;
}

export const formatPermission = (
  permission: UserPermission,
): FormattedUserPermission => {
  return {
    userPermissionId: permission.id,
    role: permission.role,
    entityId: permission.entityId,
    entityName: permission.entity.name,
    entityType: permission.entity.type,
    providers: {
      entityProviderId: permission.entity.entityProviderId ?? null,
      userProviderId: permission.entity.userProviderId ?? null,
    },
    entityTags: permission.entity.entityTags.map(
      (entityTag) => entityTag.tag.label,
    ),
  };
};

export const getDistinctEntityTypesForUsers = (
  userPermissions: UserPermission[],
) => {
  return [
    ...new Set<EntityTypes>(
      userPermissions.map((userPermission) => {
        return userPermission.entity.type;
      }),
    ),
  ];
};
