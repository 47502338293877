import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import Button from 'ui/button';
import Space from 'ui/space';

interface IndicatorListMenuProps {
  exportIndicators: () => void;
}

const IndicatorsListMenu: React.FC<IndicatorListMenuProps> = ({
  exportIndicators,
}) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          type="link"
          data-test-id="export-indicators"
          onClick={exportIndicators}
        >
          {t('indicators.strategy.export.cta')}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Space direction="vertical" justify="center">
        <Button
          data-test-id="indicator-menu-ellipsis"
          icon={<EllipsisOutlined />}
        />
      </Space>
    </Dropdown>
  );
};

export default IndicatorsListMenu;
