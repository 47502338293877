import { FormikProps, FormikState } from 'formik';
import { TFunction } from 'i18next';
import get from 'lodash.get';

export const formikStatus = (formik: FormikState<any>, fieldName: string) => {
  return get(formik.errors, fieldName) && get(formik.touched, fieldName)
    ? 'error'
    : undefined;
};

export const formikErrorMessage = (
  formik: FormikProps<any>,
  fieldName: string,
) =>
  formik.touched[fieldName] ? formik.errors[fieldName]?.toString() : undefined;

export const getAllPasswordRules = (t: TFunction) => {
  return [
    t('user.resetPassword.rules.signUp', {
      context: 'password-doesnt-contain-lowercase',
    }),
    t('user.resetPassword.rules.signUp', {
      context: 'password-doesnt-contain-uppercase',
    }),
    t('user.resetPassword.rules.signUp', {
      context: 'password-doesnt-contain-symbol',
    }),
    t('user.resetPassword.rules.signUp', {
      context: 'password-doesnt-contain-number',
    }),
    t('user.resetPassword.rules.signUp', { context: 'password-too-short' }),
  ];
};
