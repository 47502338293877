import React from 'react';
import Card from 'ui/card';
import classnames from 'classnames';
import { ReactDiv } from 'technical/type';
import styles from './index.module.scss';

const NotConnectedCard: React.FC<{ className?: string } & ReactDiv> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Card {...rest} className={classnames(styles.notConnectedCard, className)}>
      {children}
    </Card>
  );
};

export default NotConnectedCard;
