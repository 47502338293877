import React, { useRef } from 'react';
import 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';
import { useTranslation } from 'react-i18next';
import variables from 'ui/variables.module.scss';
import './index.scss';
// CookieConsent from Osana v3 implementation add the Object directly into Window
// As Soon As v4 is available on NPM : Need to change our implementation to a more
// module oriented kind.

declare global {
  interface Window {
    cookieconsent: any;
  }
}

export default function ConsentBanner() {
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);
  // We assume CC is always defined as the import of cookieconsent is not async.
  const CC = window.cookieconsent;
  CC.initialise({
    container: container.current,
    palette: {
      popup: {
        background: variables.surfaceBg,
        text: variables.surfaceText,
      },
      button: {
        background: variables.surfaceText,
        color: variables.surfaceBg,
      },
    },
    showLink: false,
    type: 'opt-in',
    cookie: {
      name: 'cookieconsent',
    },
    position: 'bottom-right',
    content: {
      message: t('cookies.mainText'),
      deny: t('cookies.declineAll'),
      allow: t('cookies.acceptAll'),
      policy: t('cookies.title'),
    },
    revokable: true,
    location: false,
    law: { contryCode: 'FR' },
  });

  return <div ref={container} />;
}
