import { useLoggedInAppContext } from 'business/AppBootstrapper';
import InfoBar from 'business/campaigns/components/infoBar';
import {
  getAllIndicatorTemplateFromCampaign,
  getLinkedIndicatorTemplates,
  getNotInstanciatedIndicatorTemplateFromCampaign,
} from 'business/campaigns/services';
import InformationDetails from 'business/common/components/informationDetails';
import AddMissingIndicatorsModal, {
  AddMissingIndicatorsModalContext,
} from 'business/indicators/components/addMissingIndicatorsModal';
import { MissingIndicator } from 'business/indicators/types';
import Routes, {
  campaignStrategyDetailsRoute,
  indicatorCatalogDetailsRoute,
} from 'business/router/routes';
import Restricted from 'business/user/components/Restricted';
import SubPageLayout from 'business/user/layout/subPage';
import { Permission } from 'business/user/types/user';
import {
  IndicatorLink_Constraint,
  IndicatorLink_Update_Column,
  Indicator_Constraint,
  Indicator_Update_Column,
  useAddMissingIndicatorsMutation,
  useGetCampaignTemplateQuery,
  useStartFromTemplateMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import logger from 'technical/logger';
import Button from 'ui/button';
import Loader from 'ui/loader';
import RichTextContainer from 'ui/richTextContainer';
import Space from 'ui/space';
import Typography from 'ui/typography';
import CampaignTemplateFields from '../../../components/campaignTemplateFields';
import ModalAddCampaignSuccess from '../../../components/modalAddCampaignSuccess';

interface CampaignCatalogDetailsPageParams {
  id: string;
}

const CampaignCatalogDetailsPage: React.FC = () => {
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const { t } = useTranslation();
  const { id } = useParams<CampaignCatalogDetailsPageParams>();
  const history = useHistory();

  const [missingIndicators, setMissingIndicators] = useState<
    MissingIndicator[]
  >([]);
  const [
    isModalAddMissingIndicatorVisible,
    setIsModalAddMissingIndicatorVisible,
  ] = useState(false);

  const [
    isModalAddCampaignSuccessVisible,
    setIsModalAddCampaignSuccessVisible,
  ] = useState(false);
  const [newCampaign, setNewCampaign] = useState<{
    campaignId: string;
    campaignUrl: string;
  }>();

  const [startFromTemplate] = useStartFromTemplateMutation();
  const [addMissingIndicators] = useAddMissingIndicatorsMutation();

  const { data } = useGetCampaignTemplateQuery({
    variables: {
      id,
    },
  });

  const campaignTemplate = data?.campaignTemplate_by_pk;

  if (!campaignTemplate) {
    return (
      <SubPageLayout goBack={Routes.CampaignsCatalog}>
        <Loader />
      </SubPageLayout>
    );
  }

  const indicatorTemplates = getAllIndicatorTemplateFromCampaign(
    campaignTemplate.indicatorTemplateCampaignTemplates.map(
      (indicatorTemplateCampaignTemplate) =>
        indicatorTemplateCampaignTemplate.indicatorTemplate,
    ),
  );

  const handleStartFromTemplate = async () => {
    const { data: startFromTemplateData } = await startFromTemplate({
      variables: {
        entityId,
        campaignTemplateId: campaignTemplate.id,
      },
    });

    if (!startFromTemplateData?.startFromTemplate.campaignId) {
      logger.error('No campaign id was found');
      return;
    }
    setNewCampaign(startFromTemplateData.startFromTemplate);
    setIsModalAddCampaignSuccessVisible(true);
  };

  const addIndicatorsBeforeStartingFromTemplate = async () => {
    try {
      await addMissingIndicators({
        variables: {
          indicators: indicatorTemplates?.map((indicatorTemplate) => ({
            indicatorTemplateId: indicatorTemplate.id,
            indicatorLinks: {
              data:
                indicatorTemplate.linkedIndicators?.map(
                  (linkedIndicatorTemplate) => ({
                    indicator: {
                      data: {
                        indicatorTemplateId: linkedIndicatorTemplate.id,
                        indicatorLinks: {
                          data:
                            linkedIndicatorTemplate.linkedIndicators?.map(
                              (linkedLinkIndicatorTemplate) => ({
                                indicator: {
                                  data: {
                                    indicatorTemplateId:
                                      linkedLinkIndicatorTemplate.id,
                                    entityId,
                                  },
                                  on_conflict: {
                                    constraint:
                                      Indicator_Constraint.IndicatorCompanyIdIndicatorTemplateIdKey,
                                    update_columns: [
                                      Indicator_Update_Column.UpdatedAt,
                                    ],
                                  },
                                },
                              }),
                            ) || [],
                          on_conflict: {
                            constraint:
                              IndicatorLink_Constraint.IndicatorLinkPkey,
                            update_columns: [
                              IndicatorLink_Update_Column.UpdatedAt,
                            ],
                          },
                        },
                        entityId,
                      },
                      on_conflict: {
                        constraint:
                          Indicator_Constraint.IndicatorCompanyIdIndicatorTemplateIdKey,
                        update_columns: [Indicator_Update_Column.UpdatedAt],
                      },
                    },
                  }),
                ) || [],
              on_conflict: {
                constraint: IndicatorLink_Constraint.IndicatorLinkPkey,
                update_columns: [IndicatorLink_Update_Column.UpdatedAt],
              },
            },
            entityId,
          })),
        },
      });

      await handleStartFromTemplate();
    } catch (error) {
      logger.error('[Start from template]', error);
    }
  };

  const handleInstanciate = async () => {
    const notInstanciatedIndicatorTemplate =
      getNotInstanciatedIndicatorTemplateFromCampaign(
        campaignTemplate.indicatorTemplateCampaignTemplates.map(
          (indicatorTemplateCampaignTemplate) =>
            indicatorTemplateCampaignTemplate.indicatorTemplate,
        ),
      );
    if (notInstanciatedIndicatorTemplate.length !== 0) {
      setMissingIndicators(notInstanciatedIndicatorTemplate);
      setIsModalAddMissingIndicatorVisible(true);
    } else {
      await handleStartFromTemplate();
    }
  };

  const linkedIndicatorTemplates =
    getLinkedIndicatorTemplates(campaignTemplate);

  return (
    <SubPageLayout goBack={Routes.CampaignsCatalog}>
      <Space align="start" direction="vertical">
        <Space justify="space-between">
          <Typography.Title data-test-id="campaign-template-name" level={3}>
            {campaignTemplate.name}
          </Typography.Title>
          <Restricted permission={Permission.CampaignAdd}>
            <Button
              data-test-id="start-campaign"
              type="primary"
              onClick={() => handleInstanciate()}
            >
              {t('campaigns.catalog.details.launchCampaign')}
            </Button>
          </Restricted>
        </Space>
        <InfoBar
          numberOfAssociatedIndicators={
            campaignTemplate.indicatorTemplateCampaignTemplates.length
          }
          numberOfQuestions={campaignTemplate.numberOfQuestions}
          isExternal={campaignTemplate.isExternal}
        />
        <RichTextContainer content={campaignTemplate.description} />
        <InformationDetails
          data-test-id="campaign-template-associated-indicators"
          title={t('campaigns.catalog.details.associatedIndicatorsLabel')}
        >
          {linkedIndicatorTemplates.map((indicatorTemplate) => (
            <Link
              key={indicatorTemplate.id}
              to={indicatorCatalogDetailsRoute(indicatorTemplate.id)}
            >
              {indicatorTemplate.label}
            </Link>
          ))}
        </InformationDetails>
        <CampaignTemplateFields questions={campaignTemplate.questions} />
      </Space>
      <AddMissingIndicatorsModal
        editable={false}
        context={AddMissingIndicatorsModalContext.Campaigns}
        isVisible={isModalAddMissingIndicatorVisible}
        missingIndicators={missingIndicators}
        handleSubmit={addIndicatorsBeforeStartingFromTemplate}
        handleClose={() => setIsModalAddMissingIndicatorVisible(false)}
      />
      <ModalAddCampaignSuccess
        isVisible={isModalAddCampaignSuccessVisible}
        campaignUrl={newCampaign?.campaignUrl}
        handleCancel={() => {
          setIsModalAddCampaignSuccessVisible(false);
        }}
        handleClose={() => {
          setIsModalAddCampaignSuccessVisible(false);
          if (newCampaign?.campaignId) {
            history.push(campaignStrategyDetailsRoute(newCampaign?.campaignId));
          } else {
            logger.error('[Front / StartFromTemplate] New Campaign Id missing');
          }
        }}
      />
    </SubPageLayout>
  );
};

export default CampaignCatalogDetailsPage;
