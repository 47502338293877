import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { ActionTemplateRow } from 'business/actions/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TableFilters from 'business/common/components/tableFilters';
import { actionCatalogDetailsRoute } from 'business/router/routes';
import ConnectedLayout from 'business/user/layout/connected';
import {
  ActionTemplate_Order_By,
  Order_By,
  useActionTemplatesListQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { handleSortChange } from 'technical/table/sort';
import EmptyState from 'ui/emptyState';
import Space from 'ui/space';
import Table from 'ui/table';
import Typography from 'ui/typography';
import {
  actionTemplatesColumns,
  actionTemplatesDataSource,
  buildActionTemplateOrderBy,
  useActionTableFilters,
} from '../../../services/table';

const ActionsCatalogPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityTags },
  } = useLoggedInAppContext();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<
    ActionTemplate_Order_By | ActionTemplate_Order_By[]
  >({ createdAt: Order_By.Desc, id: Order_By.Asc });

  const history = useHistory();

  const { filtersValues, debouncedFiltersValues, setFiltersValues } =
    useActionTableFilters();

  const { data, previousData, loading } = useActionTemplatesListQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      sort: orderBy,
      condition: {
        isVisible: { _eq: true },
        _or: [
          { label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` } },
          {
            description: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
        ],
        actionTemplateIndicatorTemplates: {
          indicatorTemplate: {
            indicatorTemplateStakes: {
              stake: {
                id: filtersValues.stakeId ? { _eq: filtersValues.stakeId } : {},
                themeId: filtersValues.themeId
                  ? { _eq: filtersValues.themeId }
                  : {},
              },
            },
            indicatorTemplateTags: {
              tag: {
                label: {
                  _in: entityTags,
                },
              },
            },
          },
        },
        _not: {
          actions: { deletedAt: { _is_null: true } },
        },
      },
    },
  });

  const actionTemplates =
    data?.actionTemplate || previousData?.actionTemplate || [];

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current || 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSortChange = (sorter: SorterResult<ActionTemplateRow>) => {
    setOrderBy(
      handleSortChange<ActionTemplateRow, ActionTemplate_Order_By>(
        sorter,
        buildActionTemplateOrderBy,
      ),
    );
  };

  return (
    <ConnectedLayout>
      <Space direction="vertical" size="middle">
        <Typography.Title level={3}>
          {t('actions.catalog.title')}
        </Typography.Title>
        <Typography.Paragraph>{t('actions.catalog.info')}</Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) => {
            setFiltersValues({ ...newFilters, page: 1 });
          }}
          searchPlaceholder={t('tableFilters.search.actions')}
        />
        {actionTemplates.length === 0 && !loading ? (
          <EmptyState
            title={t('actions.catalog.empty.title')}
            description={t('actions.catalog.empty.description')}
          />
        ) : (
          <Table
            loading={loading}
            data-test-id="action-templates-table"
            data-onboarding-id="catalog-actions-table"
            dataSource={actionTemplatesDataSource(actionTemplates)}
            columns={actionTemplatesColumns()}
            pagination={{
              total: data?.actionTemplate_aggregate.aggregate?.count || 0,
              pageSize: resultsPerPage,
              current: filtersValues.page,
            }}
            onChange={(pagination, _filters, sorter) => {
              onPaginationChange(pagination);
              onSortChange(sorter as SorterResult<ActionTemplateRow>);
            }}
            onRow={(record) => ({
              onClick: () =>
                history.push(actionCatalogDetailsRoute(record.key)),
            })}
          />
        )}
      </Space>
    </ConnectedLayout>
  );
};

export default ActionsCatalogPage;
