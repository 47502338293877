import React from 'react';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import Card from 'ui/card';
import { formatNumber } from 'technical/number/format';
import { IndicatorType, IndicatorUnit } from 'business/indicators/types';
import { getUnitDisplay } from 'business/indicators/services/units';
import { getPercent } from '../helpers';
import styles from './index.module.scss';

type PercentPieChartCustomTooltipProps = TooltipProps<number, string> & {
  total: number;
  type: IndicatorType;
  unit: IndicatorUnit;
};

function PercentPieChartCustomTooltip({
  active,
  payload,
  total,
  type,
  unit,
}: PercentPieChartCustomTooltipProps) {
  if (active && payload && payload.length) {
    const { name, value, color } = {
      name: payload[0].name ?? '',
      value: payload[0].value ?? 0,
      color: payload[0].payload.fill,
    };

    if (type === IndicatorType.Select) {
      return (
        <Card className={styles.tooltipCard} style={{ color }}>
          {getUnitDisplay(unit)(parseInt(name, 10))} : {formatNumber(value)} (
          {getPercent(value, total)})
        </Card>
      );
    }

    return (
      <Card className={styles.tooltipCard} style={{ color }}>
        {name} : {formatNumber(value)} ({getPercent(value, total)})
      </Card>
    );
  }
  return null;
}

export default PercentPieChartCustomTooltip;
