export enum Mode {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

const IndicatorStrategyDetailsTabsBase = {
  Description: 'description',
  Data: 'data',
  Actions: 'actions',
  Campaigns: 'campaigns',
  IndicatorTemplateLinks: 'indicator-template-links',
  Partners: 'partners',
} as const;

export type IndicatorStrategyDetailsTabs =
  (typeof IndicatorStrategyDetailsTabsBase)[keyof typeof IndicatorStrategyDetailsTabsBase];

export function isIndicatorStrategyDetailsTabs(
  s: string,
): s is IndicatorStrategyDetailsTabs {
  return Object.values(IndicatorStrategyDetailsTabsBase as any).includes(s);
}
