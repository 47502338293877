import { ThemeConfig } from 'antd';
import colors from './colors';
import spacing from './spacing';

// This is where you overide basic theme of ant design
// change will be moved to the useToken

const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
    colorTextHeading: colors.titleColor,
    colorTextSecondary: colors.softenColor,
    colorText: 'inherit',
    borderRadius: spacing.radiusSm,
    fontFamily: 'Ubuntu',
    paddingLG: spacing.paddingLg,
    fontSize: 14,
  },
  components: {
    Card: {
      borderRadiusLG: spacing.radiusMd,
    },
    Button: {
      paddingInline: '20px',
      controlHeight: 40,
      colorBorder: colors.surfaceBg,
      colorBgContainer: colors.surfaceBg,
      fontSize: 16,
    },
    Avatar: {
      // '@avatar-bg': colors.primary, **this variable does not exist anymore in ant v5, and should be directly overridden by className or style instead**
    },
    Menu: {
      darkItemColor: colors.surfaceBg,
      darkItemBg: colors.emphasisColor,
      darkDangerItemSelectedBg: colors.emphasisColor,
      itemHeight: 32,
    },
    Table: {
      colorBgContainer: colors.surfaceBg,
      headerBg: colors.surfaceBg,
      headerColor: colors.titleColor,
      rowHoverBg: colors.lightEmphasisColor,
      headerBorderRadius: spacing.radiusMd,
      headerSplitColor: 'transparent',
    },
    Modal: {
      //   '@modal-header-border-width': 0, **depreacated for style change**
      // '@modal-footer-border-width': 0, **depreacated for style change**
      //   '@modal-body-padding': `${spacing.paddingMd} ${spacing.paddingLg} ${spacing.paddingSm} ${spacing.paddingLg}`, **depreacated for style change**
      // '@modal-header-padding': `${spacing.paddingLg} ${spacing.paddingLg} ${spacing.paddingSm} ${spacing.paddingLg}`, **depreacated for style change**
      //   '@modal-footer-padding-vertical': spacing.paddingLg, **depreacated for style change**
      //   '@modal-footer-padding-horizontal': spacing.paddingLg, **depreacated for style change**
      colorBgMask: `fade(${colors.maskBg}, 45%)`,
    },
    Form: {
      labelColor: colors.primary,
    },
  },
};

export default defaultTheme;
