import { ApiFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IndicatorTemplateConnectorMarkerProps {
  className?: string;
}
const IndicatorTemplateConnectorMarker: React.FC<
  IndicatorTemplateConnectorMarkerProps
> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      className={className}
      placement="bottom"
      title={t('indicators.strategy.card.connected')}
    >
      <ApiFilled />
    </Tooltip>
  );
};

export default IndicatorTemplateConnectorMarker;
