import { ILanguage } from 'translations';
import { EntityTypes } from '../../business/user/types/user';

export const mapEntityToLanguage = (entity: EntityTypes): ILanguage => {
  switch (entity) {
    case EntityTypes.Company:
      return ILanguage.fr_company;
    case EntityTypes.Building:
      return ILanguage.fr_building;
    case EntityTypes.Contract:
      return ILanguage.fr_contract;
    default:
      return ILanguage.fr_fallback;
  }
};
