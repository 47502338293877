import InformationDetails from 'business/common/components/informationDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPercent } from 'technical/currency/formatters';
import { i18n } from 'translations';
import BigNumber from 'ui/bigNumber';
import Space from 'ui/space';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface CampaignGlobalStatsProps {
  insights: {
    opened?: number | null;
    started?: number | null;
    completed?: number | null;
    completionRate?: number | null;
  };
}

const CampaignGlobalStats: React.FC<CampaignGlobalStatsProps> = ({
  insights,
}) => {
  const { t } = useTranslation();
  return (
    <InformationDetails title={t('campaigns.strategy.details.stats.title')}>
      <Space>
        <BigNumber
          data-test-id="campaign-details-big-number-opened"
          className={styles.bigNumber}
          value={String(insights.opened)}
          label={t('campaigns.strategy.details.stats.opened')}
        />
        <BigNumber
          data-test-id="campaign-details-big-number-started"
          className={styles.bigNumber}
          value={String(insights.started)}
          label={t('campaigns.strategy.details.stats.started')}
        />
        <BigNumber
          data-test-id="campaign-details-big-number-completed"
          className={styles.bigNumber}
          value={String(insights.completed)}
          label={t('campaigns.strategy.details.stats.completed')}
        />
        <BigNumber
          data-test-id="campaign-details-big-number-completionRate"
          className={styles.bigNumber}
          value={`${formatPercent((insights.completionRate || 0) / 100, {
            precision: 1,
          })}`}
          label={t('campaigns.strategy.details.stats.completionRate')}
        />
      </Space>
    </InformationDetails>
  );
};

export default CampaignGlobalStats;
