import { useApolloClient } from '@apollo/client';
import {
  AppContextAttributes,
  AppContext,
  useAppContext,
} from 'business/AppBootstrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { setUserId } from 'technical/analytics';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import authService from '../services/authentication';
import getUser from '../services/user';
import { UserWithPrivateInfosAndPermissions } from '../types/user';
import ConnectedUserContextProvider from './connectedUser';

function useUserData(): Pick<
  AppContextAttributes,
  'user' | 'appBootstraped' | 'requestRebootstrap' | 'isConnected'
> {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [user, setUser] = useState<
    UserWithPrivateInfosAndPermissions | undefined
  >(undefined);
  const client = useApolloClient();

  // @todo use subscription for user data when below error is handled
  // (cf this commit to "revert" code)
  // BEWARE: on invalid JWT, subscriptions are automatically disconnected and not re-subscribed
  // Error: cannot start as connection_init failed with : Malformed Authorization header

  const fetchAndStoreUser = useCallback(
    async function refetchUser() {
      const targetUser = await getUser(client);
      setUser(targetUser);
    },
    [client],
  );

  /**
   * Fonction to be called if needed typcially when authentication status changes
   * (ie - was not connected and now am connected)
   */
  const requestRebootstrap = useCallback(
    async function requestRebootstrap() {
      try {
        await authService.initAuthentication();
        if (!authService.isAuthenticated()) {
          logger.error('User is not authenticated');
          return;
        }
        await fetchAndStoreUser();
      } catch (error) {
        logger.error(error);
        if (error instanceof Error) {
          errorReporting.warning(error);
        }
        throw error;
      } finally {
        setIsBootstraped(true);
      }
    },
    [fetchAndStoreUser],
  );

  useEffect(() => {
    requestRebootstrap();
  }, [client, requestRebootstrap]);

  // user sync with providers
  useEffect(() => {
    setUserId(user?.id);
    if (user) {
      errorReporting.setUser({
        id: user.id,
        email: user.privateInfos.email,
      });
    } else {
      errorReporting.removeUser();
    }
  }, [user]);

  return {
    user,
    appBootstraped: isBootstraped,
    requestRebootstrap,
    isConnected: !!user,
  };
}

const UserContextProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const appContext = useAppContext();
  const userData = useUserData();

  return (
    <AppContext.Provider value={{ ...appContext, ...userData }}>
      {userData.user ? (
        <ConnectedUserContextProvider user={userData.user}>
          {children}
        </ConnectedUserContextProvider>
      ) : (
        children
      )}
    </AppContext.Provider>
  );
};

export default UserContextProvider;
