import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import Typography from 'ui/typography';
import { EntityTypes } from 'business/user/types/user';
import styles from './index.module.scss';
import EntitiesList from '../entitiesList';

interface EntitySelectionCardProps {
  entityTypes: EntityTypes[];
}

const EntitySelectionCard: React.FC<EntitySelectionCardProps> = ({
  entityTypes,
}) => {
  const { t } = useTranslation();

  return (
    <Card className={styles.entitySelectionCard}>
      {entityTypes.length ? (
        <EntitiesList entityTypes={entityTypes} />
      ) : (
        <Typography.Paragraph
          center
          data-test-id="entity-selection-no-role-message"
        >
          {t('account.selection.noRole')}
        </Typography.Paragraph>
      )}
    </Card>
  );
};

export default EntitySelectionCard;
