import { FormikProps } from 'formik';
import {
  IndicatorTemplateForActionFragment,
  Maybe,
  StakeFragment,
  ThemeFragment,
} from 'generated/graphql';

export interface ActionRow {
  key: string;
  label: string;
  nbAssociatedIndicators: number;
  status: { status: string; id: string };
  dueDate: Date | null;
  cost?: Maybe<string>;
  impact?: Maybe<string>;
  responsible?: Maybe<string>;
}

export interface ActionTemplateRow {
  key: string;
  label: string;
  cost?: Maybe<string>;
  impact?: Maybe<string>;
  themes: ThemeFragment[];
  stakes: StakeFragment[];
  nbAssociatedIndicatorTemplates: number;
  relevance?: Maybe<number>;
}

export interface AssociatedActionTemplateRow {
  key: string;
  label: string;
  cost?: Maybe<string>;
  impact?: Maybe<string>;
}

export interface ActionTemplateWithRelevanceRow {
  key: string;
  label: string;
  themes: ThemeFragment[];
  stakes: StakeFragment[];
  relevance: number;
}

export interface AssociatedActionRow {
  key: string;
  label: string;
  cost?: Maybe<ActionCost>;
  impact?: Maybe<ActionImpact>;
  status: ActionStatus;
  dueDate: string;
}

// Keep in sync with ForestAdmin and custom-server/src/business/actions/services/constants.ts
export enum ActionStatus {
  Incoming = 1,
  InProgress = 2,
  Finished = 3,
}

export const AllActionStatus = [
  ActionStatus.Incoming,
  ActionStatus.InProgress,
  ActionStatus.Finished,
];

// Keep in sync with ForestAdmin and custom-server/src/business/actions/services/constants.ts
export enum ActionImpact {
  Low = 1,
  Medium = 2,
  High = 3,
}

export const AllActionImpacts = [
  ActionImpact.Low,
  ActionImpact.Medium,
  ActionImpact.High,
];

// Keep in sync with ForestAdmin and custom-server/src/business/actions/services/constants.ts
export enum ActionCost {
  Low = 1,
  Medium = 2,
  High = 3,
}

export const AllActionCosts = [
  ActionCost.Low,
  ActionCost.Medium,
  ActionCost.High,
];

export type FormikAction = FormikProps<{
  label: string;
  description: string;
  comment: string | undefined;
  status: ActionStatus;
  dueDate: Date | undefined;
  cost: ActionCost | undefined;
  impact: ActionImpact | undefined;
  linkedIndicators: Array<IndicatorTemplateForActionFragment>;
  responsibleId: string | undefined;
}>;
