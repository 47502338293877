import {
  stringFilter,
  pageFilter,
} from 'business/common/components/tableFilters/services/filters';
import { useFilters } from 'business/common/components/tableFilters/services/useFiltersHook';
import {
  AssociatedAction,
  AssociatedActionTemplate,
} from 'business/indicators/types';
import { getThemes } from 'business/stake/services';
import ThemeTag from 'business/theme/components/themeTag';
import {
  ActionFragment,
  ActionTemplateFragment,
  ActionTemplate_Order_By,
  Action_Order_By,
  ListActionWithRelevanceQuery,
  Order_By,
  StakeFragment,
  ThemeFragment,
} from 'generated/graphql';
import React, { Key } from 'react';
import { formatDate } from 'technical/date/formatter';
import { SorterOrder } from 'technical/table/sort';
import { i18n } from 'translations';
import Space from 'ui/space';
import {
  getActionTemplateStakes,
  getNbAssociatedIndicators,
  getNbAssociatedIndicatorTemplates,
} from '.';
import StatusTag from '../components/statusTag';
import StatusTagSelect from '../components/statusTagSelect';
import {
  ActionRow,
  ActionStatus,
  ActionTemplateRow,
  AssociatedActionRow,
  AssociatedActionTemplateRow,
} from '../types/index';

// Actions table (strategy)

export const buildActionOrderBy = (
  columnKey: Key | undefined,
  order: Order_By,
): Action_Order_By | ActionTemplate_Order_By | undefined => {
  switch (columnKey as keyof ActionRow | keyof ActionTemplateRow) {
    case 'label':
      return { label: order };
    case 'status':
      return { status: order };
    case 'dueDate':
      return { dueDate: order };
    case 'nbAssociatedIndicators':
      return { actionIndicators_aggregate: { count: order } };
    case 'cost':
      return { cost: order };
    case 'impact':
      return { impact: order };
    case 'relevance':
      return {
        actionTemplateRelevanceForCompanies_aggregate: {
          max: {
            relevance: order,
          },
        },
      };
    case 'responsible':
      return { responsible: { lastName: order } };
    default:
      return undefined;
  }
};

export const actionsDataSource = (actions: ActionFragment[]): ActionRow[] => {
  return actions.map((action) => {
    const { id, label, status, dueDate, cost, impact, responsible } = action;
    const nbAssociatedIndicators = getNbAssociatedIndicators(action);
    return {
      key: id,
      label,
      status: { status, id },
      dueDate: dueDate ? new Date(dueDate) : null,
      nbAssociatedIndicators,
      cost,
      impact,
      responsible: responsible
        ? `${responsible.firstName ?? ''} ${responsible.lastName ?? ''}`
        : '',
    };
  });
};

export const actionsColumns = (refetch?: () => void) => [
  {
    title: i18n.t('actions.strategy.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: true,
    width: '40%',
  },
  {
    title: i18n.t('actions.strategy.table.nbAssociatedIndicators'),
    dataIndex: 'nbAssociatedIndicators',
    key: 'nbAssociatedIndicators',
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: (action: { status: ActionStatus; id: string }) => {
      return <StatusTagSelect action={action} onChange={refetch} />;
    },
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (dueDate: Date | null) => {
      return dueDate === null
        ? i18n.t(`actions.dueDate.null`)
        : formatDate(dueDate);
    },
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.cost'),
    dataIndex: 'cost',
    key: 'cost',
    render: (cost: number) => {
      return i18n.t(`actions.cost.${cost}`);
    },
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.impact'),
    dataIndex: 'impact',
    key: 'impact',
    render: (impact: string) => {
      return i18n.t(`actions.impact.${impact}`);
    },
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.responsible'),
    dataIndex: 'responsible',
    key: 'responsible',
    render: (responsible: string) => {
      return responsible;
    },
    sorter: true,
    width: '10%',
  },
];

// Action templates table (strategy)

export const buildActionTemplateOrderBy = (
  columnKey: Key | undefined,
  order: Order_By,
): ActionTemplate_Order_By | undefined => {
  switch (columnKey as keyof ActionTemplateRow) {
    case 'label':
      return { label: order };
    case 'cost':
      return { cost: order };
    case 'impact':
      return { impact: order };
    case 'nbAssociatedIndicatorTemplates':
      return { actionTemplateIndicatorTemplates_aggregate: { count: order } };
    default:
      return undefined;
  }
};

export const actionTemplatesDataSource = (
  actionTemplates: ActionTemplateFragment[],
): ActionTemplateRow[] => {
  return actionTemplates.map((actionTemplate) => {
    const { id, label, cost, impact } = actionTemplate;

    const stakes = getActionTemplateStakes(actionTemplate);
    const nbAssociatedIndicatorTemplates =
      getNbAssociatedIndicatorTemplates(actionTemplate);
    const themes = getThemes(stakes);

    return {
      key: id,
      label,
      cost,
      impact,
      themes,
      stakes,
      nbAssociatedIndicatorTemplates,
    };
  });
};

export const actionTemplatesColumns = () => [
  {
    title: i18n.t('actions.catalog.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: true,
    width: '40%',
  },
  {
    title: i18n.t('actions.strategy.table.cost'),
    dataIndex: 'cost',
    key: 'cost',
    render: (cost: number) => {
      return i18n.t(`actions.cost.${cost}`);
    },
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.impact'),
    dataIndex: 'impact',
    key: 'impact',
    render: (impact: string) => {
      return i18n.t(`actions.impact.${impact}`);
    },
    sorter: true,
    width: '10%',
  },
  {
    title: i18n.t('actions.catalog.table.themes'),
    dataIndex: 'themes',
    key: 'themes',
    render: (themes: ThemeFragment[]) => {
      // Forced by eslint to spread themes (bug)
      return (
        <Space direction="vertical" size="extra-small">
          {[...themes].map((theme) => (
            <ThemeTag key={theme.id} theme={theme} />
          ))}
        </Space>
      );
    },
    width: '10%',
  },
  {
    title: i18n.t('actions.catalog.table.stakes'),
    dataIndex: 'stakes',
    key: 'stakes',
    render: (stakes: StakeFragment[]) => {
      return stakes.map((stake) => stake.label).join(', ');
    },
    width: '15%',
  },
  {
    title: i18n.t('actions.catalog.table.nbAssociatedIndicatorTemplates'),
    dataIndex: 'nbAssociatedIndicatorTemplates',
    key: 'nbAssociatedIndicatorTemplates',
    sorter: true,
    width: '15%',
  },
];

// Associated action templates (catalog)

export const associatedActionTemplatesDataSource = (
  actionTemplates: AssociatedActionTemplate[],
): AssociatedActionTemplateRow[] => {
  return actionTemplates.map((actionTemplate) => {
    const { id, label, cost, impact } = actionTemplate;
    return {
      key: id,
      label,
      cost,
      impact,
    };
  });
};

export const associatedActionTemplatesColumns = () => [
  {
    title: i18n.t('actions.catalog.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: (a: AssociatedActionTemplateRow, b: AssociatedActionTemplateRow) =>
      a.label.localeCompare(b.label),
    width: '60%',
  },
  {
    title: i18n.t('actions.catalog.table.cost'),
    dataIndex: 'cost',
    key: 'cost',
    render: (cost: number) => {
      return i18n.t(`actions.cost.${cost}`);
    },
    sorter: (a: AssociatedActionTemplateRow, b: AssociatedActionTemplateRow) =>
      (a.cost || '').localeCompare(b.cost || ''),
    width: '20%',
  },
  {
    title: i18n.t('actions.catalog.table.impact'),
    dataIndex: 'impact',
    key: 'impact',
    render: (impact: string) => {
      return i18n.t(`actions.impact.${impact}`);
    },
    sorter: (a: AssociatedActionTemplateRow, b: AssociatedActionTemplateRow) =>
      (a.impact || '').localeCompare(b.impact || ''),
    width: '20%',
  },
];

// Associated actions (strategy)

export const associatedActionsDataSource = (
  actions: AssociatedAction[],
): AssociatedActionRow[] => {
  return actions.map((action) => {
    const { id, label, cost, impact, status, dueDate } = action;
    return {
      key: id,
      label,
      cost,
      impact,
      status,
      dueDate,
    };
  });
};

export const associatedActionsColumns = () => [
  {
    title: i18n.t('actions.strategy.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: (a: AssociatedActionRow, b: AssociatedActionRow) =>
      a.label.localeCompare(b.label),
    width: '50%',
  },
  {
    title: i18n.t('actions.strategy.table.cost'),
    dataIndex: 'cost',
    key: 'cost',
    render: (cost: number) => {
      return i18n.t(`actions.cost.${cost}`);
    },
    sorter: (a: AssociatedActionRow, b: AssociatedActionRow) =>
      (a.cost ?? 0) - (b.cost ?? 0),
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.impact'),
    dataIndex: 'impact',
    key: 'impact',
    render: (impact: string) => {
      return i18n.t(`actions.impact.${impact}`);
    },
    sorter: (a: AssociatedActionRow, b: AssociatedActionRow) =>
      (a.impact ?? 0) - (b.impact ?? 0),
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status: ActionStatus) => {
      return <StatusTag status={status} />;
    },
    sorter: (a: AssociatedActionRow, b: AssociatedActionRow) =>
      a.status - b.status,
    width: '10%',
  },
  {
    title: i18n.t('actions.strategy.table.dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (dueDate: string) => {
      return formatDate(dueDate);
    },
    sorter: true,
    width: '10%',
  },
];

// Action With Relevance
export const actionTemplateWithRelevanceColumns = () => [
  {
    title: i18n.t('actions.catalog.table.title'),
    dataIndex: 'label',
    key: 'label',
    sorter: true,
    width: '60%',
  },
  {
    title: i18n.t('actions.catalog.table.themes'),
    dataIndex: 'themes',
    key: 'themes',
    render: (themes: ThemeFragment[]) => {
      // Forced by eslint to spread themes (bug)
      return (
        <Space direction="vertical" size="extra-small">
          {[...themes].map((theme) => (
            <ThemeTag key={theme.id} theme={theme} />
          ))}
        </Space>
      );
    },
    width: '10%',
  },
  {
    title: i18n.t('actions.catalog.table.stakes'),
    dataIndex: 'stakes',
    key: 'stakes',
    render: (stakes: StakeFragment[]) => {
      return stakes.map((stake) => stake.label).join(', ');
    },
    width: '15%',
  },
  {
    title: i18n.t('actions.catalog.table.relevance'),
    dataIndex: 'relevance',
    key: 'relevance',
    sorter: true,
    width: '15%',
    defaultSortOrder: SorterOrder.Descend,
  },
];

export const actionTemplateWithRelevanceData = (
  actionTemplates: ListActionWithRelevanceQuery['actionTemplate'],
) => {
  return actionTemplates.map((actionTemplate) => {
    const { id, label } = actionTemplate;

    const stakes = getActionTemplateStakes(actionTemplate);
    const relevance =
      actionTemplate.actionTemplateRelevanceForCompanies.length > 0
        ? actionTemplate.actionTemplateRelevanceForCompanies[0].relevance
        : 0;
    const themes = getThemes(stakes);

    return {
      key: id,
      label,
      themes,
      stakes,
      relevance,
    };
  });
};

export const useActionTableFilters = () =>
  useFilters({
    search: stringFilter,
    stakeId: stringFilter,
    themeId: stringFilter,
    page: pageFilter,
  });
