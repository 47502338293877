import React from 'react';

interface ExternalLinkProps {
  to?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  children,
  to,
  target = '_blank',
}) => (
  <a href={to} target={target} rel="noopenner noreferrer">
    {children}
  </a>
);

export default ExternalLink;
