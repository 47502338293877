import React from 'react';
import Table from 'ui/table';
import { AssociatedCampaignTemplate } from 'business/indicators/types';
import {
  associatedCampaignTemplateData,
  associatedCampaignTemplateColumns,
} from 'business/campaigns/services/table';
import { useHistory } from 'react-router';
import { campaignCatalogDetailsRoute } from 'business/router/routes';

interface AssociatedCampaignTemplatesTableProps {
  campaignTemplates: AssociatedCampaignTemplate[];
}

const AssociatedCampaignTemplatesTable: React.FC<
  AssociatedCampaignTemplatesTableProps
> = ({ campaignTemplates }) => {
  const history = useHistory();

  return (
    <Table
      data-test-id="associated-campaign-templates-table"
      columns={associatedCampaignTemplateColumns}
      dataSource={associatedCampaignTemplateData(campaignTemplates)}
      onRow={(record) => ({
        onClick: () => history.push(campaignCatalogDetailsRoute(record.key)),
      })}
    />
  );
};

export default AssociatedCampaignTemplatesTable;
