import React from 'react';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar as RechartsRadar,
  Tooltip,
  Text,
} from 'recharts';
import ResponsiveContainer from 'ui/responsiveContainer';
import styles from './index.module.scss';

export interface RadarProps {
  data: any[];
  color: string;
  tooltipLabel: string;
}

const customTick = ({ payload, x, y, textAnchor, stroke, radius }: any) => {
  return (
    <Text
      width={120}
      x={x}
      y={y}
      textAnchor={textAnchor}
      stroke={stroke}
      radius={radius}
      className={styles.radarLabel}
    >
      {payload.value}
    </Text>
  );
};

const Radar: React.FC<RadarProps> = ({ data, color, tooltipLabel }) => {
  // debounce is used to avoid ResizeObserver Errors
  return (
    <ResponsiveContainer height={400}>
      <RadarChart outerRadius="70%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="stakeLabel" tick={customTick} />
        <PolarRadiusAxis />
        <RechartsRadar
          name="indicators"
          dataKey="count"
          stroke={color}
          fill={color}
          fillOpacity={0.6}
        />
        <Tooltip formatter={(value: number) => [value, tooltipLabel]} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default Radar;
