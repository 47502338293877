import React from 'react';
import {
  HomeOutlined,
  TeamOutlined,
  FileProtectOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { EntityTypes } from '../types/user';

const getEntityIcon = (entityType: EntityTypes) => {
  switch (entityType) {
    case EntityTypes.Building:
      return <HomeOutlined />;
    case EntityTypes.Company:
      return <TeamOutlined />;
    case EntityTypes.Contract:
      return <FileProtectOutlined />;
    default:
      return <GlobalOutlined />;
  }
};

export default getEntityIcon;
