import React from 'react';
import { useConnectedUserContext } from 'business/AppBootstrapper';
import Layout from 'ui/layout';
import Typography from 'ui/typography';
import Button from 'ui/button';
import authService from 'business/user/services/authentication';
import { useTranslation } from 'react-i18next';
import Space from 'ui/space';
import AppLogo from 'ui/appLogo';
import styles from './index.module.scss';

const ContextSwitchLayout: React.FC = ({ children }) => {
  const { user } = useConnectedUserContext();
  const { t } = useTranslation();

  return (
    <Layout mode="dark" isMenuVisible={false}>
      <Space>
        <Space direction="vertical" align="center" size="large">
          <AppLogo mode="dark" />
          <Typography.Title className={styles.title}>
            {t('account.selection.title')}
          </Typography.Title>
          <Typography.Text className={styles.contextSelectionText}>
            {t('account.selection.connectionMessage', {
              email: user.privateInfos.email || '',
            })}
          </Typography.Text>
          <Typography.Text className={styles.contextSelectionText}>
            {t('account.selection.notYou')}
            <Button
              type="link"
              size="small"
              onClick={() => authService.logout()}
            >
              {t('common.logout')}
            </Button>
          </Typography.Text>
          {children}
        </Space>
      </Space>
    </Layout>
  );
};

export default ContextSwitchLayout;
