import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import Button from 'ui/button';
import Space from 'ui/space';

interface ActionsMenuProps {
  handleDeleteCta: () => void;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ handleDeleteCta }) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          type="link"
          data-test-id="delete-action"
          onClick={handleDeleteCta}
        >
          {t('actions.strategy.delete.cta')}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Space direction="vertical" justify="center">
        <Button
          data-test-id="action-menu-ellipsis"
          icon={<EllipsisOutlined />}
        />
      </Space>
    </Dropdown>
  );
};

export default ActionsMenu;
