import React from 'react';
import Space from 'ui/space';
import Sprinkle from 'ui/sprinkle';
import styles from './index.module.scss';

const DocumentationContainer: React.FC = ({ children }) => {
  return (
    <Space className={styles.documentationHolder} direction="vertical">
      <Space className={styles.documentationContainer} direction="vertical">
        {children}
      </Space>
      <div className={styles.sprinklesContainer}>
        <Sprinkle
          color="green"
          style={{ top: 0, left: 0, transform: 'rotate(30deg)' }}
        />
        <Sprinkle
          color="green"
          style={{ top: 0, left: '70%', transform: 'rotate(30deg)' }}
        />
        <Sprinkle
          color="green"
          style={{ top: '250px', right: 0, transform: 'rotate(30deg)' }}
        />
        <Sprinkle
          color="blue"
          style={{ top: 0, right: 0, transform: 'rotate(-30deg)' }}
        />
        <Sprinkle
          color="blue"
          style={{ top: '150px', left: 0, transform: 'rotate(-30deg)' }}
        />
        <Sprinkle
          color="blue"
          style={{ top: '-10px', right: '70%', transform: 'rotate(66deg)' }}
        />
        <Sprinkle
          color="pink"
          style={{ top: 0, left: '50%', transform: 'rotate(15deg)' }}
        />
        <Sprinkle
          color="pink"
          style={{ top: '50px', left: '10%', transform: 'rotate(-45deg)' }}
        />
        <Sprinkle
          color="pink"
          style={{ top: '100px', right: '10%', transform: 'rotate(45deg)' }}
        />
      </div>
    </Space>
  );
};

export default DocumentationContainer;
