import React from 'react';
import Table from 'ui/table';
import { AssociatedCampaign } from 'business/indicators/types';
import {
  associatedCampaignData,
  associatedCampaignColumns,
} from 'business/campaigns/services/table';
import { useHistory } from 'react-router';
import Routes, { campaignStrategyDetailsRoute } from 'business/router/routes';
import EmptyState from 'ui/emptyState';
import { useTranslation } from 'react-i18next';
import images from 'ui/images';

interface AssociatedCampaignsTableProps {
  campaigns: AssociatedCampaign[];
}

const AssociatedCampaignsTable: React.FC<AssociatedCampaignsTableProps> = ({
  campaigns,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return campaigns.length === 0 ? (
    <EmptyState
      title={t('campaigns.catalog.empty.altTitle')}
      description={t('campaigns.catalog.empty.description')}
      picture={images.campaignPerson}
      cta={{
        to: Routes.CampaignsCatalog,
        label: t('campaigns.catalog.empty.cta'),
      }}
    />
  ) : (
    <Table
      data-test-id="associated-campaign-table"
      columns={associatedCampaignColumns}
      dataSource={associatedCampaignData(campaigns)}
      onRow={(record) => ({
        onClick: () => history.push(campaignStrategyDetailsRoute(record.key)),
      })}
    />
  );
};

export default AssociatedCampaignsTable;
