import IndicatorTabs from 'business/indicators/components/indicatorTabs';
import { IndicatorStrategyDetailsTabs } from 'business/indicators/services/type';
import { IndicatorUnit } from 'business/indicators/types';
import { ParameterDetailsFragment } from 'generated/graphql';
import React from 'react';

interface ParameterTabsProps {
  parameter: ParameterDetailsFragment;
  refetch: () => void;
  selectedUnit: IndicatorUnit;
  defaultUnit: IndicatorUnit;
  activeTabKey: IndicatorStrategyDetailsTabs;
  setActiveTabKey: (activeTabKey: string) => void;
}

const ParameterTabs: React.FC<ParameterTabsProps> = ({
  parameter,
  refetch,
  selectedUnit,
  defaultUnit,
  activeTabKey,
  setActiveTabKey,
}) => {
  return (
    <IndicatorTabs
      activeTabKey={activeTabKey}
      setActiveTabKey={(newActiveTabKey) => setActiveTabKey(newActiveTabKey)}
      indicator={parameter}
      refetch={refetch}
      selectedUnit={selectedUnit}
      defaultUnit={defaultUnit}
    />
  );
};

export default ParameterTabs;
