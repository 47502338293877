import classnames from 'classnames';
import React from 'react';
import { ReactDiv } from 'technical/type';
import Card from 'ui/card';
import Space from 'ui/space';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface BigNumberProps extends ReactDiv {
  className?: string;
  value: string;
  label: string;
}

const BigNumber: React.FC<BigNumberProps> = ({
  value,
  label,
  className,
  ...rest
}) => {
  return (
    <Card {...rest} className={classnames(styles.bigNumber, className)}>
      <Space direction="vertical" align="center">
        <Typography.Text className={styles.number}>{value}</Typography.Text>
        <Typography.Text center emphase>
          {label}
        </Typography.Text>
      </Space>
    </Card>
  );
};

export default BigNumber;
