import { Pagination } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TableFilters from 'business/common/components/tableFilters';
import notifyPromise from 'business/common/services/notifyPromise';
import IndicatorCard from 'business/indicators/components/indicatorCard';
import IndicatorListMenu from 'business/indicators/components/indicatorListActions';
import { exportIndicatorsXlsx } from 'business/indicators/services';
import { useStrategyIndicatorListFilters } from 'business/indicators/services/table';
import Routes from 'business/router/routes';
import Restricted from 'business/user/components/Restricted';
import ConnectedLayout from 'business/user/layout/connected';
import { Permission } from 'business/user/types/user';
import config from 'config';
import {
  useHiddenIndicatorCountQuery,
  useIndicatorListQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'ui/button';
import EmptyState from 'ui/emptyState';
import images from 'ui/images';
import Space from 'ui/space';
import Typography from 'ui/typography';

const IndicatorsStrategyPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    selectedEntity: { entityId, entityName },
    isAllowedTo,
  } = useLoggedInAppContext();
  const [resultsPerPage, setResultPerPage] = useState<number>(10);

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useStrategyIndicatorListFilters();

  const { data: hiddenIndicatorCount } = useHiddenIndicatorCountQuery({
    variables: {
      condition: {
        entityId: { _eq: entityId },
      },
    },
  });

  const { loading, data, previousData } = useIndicatorListQuery({
    variables: {
      entityId,
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      condition: {
        entityId: { _eq: entityId },

        ...(!filtersValues.showHidden ? { hiddenAt: { _is_null: true } } : {}),

        indicatorTemplate: {
          label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          indicatorTemplateStakes: {
            stake: {
              id: filtersValues.stakeId ? { _eq: filtersValues.stakeId } : {},
              themeId: filtersValues.themeId
                ? { _eq: filtersValues.themeId }
                : {},
            },
          },
        },
      },
    },
  });

  const indicators = data?.indicator || previousData?.indicator || [];

  const totalIndicators =
    data?.indicator_aggregate.aggregate?.count ??
    previousData?.indicator_aggregate.aggregate?.count ??
    0;

  const onPaginationChange = (page: number, pageSize?: number | undefined) => {
    setFiltersValues({ ...filtersValues, page: page ?? 1 });
    setResultPerPage(pageSize || 10);
  };

  return (
    <ConnectedLayout showEntityBreadcrumbs>
      <Space direction="vertical" size="middle">
        <Space justify="space-between">
          <Typography.Title level={3}>
            {t('indicators.strategy.title')}
          </Typography.Title>
          <Space justify="center" align="center">
            <Restricted permission={Permission.IndicatorsAdd}>
              <Button
                type="primary"
                onClick={() => history.push(Routes.IndicatorsCatalog)}
                data-onboarding-id="strategy-add-indicator"
              >
                {t('indicators.strategy.cta.addIndicator')}
              </Button>
            </Restricted>
            {config.featureFlag.isExportIndicatorsEnabled &&
            indicators.length ? (
              <Restricted permission={Permission.ActionRead}>
                <IndicatorListMenu
                  exportIndicators={() => {
                    notifyPromise(
                      t,
                      () => exportIndicatorsXlsx(entityId, entityName),
                      {
                        errorNotification: t(
                          'indicators.strategy.export.notification.error',
                        ),
                        reportError: 'error',
                        successNotification: t(
                          'indicators.strategy.export.notification.success',
                        ),
                      },
                    );
                  }}
                />
              </Restricted>
            ) : null}
          </Space>
        </Space>
        <Typography.Paragraph>
          {t('indicators.strategy.info')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.indicators')}
          showVisibilityToggle={
            !!hiddenIndicatorCount?.indicator_aggregate.aggregate?.count
          }
        />
        <Space direction="vertical">
          {indicators.length === 0 && !loading ? (
            <EmptyState
              title={t(
                noFilters
                  ? 'indicators.strategy.empty.titleNoData'
                  : 'indicators.strategy.empty.titleNoResult',
              )}
              description={t('indicators.strategy.empty.description')}
              picture={images.indicatorPerson}
              cta={
                isAllowedTo(Permission.IndicatorsAdd)
                  ? {
                      label: t('indicators.strategy.empty.ctaLabel'),
                      to: Routes.IndicatorsCatalog,
                    }
                  : undefined
              }
            />
          ) : (
            <>
              {indicators.map((indicator) => (
                <IndicatorCard key={indicator.id} indicator={indicator} />
              ))}
              <Space justify="end">
                <Pagination
                  pageSize={resultsPerPage}
                  current={filtersValues.page}
                  onChange={onPaginationChange}
                  total={totalIndicators}
                  hideOnSinglePage
                />
              </Space>
            </>
          )}
        </Space>
      </Space>
    </ConnectedLayout>
  );
};

export default IndicatorsStrategyPage;
