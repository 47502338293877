import React from 'react';
import AccountSelectionCard from 'business/user/components/accountSelectionCard';
import ContextSwitchLayout from 'business/user/layout/contextSwitch';
import { useConnectedUserContext } from 'business/AppBootstrapper';

const AccountSelectionPage: React.FC = () => {
  const { selectedEntityType, user } = useConnectedUserContext();

  return (
    <ContextSwitchLayout>
      <AccountSelectionCard
        userPermissions={user.userPermissions.filter(
          (userPermission) => userPermission.entity.type === selectedEntityType,
        )}
      />
    </ContextSwitchLayout>
  );
};

export default AccountSelectionPage;
