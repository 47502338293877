import React from 'react';
import {
  ResponsiveContainer as RechartsResponsiveContainer,
  ResponsiveContainerProps,
} from 'recharts';

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({
  children,
  width,
  height,
  ...rest
}) => {
  return (
    <RechartsResponsiveContainer
      width={width ?? '100%'}
      height={height ?? 300}
      {...rest}
    >
      {children}
    </RechartsResponsiveContainer>
  );
};

export default ResponsiveContainer;
