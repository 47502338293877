import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';

interface DeleteIndicatorStatusModalProps {
  isVisible: boolean;
  redirect: string;
  isError: boolean;
  message?: JSX.Element;
}

const DeleteIndicatorStatusModal: React.FC<DeleteIndicatorStatusModalProps> = ({
  isVisible,
  redirect,
  isError,
  message = '',
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={
        isError
          ? t('indicators.details.statusModal.error.title')
          : t('indicators.details.statusModal.success.title')
      }
      open={isVisible}
      footer={
        <Space size="extra-small" justify="space-around">
          <Link to={redirect}>
            <Button data-test-id="redirect-to-indicators" type="primary">
              {t('indicators.details.statusModal.cta')}
            </Button>
          </Link>
        </Space>
      }
      closable={false}
      maskClosable={false}
      keyboard={false}
    >
      <Space direction="vertical" align="center">
        <Typography.Text center>{message}</Typography.Text>
      </Space>
    </Modal>
  );
};

export default DeleteIndicatorStatusModal;
