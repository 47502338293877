import React from 'react';
import styles from './index.module.scss';

interface NavProps {
  header?: React.ReactElement;
  footer?: React.ReactElement;
}

const Nav: React.FC<NavProps> = ({ header, children, footer }) => {
  return (
    <nav className={styles.navContainer}>
      {header}
      <div className={styles.content}>{children}</div>
      {footer}
    </nav>
  );
};

export default Nav;
