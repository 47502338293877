import { Select } from 'antd';
import {
  ActionCost,
  ActionImpact,
  ActionStatus,
  AllActionCosts,
  AllActionImpacts,
  AllActionStatus,
  FormikAction,
} from 'business/actions/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import {
  IndicatorTemplateListForCustomActionQuery,
  useGetUsersFromEntityQuery,
  useIndicatorTemplateForCustomActionQuery,
  useIndicatorTemplateListForCustomActionQuery,
} from 'generated/graphql';
import uniqBy from 'lodash.uniqby';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formikErrorMessage, formikStatus } from 'technical/form';
import useDebounce from 'technical/hooks/useDebounce';
import DatePicker from 'ui/datePicker';
import Form from 'ui/form';
import RichTextEditor from 'ui/form/richTextEditor';
import Space from 'ui/space';
import Typography from 'ui/typography';

interface ActionFormProps {
  formik: FormikAction;
  prefilledIndicatorTemplateId?: string;
  shouldDisplayIndicatorTemplateLinkSelector?: boolean;
}

const ActionForm: React.FC<ActionFormProps> = ({
  formik,
  prefilledIndicatorTemplateId,
  shouldDisplayIndicatorTemplateLinkSelector = false,
}) => {
  const { t } = useTranslation();
  const { selectedEntity } = useLoggedInAppContext();

  // There's two set of data we need to focus on
  // 1) User for attributed collaborator selection
  const [userSearch, setUserSearch] = useState('');

  const debouncedUserSearch = useDebounce(userSearch, 300);

  const { data: dataUsers } = useGetUsersFromEntityQuery({
    variables: {
      entityId: selectedEntity.entityId,
      search: `%${debouncedUserSearch}%`,
    },
  });

  const users = dataUsers?.user ?? [];

  // 2) IndicatorTemplate for Links
  const [indicatorTemplateSearch, setIndicatorTemplateSearch] = useState('');

  const debouncedIndicatorTemplateSearch = useDebounce(
    indicatorTemplateSearch,
    300,
  );
  // 2.a) Prefilled Indicator Template
  const { data: indicatorData } = useIndicatorTemplateForCustomActionQuery({
    variables: {
      indicatorTemplateId: prefilledIndicatorTemplateId,
    },
    skip: !prefilledIndicatorTemplateId,
  });

  const prelockedIndicatorTemplate = indicatorData?.indicatorTemplate_by_pk;
  useEffect(() => {
    if (prelockedIndicatorTemplate) {
      formik.setFieldValue('linkedIndicators', [prelockedIndicatorTemplate]);
    }
  }, [prelockedIndicatorTemplate]);

  // 2.b) Searched Indicator Template
  const {
    data: dataIndicatorTemplate,
    previousData: previousDataIndicatorTemplate,
  } = useIndicatorTemplateListForCustomActionQuery({
    variables: {
      search: `%${debouncedIndicatorTemplateSearch}%`,
    },
  });

  const indicatorTemplates =
    dataIndicatorTemplate?.indicatorTemplate ||
    previousDataIndicatorTemplate?.indicatorTemplate ||
    [];

  const filteredIndicatorTemplates = indicatorTemplates
    .filter(
      (indicatorTemplate) => indicatorTemplate.indicatorTemplateStakes.length,
    )
    .reduce(
      (acc, indicatorTemplate) => {
        acc[
          indicatorTemplate.indicators.length > 0
            ? 'relevantIndicatorTemplates'
            : 'irrelevantIndicatorTemplates'
        ].push(indicatorTemplate);
        return acc;
      },
      {
        relevantIndicatorTemplates:
          [] as IndicatorTemplateListForCustomActionQuery['indicatorTemplate'],
        irrelevantIndicatorTemplates:
          [] as IndicatorTemplateListForCustomActionQuery['indicatorTemplate'],
      },
    );

  return (
    <>
      <Form.Item
        required
        label={t('actions.strategy.customAction.form.label.label')}
        validateStatus={formikStatus(formik, 'label')}
        help={formikErrorMessage(formik, 'label')}
      >
        <Form.Input
          required
          data-test-id="custom-action-form-label"
          name="label"
          placeholder={t(
            'actions.strategy.customAction.form.label.placeholder',
          )}
          value={formik.values.label}
          onChange={(e) =>
            formik.setValues({ ...formik.values, label: e.target.value })
          }
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        required
        label={t('actions.strategy.customAction.form.description.label')}
        validateStatus={formikStatus(formik, 'description')}
        help={formikErrorMessage(formik, 'description')}
      >
        <RichTextEditor
          required
          data-test-id="custom-action-form-description"
          name="description"
          placeholder={t(
            'actions.strategy.customAction.form.description.placeholder',
          )}
          value={formik.values.description}
          // formik.values is empty so only the description field is set here
          onChange={(e) => formik.setFieldValue('description', e.target.value)}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label={t('actions.strategy.customAction.form.comment.label')}
        validateStatus={formikStatus(formik, 'comment')}
        help={formikErrorMessage(formik, 'comment')}
      >
        <Form.Input.TextArea
          data-test-id="custom-action-form-comment"
          name="comment"
          autoSize={{ minRows: 2 }}
          placeholder={t(
            'actions.strategy.customAction.form.comment.placeholder',
          )}
          value={formik.values.comment}
          onChange={(e) =>
            formik.setValues({
              ...formik.values,
              comment: e.target.value,
            })
          }
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        required
        label={t('actions.strategy.customAction.form.status.label')}
        validateStatus={formikStatus(formik, 'status')}
        help={formikErrorMessage(formik, 'status')}
      >
        <Select
          data-test-id="custom-action-form-status"
          options={AllActionStatus.map((status) => ({
            label: t(`actions.status.${status}`),
            value: status,
            key: status,
            'data-test-id': `custom-action-form-status-${status}`,
          }))}
          value={formik.values.status}
          onChange={(newStatus) =>
            formik.setValues({
              ...formik.values,
              status: newStatus as ActionStatus,
            })
          }
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label={t('actions.strategy.customAction.form.dueDate.label')}
        validateStatus={formikStatus(formik, 'dueDate')}
        help={formikErrorMessage(formik, 'dueDate')}
      >
        <DatePicker
          data-test-id="custom-action-form-dueDate"
          name="dueDate"
          format="dd/MM/y"
          picker="date"
          onChange={(date) => {
            formik.setValues({
              ...formik.values,
              // date is Date or null, so we cast it as undefined to avoid dealing with "null value" in formik later on
              dueDate: date ?? undefined,
            });
          }}
          onBlur={formik.handleBlur}
          value={formik.values.dueDate}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label={t('actions.strategy.customAction.form.cost.label')}
        validateStatus={formikStatus(formik, 'cost')}
        help={formikErrorMessage(formik, 'cost')}
      >
        <Select
          data-test-id="custom-action-form-cost"
          allowClear
          value={formik.values.cost}
          placeholder={t('actions.strategy.customAction.form.cost.placeholder')}
          options={[
            ...AllActionCosts.map((cost: ActionCost) => ({
              label: t(`actions.cost.${cost}`),
              value: cost,
              key: cost,
              'data-test-id': `custom-action-form-cost-${cost}`,
            })),
          ]}
          onChange={(newCost) =>
            formik.setValues({
              ...formik.values,
              cost: (newCost as ActionCost) ?? undefined,
            })
          }
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label={t('actions.strategy.customAction.form.impact.label')}
        validateStatus={formikStatus(formik, 'impact')}
        help={formikErrorMessage(formik, 'impact')}
      >
        <Select
          data-test-id="custom-action-form-impact"
          allowClear
          options={[
            ...AllActionImpacts.map((impact: ActionImpact) => ({
              label: t(`actions.impact.${impact}`),
              value: impact,
              key: impact,
              'data-test-id': `custom-action-form-impact-${impact}`,
            })),
          ]}
          placeholder={t(
            'actions.strategy.customAction.form.impact.placeholder',
          )}
          value={formik.values.impact}
          onChange={(newImpact) =>
            formik.setValues({
              ...formik.values,
              impact: (newImpact as ActionImpact) ?? undefined,
            })
          }
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label={t('actions.strategy.customAction.form.responsibleId.label')}
        validateStatus={formikStatus(formik, 'responsibleId')}
        help={formikErrorMessage(formik, 'responsibleId')}
        name="responsibleId"
      >
        <Form.Select
          options={users.map((user) => ({
            label: `${user.firstName ?? ''} ${user.lastName ?? ''} (${
              user.email
            })`,
            value: user.id,
            key: user.id,
          }))}
          filterOption={false}
          data-test-id="custom-action-form-responsible-id"
          placeholder={t(
            'actions.strategy.customAction.form.responsibleId.placeholder',
          )}
          searchValue={userSearch}
          allowClear
          showSearch
          onSearch={(newSearch) => setUserSearch(newSearch)}
          value={formik.values.responsibleId}
          onBlur={formik.handleBlur}
          onChange={(newResponsibleId) =>
            formik.setValues({
              ...formik.values,
              responsibleId: (newResponsibleId as string) ?? undefined,
            })
          }
        />
      </Form.Item>
      {shouldDisplayIndicatorTemplateLinkSelector ? (
        <Form.Item
          required
          label={t('actions.strategy.customAction.form.linkedIndicators.label')}
          validateStatus={formikStatus(formik, 'linkedIndicators')}
          help={formikErrorMessage(formik, 'linkedIndicators')}
          name="linkedIndicators"
        >
          <Space direction="vertical">
            <Typography.Paragraph>
              {t(
                'actions.strategy.customAction.form.linkedIndicators.description',
              )}
            </Typography.Paragraph>
            <Form.Select
              options={[
                {
                  label: t(
                    'actions.strategy.customAction.form.linkedIndicators.relevantIndicators',
                  ),
                  options: [
                    ...filteredIndicatorTemplates.relevantIndicatorTemplates.map(
                      (indicatorTemplate) => ({
                        label: indicatorTemplate.label,
                        value: indicatorTemplate.id,
                        key: indicatorTemplate.id,
                        'data-test-id': `custom-action-form-linked-indicator-${indicatorTemplate.id}`,
                      }),
                    ),
                    ...(prelockedIndicatorTemplate
                      ? [
                          {
                            label: prelockedIndicatorTemplate.label,
                            value: prelockedIndicatorTemplate.id,
                            key: prelockedIndicatorTemplate.id,
                            'data-test-id': `custom-action-form-linked-indicator-${prelockedIndicatorTemplate.id}`,
                          },
                        ]
                      : []),
                  ],
                },
                {
                  label: t(
                    'actions.strategy.customAction.form.linkedIndicators.irrelevantIndicators',
                  ),
                  options:
                    filteredIndicatorTemplates.irrelevantIndicatorTemplates.map(
                      (indicatorTemplate) => ({
                        label: indicatorTemplate.label,
                        value: indicatorTemplate.id,
                        key: indicatorTemplate.id,
                        'data-test-id': `custom-action-form-linked-indicator-${indicatorTemplate.id}`,
                      }),
                    ),
                },
              ]}
              filterOption={false}
              mode="multiple"
              data-test-id="custom-action-form-linked-indicator"
              placeholder={t(
                'actions.strategy.customAction.form.linkedIndicators.placeholder',
              )}
              searchValue={indicatorTemplateSearch}
              onSearch={(newSearch) => setIndicatorTemplateSearch(newSearch)}
              value={formik.values.linkedIndicators.map(
                (linkedIndicator) => linkedIndicator.id,
              )}
              onBlur={formik.handleBlur}
              onChange={(values) => {
                const newLinkedIndicators = uniqBy(
                  [
                    ...indicatorTemplates.filter(
                      (indicatorTemplate) =>
                        values.indexOf(indicatorTemplate.id) !== -1,
                    ),
                    ...formik.values.linkedIndicators.filter(
                      (indicatorTemplate) =>
                        values.indexOf(indicatorTemplate.id) !== -1,
                    ),
                  ],
                  'id',
                );
                formik.setValues({
                  ...formik.values,
                  linkedIndicators: newLinkedIndicators,
                });
              }}
            />
          </Space>
        </Form.Item>
      ) : null}
    </>
  );
};

export default ActionForm;
