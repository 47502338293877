import React, { useState } from 'react';
import { DownOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Dropdown, Menu, TreeSelect } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import { DataNode as AntdDataNode } from 'antd/lib/tree';
import Space from 'ui/space';
import createFindInTreeFunction from 'technical/tree';
import styles from './index.module.scss';

export type Node<T> = Omit<
  AntdDataNode,
  'checkable' | 'disableCheckbox' | 'children'
> & {
  children: Node<T>[];
  data: T;
};

type NodeWithParent<T> = Node<T> & {
  parentNode?: NodeWithParent<T>;
};

const createParentLinks = <T,>(
  node: Node<T>,
  parentNode?: Node<T>,
): NodeWithParent<T> => {
  const result = { ...node, parentNode };
  result.children = node.children?.map((child) =>
    createParentLinks(child, result),
  );
  return result;
};

const generateBreadcrumb = <T,>(
  lastNode: NodeWithParent<T>,
): NodeWithParent<T>[] => [
  ...(lastNode.parentNode ? generateBreadcrumb(lastNode.parentNode) : []),
  lastNode,
];

const findNodeFromKey = createFindInTreeFunction('key', 'children');

interface BreadcrumbsSelectProps<T> {
  /**
   * The root of the tree structure
   */
  root: Node<T>;
  /**
   * The key of the selected node by default
   */
  selectedNode?: Node<T>['key'];
  onChange?: (selectedNode: T) => void;
}

/**
 * A component used to select a node in a tree data structure
 * The component displays a breadcrumb used to show the current selected node,
 * and a TreeSelect component for searching/displaying the whole tree
 */
const BreadcrumbsSelect = <T,>({
  root: rawRoot,
  selectedNode: defaultSelectedNodeId,
  onChange,
}: BreadcrumbsSelectProps<T>) => {
  const [searching, setSearching] = useState(false);

  const root = createParentLinks(rawRoot);

  const defaultSelected = defaultSelectedNodeId
    ? findNodeFromKey(defaultSelectedNodeId, root)
    : null;

  const [selectedNode, setSelectedNode] = useState<Node<T>>(
    defaultSelected ?? root,
  );
  const [lastNode, setLastNode] = useState<NodeWithParent<T>>(
    defaultSelected ?? root,
  );

  function selectNode(node: NodeWithParent<T>) {
    setSelectedNode(node);
    onChange?.(node.data);
  }

  function selectNodeFromKey(
    key: NodeWithParent<T>['key'],
    searchRoot?: NodeWithParent<T>,
  ) {
    const node = findNodeFromKey(key, searchRoot ?? root);
    if (!node) {
      throw new Error('Node not found');
    }
    selectNode(node);
    setLastNode(node);
  }

  const breadcrumbs = generateBreadcrumb(lastNode);

  if (!searching) {
    return (
      <Space justify="space-between" align="center">
        <Breadcrumb separator={<RightOutlined />}>
          {breadcrumbs.map((node) => {
            const style: Omit<DropdownButtonProps, 'overlay'> = {
              size: 'small',
              type: node.key === selectedNode.key ? 'primary' : undefined,
            };
            return (
              <Breadcrumb.Item key={node.key}>
                {node.children && node.children.length ? (
                  <Dropdown.Button
                    data-test-id={`entity-breadcrumb-${node.key}`}
                    icon={<DownOutlined />}
                    {...style}
                    placement="bottomLeft"
                    overlay={
                      <Menu onClick={({ key }) => selectNodeFromKey(key, node)}>
                        {node.children.map((childNode) => (
                          <Menu.Item key={childNode.key}>
                            {childNode.title}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    onClick={() => selectNode(node)}
                  >
                    {node.title}
                  </Dropdown.Button>
                ) : (
                  <Button
                    {...style}
                    onClick={() => selectNode(node)}
                    data-test-id={`entity-breadcrumb-${node.key}`}
                  >
                    {node.title}
                  </Button>
                )}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <Button
          className={styles.cta}
          onClick={() => setSearching(true)}
          type="primary"
          data-test-id="entity-breadcrumb-toggle-search-button"
        >
          <SearchOutlined />
        </Button>
      </Space>
    );
  }
  return (
    <TreeSelect
      treeData={[root]}
      defaultValue={selectedNode.key}
      treeDefaultExpandedKeys={breadcrumbs.map((node) => node.key)}
      onSelect={(key) => selectNodeFromKey(key)}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setSearching(false);
        }
      }}
      autoFocus
      treeNodeFilterProp="title"
      defaultOpen
      showSearch
    />
  );
};

export default BreadcrumbsSelect;
