import { Result } from 'antd';
import { useAppContext } from 'business/AppBootstrapper';
import ConnectedLayout from 'business/user/layout/connected';
import DisconnectedLayout from 'business/user/layout/disconnected';
import React, { useEffect } from 'react';
import { Route } from 'react-router';
import history from 'technical/history';
import Button from 'ui/button';
import errorReporting from 'technical/error-reporting';
import { useTranslation } from 'react-i18next';

const ReportingNoMatch: React.FC = () => {
  const { isConnected } = useAppContext();
  const { t } = useTranslation();
  useEffect(() => {
    errorReporting.info(new Error('404'), { location: history.location });
  }, []);

  const Layout = isConnected ? ConnectedLayout : DisconnectedLayout;

  return (
    <Route
      component={() => (
        <Layout>
          <Result
            status="404"
            title="404"
            subTitle={t('errors.notAuthorized')}
            extra={
              <Button type="primary" onClick={() => history.goBack()}>
                {t('common.back')}
              </Button>
            }
          />
        </Layout>
      )}
    />
  );
};

export default ReportingNoMatch;
