import { IndicatorGraphMode, IndicatorType } from 'business/indicators/types';

export const getAllKeysInArray = (array: Record<string, unknown>[]) => {
  return [
    ...new Set(
      array.reduce((acc, curr) => {
        return [...acc, ...Object.keys(curr)];
      }, [] as string[]),
    ),
  ];
};

export const toPercent = (decimal: number) =>
  `${parseFloat((decimal * 100).toFixed(2))}%`;

export const getPercent = (value: number, totalValue: number) => {
  const ratio = totalValue > 0 ? value / totalValue : 0;

  return toPercent(ratio);
};

export const getDateFormat = (
  type: IndicatorType,
  dateMode?: IndicatorGraphMode,
) => {
  if (dateMode === IndicatorGraphMode.AllYears) {
    return 'yyyy';
  }
  return type === IndicatorType.Stock ? 'MMMM yyyy' : 'dd MMMM yyyy';
};
