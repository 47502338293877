import React from 'react';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface ErrorProps {
  message?: string | null;
}

const Error: React.FC<ErrorProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Typography.Text data-test-id="error-message" className={styles.error}>
      {message}
    </Typography.Text>
  );
};

export default Error;
