import { contextualizeParametersLabel } from 'business/myEntity/services';
import { indicatorStrategyDetailsRoute } from 'business/router/routes';
import { IndicatorDetailsFragment } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Table from 'ui/table';
import {
  linkedIndicatorsDataSource,
  linkedIndicatorsColumns,
} from '../../services/table';

type LinkedIndicator = Pick<
  IndicatorDetailsFragment['indicatorLinks'][number]['indicator'],
  'id' | 'indicatorTemplate' | 'hiddenAt'
>;

interface LinkedIndicatorsTableProps {
  indicators: LinkedIndicator[];
}

const LinkedIndicatorsTable: React.FC<LinkedIndicatorsTableProps> = ({
  indicators,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Table
      data-test-id="linked-indicators-table"
      dataSource={linkedIndicatorsDataSource(
        contextualizeParametersLabel(indicators, t),
      )}
      columns={linkedIndicatorsColumns()}
      onRow={(record) => ({
        onClick: () => history.push(indicatorStrategyDetailsRoute(record.key)),
      })}
    />
  );
};

export default LinkedIndicatorsTable;
