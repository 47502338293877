export type IndicatorCatalogDetailsRouteSlug =
  | 'description'
  | 'actions'
  | 'campaigns'
  | 'linked-indicators'
  | 'partners';

export const ensureSlugIsValid = (
  slug?: string,
): slug is IndicatorCatalogDetailsRouteSlug => {
  if (!slug) {
    return false;
  }
  if (
    slug !== 'description' &&
    slug !== 'actions' &&
    slug !== 'campaigns' &&
    slug !== 'linked-indicators' &&
    slug !== 'partners'
  ) {
    return false;
  }
  return true;
};
