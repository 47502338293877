import React from 'react';

export type ReactDiv = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export function isNotNullOrUndefined<T>(
  value: T | null | undefined,
): value is T {
  return value !== undefined && value !== null;
}

export type EnsureDefined<T, R extends keyof T> = {
  [K in keyof T]: K extends R ? NonNullable<T[K]> : T[K];
};
