import React from 'react';
import Table from 'ui/table';
import { useHistory } from 'react-router';
import { partnerCatalogDetailsRoute } from 'business/router/routes';
import {
  associatedPartnersColumns,
  associatedPartnersTemplatesDataSource,
} from 'business/partners/services/table';
import { AssociatedPartners } from 'business/partners/types';

interface AssociatedPartnersTableProps {
  partners: AssociatedPartners[];
}

const AssociatedPartnersTable: React.FC<AssociatedPartnersTableProps> = ({
  partners,
}) => {
  const history = useHistory();

  return (
    <Table
      data-test-id="associated-campaign-templates-table"
      columns={associatedPartnersColumns()}
      dataSource={associatedPartnersTemplatesDataSource(partners)}
      onRow={(record) => ({
        onClick: () => history.push(partnerCatalogDetailsRoute(record.key)),
      })}
    />
  );
};

export default AssociatedPartnersTable;
