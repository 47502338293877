import Modal from 'ui/modal';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import InformationDetails from 'business/common/components/informationDetails';
import AddMissingIndicatorsModal, {
  AddMissingIndicatorsModalContext,
} from 'business/indicators/components/addMissingIndicatorsModal';
import Routes, {
  actionCatalogForIndicatorRoute,
  actionStrategyDetailsRoute,
} from 'business/router/routes';
import { getThemes } from 'business/stake/services';
import SubPageLayout from 'business/user/layout/subPage';
import { Permission } from 'business/user/types/user';
import { useActionTemplateQuery } from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Button from 'ui/button';
import Loader from 'ui/loader';
import RichTextContainer from 'ui/richTextContainer';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { MissingIndicator } from 'business/indicators/types';
import useSearchParams from 'technical/hooks/useSearchParams';
import LinkedIndicatorTemplatesTable from 'business/indicators/components/linkedIndicatorTemplatesTable';
import ThemeAndStakesDetails from 'business/common/components/themeAndStakesDetails';
import Restricted from 'business/user/components/Restricted';
import {
  addActionToStrategy,
  getActionTemplateStakes,
  getAlreadyInstanciedIndicatorTemplates,
  getAssociatedIndicatorTemplates,
  getNotInstanciedIndicatorTemplates,
  restoreActionToStrategy,
} from '../../../services';

interface ActionDetailsRouteParams {
  id: string;
}

const ActionCatalogDetailsPage: React.FC = () => {
  const { id } = useParams<ActionDetailsRouteParams>();
  const query = useSearchParams();
  const { t } = useTranslation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const history = useHistory();
  const [
    isAddMissingIndicatorsModalVisible,
    setIsAddMissingIndicatorsModalVisible,
  ] = useState(false);

  const { data } = useActionTemplateQuery({
    variables: {
      id,
    },
  });

  const linkedIndicatorId = query.get('indicator');

  const actionTemplate = data?.actionTemplate_by_pk;

  const isThisActionTemplateAddable = data?.actionTemplate_by_pk?.actions.every(
    (action) => action.deletedAt !== null,
  );

  const actionIdToReAdd: string | undefined =
    data?.actionTemplate_by_pk?.actions.find(
      (action) => action.deletedAt !== null,
    )?.id;

  if (!actionTemplate) {
    return (
      <SubPageLayout goBack={Routes.ActionsCatalog}>
        <Loader />
      </SubPageLayout>
    );
  }

  const stakes = getActionTemplateStakes(actionTemplate);
  const themes = getThemes(stakes);
  const associatedIndicatorTemplates =
    getAssociatedIndicatorTemplates(actionTemplate);
  const alreadyInstanciedIndicatorTemplates =
    getAlreadyInstanciedIndicatorTemplates(
      actionTemplate.actionTemplateIndicatorTemplates.map(
        (actionTemplateIndicatorTemplate) =>
          actionTemplateIndicatorTemplate.indicatorTemplate,
      ),
    );

  const notInstanciedIndicatorTemplates = getNotInstanciedIndicatorTemplates(
    actionTemplate.actionTemplateIndicatorTemplates.map(
      (actionTemplateIndicatoTemplate) =>
        actionTemplateIndicatoTemplate.indicatorTemplate,
    ),
  );

  const handleAddActionToStrategy = async (
    missingIndicators: MissingIndicator[],
  ) => {
    // 1. Add action, actionIndicators and indicators if necessary.
    // If the action already exist
    let actionId: string;
    if (actionIdToReAdd) {
      await restoreActionToStrategy(
        actionIdToReAdd,
        missingIndicators,
        entityId,
      );
      actionId = actionIdToReAdd;
    } else {
      const { data: actionData } = await addActionToStrategy(
        actionTemplate,
        missingIndicators,
        entityId,
      );
      actionId = actionData?.insert_action_one?.id;
    }

    const cancelAction = () => {
      if (linkedIndicatorId) {
        return history.push(actionCatalogForIndicatorRoute(linkedIndicatorId));
      }

      if (history.length > 1) {
        return history.goBack();
      }

      return history.push(Routes.ActionsCatalog);
    };

    // 2. Show success modal
    Modal.confirm({
      title: t('actions.catalog.add.successTitle'),
      content: (
        <Typography.Paragraph>
          {t('actions.catalog.add.successContent')}
        </Typography.Paragraph>
      ),
      okText: t('actions.catalog.add.successOkCta'),
      cancelText: linkedIndicatorId
        ? t('actions.catalog.add.keepAddingActionToThisIndicator')
        : t('actions.catalog.add.successCancelCta'),
      onOk: () => history.push(actionStrategyDetailsRoute(actionId)),
      onCancel: cancelAction,
      okButtonProps: {
        id: 'see-my-action',
      },
    });
  };

  const handleAddActionCta = async () => {
    if (notInstanciedIndicatorTemplates.length !== 0) {
      setIsAddMissingIndicatorsModalVisible(true);
    } else {
      await handleAddActionToStrategy(
        alreadyInstanciedIndicatorTemplates.map((indicatorTemplate) => ({
          id: indicatorTemplate.id,
          label: '',
        })),
      );
    }
  };

  return (
    <SubPageLayout goBack={Routes.ActionsCatalog}>
      <Space direction="vertical" size="large">
        <Space justify="space-between">
          <Typography.Title data-test-id="action-name" level={3}>
            {actionTemplate.label}
          </Typography.Title>
          <Restricted permission={Permission.ActionAdd}>
            {isThisActionTemplateAddable ? (
              <Button
                data-test-id="add-action"
                type="primary"
                onClick={handleAddActionCta}
                data-onboarding-id="catalog-actions-detail-add"
              >
                {t('actions.details.addAction')}
              </Button>
            ) : null}
          </Restricted>
        </Space>
        <Space
          data-onboarding-id="catalog-actions-detail"
          direction="vertical"
          size="large"
        >
          <Space>
            <InformationDetails
              orientation="horizontal"
              title={t('actions.details.cost')}
            >
              <Typography.Paragraph>
                {t(`actions.cost.${actionTemplate.cost}`)}
              </Typography.Paragraph>
            </InformationDetails>
            <InformationDetails
              orientation="horizontal"
              title={t('actions.details.impact')}
            >
              <Typography.Paragraph>
                {t(`actions.impact.${actionTemplate.impact}`)}
              </Typography.Paragraph>
            </InformationDetails>
          </Space>
          <InformationDetails title={t('actions.details.description')}>
            <RichTextContainer content={actionTemplate.description} />
          </InformationDetails>
          {associatedIndicatorTemplates.length ? (
            <InformationDetails
              title={t('actions.details.associatedIndicators.title')}
            >
              <LinkedIndicatorTemplatesTable
                indicatorTemplates={associatedIndicatorTemplates}
              />
            </InformationDetails>
          ) : null}
        </Space>
        <ThemeAndStakesDetails themes={themes} stakes={stakes} />
      </Space>
      <AddMissingIndicatorsModal
        editable
        context={AddMissingIndicatorsModalContext.Actions}
        isVisible={isAddMissingIndicatorsModalVisible}
        missingIndicators={notInstanciedIndicatorTemplates}
        alreadyInstanciatedIndicators={alreadyInstanciedIndicatorTemplates.map(
          (indicatorTemplate) => ({
            id: indicatorTemplate.id,
            label: '',
          }),
        )}
        handleSubmit={async (indicatorsToAdd) => {
          await handleAddActionToStrategy(indicatorsToAdd);
          setIsAddMissingIndicatorsModalVisible(false);
        }}
        handleClose={() => setIsAddMissingIndicatorsModalVisible(false)}
      />
    </SubPageLayout>
  );
};

export default ActionCatalogDetailsPage;
