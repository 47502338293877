import { useLoggedInAppContext } from 'business/AppBootstrapper';
import CampaignTemplateCard from 'business/campaigns/components/campaignTemplateCard';
import { useCatalogCampaignListFilters } from 'business/campaigns/services/table';
import TableFilters from 'business/common/components/tableFilters';
import ConnectedLayout from 'business/user/layout/connected';
import { useCampaignTemplateListQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from 'ui/emptyState';
import images from 'ui/images';
import Space from 'ui/space';
import Typography from 'ui/typography';

const CampaignsCatalogPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityTags },
  } = useLoggedInAppContext();

  const { filtersValues, debouncedFiltersValues, setFiltersValues } =
    useCatalogCampaignListFilters();

  const { data, previousData } = useCampaignTemplateListQuery({
    variables: {
      condition: {
        _or: [
          {
            name: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
          {
            description: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
        ],
        indicatorTemplateCampaignTemplates: {
          indicatorTemplate: {
            indicatorTemplateStakes: {
              stake: {
                id: filtersValues.stakeId ? { _eq: filtersValues.stakeId } : {},
                themeId: filtersValues.themeId
                  ? { _eq: filtersValues.themeId }
                  : {},
              },
            },
            indicatorTemplateTags: {
              tag: {
                label: {
                  _in: entityTags,
                },
              },
            },
          },
        },
      },
    },
  });

  const campaignTemplates =
    data?.campaignTemplate || previousData?.campaignTemplate || [];

  return (
    <ConnectedLayout>
      <Space direction="vertical" size="middle">
        <Typography.Title level={3}>
          {t('campaigns.catalog.title')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('campaigns.catalog.info')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.campaigns')}
        />
        {campaignTemplates.length === 0 && (
          <EmptyState
            title={t('campaigns.catalog.empty.title')}
            description={t('campaigns.catalog.empty.description')}
            picture={images.campaignPerson}
          />
        )}
        {campaignTemplates.map((campaignTemplate) => (
          <CampaignTemplateCard
            key={campaignTemplate.id}
            campaignTemplate={campaignTemplate}
          />
        ))}
      </Space>
    </ConnectedLayout>
  );
};

export default CampaignsCatalogPage;
