import about from './about/about.png';
import astrid from './about/Astrid.jpeg';
import tree from './about/tree.svg';
import actionPerson from './actionPerson.svg';
import campaignPerson from './campaignPerson.svg';
import indicatorPerson from './indicatorPerson.svg';
import logoDark from './logoDark.png';
import logoGreenAffair from './logoGreenAffair.svg';
import logoLight from './logoLight.png';
import lostPerson from './lostPerson.svg';
import mandatoryCsr from './documentation/mandatory-csr.svg';
import labelsListe from './documentation/certifications-and-labels.png';

export default {
  logoGreenAffair,
  logo: {
    light: logoLight,
    dark: logoDark,
  },
  about: {
    schema: about,
    tree,
    astrid,
  },
  documentation: {
    labelsListe,
    mandatory: mandatoryCsr,
  },
  campaignPerson,
  indicatorPerson,
  actionPerson,
  lostPerson,
};
