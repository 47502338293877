import React from 'react';
import { CampaignFragment, CampaignTemplateFragment } from 'generated/graphql';
import { i18n } from 'translations';
import { Link } from 'react-router-dom';
import { indicatorCatalogDetailsRoute } from 'business/router/routes';
import { formatDate } from 'technical/date/formatter';
import {
  AssociatedCampaignTemplate,
  AssociatedCampaign,
} from 'business/indicators/types';
import RichTextContainer from 'ui/richTextContainer';
import { useFilters } from 'business/common/components/tableFilters/services/useFiltersHook';
import {
  stringFilter,
  pageFilter,
  campaignStatusFilter,
} from 'business/common/components/tableFilters/services/filters';

interface QuestionRow {
  key: string;
  title: string;
  description: string;
  type: string;
  choice?: string;
  indicator?: {
    id: string;
    label: string;
  } | null;
}

export const campaignTemplateQuestionsColumn = () => [
  {
    title: i18n.t('campaigns.catalog.details.fields.title'),
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: i18n.t('campaigns.catalog.details.fields.description'),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: i18n.t('campaigns.catalog.details.fields.type.label'),
    dataIndex: 'type',
    key: 'type',
    render: (value: string) =>
      i18n.t(`campaigns.catalog.details.fields.type.${value}`),
  },
  {
    title: i18n.t('campaigns.catalog.details.fields.choice'),
    dataIndex: 'choice',
    key: 'choice',
  },
  {
    title: i18n.t('campaigns.catalog.details.fields.indicatorTemplate'),
    dataIndex: 'indicator',
    key: 'indicator',
    render: (_: any, row: QuestionRow) => {
      return row.indicator ? (
        <Link to={indicatorCatalogDetailsRoute(row.indicator.id)}>
          {row.indicator.label}
        </Link>
      ) : null;
    },
  },
];

export const campaignTemplateQuestionsData = (
  questions: CampaignTemplateFragment['questions'],
): QuestionRow[] => {
  return questions.map((question) => ({
    key: question.id,
    title: question.title || '',
    description: question.properties?.description || '',
    type: question.type || '',
    choice: question.properties?.choices
      ?.map((choice) => choice.label || '')
      .join(', '),
    indicator: question.indicatorTemplate,
  }));
};

export const campaignAnswersColumn = (
  questions: CampaignFragment['questions'],
) => {
  return [
    {
      title: i18n.t('campaigns.strategy.details.responses.date'),
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      width: '200px',
      sorter: (a: any, b: any) => {
        return a.submittedAt.localeCompare(b.submittedAt);
      },
    },
    ...questions.map((question) => ({
      title: question.title,
      dataIndex: question.ref || '',
      key: question.ref || '',
      width: '400px',
      sorter: (a: any, b: any) => {
        return a[question.id].localeCompare(b[question.id]);
      },
    })),
  ];
};

export const campaignAnswersData = (
  responses: CampaignFragment['responses'],
) => {
  return responses.map((response) => ({
    key: response.submittedAt,
    submittedAt: formatDate(response.submittedAt),
    ...response.answers.reduce((acc, cur) => {
      return { ...acc, [cur.fieldRef]: cur.value };
    }, {}),
  }));
};

export const associatedCampaignTemplateColumns = [
  {
    title: i18n.t('campaigns.catalog.table.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (
      a: AssociatedCampaignTemplateRow,
      b: AssociatedCampaignTemplateRow,
    ) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: i18n.t('campaigns.catalog.table.description'),
    dataIndex: 'description',
    key: 'description',
    render: (description: string) => (
      <RichTextContainer content={description} />
    ),
    sorter: (
      a: AssociatedCampaignTemplateRow,
      b: AssociatedCampaignTemplateRow,
    ) => {
      return a.description.localeCompare(b.description);
    },
  },
  {
    title: i18n.t('campaigns.catalog.table.isExternal'),
    dataIndex: 'isExternal',
    key: 'isExternal',
    render: (isExternal: boolean) => {
      return i18n.t('campaigns.catalog.card.external', {
        context: isExternal ? 'external' : 'internal',
      });
    },
    sorter: (a: AssociatedCampaignTemplateRow) => {
      return a.isExternal ? 1 : -1;
    },
  },
  {
    title: i18n.t('campaigns.catalog.table.numberOfQuestions'),
    dataIndex: 'numberOfQuestions',
    key: 'numberOfQuestions',
    sorter: (
      a: AssociatedCampaignTemplateRow,
      b: AssociatedCampaignTemplateRow,
    ) => {
      return b.numberOfQuestions - a.numberOfQuestions;
    },
  },
];

interface AssociatedCampaignTemplateRow {
  key: string;
  name: string;
  description: string;
  isExternal: boolean;
  numberOfQuestions: number;
}

export const associatedCampaignTemplateData = (
  campaignTemplates: AssociatedCampaignTemplate[],
): AssociatedCampaignTemplateRow[] => {
  return campaignTemplates.map((campaignTemplate) => ({
    key: campaignTemplate.id,
    name: campaignTemplate.name,
    description: campaignTemplate.description,
    isExternal: campaignTemplate.isExternal,
    numberOfQuestions: campaignTemplate.numberOfQuestions,
  }));
};

export const associatedCampaignColumns = [
  ...associatedCampaignTemplateColumns,
  {
    title: i18n.t('campaigns.catalog.table.startDate'),
    dataIndex: 'startDate',
    key: 'startDate',
    render: (date: string) => formatDate(date),
    sorter: (a: AssociatedCampaignRow, b: AssociatedCampaignRow) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    },
  },
  {
    title: i18n.t('campaigns.catalog.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => i18n.t(`campaigns.status.${status}`),
    sorter: (a: AssociatedCampaignRow, b: AssociatedCampaignRow) => {
      return a.status.localeCompare(b.status);
    },
  },
];

interface AssociatedCampaignRow extends AssociatedCampaignTemplateRow {
  status: string;
  startDate: string;
}

export const associatedCampaignData = (
  campaigns: AssociatedCampaign[],
): AssociatedCampaignRow[] => {
  return campaigns.map((campaign) => ({
    key: campaign.id,
    name: campaign.name,
    description: campaign.description,
    status: campaign.status,
    isExternal: campaign.isExternal,
    numberOfQuestions: campaign.numberOfQuestions,
    startDate: campaign.createdAt,
  }));
};

export const useCatalogCampaignListFilters = () =>
  useFilters({
    search: stringFilter,
    stakeId: stringFilter,
    themeId: stringFilter,
    page: pageFilter,
  });

export const useStrategyCampaignListFilters = () =>
  useFilters({
    search: stringFilter,
    stakeId: stringFilter,
    themeId: stringFilter,
    page: pageFilter,
    campaignStatus: campaignStatusFilter,
  });
