import React, { Key, ReactNode } from 'react';
import {
  pageFilter,
  roleFilter,
  stringFilter,
} from 'business/common/components/tableFilters/services/filters';
import { useFilters } from 'business/common/components/tableFilters/services/useFiltersHook';
import { Roles } from 'business/user/types/user';
import { Order_By, UserPermission_Order_By } from 'generated/graphql';
import { i18n } from 'translations';
import Typography from 'ui/typography';
import Space from 'ui/space';
import { UserPermission, UserPermissionRow } from '../types';

export const buildUserPermissionOrderBy = (
  columnKey: Key | undefined,
  order: Order_By,
): UserPermission_Order_By | undefined => {
  switch (columnKey as keyof UserPermissionRow) {
    case 'user':
      return { user: { email: order } };
    case 'role':
      return { role: order };
    default:
      return undefined;
  }
};

export const userPermissionsDataSource = (
  userPermissions: UserPermission[],
): UserPermissionRow[] => {
  return userPermissions.map((userPermission) => {
    const {
      id,
      role,
      user: { email, firstName, lastName },
    } = userPermission;
    return {
      key: id,
      user: { email, firstName: firstName ?? '', lastName: lastName ?? '' },
      role: {
        role: role as Roles,
        userPermissionId: id,
      },
    };
  });
};

export const userPermissionsColumns = (
  roleColumnRenderer: (role: UserPermissionRow['role']) => ReactNode,
) => [
  {
    title: i18n.t('my-entity.users-permissions.table.user'),
    dataIndex: 'user',
    key: 'user',
    sorter: true,
    render: (user: UserPermissionRow['user']) => (
      <Space direction="horizontal" size="extra-small">
        <Typography.Text>
          {user.firstName} {user.lastName}
        </Typography.Text>
        <Typography.Text type="secondary">({user.email})</Typography.Text>
      </Space>
    ),
  },
  {
    title: i18n.t('my-entity.users-permissions.table.role'),
    dataIndex: 'role',
    key: 'role',
    sorter: true,
    render: roleColumnRenderer,
    width: '300px',
  },
];

export const useUsersTableFilters = () =>
  useFilters({
    search: stringFilter,
    role: roleFilter,
    page: pageFilter,
  });
