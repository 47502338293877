import { EditOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import TableFilters from 'business/common/components/tableFilters';
import InviteUserModal from 'business/myEntity/components/inviteUserModal';
import { getAvailableRoles } from 'business/myEntity/services';
import {
  buildUserPermissionOrderBy,
  userPermissionsColumns,
  useUsersTableFilters,
  userPermissionsDataSource,
} from 'business/myEntity/services/usersTable';
import {
  isEmailAsReferenceUserProvider,
  UserPermissionRow,
} from 'business/myEntity/types';
import Restricted from 'business/user/components/Restricted';
import ConnectedLayout from 'business/user/layout/connected';
import { Permission, Roles } from 'business/user/types/user';
import {
  Order_By,
  useGetProviderMailDomainQuery,
  useGetUsersPermissionsQuery,
  UserPermission_Order_By,
  useUpdateUserRoleMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import apolloClient from 'technical/graphql/client';
import { handleSortChange } from 'technical/table/sort';
import { isNotNullOrUndefined } from 'technical/type';
import Button from 'ui/button';
import Dropdown from 'ui/dropdown';
import EmptyState from 'ui/emptyState';
import images from 'ui/images';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Table from 'ui/table';
import Typography from 'ui/typography';

const UsersPermissionsPage: React.FC = () => {
  const { t } = useTranslation();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const [orderBy, setOrderBy] = useState<
    UserPermission_Order_By | UserPermission_Order_By[]
  >({ user: { firstName: Order_By.Asc } });

  const {
    selectedPermission: { role, userPermissionId: selectedUserPermissionId },
    selectedEntity: {
      entityId,
      entityName,
      providers: { userProviderId },
    },
    choosePermission,
    isAllowedTo,
  } = useLoggedInAppContext();

  const [isInviteUserModalVisible, setIsInviteUserModalVisible] =
    useState(false);

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useUsersTableFilters();

  const { data, loading, refetch } = useGetUsersPermissionsQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      sort: orderBy,
      condition: {
        role: filtersValues.role ? { _eq: filtersValues.role } : {},
        user: {
          _or: [
            { email: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` } },
            {
              firstName: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
            },
            {
              lastName: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
            },
          ],
        },
      },
    },
  });

  const { data: providerMailDomainData } = useGetProviderMailDomainQuery({
    variables: { providerId: userProviderId },
  });

  const [updateUserRoleMutation] = useUpdateUserRoleMutation();

  const userPermissions = data?.userPermission ?? [];

  const providerMailDomain =
    providerMailDomainData?.userProvider_by_pk?.domainName;
  const reference = providerMailDomainData?.userProvider_by_pk?.reference;
  const shouldDisplayReference =
    isNotNullOrUndefined(providerMailDomain) &&
    isNotNullOrUndefined(reference) &&
    !isEmailAsReferenceUserProvider(reference);

  const onPaginationChange = (pagination: TablePaginationConfig) => {
    setFiltersValues({ ...filtersValues, page: pagination.current || 1 });
    setResultsPerPage(pagination.pageSize || 10);
  };

  const onSortChange = (sorter: SorterResult<UserPermissionRow>) => {
    setOrderBy(
      handleSortChange<UserPermissionRow, UserPermission_Order_By>(
        sorter,
        buildUserPermissionOrderBy,
      ),
    );
  };

  const updateRole = async (userPermissionId: string, newRole: Roles) => {
    try {
      await updateUserRoleMutation({
        variables: {
          role: newRole,
          userPermissionsId: userPermissionId,
        },
      });
      refetch();
    } catch (e) {
      if (e instanceof ApolloError) {
        Modal.info({
          title: t('errors.generic'),
          content: (
            <Typography.Paragraph>
              {t('my-entity.users-permissions.error', {
                role: t(`roles.${newRole}`),
                entity: entityName,
              })}
            </Typography.Paragraph>
          ),
        });
      }
    }
  };

  const updateOwnRoleCheck = (userPermissionId: string, newRole: Roles) => {
    if (userPermissionId !== selectedUserPermissionId) {
      updateRole(userPermissionId, newRole);
      return;
    }

    Modal.confirm({
      title: t('my-entity.users-permissions.edit-own-dialog.title'),
      content: (
        <Typography.Paragraph>
          {t('my-entity.users-permissions.edit-own-dialog.content')}
        </Typography.Paragraph>
      ),
      okText: t('common.validate'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        await updateRole(userPermissionId, newRole);
        choosePermission(entityId, newRole);
        await apolloClient.cache.reset();
      },
      okButtonProps: {
        id: 'delete-action',
      },
    });
  };

  return (
    <ConnectedLayout>
      <Space direction="vertical" size="middle">
        <Space justify="space-between">
          <Typography.Title level={3}>
            {t('my-entity.users-permissions.title')}
          </Typography.Title>
          <Restricted permission={Permission.UsersInvite}>
            <Button
              onClick={() => setIsInviteUserModalVisible(true)}
              data-test-id="user-invite-button"
              type="primary"
            >
              {t('my-entity.users-permissions.invite.label')}
            </Button>
          </Restricted>
        </Space>
        <Typography.Paragraph>
          {t('my-entity.users-permissions.description')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.users')}
        />
        {userPermissions.length === 0 && !loading ? (
          <EmptyState
            title={
              noFilters
                ? t('my-entity.users-permissions.empty.titleNoData')
                : t('my-entity.users-permissions.empty.titleNoResult')
            }
            picture={images.actionPerson}
          />
        ) : (
          <Table
            loading={loading}
            data-test-id="users-table"
            dataSource={userPermissionsDataSource(userPermissions)}
            columns={userPermissionsColumns((roleColumn) =>
              isAllowedTo(Permission.UsersPermissionsUpdate) ? (
                <Dropdown
                  value={roleColumn.role}
                  suffixIcon={<EditOutlined className="ant-select-suffix" />}
                  bordered={false}
                  dropdownMatchSelectWidth={false}
                  size="auto"
                  options={getAvailableRoles(
                    t,
                    role as Roles,
                    roleColumn.role,
                    data,
                  ).map((rolePermission) => ({
                    value: rolePermission.role,
                    label: t(`roles.${rolePermission.role}`),
                    disabled: rolePermission.disabledTooltipText !== undefined,
                    disabledTooltip: rolePermission.disabledTooltipText ? (
                      <Typography.Text>
                        {rolePermission.disabledTooltipText}
                      </Typography.Text>
                    ) : undefined,
                  }))}
                  onChange={(newRole) => {
                    updateOwnRoleCheck(
                      roleColumn.userPermissionId,
                      newRole as Roles,
                    );
                  }}
                />
              ) : (
                <Typography.Text>
                  {t(`roles.${roleColumn.role}`)}
                </Typography.Text>
              ),
            )}
            pagination={{
              total: data?.userPermission_aggregate.aggregate?.count || 0,
              pageSize: resultsPerPage,
              current: filtersValues.page,
            }}
            onChange={(pagination, _filters, sorter) => {
              onPaginationChange(pagination);
              onSortChange(sorter as SorterResult<UserPermissionRow>);
            }}
          />
        )}
      </Space>
      <InviteUserModal
        isVisible={isInviteUserModalVisible}
        handleClose={() => setIsInviteUserModalVisible(false)}
        providerMailDomain={providerMailDomain}
        shouldDisplayReference={shouldDisplayReference}
        refetchUsers={refetch}
      />
    </ConnectedLayout>
  );
};

export default UsersPermissionsPage;
