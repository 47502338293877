import { EntityTypes, Roles } from 'business/user/types/user';
import {
  BuildingPayload,
  CompanyPayload,
  ContractPayload,
  useGetEntityInformationsQuery,
  useUpdateBuildingPayloadMutation,
  useUpdateCompanyPayloadMutation,
  useUpdateContractPayloadMutation,
  GetUsersPermissionsQueryResult,
  IndicatorFragment,
} from 'generated/graphql';
import { i18n } from 'translations';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const useGetEntityInformations = (
  entityId: string,
  entityTypeRef: string,
) => {
  const isCompany = entityTypeRef === EntityTypes.Company;
  const isBuilding = entityTypeRef === EntityTypes.Building;
  const isContract = entityTypeRef === EntityTypes.Contract;

  const { data, previousData, refetch } = useGetEntityInformationsQuery({
    variables: {
      entityId,
      isCompany,
      isBuilding,
      isContract,
    },
  });

  const address =
    data?.entity_by_pk?.address ??
    previousData?.entity_by_pk?.address ??
    undefined;

  const numberOfChildrenEntities =
    data?.entity_by_pk?.childrenEntities_aggregate.aggregate?.count.toString() ??
    previousData?.entity_by_pk?.childrenEntities_aggregate.aggregate?.count.toString();

  const parentEntityName = data?.entity_by_pk?.parent?.name;

  let entityPayload:
    | Pick<CompanyPayload, 'sector'>
    | Pick<BuildingPayload, 'numberOfFloors' | 'yearOfConstruction'>
    | 'mainUsage'
    | Pick<
        ContractPayload,
        | 'contractNature'
        | 'clientName'
        | 'numberOfSites'
        | 'providerName'
        | 'totalSurface'
      >
    | null;
  let updateEntityPayload;
  switch (true) {
    case isCompany:
      entityPayload =
        data?.entity_by_pk?.companyPayload ??
        previousData?.entity_by_pk?.companyPayload ??
        null;
      [updateEntityPayload] = useUpdateCompanyPayloadMutation();
      break;
    case isBuilding:
      entityPayload =
        data?.entity_by_pk?.buildingPayload ??
        previousData?.entity_by_pk?.buildingPayload ??
        null;
      [updateEntityPayload] = useUpdateBuildingPayloadMutation();
      break;
    case isContract:
      entityPayload =
        data?.entity_by_pk?.contractPayload ??
        previousData?.entity_by_pk?.contractPayload ??
        null;
      [updateEntityPayload] = useUpdateContractPayloadMutation();
      break;
    default:
      throw new Error(`Unhandled type ${entityTypeRef} for entity`);
  }

  return {
    address,
    numberOfChildrenEntities,
    parentEntityName,
    entityPayload,
    refetch,
    updateEntityPayload,
  };
};

export const getValidationSchemaForKey = (key: string | null) => {
  switch (key) {
    case 'sector':
    case 'mainUsage':
    case 'contractNature':
    case 'clientName':
    case 'providerName':
    case 'city':
    case 'country':
    case 'streetName':
    case 'zipCode':
      return yup.string();
    case 'numberOfFloors':
    case 'yearOfConstruction':
    case 'numberOfSites':
    case 'totalSurface':
      return yup
        .number()
        .integer(i18n.t('errors.integer'))
        .min(0, i18n.t('errors.indicatorValues.min'));
    default:
      return yup.string();
  }
};

export const addressFieldsKeys = ['city', 'country', 'streetName', 'zipCode'];

export const getAvailableRoles = (
  t: TFunction,
  role: Roles,
  targetCurrentRole: Roles,
  usersPermissions: GetUsersPermissionsQueryResult['data'],
) => {
  const supervisorOrDirectorCount =
    usersPermissions?.supervisorOrCompanyDirector.aggregate?.count ?? 0;

  const cantDownGrade =
    (targetCurrentRole === Roles.CompanyDirector ||
      targetCurrentRole === Roles.Supervisor) &&
    supervisorOrDirectorCount === 1;

  const adminConstraintDisabledTooltipText = t(
    'my-entity.users-permissions.table.tooltips.one-admin-constraint',
    {
      supervisor: t(`roles.${Roles.Supervisor}`),
      compagnyDirector: t(`roles.${Roles.CompanyDirector}`),
    },
  );

  const forbiddenTooltipText = t(
    'my-entity.users-permissions.table.tooltips.forbidden',
    {
      supervisor: t(`roles.${Roles.Supervisor}`),
    },
  );

  return [
    {
      role: Roles.Collaborator,
      disabledTooltipText: cantDownGrade
        ? adminConstraintDisabledTooltipText
        : undefined,
    },
    {
      role: Roles.CsrManager,
      disabledTooltipText: cantDownGrade
        ? adminConstraintDisabledTooltipText
        : undefined,
    },
    { role: Roles.CompanyDirector },
    {
      role: Roles.Supervisor,
      disabledTooltipText:
        role !== Roles.Supervisor ? forbiddenTooltipText : undefined,
    },
  ];
};

type Indicator = Pick<IndicatorFragment, 'hiddenAt'> & {
  indicatorTemplate: {
    reference: string;
    indicatorTemplateStakes?: any[];
  };
};

export const contextualizeParametersLabel = <T extends Indicator>(
  indicators: T[],
  t: TFunction,
) => {
  return indicators.reduce<T[]>((acc, indicator) => {
    if (!indicator.indicatorTemplate.indicatorTemplateStakes?.length) {
      if (indicator.hiddenAt !== null) {
        return acc;
      }
      return [
        ...acc,
        {
          ...indicator,
          indicatorTemplate: {
            ...indicator.indicatorTemplate,
            label: t(`parameters.${indicator.indicatorTemplate.reference}`),
          },
        },
      ];
    }
    return [...acc, indicator];
  }, []);
};
