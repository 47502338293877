import { Pagination } from 'antd';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import ParameterCard from 'business/myEntity/components/ParameterCard';
import ConnectedLayout from 'business/user/layout/connected';
import { useParameterListQuery } from 'generated/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from 'ui/emptyState';
import images from 'ui/images';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { useFilters } from 'business/common/components/tableFilters/services/useFiltersHook';
import {
  pageFilter,
  stringFilter,
} from 'business/common/components/tableFilters/services/filters';
import TableFilters from 'business/common/components/tableFilters';
import { contextualizeParametersLabel } from 'business/myEntity/services';

const ParametersPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const [resultsPerPage, setResultPerPage] = useState<number>(10);

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useFilters({
      search: stringFilter,
      page: pageFilter,
    });

  const { loading, data, previousData } = useParameterListQuery({
    variables: {
      limit: resultsPerPage,
      offset: resultsPerPage * (filtersValues.page - 1),
      condition: {
        entityId: { _eq: entityId },
        hiddenAt: { _is_null: true },
        indicatorTemplate: {
          label: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          _not: { indicatorTemplateStakes: {} },
        },
      },
    },
  });

  const parameters = contextualizeParametersLabel(
    data?.indicator || previousData?.indicator || [],
    t,
  );

  const onPaginationChange = (page?: number, pageSize?: number | undefined) => {
    setFiltersValues({ ...filtersValues, page: page ?? 1 });
    setResultPerPage(pageSize ?? 10);
  };

  return (
    <ConnectedLayout showEntityBreadcrumbs>
      <Space direction="vertical" size="middle">
        <Space justify="space-between">
          <Typography.Title level={3}>
            {t('my-entity.parameters.title')}
          </Typography.Title>
        </Space>
        <Typography.Paragraph>
          {t('my-entity.parameters.description')}
        </Typography.Paragraph>

        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.parameters')}
        />

        <Space direction="vertical">
          {parameters.length === 0 && !loading ? (
            <EmptyState
              title={t(
                noFilters
                  ? 'my-entity.parameters.empty.titleNoData'
                  : 'my-entity.parameters.empty.titleNoResult',
              )}
              picture={images.indicatorPerson}
            />
          ) : (
            <>
              {parameters.map((parameter) => (
                <ParameterCard key={parameter.id} parameter={parameter} />
              ))}
              <Space justify="end">
                <Pagination
                  pageSize={resultsPerPage}
                  current={filtersValues.page}
                  onChange={onPaginationChange}
                  total={data?.indicator_aggregate.aggregate?.count}
                  hideOnSinglePage
                />
              </Space>
            </>
          )}
        </Space>
      </Space>
    </ConnectedLayout>
  );
};

export default ParametersPage;
