import React from 'react';
import Space from 'ui/space';
import { useTranslation } from 'react-i18next';
import { IndicatorFragment } from 'generated/graphql';
import Typography from 'ui/typography';
import { computeKeyValuesRelativePercentage } from 'business/indicators/services';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import colors from 'config/colors';
import { formatDate } from 'technical/date/formatter';
import { IndicatorType } from 'business/indicators/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import NoRemarkableValueIcon from 'ui/noRemarkableValueIcon';
import { Mode } from 'business/indicators/services/type';
import styles from './index.module.scss';
import KeyValueDisplay from '../keyValue';

interface TrendIndicatorProps {
  keyValues: IndicatorFragment['keyValues'];
  positiveTrend: IndicatorFragment['indicatorTemplate']['positiveTrend'];
  unit: IndicatorFragment['indicatorTemplate']['unit'];
  mode: Mode;
  indicatorType: IndicatorType;
}

const TrendIndicator: React.FC<TrendIndicatorProps> = ({
  keyValues,
  positiveTrend,
  unit,
  mode,
  indicatorType,
}) => {
  const { t } = useTranslation();
  const { isAllowedTo } = useLoggedInAppContext();

  if (!keyValues) {
    return <NoRemarkableValueIcon indicatorType={indicatorType} mode={mode} />;
  }

  const relativePercentage = computeKeyValuesRelativePercentage(keyValues);

  return (
    <Space direction={mode} justify="space-between" size="extra-small">
      <Space
        direction={mode === Mode.Vertical ? 'horizontal' : 'vertical'}
        size="extra-small"
        align={mode === Mode.Vertical ? 'center' : 'start'}
        justify="end"
      >
        <KeyValueDisplay
          mode={mode}
          unit={unit}
          indicatorType={indicatorType}
          keyValues={keyValues}
        />
      </Space>
      <Space
        direction={mode === Mode.Vertical ? 'horizontal' : 'vertical'}
        size="extra-small"
        align="center"
        justify="end"
      >
        {relativePercentage !== null ? (
          <>
            <Space size="extra-small" align="center" justify="end">
              {relativePercentage >= 0 ? (
                <RiseOutlined
                  className={styles.trend}
                  style={{
                    color:
                      positiveTrend === 'increasing'
                        ? colors.success
                        : colors.danger,
                  }}
                />
              ) : (
                <FallOutlined
                  className={styles.trend}
                  style={{
                    color:
                      positiveTrend === 'increasing'
                        ? colors.danger
                        : colors.success,
                  }}
                />
              )}
              <Typography.Text className={styles.trend}>
                {relativePercentage}%
              </Typography.Text>
              <Typography.Text type="secondary">
                {indicatorType === IndicatorType.Stock
                  ? t('indicators.strategy.card.sinceDateStock')
                  : t('indicators.strategy.card.sinceDate', {
                      date: formatDate(keyValues.lastYear!.date),
                    })}
              </Typography.Text>
            </Space>
          </>
        ) : (
          <Typography.Text type="secondary">---</Typography.Text>
        )}
      </Space>
    </Space>
  );
};

export default TrendIndicator;
