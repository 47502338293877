import history from 'technical/history/index';
import Routes from './routes';

export default function navBack(defaultRoute: Routes | string) {
  // Try if there's an history present that can be used, else we use the defaultRoute props
  if (history.location.key === null || history.location.key === undefined) {
    history.push(defaultRoute);
  } else {
    history.goBack();
  }
}
