import { Roles } from 'business/user/types/user';
import { GetUsersPermissionsQueryResult } from 'generated/graphql';

export interface IdentitySheetRow {
  key: string;
  label: string;
  value: string | number | null | undefined;
  editable: boolean;
  type: string;
  options?: Array<{ value: string; label: string }>;
}

export type UserPermission = Exclude<
  GetUsersPermissionsQueryResult['data'],
  undefined
>['userPermission'][number];

export interface UserPermissionRow {
  key: string;
  user: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
  role: {
    userPermissionId: string;
    role: Roles;
  };
}

export const AllRoles = [
  Roles.Collaborator,
  Roles.CsrManager,
  Roles.CompanyDirector,
  Roles.Supervisor,
];

// list of providers that have a user reference set to be the email
export enum EmailAsReferenceUserProviders {
  VINCI = 'vinci-energie-france',
}

export const isEmailAsReferenceUserProvider = (
  userProvider: string,
): userProvider is EmailAsReferenceUserProviders => {
  return Object.values(EmailAsReferenceUserProviders).includes(
    userProvider as EmailAsReferenceUserProviders,
  );
};
