// KEEP IN SYNC WITH UI/VARIABLES.MODULE.SCSS !!!

const colors = {
  purple: '#2F1A56',
  lightPurple: '#8566ff',
  white: '#FFFFFF',
  offWhite: '#FAFAFA',
  veryLightGrey: '#DFDFDF',
  lightGrey: '#BFBFBF',
  middleGrey: '#777777',
  corail: '#FF5566',
  green: '#8ed50b',
  blue: '#005695',
  dark: '#1B0A04',
};

const intentions = {
  primary: colors.lightPurple,
  surfaceBg: colors.white,
  surfaceText: colors.purple,
  backgroundColor: colors.offWhite,
  titleColor: colors.purple,
  emphasisColor: colors.purple,
  textColor: colors.middleGrey,
  softenColor: colors.lightGrey,
  danger: colors.corail,
  success: colors.green,
  neutral: colors.blue,
  maskBg: colors.purple,
  lightEmphasisColor: colors.veryLightGrey,
  totalGraphColor: colors.dark,
};

module.exports = intentions;
