import { Alert, Form, Input, message, Select } from 'antd';
import { AllRoles } from 'business/myEntity/types';
import { Roles } from 'business/user/types/user';
import { useFormik } from 'formik';
import { formikStatus, formikErrorMessage } from 'technical/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui/modal';
import Space from 'ui/space';
import * as yup from 'yup';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import Typography from 'ui/typography';
import { email, notEmail } from 'business/user/validations/string';
import { useInviteUserMutation } from 'generated/graphql';
import { isNotNullOrUndefined } from 'technical/type';

const { Option } = Select;

interface InviteUserModalProps {
  isVisible: boolean;
  handleClose: () => void;
  providerMailDomain?: string;
  shouldDisplayReference?: boolean;
  refetchUsers: () => void;
}

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  isVisible,
  handleClose,
  providerMailDomain,
  shouldDisplayReference = false,
  refetchUsers,
}) => {
  const {
    selectedPermission: { role, entityId },
    user,
  } = useLoggedInAppContext();
  const { t } = useTranslation();

  const [inviteUser] = useInviteUserMutation();

  const formik = useFormik({
    initialValues: {
      mail: '',
      externalReference: '',
      firstName: '',
      lastName: '',
      role: Roles.Collaborator,
    },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      mail: providerMailDomain
        ? notEmail(t, providerMailDomain).required(t('errors.required'))
        : email(t),
      firstName: yup.string().required(t('errors.required')),
      externalReference: shouldDisplayReference
        ? yup.string().required(t('errors.required'))
        : yup.string().optional(),
      lastName: yup.string().required(t('errors.required')),
      role: yup
        .mixed<Roles>()
        .oneOf(
          Object.values(Roles).filter(
            (r) => r !== Roles.Supervisor || role === Roles.Supervisor,
          ),
        ),
    }),
    onSubmit: async (
      { mail: p, firstName, lastName, role: selectedRole, externalReference },
      { resetForm },
    ) => {
      try {
        const mail = providerMailDomain ? `${p}@${providerMailDomain}` : p;

        const response = await inviteUser({
          variables: {
            email: mail,
            entityId,
            role: selectedRole,
            firstName,
            lastName,
            externalUserReference:
              providerMailDomain && !externalReference
                ? mail.toLowerCase()
                : externalReference,
          },
        });

        if (!response.data?.inviteUser.success) {
          formik.setFieldError('mail', t('errors.generic'));
        } else {
          resetForm();
          refetchUsers();
          handleClose();
          message.success(
            providerMailDomain
              ? t('entity.users-permissions.invite.success.sso')
              : t('entity.users-permissions.invite.success.classic'),
          );
        }
      } catch (err) {
        formik.setStatus({
          error: t('my-entity.users-permissions.invite.error'),
        });
      }
    },
  });

  return (
    <Modal
      title={t('my-entity.users-permissions.invite.label')}
      open={isVisible}
      okText={t('common.add')}
      cancelText={t('common.cancel')}
      onOk={() => formik.handleSubmit()}
      onCancel={() => {
        formik.resetForm();
        handleClose();
      }}
    >
      <Space direction="vertical">
        <Form.Item
          validateStatus={formikStatus(formik, 'mail')}
          help={formikErrorMessage(formik, 'mail')}
          label={t('my-entity.users-permissions.invite.mail.label')}
        >
          <Input
            addonAfter={
              providerMailDomain ? `@${providerMailDomain}` : undefined
            }
            placeholder={t(
              'my-entity.users-permissions.invite.mail.placeholder',
            )}
            id="mail"
            onChange={(e) => {
              formik.setTouched({ ...formik.touched, mail: true });
              formik.handleChange(e);
            }}
            value={formik.values.mail}
            onBlur={formik.handleBlur}
          />
        </Form.Item>

        {shouldDisplayReference && (
          <Form.Item
            validateStatus={formikStatus(formik, 'externalReference')}
            help={formikErrorMessage(formik, 'externalReference')}
            label={t(
              'my-entity.users-permissions.invite.externalReference.label',
            )}
          >
            <Input
              placeholder={t(
                'my-entity.users-permissions.invite.externalReference.placeholder',
              )}
              id="externalReference"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.externalReference}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        )}

        <Form.Item
          validateStatus={formikStatus(formik, 'firstName')}
          help={formikErrorMessage(formik, 'firstName')}
          label={t('my-entity.users-permissions.invite.firstName.label')}
        >
          <Input
            placeholder={t(
              'my-entity.users-permissions.invite.firstName.placeholder',
            )}
            id="firstName"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
          />
        </Form.Item>

        <Form.Item
          validateStatus={formikStatus(formik, 'lastName')}
          help={formikErrorMessage(formik, 'lastName')}
          label={t('my-entity.users-permissions.invite.lastName.label')}
        >
          <Input
            placeholder={t(
              'my-entity.users-permissions.invite.lastName.placeholder',
            )}
            id="lastName"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
          />
        </Form.Item>

        <Form.Item
          validateStatus={formikStatus(formik, 'role')}
          help={formikErrorMessage(formik, 'role')}
          label={t('my-entity.users-permissions.table.role')}
        >
          <Select
            defaultValue={Roles.Collaborator}
            onChange={(e) => {
              formik.setValues({ ...formik.values, role: e });
            }}
            value={formik.values.role}
            onBlur={formik.handleBlur}
          >
            {AllRoles.map((r) => (
              <Option
                key={r}
                value={r}
                disabled={r === Roles.Supervisor && role !== Roles.Supervisor}
              >
                {t(`roles.${r}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {formik.status && formik.status.error && (
          <Alert message={formik.status.error} type="error" />
        )}

        <Typography.Text>
          {t('my-entity.users-permissions.invite.import-multiple')}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default InviteUserModal;
