import { MailOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useAppContext } from 'business/AppBootstrapper';
import AuthLayout from 'business/user/layout/auth';
import DisconnectedLayout from 'business/user/layout/disconnected';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import Loader from 'ui/loader';

const LoginProviderCallbackFromSso: React.FC = () => {
  const { requestRebootstrap } = useAppContext();
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      requestRebootstrap();
    } catch (err) {
      logger.error(err);
      setError(true);
    }
  }, [requestRebootstrap]);

  if (error) {
    return (
      <AuthLayout>
        <Result
          icon={<MailOutlined />}
          title={t('notConnected.login.callback.error.title')}
          subTitle={t('notConnected.login.callback.error.subTitle')}
        />
      </AuthLayout>
    );
  }

  return (
    <DisconnectedLayout>
      <Loader />
    </DisconnectedLayout>
  );
};

export default LoginProviderCallbackFromSso;
