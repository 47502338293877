import { MailOutlined } from '@ant-design/icons';
import { useAppContext } from 'business/AppBootstrapper';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import Loader from 'ui/loader';
import { Result } from 'antd';
import AuthLayout from 'business/user/layout/auth';
import authService from '../../services/authentication';
import DisconnectedLayout from '../../layout/disconnected';

function LoginProviderCallbackScreen() {
  const [error, setError] = React.useState<Error | undefined>(undefined);
  const { t } = useTranslation();
  const { requestRebootstrap } = useAppContext();

  React.useEffect(() => {
    authService
      .requestLoginCallback()
      .then(() => requestRebootstrap())
      .catch((err) => {
        logger.error(err);
        setError(err);
      });
  }, [requestRebootstrap]);

  if (error) {
    let errorKey = '';

    switch (error.message) {
      case 'invalid-email-domain':
      case 'email-not-verified':
        errorKey = error.message;
        break;
      default:
        break;
    }

    return (
      <AuthLayout>
        <Result
          icon={<MailOutlined />}
          title={t('notConnected.login.callback.error.title', {
            context: errorKey,
          })}
          subTitle={t('notConnected.login.callback.error.subTitle', {
            context: errorKey,
          })}
        />
      </AuthLayout>
    );
  }
  return (
    // Shortly displaying loading indicator, before either displaying error
    // or redirecting to home page
    <DisconnectedLayout>
      <Loader />
    </DisconnectedLayout>
  );
}

export default LoginProviderCallbackScreen;
