import { Typography } from 'antd';
import React from 'react';
import Card from 'ui/card';
import Radar, { RadarProps } from 'ui/radar';
import Space from 'ui/space';
import styles from './index.module.scss';

interface RadarInCardProps extends RadarProps {
  title: string;
}

const RadarInCard: React.FC<RadarInCardProps> = ({ title, ...rest }) => {
  return (
    <Card className={styles.card}>
      <Space direction="vertical" size="large">
        <Space justify="space-between">
          <Typography.Title level={5}> {title} </Typography.Title>
        </Space>

        <Radar {...rest} />
      </Space>
    </Card>
  );
};

export default RadarInCard;
