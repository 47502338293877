import { AppContextAttributes } from 'business/AppBootstrapper';
import Routes from 'business/router/routes';
import {
  ConnectedHoc,
  NotConnectedHoc,
} from 'business/user/components/protectedRouteContainer';
import ForgotPasswordPage from 'business/user/pages/forgotPassword';
import LoginProviderCallbackScreen from 'business/user/pages/loginProviderCallback';
import ResetPasswordPage from 'business/user/pages/resetPassword';
import SignInPage from 'business/user/pages/signIn';
import IndicatorsStrategyPage from 'business/indicators/pages/strategy/list';
import ActionsStrategyPage from 'business/actions/pages/strategy/list';
import ActionStrategyDetailsPage from 'business/actions/pages/strategy/details';
import AddActionPage from 'business/actions/pages/strategy/add';
import CreateActionPage from 'business/actions/pages/strategy/create';
import CampaignsStrategyPage from 'business/campaigns/pages/strategy/list';
import CampaignStrategyDetailsPage from 'business/campaigns/pages/strategy/details';
import IndicatorStrategyDetailsPage from 'business/indicators/pages/strategy/details';
import PartnersCatalogPage from 'business/partners/pages/catalog/list';
import PartnerCatalogDetailsPage from 'business/partners/pages/catalog/details';
import AccountSelectionPage from 'business/user/pages/accountSelection';
import IndicatorsCatalogPage from 'business/indicators/pages/catalog/list';
import IndicatorsCatalogDetailsPage from 'business/indicators/pages/catalog/details';
import ActionsCatalogPage from 'business/actions/pages/catalog/list';
import ActionsCatalogDetailsPage from 'business/actions/pages/catalog/details';
import CampaignsCatalogPage from 'business/campaigns/pages/catalog/list';
import CampaignCatalogDetailsPage from 'business/campaigns/pages/catalog/details';
import ActionCatalogForIndicatorPage from 'business/actions/pages/catalog/listForIndicator';
import ActionStrategyEditPage from 'business/actions/pages/strategy/edit';
import ParametersPage from 'business/myEntity/pages/parameters/list';
import ParameterDetailsPage from 'business/myEntity/pages/parameters/details';
import CaliDocumentationPage from 'business/documentation/pages/cali';
import CsrDocumentationPage from 'business/documentation/pages/csr';
import CsrCertificationAndLabelsDocumentationPage from 'business/documentation/pages/csrCertificationAndLabels';
import SummaryPage from 'business/summary/pages/summary';
import EntitySelectionPage from 'business/user/pages/entitySelection';
import LoginProviderCallbackFromSso from 'business/user/pages/loginProviderCallbackFromSso';
import IdentitySheetPage from 'business/myEntity/pages/identitySheet';
import UsersPermissionsPage from 'business/myEntity/pages/usersPermissions';
import { Permission } from 'business/user/types/user';
import SSOErrorPage from 'business/user/pages/signIn/ssoError';
import SsoSignInPage from 'business/user/pages/ssoSignIn';
import { RouteProps } from './types';

export const commonRoutesProps: RouteProps[] = [
  {
    path: Routes.LoginCallback,
    component: NotConnectedHoc(LoginProviderCallbackScreen),
  },
  {
    path: Routes.LoginCallbackFromSso,
    component: NotConnectedHoc(LoginProviderCallbackFromSso),
  },
  {
    path: Routes.SignIn,
    component: NotConnectedHoc(SignInPage),
  },
  {
    path: Routes.SsoSignIn,
    component: NotConnectedHoc(SsoSignInPage),
  },
  {
    path: Routes.SSOError,
    component: NotConnectedHoc(SSOErrorPage),
  },
  {
    path: Routes.ForgotPassword,
    component: NotConnectedHoc(ForgotPasswordPage),
  },
  {
    path: Routes.ResetPassword,
    component: NotConnectedHoc(ResetPasswordPage),
  },
];

const userRoutesProps: RouteProps[] = [
  {
    path: Routes.ConnectedResetPassword,
    component: ConnectedHoc(ResetPasswordPage),
  },
  {
    path: Routes.IndicatorsStrategy,
    component: ConnectedHoc(IndicatorsStrategyPage),
    neededPermissions: [Permission.IndicatorsRead],
  },
  {
    path: Routes.IndicatorStrategyDetails,
    component: ConnectedHoc(IndicatorStrategyDetailsPage),
    neededPermissions: [Permission.IndicatorsRead],
  },
  {
    path: Routes.PartnersCatalog,
    component: ConnectedHoc(PartnersCatalogPage),
  },
  {
    path: Routes.PartnerCatalogDetails,
    component: ConnectedHoc(PartnerCatalogDetailsPage),
  },
  {
    path: Routes.ActionCatalogForIndicator,
    component: ConnectedHoc(ActionCatalogForIndicatorPage),
  },
  {
    path: Routes.IndicatorsCatalog,
    component: ConnectedHoc(IndicatorsCatalogPage),
  },
  {
    path: Routes.IndicatorCatalogDetails,
    component: ConnectedHoc(IndicatorsCatalogDetailsPage),
  },
  {
    path: Routes.ActionsCatalog,
    component: ConnectedHoc(ActionsCatalogPage),
  },
  {
    path: Routes.ActionCatalogDetails,
    component: ConnectedHoc(ActionsCatalogDetailsPage),
  },
  {
    path: Routes.AddActionStrategy,
    component: ConnectedHoc(AddActionPage),
    neededPermissions: [Permission.ActionAdd],
  },
  {
    path: Routes.ActionStrategyCreate,
    component: ConnectedHoc(CreateActionPage),
    neededPermissions: [Permission.ActionAdd],
  },
  {
    path: Routes.ActionStrategyCreateWithParams,
    component: ConnectedHoc(CreateActionPage),
    neededPermissions: [Permission.ActionAdd],
  },
  {
    path: Routes.UsersPermissions,
    component: ConnectedHoc(UsersPermissionsPage),
    neededPermissions: [Permission.UsersPermissionsRead],
  },
  {
    path: Routes.ActionsStrategy,
    component: ConnectedHoc(ActionsStrategyPage),
    neededPermissions: [Permission.ActionRead],
  },
  {
    path: Routes.ActionStrategyDetails,
    component: ConnectedHoc(ActionStrategyDetailsPage),
    neededPermissions: [Permission.ActionRead],
  },
  {
    path: Routes.ActionStrategyEdit,
    component: ConnectedHoc(ActionStrategyEditPage),
    neededPermissions: [Permission.ActionUpdate],
  },
  {
    path: Routes.ActionCatalogDetails,
    component: ConnectedHoc(ActionsCatalogDetailsPage),
  },
  {
    path: Routes.CampaignsCatalog,
    component: ConnectedHoc(CampaignsCatalogPage),
  },
  {
    path: Routes.CampaignCatalogDetails,
    component: ConnectedHoc(CampaignCatalogDetailsPage),
  },
  {
    path: Routes.CampaignsStrategy,
    component: ConnectedHoc(CampaignsStrategyPage),
    neededPermissions: [Permission.CampaignRead],
  },
  {
    path: Routes.CampaignStrategyDetails,
    component: ConnectedHoc(CampaignStrategyDetailsPage),
    neededPermissions: [Permission.CampaignRead],
  },
  {
    path: Routes.Parameters,
    component: ConnectedHoc(ParametersPage),
    neededPermissions: [Permission.ParametersRead],
  },
  {
    path: Routes.ParameterDetails,
    component: ConnectedHoc(ParameterDetailsPage),
    neededPermissions: [Permission.ParametersRead],
  },
  {
    path: Routes.IdentitySheet,
    component: ConnectedHoc(IdentitySheetPage),
  },
  {
    path: Routes.Summary,
    component: ConnectedHoc(SummaryPage),
    neededPermissions: [Permission.SummaryRead],
  },
  {
    path: Routes.CaliDocumentation,
    component: ConnectedHoc(CaliDocumentationPage),
  },
  {
    path: Routes.CsrDocumentation,
    component: ConnectedHoc(CsrDocumentationPage),
  },
  {
    path: Routes.CsrCertificationAndLabelsDocumentation,
    component: ConnectedHoc(CsrCertificationAndLabelsDocumentationPage),
  },
  {
    path: Routes.Home,
    redirectTo: Routes.IndicatorsStrategy,
    neededPermissions: [Permission.IndicatorsRead],
  },
];

const disconnectedRoutesProps: RouteProps[] = [
  {
    path: Routes.Home,
    redirectTo: Routes.SignIn,
  },
];

const accountSelectionRoutesProps: RouteProps[] = [
  {
    path: Routes.AccountSelection,
    component: ConnectedHoc(AccountSelectionPage),
  },
  {
    path: Routes.Home,
    redirectTo: Routes.AccountSelection,
  },
];

const entitySelectionRouteProps: RouteProps[] = [
  {
    path: Routes.EntitySelection,
    component: ConnectedHoc(EntitySelectionPage),
  },
  {
    path: Routes.Home,
    redirectTo: Routes.EntitySelection,
  },
];

export const routesPropsListBuilder = ({
  isConnected,
  selectedPermission,
  selectedEntityType,
  isAllowedTo,
}: AppContextAttributes) => {
  if (isConnected && selectedPermission) {
    return userRoutesProps.filter((route) => {
      if (!route.neededPermissions?.length) {
        return true;
      }
      return route.neededPermissions.some(isAllowedTo);
    });
  }

  if (isConnected && !selectedPermission && !selectedEntityType) {
    return entitySelectionRouteProps;
  }

  if (isConnected && !selectedPermission) {
    return accountSelectionRoutesProps;
  }

  return disconnectedRoutesProps;
};
