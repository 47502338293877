import classnames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type SprinkleColors = 'blue' | 'pink' | 'green';

interface SprinkleProps {
  color: SprinkleColors;
  className?: string;
  style?: React.CSSProperties;
}

const Sprinkle: React.FC<SprinkleProps> = ({ color, className, style }) => {
  return (
    <span
      style={style}
      className={classnames(styles.sprinkle, styles[color], className)}
    />
  );
};

export default Sprinkle;
