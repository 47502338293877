import colors from 'config/colors';
import React from 'react';

interface Props {
  className?: string;
  width?: number;
  style?: React.CSSProperties;
}

const SuccessIllustration = ({ className, width = 150, style }: Props) => (
  <svg
    className={className}
    style={style}
    width={width}
    viewBox="0 0 215 175"
    fill="none"
  >
    <path
      d="M212.623 66.9235C212.546 66.9236 212.471 66.9022 212.408 66.8623L108.577 0.989141C108.261 0.78961 107.886 0.682977 107.502 0.683575C107.117 0.684172 106.742 0.79197 106.427 0.992481L3.34383 66.8616C3.26229 66.9137 3.16138 66.9341 3.06329 66.9185C2.9652 66.9028 2.87796 66.8522 2.82077 66.778C2.76358 66.7037 2.74112 66.6118 2.75834 66.5224C2.77556 66.433 2.83103 66.3536 2.91257 66.3015L105.996 0.432366C106.437 0.151692 106.962 0.000816705 107.5 1.08743e-05C108.039 -0.000794957 108.564 0.148506 109.007 0.427857L212.837 66.3008C212.903 66.3424 212.952 66.4021 212.978 66.471C213.004 66.5399 213.005 66.6145 212.981 66.684C212.957 66.7535 212.909 66.8142 212.845 66.8573C212.78 66.9004 212.703 66.9236 212.623 66.9235L212.623 66.9235Z"
      fill="#3F3D56"
    />
    <path
      d="M9.23438 69.2585L107.604 2.84518L206.72 73.9841L112.672 124.773L61.6126 114.171L9.23438 69.2585Z"
      fill="#E6E6E6"
    />
    <path
      d="M65.9222 156.144H17.3253C17.0269 156.144 16.7314 156.091 16.4556 155.987C16.1798 155.883 15.9292 155.731 15.718 155.539C15.5069 155.347 15.3394 155.118 15.2251 154.867C15.1108 154.616 15.052 154.347 15.052 154.075C15.052 153.803 15.1108 153.534 15.2251 153.283C15.3394 153.032 15.5069 152.804 15.718 152.612C15.9292 152.42 16.1798 152.267 16.4556 152.164C16.7314 152.06 17.0269 152.007 17.3253 152.007H65.9222C66.2206 152.007 66.5162 152.06 66.792 152.164C67.0678 152.267 67.3184 152.42 67.5295 152.612C67.7407 152.804 67.9082 153.032 68.0225 153.283C68.1367 153.534 68.1956 153.803 68.1956 154.075C68.1956 154.347 68.1367 154.616 68.0225 154.867C67.9082 155.118 67.7407 155.347 67.5295 155.539C67.3184 155.731 67.0678 155.883 66.792 155.987C66.5162 156.091 66.2206 156.144 65.9222 156.144V156.144Z"
      fill={colors.primary}
    />
    <path
      d="M34.7608 147.251H17.3253C17.0269 147.251 16.7314 147.198 16.4556 147.094C16.1798 146.991 15.9292 146.838 15.718 146.646C15.5069 146.454 15.3394 146.226 15.2251 145.975C15.1108 145.724 15.052 145.455 15.052 145.183C15.052 144.911 15.1108 144.642 15.2251 144.391C15.3394 144.14 15.5069 143.912 15.718 143.719C15.9292 143.527 16.1798 143.375 16.4556 143.271C16.7314 143.167 17.0269 143.114 17.3253 143.115H34.7608C35.0592 143.114 35.3548 143.167 35.6306 143.271C35.9064 143.375 36.157 143.527 36.3681 143.719C36.5793 143.912 36.7468 144.14 36.8611 144.391C36.9753 144.642 37.0342 144.911 37.0342 145.183C37.0342 145.455 36.9753 145.724 36.8611 145.975C36.7468 146.226 36.5793 146.454 36.3681 146.646C36.157 146.838 35.9064 146.991 35.6306 147.094C35.3548 147.198 35.0592 147.251 34.7608 147.251Z"
      fill={colors.primary}
    />
    <path
      d="M108.987 106.25C108.617 106.25 108.252 106.185 107.91 106.058L47.2422 83.1261V11.3462C47.243 10.7115 47.5201 10.103 48.0128 9.65417C48.5055 9.20535 49.1735 8.95288 49.8703 8.95214H166.256C166.953 8.95288 167.621 9.20535 168.114 9.65417C168.606 10.103 168.883 10.7115 168.884 11.3462V83.1762L168.77 83.2206L110.097 106.047C109.745 106.181 109.368 106.25 108.987 106.25Z"
      fill="white"
    />
    <path
      d="M108.987 106.421C108.593 106.421 108.203 106.352 107.838 106.216L47.0544 83.2403V11.3462C47.0553 10.6662 47.3522 10.0142 47.8801 9.53328C48.408 9.0524 49.1237 8.7819 49.8702 8.78113H166.256C167.003 8.7819 167.718 9.0524 168.246 9.53328C168.774 10.0142 169.071 10.6662 169.072 11.3462V83.2891L110.17 106.204C109.796 106.347 109.393 106.421 108.987 106.421V106.421ZM47.8053 82.7834L108.124 105.584C108.687 105.792 109.317 105.788 109.877 105.574L168.321 82.8375V11.3462C168.32 10.8475 168.103 10.3694 167.716 10.0168C167.329 9.66412 166.804 9.46575 166.256 9.46516H49.8702C49.3228 9.46575 48.798 9.66412 48.4108 10.0168C48.0237 10.3694 47.806 10.8475 47.8053 11.3462L47.8053 82.7834Z"
      fill="#3F3D56"
    />
    <path
      d="M211.872 66.2395H211.797L168.696 83.0051L109.726 105.944C109.496 106.032 109.249 106.078 108.999 106.079C108.749 106.081 108.501 106.037 108.27 105.951L47.4298 82.9572L3.27069 66.2669L3.20323 66.2395H3.12807C2.4313 66.2402 1.76329 66.4927 1.2706 66.9415C0.777904 67.3903 0.500773 67.9989 0.5 68.6336V172.606C0.500774 173.241 0.777904 173.849 1.2706 174.298C1.76329 174.747 2.4313 174.999 3.12807 175H211.872C212.569 174.999 213.237 174.747 213.729 174.298C214.222 173.849 214.499 173.241 214.5 172.606V68.6336C214.499 67.9989 214.222 67.3903 213.729 66.9415C213.237 66.4927 212.569 66.2402 211.872 66.2395V66.2395ZM213.749 172.606C213.749 173.059 213.551 173.494 213.199 173.815C212.847 174.136 212.37 174.316 211.872 174.316H3.12807C2.63027 174.316 2.15292 174.136 1.80092 173.815C1.44892 173.494 1.25108 173.059 1.25088 172.606V68.6336C1.25156 68.1914 1.43986 67.7665 1.77641 67.4478C2.11296 67.1291 2.57171 66.9413 3.05676 66.9235L47.4298 83.6959L107.981 106.583C108.636 106.826 109.371 106.823 110.023 106.573L168.696 83.7472L211.947 66.9235C212.431 66.9426 212.889 67.131 213.224 67.4495C213.56 67.768 213.748 68.1921 213.749 68.6336V172.606Z"
      fill="#3F3D56"
    />
    <path
      d="M108.38 85.8958C106.141 85.8996 103.96 85.2399 102.168 84.0161L102.057 83.9401L78.6634 67.6234C77.58 66.8672 76.6707 65.924 75.9874 64.8477C75.3042 63.7713 74.8603 62.5829 74.6812 61.3503C74.5021 60.1177 74.5912 58.865 74.9436 57.6638C75.2959 56.4625 75.9045 55.3363 76.7346 54.3493C77.5647 53.3623 78.6001 52.534 79.7816 51.9116C80.9632 51.2891 82.2677 50.8848 83.6208 50.7216C84.9739 50.5584 86.349 50.6396 87.6676 50.9606C88.9862 51.2816 90.2226 51.836 91.306 52.5922L106.459 63.1768L142.267 20.6347C143.098 19.648 144.134 18.82 145.315 18.1979C146.497 17.5758 147.802 17.1719 149.155 17.0092C150.508 16.8465 151.883 16.9282 153.202 17.2496C154.52 17.571 155.756 18.1258 156.839 18.8824L156.617 19.1578L156.845 18.8864C159.03 20.4161 160.459 22.6728 160.82 25.1615C161.181 27.6501 160.443 30.1674 158.769 32.1612L116.649 82.2008C115.675 83.3539 114.422 84.287 112.988 84.9278C111.554 85.5686 109.978 85.8999 108.38 85.8958V85.8958Z"
      fill={colors.primary}
    />
  </svg>
);

export default SuccessIllustration;
