import React, { useState } from 'react';
import Table from 'ui/table';
import {
  IndicatorType,
  IndicatorUnit,
  IndicatorValueRow,
} from 'business/indicators/types';
import {
  IndicatorDetailsFragment,
  IndicatorValue_Constraint,
  IndicatorValue_Update_Column,
  ParameterDetailsFragment,
  useDeleteIndicatorValueMutation,
  useInsertIndicatorValueMutation,
} from 'generated/graphql';
import {
  indicatorValuesColumns,
  indicatorValuesDataSource,
} from 'business/indicators/services/table';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getUnitValidationSchema } from 'business/indicators/services/units';
import { endOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Space from 'ui/space';
import Button from 'ui/button';
import { ApolloError } from '@apollo/client';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { Permission } from 'business/user/types/user';
import { formatDate } from 'technical/date/formatter';
import Restricted from 'business/user/components/Restricted';
import AddIndicatorValueModal from '../addIndicatorValueModal';

interface IndicatorValuesTableProps {
  indicator: IndicatorDetailsFragment | ParameterDetailsFragment;
  refetch: () => void;
  selectedUnit: IndicatorUnit;
  defaultUnit: IndicatorUnit;
  refetchValues?: () => void;
}

const IndicatorValuesTable: React.FC<IndicatorValuesTableProps> = ({
  indicator,
  refetch,
  refetchValues,
  selectedUnit,
  defaultUnit,
}) => {
  const { t } = useTranslation();
  const { isAllowedTo } = useLoggedInAppContext();
  const [editingKey, setEditingKey] = useState<{
    key: string;
    isTemporary: boolean;
  } | null>(null);
  const [insertIndicatorValue] = useInsertIndicatorValueMutation();
  const [deleteIndicatorValue] = useDeleteIndicatorValueMutation();
  const [isAddIndicatorValueModalVisible, setIsAddIndicatorValueModalVisible] =
    useState(false);

  const formik = useFormik<{
    value: number | undefined;
    date: Date | undefined;
  }>({
    initialValues: { value: undefined, date: undefined },
    validationSchema: yup
      .object({
        value: getUnitValidationSchema(indicator.indicatorTemplate.unit),
        date: yup.date().required(t('errors.required')),
      })
      .required(),
    onSubmit: async (values) => {
      try {
        await insertIndicatorValue({
          variables: {
            indicatorValue: {
              id: editingKey?.isTemporary ? undefined : editingKey?.key,
              indicatorId: indicator.id,
              startDate: formatDate(values.date!, 'y-MM-dd'),
              endDate:
                indicator.indicatorTemplate.type === IndicatorType.Stock
                  ? formatDate(endOfMonth(values.date!), 'y-MM-dd')
                  : formatDate(values.date!, 'y-MM-dd'),
              rawValue: values.value,
            },
            onConflict: {
              constraint: IndicatorValue_Constraint.IndicatorValuePkey,
              update_columns: [
                IndicatorValue_Update_Column.UpdatedAt,
                IndicatorValue_Update_Column.RawValue,
                IndicatorValue_Update_Column.StartDate,
                IndicatorValue_Update_Column.EndDate,
                IndicatorValue_Update_Column.Source,
              ],
            },
          },
        });
        await refetch();
        if (refetchValues) {
          refetchValues();
        }
        setEditingKey(null);
      } catch (err) {
        if (
          err instanceof ApolloError &&
          err.graphQLErrors.some(
            (gqlErr) => gqlErr.extensions?.code === 'constraint-violation',
          )
        ) {
          formik.setFieldError(
            'date',
            t('errors.indicatorValues.dateAlreadyExists'),
          );
        }
      }
    },
  });

  const canEdit = isAllowedTo(Permission.IndicatorsUpdate);
  const isEditing = (record: IndicatorValueRow) =>
    record.key === editingKey?.key;

  const edit = (record: IndicatorValueRow) => {
    formik.setValues({
      date: new Date(record.date),
      value: record.value,
    });

    setEditingKey({ key: record.key, isTemporary: record.isTemporary });
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const save = async () => {
    await formik.handleSubmit();
  };

  const remove = async (record: IndicatorValueRow) => {
    await deleteIndicatorValue({
      variables: {
        id: record.key,
      },
    });
    await refetch();
    if (refetchValues) {
      refetchValues();
    }
  };

  return (
    <Space direction="vertical">
      <Restricted permission={Permission.IndicatorsUpdate}>
        <Space justify="end">
          <Button
            data-test-id="add-indicator-value"
            type="primary"
            onClick={() => setIsAddIndicatorValueModalVisible(true)}
          >
            {t('indicators.strategy.indicatorValues.add.cta')}
          </Button>
        </Space>
      </Restricted>
      <Table
        data-test-id="indicator-values-table"
        dataSource={indicatorValuesDataSource(indicator)}
        columns={indicatorValuesColumns({
          canEdit,
          isEditing,
          save,
          edit,
          cancel,
          remove,
          formik,
          t,
        })}
        pagination={{
          onChange: cancel,
          pageSize:
            indicator.indicatorTemplate.type === IndicatorType.Stock ? 12 : 10,
        }}
      />
      <AddIndicatorValueModal
        isVisible={isAddIndicatorValueModalVisible}
        indicator={indicator}
        handleClose={() => setIsAddIndicatorValueModalVisible(false)}
        selectedUnit={selectedUnit}
        defaultUnit={defaultUnit}
      />
    </Space>
  );
};

export default IndicatorValuesTable;
