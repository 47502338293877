import {
  ImpersonateEntityDocument,
  ImpersonateEntityMutation,
  ImpersonateEntityMutationVariables,
} from 'generated/graphql';
import apolloClient from 'technical/graphql/client';

let impersonateToken: string | null = null;

export function getImpersonateToken() {
  return impersonateToken;
}

function setImpersonateToken(token: string) {
  impersonateToken = token;
}

export function removeImpersonateToken() {
  impersonateToken = null;
}

export async function impersonateEntity(entityId: string) {
  const { data, errors } = await apolloClient.mutate<
    ImpersonateEntityMutation,
    ImpersonateEntityMutationVariables
  >({
    mutation: ImpersonateEntityDocument,
    variables: {
      entityId,
    },
  });

  if (data) {
    setImpersonateToken(data.impersonateEntity.impersonateEntityToken);
    return;
  }

  if (errors) {
    throw new Error(`[Impersonate Entity] ${errors?.join('\n')}`);
  }

  throw new Error('[Impersonate Entity] Unknown error');
}
