import { PartnerFragment, StakeFragment } from 'generated/graphql';

export const getPartnerStakes = (partner: PartnerFragment): StakeFragment[] => {
  return Array.from(
    new Set(
      partner.partnerIndicatorTemplates.flatMap((partnerIndicatorTemplate) =>
        partnerIndicatorTemplate.indicatorTemplate.indicatorTemplateStakes.map(
          (indicatorTemplateStake) => indicatorTemplateStake.stake,
        ),
      ),
    ),
  );
};
