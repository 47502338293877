import AuthLayout from 'business/user/layout/auth';
import React from 'react';
import LoginCard from 'business/user/components/loginCard';

const SignInPage: React.FC = () => {
  return (
    <AuthLayout>
      <LoginCard />
    </AuthLayout>
  );
};

export default SignInPage;
