import Space from 'ui/space';
import Tag from 'ui/tag';
import InformationDetails from 'business/common/components/informationDetails';
import ThemeTag from 'business/theme/components/themeTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StakeFragment, ThemeFragment } from 'generated/graphql';

interface ThemeAndStakesDetailsProps {
  themes: ThemeFragment[];
  stakes: StakeFragment[];
}

const ThemeAndStakesDetails: React.FC<ThemeAndStakesDetailsProps> = ({
  themes,
  stakes,
}) => {
  const { t } = useTranslation();

  return (
    <InformationDetails title={t('indicators.details.themesAndStakes')}>
      <Space size="extra-small">
        {themes.map((theme) => (
          <ThemeTag key={theme.id} theme={theme} />
        ))}
      </Space>
      <Space size="extra-small">
        {stakes.map(({ label }) => (
          <Tag key={label}>{label}</Tag>
        ))}
      </Space>
    </InformationDetails>
  );
};

export default ThemeAndStakesDetails;
