import React, { createContext, useContext } from 'react';
import { EnsureDefined } from 'technical/type';
import ensureFieldsAreDefined from 'technical/validation';
import Loader from 'ui/loader';
import UserContextProvider from './user/context/user';
import { EntitySelection } from './user/entitySelection/useEntitySelection';
import {
  UserWithPrivateInfosAndPermissions,
  FormattedUserPermission,
  EntityTypes,
  FormattedEntity,
  Permission,
} from './user/types/user';

export interface AppContextAttributes {
  // App context
  user: UserWithPrivateInfosAndPermissions | undefined;
  appBootstraped: boolean;
  requestRebootstrap: () => Promise<void>;
  isConnected: boolean;
  // User Connected Context
  selectedPermission: FormattedUserPermission | null;
  selectedEntityType: EntityTypes | null;
  choosePermission: (entityId: string, role: string) => void;
  chooseEntityType: (entityType: EntityTypes) => void;
  deletePermission: () => void;
  deleteEntityType: () => void;
  // Selected Permission Context
  shouldDisplayOnBoarding: boolean;
  setShouldDisplayOnBoarding: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEntity: FormattedEntity | null;
  entitySelection: Omit<EntitySelection, 'selectedEntity'> | null;
  isAllowedTo: (perm: Permission) => boolean;
}

const defaultAppContextAttributes: AppContextAttributes = {
  user: undefined,
  appBootstraped: false,
  requestRebootstrap: () => Promise.resolve(),
  isConnected: false,
  selectedPermission: null,
  selectedEntityType: null,
  deletePermission: () => {},
  deleteEntityType: () => {},
  choosePermission: () => {},
  chooseEntityType: () => {},
  shouldDisplayOnBoarding: false,
  setShouldDisplayOnBoarding: () => {},
  selectedEntity: null,
  entitySelection: null,
  isAllowedTo: () => false,
};

export const AppContext = createContext<AppContextAttributes>(
  defaultAppContextAttributes,
);

/**
 * To use anywhere
 */
export const useAppContext = () => useContext(AppContext);

export type ConnectedUserContextAttributes = EnsureDefined<
  AppContextAttributes,
  'user'
>;

/**
 * To use when the user is logged in
 */
export const useConnectedUserContext = (): ConnectedUserContextAttributes => {
  const userContextValue = useAppContext();
  return ensureFieldsAreDefined(userContextValue, ['user']);
};

export type LoggedInAppContextAttributes = ConnectedUserContextAttributes &
  EnsureDefined<
    AppContextAttributes,
    | 'selectedEntity'
    | 'selectedPermission'
    | 'selectedEntityType'
    | 'entitySelection'
  >;

/**
 * To use when the use is logged in and has selection a permission and entity type
 */
export const useLoggedInAppContext = (): LoggedInAppContextAttributes => {
  const userContextValue = useAppContext();
  return ensureFieldsAreDefined(userContextValue, [
    'user',
    'selectedEntity',
    'selectedPermission',
    'selectedEntityType',
    'entitySelection',
  ]);
};

interface Props {
  children: React.ReactElement;
}

const AppBootstrapper: React.FC<Props> = ({ children }) => {
  const { appBootstraped } = useAppContext();
  return appBootstraped ? children : <Loader size="large" />;
};

const AppProvider: React.FC<Props> = ({ children }) => {
  // logger.info('AppProvider', appProviderValue);
  return (
    <UserContextProvider>
      <AppBootstrapper>{children}</AppBootstrapper>
    </UserContextProvider>
  );
};

export default AppProvider;
