import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const List: React.FC<{ className?: string; color?: string }> = ({
  children,
  className,
  color,
}) => {
  return (
    <ul
      style={{ '--color': color } as React.CSSProperties}
      className={classnames(styles.list, className, {
        [styles.color]: !!color,
      })}
    >
      {children}
    </ul>
  );
};

export const Item: React.FC = ({ children }) => {
  return <li className={styles.item}>{children}</li>;
};

export default List;
