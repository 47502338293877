import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';

interface ModalAddCampaignSuccessProps {
  isVisible: boolean;
  handleClose: () => void;
  handleCancel: () => void;
  campaignUrl?: string;
}

const ModalAddCampaignSuccess: React.FC<ModalAddCampaignSuccessProps> = ({
  isVisible,
  handleClose,
  handleCancel,
  campaignUrl = '',
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('campaigns.catalog.modalSuccess.title')}
      open={isVisible}
      onCancel={handleCancel}
      onOk={handleClose}
      okText={t('campaigns.catalog.modalSuccess.cta')}
      okButtonProps={{
        id: 'see-my-campaign',
      }}
    >
      <Space direction="vertical" align="center">
        <Typography.Paragraph center>
          {t('campaigns.catalog.modalSuccess.description')}
        </Typography.Paragraph>
        <Typography.Text copyable={{ text: campaignUrl }}>
          <a href={campaignUrl} target="_blank" rel="noreferrer">
            {campaignUrl}
          </a>
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default ModalAddCampaignSuccess;
