import { SorterResult } from 'antd/es/table/interface';
import { Order_By } from 'generated/graphql';
import { Key } from 'react';

export enum SorterOrder {
  Ascend = 'ascend',
  Descend = 'descend',
}

export interface SortedInfo {
  order: string | undefined;
  columnKey: Key | undefined;
}

const mapSorterOrderToOrderBy = {
  [SorterOrder.Ascend]: Order_By.AscNullsFirst,
  [SorterOrder.Descend]: Order_By.DescNullsLast,
};

export function handleSortChange<T, S>(
  sorter: SorterResult<T>,
  buildItemOrderBy: (
    columnKey: Key | undefined,
    order: Order_By,
  ) => S | undefined,
): S | S[] {
  const defaultSort = {
    createdAt: Order_By.Desc,
    id: Order_By.Asc,
  } as unknown as S;

  if (!sorter.order) {
    return defaultSort;
  }
  const builtItemOrderBy = buildItemOrderBy(
    sorter.columnKey,
    mapSorterOrderToOrderBy[sorter.order],
  );

  if (!builtItemOrderBy) {
    return defaultSort;
  }

  return [builtItemOrderBy, defaultSort];
}
