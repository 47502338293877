import { Form as AntdForm } from 'antd';
import Routes, { actionStrategyDetailsRoute } from 'business/router/routes';
import SubPageLayout from 'business/user/layout/subPage';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useActionQuery,
  ActionFragment,
  useUpdateActionMutation,
  useRemoveActionIndicatorLinkMutation,
} from 'generated/graphql';
import Button from 'ui/button';
import Space from 'ui/space';
import Typography from 'ui/typography';
import * as yup from 'yup';
import ActionForm from 'business/actions/components/actionForm';
import {
  ActionCost,
  ActionImpact,
  ActionStatus,
  FormikAction,
} from 'business/actions/types';
import Loader from 'ui/loader';
import navBack from 'business/router/navBack';
import AddMissingIndicatorsModal, {
  AddMissingIndicatorsModalContext,
} from 'business/indicators/components/addMissingIndicatorsModal';
import { MissingIndicator } from 'business/indicators/types';
import {
  getNotInstanciedIndicatorTemplates,
  restoreActionToStrategy,
} from 'business/actions/services';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import * as actionValidation from '../../../services/validation';

interface ActionEditRouteParams {
  id: string;
}

interface EditActionFormContainerProps {
  action: ActionFragment;
}

const EditActionFormContainer: React.FC<EditActionFormContainerProps> = ({
  action,
}) => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const [
    isAddMissingIndicatorsModalVisible,
    setIsAddMissingIndicatorsModalVisible,
  ] = useState(false);

  const [updateAction] = useUpdateActionMutation();
  const [removeActionIndicatorLink] = useRemoveActionIndicatorLinkMutation();

  const [missingIndicators, setMissingIndicators] = useState<
    MissingIndicator[]
  >([]);

  const handleUpdateAction = async (formik: FormikAction) => {
    await updateAction({
      variables: {
        id: action.id,
        label: formik.values.label,
        description: formik.values.description,
        comment: formik.values.comment,
        status: formik.values.status,
        dueDate: formik.values.dueDate,
        cost: formik.values.cost,
        impact: formik.values.impact,
        responsibleId: formik.values.responsibleId,
      },
    });
    const newIndicatorLinkTemplate = formik.values.linkedIndicators.map(
      (linkedIndicator) => linkedIndicator.id,
    );
    const indicatorTemplateToRemove = action.actionIndicators
      .filter(
        (actionIndicator) =>
          newIndicatorLinkTemplate.indexOf(
            actionIndicator.indicator.indicatorTemplate.id,
          ) === -1,
      )
      .map((actionIndicator) => actionIndicator.indicator.indicatorTemplate.id);
    await removeActionIndicatorLink({
      variables: {
        id: action.id,
        indicatorTemplateToRemove,
      },
    });
    // We use the restoration action to strategy because it do the thing we want :
    // Create indicator and link them to our action
    await restoreActionToStrategy(
      action.id,
      formik.values.linkedIndicators,
      entityId,
    );
    navBack(actionStrategyDetailsRoute(action.id));
  };

  const formik = useFormik<FormikAction['values']>({
    initialValues: {
      label: action.label,
      description: action.description,
      comment: action.comment !== null ? action.comment : undefined,
      status: action.status as ActionStatus,
      dueDate: action.dueDate !== null ? new Date(action.dueDate) : undefined,
      cost: action.cost !== null ? (action.cost as ActionCost) : undefined,
      impact:
        action.impact !== null ? (action.impact as ActionImpact) : undefined,
      linkedIndicators: action.actionIndicators.map(
        (actionIndicator) => actionIndicator.indicator.indicatorTemplate,
      ),
      responsibleId: action.responsible?.id,
    },
    validationSchema: yup.object().shape({
      label: actionValidation.label,
      description: actionValidation.description,
      comment: actionValidation.comment,
      status: actionValidation.status,
      dueDate: actionValidation.dueDate,
      cost: actionValidation.cost,
      impact: actionValidation.impact,
      linkedIndicators: actionValidation.linkedIndicators,
    }),
    onSubmit: async (values) => {
      const notInstanciedIndicatorTemplates =
        getNotInstanciedIndicatorTemplates(values.linkedIndicators);

      setMissingIndicators(notInstanciedIndicatorTemplates);

      if (notInstanciedIndicatorTemplates.length !== 0) {
        setIsAddMissingIndicatorsModalVisible(true);
      } else {
        await handleUpdateAction(formik);
      }
    },
  });

  return (
    <SubPageLayout goBack={Routes.AddActionStrategy}>
      <AntdForm onFinish={formik.handleSubmit}>
        <Space direction="vertical">
          <Space justify="space-between">
            <Typography.Title level={2}>{t('common.modify')}</Typography.Title>
            <Button
              data-test-id="edit-action"
              type="primary"
              onClick={() => formik.handleSubmit()}
            >
              {t('common.save')}
            </Button>
          </Space>
          <ActionForm
            formik={formik}
            // We display the template link selector if there's no actionTemplate
            // This means it is a custom action and therefore the user can edit the
            // indicator links.
            shouldDisplayIndicatorTemplateLinkSelector={!action.actionTemplate}
          />
        </Space>
      </AntdForm>
      <AddMissingIndicatorsModal
        missingIndicators={missingIndicators}
        handleSubmit={() => handleUpdateAction(formik)}
        context={AddMissingIndicatorsModalContext.Actions}
        isVisible={isAddMissingIndicatorsModalVisible}
        handleClose={() => setIsAddMissingIndicatorsModalVisible(false)}
        editable={false}
      />
    </SubPageLayout>
  );
};

const EditActionPage: React.FC = () => {
  const { id } = useParams<ActionEditRouteParams>();
  const { data } = useActionQuery({
    variables: {
      id,
    },
  });

  const action = data?.action_by_pk;

  if (!action) {
    return (
      <SubPageLayout goBack={Routes.ActionsStrategy}>
        <Loader />
      </SubPageLayout>
    );
  }

  return <EditActionFormContainer action={action} />;
};

export default EditActionPage;
