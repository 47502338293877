import classnames from 'classnames';
import React from 'react';
import { ReactDiv } from 'technical/type';
import styles from './index.module.scss';

export interface CardProps extends ReactDiv {
  className?: string;
  block?: boolean;
}

const Card: React.FC<CardProps> = ({
  className,
  children,
  block = false,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classnames(styles.card, className, {
        [styles.block]: block,
        [styles.clickable]: !!rest.onClick,
      })}
    >
      {children}
    </div>
  );
};

export default Card;
