import { useLoggedInAppContext } from 'business/AppBootstrapper';
import {
  identitySheetColumns,
  identitySheetDataSource,
} from 'business/myEntity/services/table';
import * as yup from 'yup';
import ConnectedLayout from 'business/user/layout/connected';
import { Permission } from 'business/user/types/user';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Space from 'ui/space';
import Table from 'ui/table';
import Typography from 'ui/typography';
import { IdentitySheetRow } from 'business/myEntity/types';
import {
  addressFieldsKeys,
  getValidationSchemaForKey,
  useGetEntityInformations,
} from 'business/myEntity/services';
import { useUpdateEntityAddressMutation } from 'generated/graphql';

const IdentitySheetPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    isAllowedTo,
    selectedEntity: { entityId, entityName, entityType },
  } = useLoggedInAppContext();
  const [editingKey, setEditingKey] = useState<string | null>(null);

  const [updateAddressPayload] = useUpdateEntityAddressMutation();

  const {
    address,
    numberOfChildrenEntities,
    parentEntityName,
    entityPayload,
    refetch,
    updateEntityPayload,
  } = useGetEntityInformations(entityId, entityType);

  const formik = useFormik<{
    value: string | number | null | undefined;
  }>({
    initialValues: { value: undefined },
    validationSchema: yup
      .object({
        value: getValidationSchemaForKey(editingKey),
      })
      .required(),
    onSubmit: async () => {
      try {
        if (!editingKey) {
          throw new Error(
            "[SUBMIT FORM] Form can't be submitted with null editingKey value",
          );
        }
        if (addressFieldsKeys.includes(editingKey)) {
          await updateAddressPayload({
            variables: {
              entityId,
              input: {
                [editingKey]: formik.values.value,
              },
            },
          });
        } else {
          await updateEntityPayload({
            variables: {
              entityId,
              input: {
                [editingKey]: formik.values.value,
              },
            },
          });
        }
        await refetch();
        setEditingKey(null);
      } catch (err) {
        formik.setFieldError('value', t('errors.generic'));
      }
    },
  });

  const canEdit = isAllowedTo(Permission.EntityUpdate);
  const isEditing = (record: IdentitySheetRow) => record.key === editingKey;
  const edit = (record: IdentitySheetRow) => {
    formik.setFieldValue('value', record.value);
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey(null);
  };

  const save = async () => {
    await formik.handleSubmit();
  };

  const remove = async (record: IdentitySheetRow) => {
    if (addressFieldsKeys.includes(record.key)) {
      await updateAddressPayload({
        variables: {
          entityId,
          input: {
            [record.key]: null,
          },
        },
      });
    } else {
      await updateEntityPayload({
        variables: {
          entityId,
          input: {
            [record.key]: null,
          },
        },
      });
    }
    refetch();
  };

  return (
    <ConnectedLayout showEntityBreadcrumbs>
      <Space direction="vertical" size="middle">
        <Space justify="space-between">
          <Typography.Title level={3} data-test-id="identity-card-title">
            {t('my-entity.identity-card.title')}
          </Typography.Title>
        </Space>
        <Typography.Paragraph>
          {t('my-entity.identity-card.description')}
        </Typography.Paragraph>
      </Space>
      <Table
        data-test-id="indicator-values-table"
        dataSource={identitySheetDataSource(
          entityPayload,
          address,
          entityName,
          parentEntityName,
          numberOfChildrenEntities,
          entityType,
          t,
        )}
        columns={identitySheetColumns({
          canEdit,
          isEditing,
          save,
          edit,
          cancel,
          remove,
          formik,
          t,
        })}
      />
    </ConnectedLayout>
  );
};

export default IdentitySheetPage;
