import NotConnectedCard from 'business/user/components/notConnectedCard';
import AuthLayout from 'business/user/layout/auth';
import React from 'react';
import AppLogo from 'ui/appLogo';
import { Form as AntdForm, Input } from 'antd';
import Space from 'ui/space';
import Typography from 'ui/typography';
import { useTranslation } from 'react-i18next';
import Form from 'ui/form';
import { formikStatus, formikErrorMessage } from 'technical/form';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { email } from 'business/user/validations/string';
import Button from 'ui/button';
import logger from 'technical/logger';
import config from 'config';
import Routes from 'business/router/routes';
import { Link } from 'react-router-dom';

const SsoSignInPage: React.FC = () => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { email: '' },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      email: email(t),
    }),
    onSubmit: async (values) => {
      if (config.sso.url) {
        // eslint-disable-next-line no-restricted-globals
        location.href = `${config.sso.url}?email=${values.email}`;
      } else {
        logger.error('[LOGIN ERROR] NO SSO URL WAS FOUND');
      }
    },
  });

  return (
    <AuthLayout>
      <NotConnectedCard>
        <AntdForm onFinish={formik.handleSubmit}>
          <Space direction="vertical" align="center">
            <AppLogo />
            <Typography.Title level={3}>
              {t('notConnected.login.sso.title')}
            </Typography.Title>
            <Typography.Paragraph>
              {t('notConnected.login.sso.description')}
            </Typography.Paragraph>
            <Form.Item
              label={t('notConnected.login.sso.email')}
              validateStatus={formikStatus(formik, 'email')}
              help={formikErrorMessage(formik, 'email')}
            >
              <Input
                name="email"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
            <Button
              disabled={!formik.isValid}
              onClick={() => {
                formik.handleSubmit();
              }}
              htmlType="submit"
              data-test-id="sso-cta"
            >
              {t('notConnected.login.sso.cta')}
            </Button>
            <Link to={Routes.SignIn}>
              <Button type="link">{t('notConnected.login.sso.goBack')}</Button>
            </Link>
          </Space>
        </AntdForm>
      </NotConnectedCard>
    </AuthLayout>
  );
};

export default SsoSignInPage;
