import React from 'react';
import Card from 'ui/card';
import Statistic from 'ui/statistic';
import styles from './index.module.scss';

interface CardWithStatisticProps {
  label: string;
  value: number | null;
  isLoading: boolean;
}

const CardWithStatistic: React.FC<CardWithStatisticProps> = ({
  label,
  value,
  isLoading,
}) => {
  return (
    <Card className={styles.card}>
      <Statistic label={label} value={value} isLoading={isLoading} />
    </Card>
  );
};

export default CardWithStatistic;
