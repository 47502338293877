import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AppLogo from 'ui/appLogo';
import Button from 'ui/button';
import Card from 'ui/card';
import images from 'ui/images';
import Space from 'ui/space';
import Typography from 'ui/typography';
import ExternalLink from 'ui/externalLink';
import Routes from 'business/router/routes';
import { Link } from 'react-router-dom';
import Hat from 'business/documentation/component/hat';
import DocumentationLayout from 'business/documentation/layout/documentationLayout';
import styles from './index.module.scss';

const CsrDocumentationPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DocumentationLayout>
      <Space direction="vertical">
        <Typography.Title center>
          {t('documentation.csr.title')}
        </Typography.Title>
        <Hat>{t('documentation.csr.introduction')}</Hat>
        <Space direction="vertical">
          <Typography.Title level={3}>
            {t('documentation.csr.whatIsIt.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.csr.whatIsIt.text')}
          </Typography.Paragraph>
          <Typography.Paragraph center>
            <Typography.Text strong className={styles.quote}>
              {t('documentation.csr.whatIsIt.quote')}
            </Typography.Text>
            <ExternalLink to={t('documentation.csr.whatIsIt.link')}>
              {t('documentation.csr.whatIsIt.study')}
            </ExternalLink>
          </Typography.Paragraph>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={3}>
            {t('documentation.csr.why.title')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('documentation.csr.why.text')}
          </Typography.Paragraph>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={3}>
            {t('documentation.csr.who.title')}
          </Typography.Title>
          <img
            src={images.documentation.mandatory}
            alt={t('documentation.csr.who.alt')}
          />
          <Typography.Paragraph>
            {t('documentation.csr.who.text')}
          </Typography.Paragraph>
        </Space>
        <Space justify="center">
          <Link to={Routes.CaliDocumentation}>
            <Button type="primary">
              <Space className={styles.insideBtn}>
                {t('documentation.csr.who.cta')}
                <AppLogo mode="dark" />
              </Space>
            </Button>
          </Link>
        </Space>
        <Card>
          <Space direction="vertical" className={styles.knowMoreContainer}>
            <Typography.Title level={3}>
              {t('documentation.csr.know-more.title')}
            </Typography.Title>
            <Space direction="vertical" size="small">
              <Typography.Paragraph className={styles.paragraph}>
                <Trans t={t} i18nKey="documentation.csr.know-more.text.one">
                  Les&nbsp;
                  <ExternalLink to="https://www.un.org/sustainabledevelopment/fr/">
                    17 objectifs de développement durable
                  </ExternalLink>
                  &nbsp;(ODD) mis en place par l&apos;ONU, souvent reconnus
                  comme un des socles de la RSE.
                </Trans>
              </Typography.Paragraph>
              <Typography.Paragraph className={styles.paragraph}>
                <Trans t={t} i18nKey="documentation.csr.know-more.text.two">
                  La&nbsp;
                  <ExternalLink to="https://www.globalreporting.org/">
                    Global reportiing initiative
                  </ExternalLink>
                  &nbsp;(GRI), standard international d&apos;indicateurs communs
                  couvrant les trois volets (Economique, Social,
                  Environnemental).
                </Trans>
              </Typography.Paragraph>
              <Typography.Paragraph className={styles.paragraph}>
                <Trans t={t} i18nKey="documentation.csr.know-more.text.three">
                  L&apos;
                  <ExternalLink to="https://www.iso.org/fr/iso-26000-social-responsibility.html">
                    ISO 26000
                  </ExternalLink>
                  &nbsp;qui constitue le princiapal guide de responsabilité
                  sociétale des entreprises. Elle n&apos;est pas orientée
                  &quot;produit&quot; mais s&apos;attache à la façon de le faire
                  et ce que cela induit.
                </Trans>
              </Typography.Paragraph>
              <Typography.Paragraph className={styles.paragraph}>
                <Trans t={t} i18nKey="documentation.csr.know-more.text.four">
                  Le&nbsp;
                  <ExternalLink to="https://www.ipcc.ch/report/ar6/wg1/">
                    6ème rapport du GIEC
                  </ExternalLink>
                  &nbsp;,groupe d&apos;experts intergouvernemental de référence
                  sur l&apos;évolution du climat, en anglais (2021)
                </Trans>
              </Typography.Paragraph>
              <Typography.Paragraph className={styles.paragraph}>
                <Trans t={t} i18nKey="documentation.csr.know-more.text.five">
                  Le&nbsp;
                  <ExternalLink to="https://www.strategie.gouv.fr/sites/strategie.gouv.fr/files/atoms/files/txtreference-rse.qxd_.pdf">
                    texte de référence
                  </ExternalLink>
                  &nbsp;sur la responsabilité sociétale des entreprises partagé
                  par les membres de la Plateforme RSE (2014)
                </Trans>
              </Typography.Paragraph>
              <Typography.Paragraph className={styles.paragraph}>
                <Trans t={t} i18nKey="documentation.csr.know-more.text.six">
                  Notre page de&nbsp;
                  <Link to={Routes.CsrCertificationAndLabelsDocumentation}>
                    décryptage des certifications & labels RSE
                  </Link>
                </Trans>
              </Typography.Paragraph>
            </Space>
          </Space>
        </Card>
      </Space>
    </DocumentationLayout>
  );
};

export default CsrDocumentationPage;
