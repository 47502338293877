import React from 'react';
import { ReactDiv } from 'technical/type';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface RichTextContainerProps extends ReactDiv {
  content?: string | null;
}

const RichTextContainer: React.FC<RichTextContainerProps> = ({
  content,
  onClick,
  ...rest
}) => {
  return (
    <Typography.Paragraph className={styles.richTextContainer} {...rest}>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: content || '' }} />
    </Typography.Paragraph>
  );
};

export default RichTextContainer;
