import React from 'react';
import Typography from 'ui/typography';
import styles from './index.module.scss';

const Hat: React.FC = ({ children }) => {
  return (
    <Typography.Paragraph emphase className={styles.hat}>
      {children}
    </Typography.Paragraph>
  );
};

export default Hat;
