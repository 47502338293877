import React from 'react';
import ActionsMenu from 'business/actions/components/actionsMenu';
import InformationDetails from 'business/common/components/informationDetails';
import Routes, { actionStrategyEditRoute } from 'business/router/routes';
import ThemeAndStakesDetails from 'business/common/components/themeAndStakesDetails';
import LinkedIndicatorsTable from 'business/indicators/components/linkedIndicatorsTable';
import { getThemes } from 'business/stake/services';
import SubPageLayout from 'business/user/layout/subPage';
import { Permission } from 'business/user/types/user';
import { useActionQuery, useHideActionsMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { formatDate } from 'technical/date/formatter';
import Button from 'ui/button';
import Loader from 'ui/loader';
import Modal from 'ui/modal';
import RichTextContainer from 'ui/richTextContainer';
import Space from 'ui/space';
import Typography from 'ui/typography';
import LinkedIndicatorTemplatesTable from 'business/indicators/components/linkedIndicatorTemplatesTable';
import Restricted from 'business/user/components/Restricted';
import StatusTagSelect from 'business/actions/components/statusTagSelect';
import { getActionStakes, getAllLinkedIndicators } from '../../../services';

interface ActionDetailsRouteParams {
  id: string;
}

const ActionStrategyDetailsPage: React.FC = () => {
  const { id } = useParams<ActionDetailsRouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const [hideAction] = useHideActionsMutation();

  const { data, refetch } = useActionQuery({
    variables: {
      id,
    },
  });

  const action = data?.action_by_pk;

  if (!action) {
    return (
      <SubPageLayout goBack={Routes.ActionsStrategy}>
        <Loader />
      </SubPageLayout>
    );
  }

  const stakes = getActionStakes(action);
  const themes = getThemes(stakes);
  const [presentLinkedIndicators, absentLinkedIndicators] =
    getAllLinkedIndicators(action);

  const removeAction = async () => {
    // Delete action
    await hideAction({
      variables: {
        ids: [action.id],
      },
    });
    // Redirect to my actions
    history.push(Routes.ActionsStrategy);
  };

  const handleDeleteCta = () => {
    Modal.confirm({
      title: t('actions.strategy.delete.confirmTitle'),
      content: (
        <Typography.Paragraph>
          {t('actions.strategy.delete.confirmContent')}
        </Typography.Paragraph>
      ),
      okText: t('common.delete'),
      cancelText: t('common.cancel'),
      onOk: removeAction,
      okButtonProps: {
        id: 'delete-action',
      },
    });
  };

  return (
    <SubPageLayout goBack={Routes.ActionsStrategy}>
      <Space direction="vertical" size="large">
        <Space justify="space-between" align="start">
          <Typography.Title data-test-id="action-name" level={3}>
            {action.label}
          </Typography.Title>
          <Space justify="center" align="center">
            <Restricted permission={Permission.ActionUpdate}>
              <Link to={actionStrategyEditRoute(id)}>
                <Button data-test-id="edit-action-cta" type="primary">
                  {t('common.modify')}
                </Button>
              </Link>
            </Restricted>
            <Restricted permission={Permission.ActionDelete}>
              <ActionsMenu handleDeleteCta={handleDeleteCta} />
            </Restricted>
          </Space>
        </Space>
        <Space>
          <InformationDetails
            orientation="horizontal"
            title={t('actions.details.status')}
          >
            <StatusTagSelect action={action} onChange={refetch} />
          </InformationDetails>
          <InformationDetails
            orientation="horizontal"
            title={t('actions.details.cost')}
          >
            <Typography.Paragraph>
              {t(`actions.cost.${action.cost}`)}
            </Typography.Paragraph>
          </InformationDetails>
          <InformationDetails
            orientation="horizontal"
            title={t('actions.details.impact')}
          >
            <Typography.Paragraph>
              {t(`actions.impact.${action.impact}`)}
            </Typography.Paragraph>
          </InformationDetails>
        </Space>
        <InformationDetails title={t('actions.details.description')}>
          <RichTextContainer content={action.description} />
        </InformationDetails>
        <InformationDetails title={t('actions.details.responsible')}>
          <Typography.Paragraph>
            {action.responsible
              ? `${action.responsible.firstName ?? ''} ${
                  action.responsible.lastName ?? ''
                }`
              : '--'}
          </Typography.Paragraph>
        </InformationDetails>
        <InformationDetails title={t('actions.details.comment')}>
          <Typography.Paragraph>{action.comment || '--'}</Typography.Paragraph>
        </InformationDetails>
        {action.dueDate !== null && (
          <InformationDetails title={t('actions.details.dueDate')}>
            <Typography.Paragraph>
              {formatDate(action.dueDate)}
            </Typography.Paragraph>
          </InformationDetails>
        )}
        {presentLinkedIndicators.length ? (
          <InformationDetails
            title={t('actions.details.associatedIndicators.present')}
          >
            <LinkedIndicatorsTable indicators={presentLinkedIndicators} />
          </InformationDetails>
        ) : null}
        {absentLinkedIndicators.length ? (
          <InformationDetails
            title={t('actions.details.associatedIndicators.absent')}
          >
            <LinkedIndicatorTemplatesTable
              indicatorTemplates={absentLinkedIndicators}
            />
          </InformationDetails>
        ) : null}
        <ThemeAndStakesDetails themes={themes} stakes={stakes} />
      </Space>
    </SubPageLayout>
  );
};

export default ActionStrategyDetailsPage;
