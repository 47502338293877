import { PieChartOutlined, BarChartOutlined } from '@ant-design/icons';
import React from 'react';
import Space from 'ui/space';
import { IndicatorType } from 'business/indicators/types';
import { Mode } from 'business/indicators/services/type';
import styles from './index.module.scss';

interface NoRemarkableValueIconProps {
  mode: Mode;
  indicatorType: IndicatorType;
}
const NoRemarkableValueIcon: React.FC<NoRemarkableValueIconProps> = ({
  mode,
  indicatorType,
}) => {
  return (
    <Space direction={mode} size="extra-small">
      {indicatorType === IndicatorType.Repartition ? (
        <PieChartOutlined className={styles.graphIcon} />
      ) : (
        <BarChartOutlined className={styles.graphIcon} />
      )}
    </Space>
  );
};

export default NoRemarkableValueIcon;
