import DocumentationContainer from 'business/documentation/component/documentationContainer';
import ConnectedLayout from 'business/user/layout/connected';
import React from 'react';

const DocumentationLayout: React.FC = ({ children }) => {
  return (
    <ConnectedLayout isPaddingActive={false}>
      <DocumentationContainer>{children}</DocumentationContainer>
    </ConnectedLayout>
  );
};

export default DocumentationLayout;
