import { recursivelyGenerateIndicator } from 'business/indicators/services';
import {
  LinkedIndicator,
  LinkedIndicatorTemplate,
  MissingIndicator,
} from 'business/indicators/types';
import {
  InsertActionDocument,
  InsertActionMutation,
  InsertActionMutationVariables,
  ActionFragment,
  ActionTemplateDetailsFragment,
  ActionTemplateFragment,
  IndicatorTemplate,
  Indicator,
  ActionIndicator_Arr_Rel_Insert_Input,
  ActionIndicator_Constraint,
  ActionIndicator_Update_Column,
  RestoreActionMutation,
  RestoreActionMutationVariables,
  RestoreActionDocument,
  InsertActionIndicatorMutationVariables,
  ExportActionsXlsxDocument,
  ExportActionsXlsxQuery,
  ExportActionsXlsxQueryVariables,
} from 'generated/graphql';

import client from 'technical/graphql/client';
import { downloadXlsxFileFromBinaryString } from 'technical/file';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { ActionCost, ActionImpact, ActionStatus } from '../types';
import {
  InsertActionIndicatorDocument,
  InsertActionIndicatorMutation,
} from '../../../generated/graphql';

export const getActionTemplateStakes = (
  actionTemplate: ActionTemplateFragment | ActionTemplateDetailsFragment,
) => {
  return [
    ...new Map(
      actionTemplate.actionTemplateIndicatorTemplates.flatMap(
        (actionTemplateIndicatorTemplate) =>
          actionTemplateIndicatorTemplate.indicatorTemplate.indicatorTemplateStakes.map(
            (indicatorTemplateStake) => [
              indicatorTemplateStake.stake.id,
              indicatorTemplateStake.stake,
            ],
          ),
      ),
    ).values(),
  ];
};

export const getActionStakes = (action: ActionFragment) => {
  return [
    ...new Map(
      action.actionIndicators.flatMap((actionIndicator) =>
        actionIndicator.indicator.indicatorTemplate.indicatorTemplateStakes.map(
          (indicatorTemplateStake) => [
            indicatorTemplateStake.stake.id,
            indicatorTemplateStake.stake,
          ],
        ),
      ),
    ).values(),
  ];
};

export const getAssociatedIndicatorTemplates = (
  actionTemplate: ActionTemplateFragment | ActionTemplateDetailsFragment,
) => {
  return actionTemplate.actionTemplateIndicatorTemplates.map(
    (actionTemplateIndicatorTemplate) =>
      actionTemplateIndicatorTemplate.indicatorTemplate,
  );
};

export const getNbAssociatedIndicatorTemplates = (
  actionTemplate: ActionTemplateFragment | ActionTemplateDetailsFragment,
) => {
  return getAssociatedIndicatorTemplates(actionTemplate).length;
};

export const getAssociatedIndicators = (action: ActionFragment) => {
  return action.actionIndicators.map(
    (actionIndicator) => actionIndicator.indicator,
  );
};

export const getNbAssociatedIndicators = (action: ActionFragment) => {
  return getAssociatedIndicators(action).length;
};

export const getAlreadyInstanciedIndicatorTemplates = (
  indicatorTemplates: Array<
    Pick<IndicatorTemplate, 'id'> & {
      indicators: Pick<Indicator, 'id' | 'entityId'>[];
    }
  >,
) => {
  return indicatorTemplates.filter(
    (indicatorTemplate) => indicatorTemplate.indicators.length !== 0,
  );
};

export const getNotInstanciedIndicatorTemplates = (
  indicatorTemplates: Array<
    Pick<IndicatorTemplate, 'id' | 'label'> & {
      indicators: Pick<Indicator, 'id'>[];
      indicatorTemplateLinks: Array<{
        indicatorTemplate: Pick<IndicatorTemplate, 'id' | 'label'> & {
          indicatorTemplateLinks?: Array<{
            indicatorTemplate: Pick<IndicatorTemplate, 'id' | 'label'>;
          }>;
        };
      }>;
    }
  >,
): MissingIndicator[] => {
  return indicatorTemplates
    .filter((indicatorTemplate) => indicatorTemplate.indicators.length === 0)
    .map((indicatorTemplate) => ({
      id: indicatorTemplate.id,
      label: indicatorTemplate.label,
      linkedIndicators: indicatorTemplate.indicatorTemplateLinks.map(
        (indicatorTemplateLink) => ({
          id: indicatorTemplateLink.indicatorTemplate.id,
          label: indicatorTemplateLink.indicatorTemplate.label,
          linkedIndicators:
            indicatorTemplateLink.indicatorTemplate.indicatorTemplateLinks?.map(
              (indicatorTemplateLinkedLink) => ({
                id: indicatorTemplateLinkedLink.indicatorTemplate.id,
                label: indicatorTemplateLinkedLink.indicatorTemplate.label,
              }),
            ),
        }),
      ),
    }));
};

const getActionIndicators = (
  indicatorTemplates: MissingIndicator[],
  entityId: string,
): ActionIndicator_Arr_Rel_Insert_Input => ({
  data: recursivelyGenerateIndicator(indicatorTemplates, entityId),
  on_conflict: {
    constraint: ActionIndicator_Constraint.ActionIndicatorPkey,
    update_columns: [ActionIndicator_Update_Column.UpdatedAt],
  },
});

export const addActionToStrategy = async (
  actionTemplate: ActionTemplateDetailsFragment,
  indicatorTemplates: MissingIndicator[],
  entityId: string,
) => {
  return client.mutate<InsertActionMutation, InsertActionMutationVariables>({
    mutation: InsertActionDocument,
    variables: {
      action: {
        label: actionTemplate.label,
        description: actionTemplate.description,
        cost: actionTemplate.cost,
        impact: actionTemplate.impact,
        status: ActionStatus.Incoming,
        dueDate: null,
        actionTemplateId: actionTemplate.id,
        actionIndicators: getActionIndicators(indicatorTemplates, entityId),
      },
    },
  });
};

export const restoreActionToStrategy = async (
  actionId: string,
  indicatorTemplates: MissingIndicator[],
  entityId: string,
) => {
  client.mutate<RestoreActionMutation, RestoreActionMutationVariables>({
    mutation: RestoreActionDocument,
    variables: {
      actionId,
    },
  });
  client.mutate<
    InsertActionIndicatorMutation,
    InsertActionIndicatorMutationVariables
  >({
    mutation: InsertActionIndicatorDocument,
    variables: {
      actionIndicators: recursivelyGenerateIndicator(
        indicatorTemplates,
        entityId,
        actionId,
      ),
      on_conflict: {
        constraint: ActionIndicator_Constraint.ActionIndicatorPkey,
        update_columns: [ActionIndicator_Update_Column.UpdatedAt],
      },
    },
  });
};

export const createActionToStrategy = async (
  label: string,
  description: string,
  status: ActionStatus,
  indicatorTemplates: MissingIndicator[],
  entityId: string,
  dueDate?: Date,
  comment?: string,
  cost?: ActionCost,
  impact?: ActionImpact,
  responsibleId?: string,
) =>
  client.mutate<InsertActionMutation, InsertActionMutationVariables>({
    mutation: InsertActionDocument,
    variables: {
      action: {
        label,
        description,
        comment,
        cost,
        status,
        dueDate,
        impact,
        actionIndicators: getActionIndicators(indicatorTemplates, entityId),
        responsibleId,
      },
    },
  });

export const getAllLinkedIndicators = (
  action: ActionFragment,
): [LinkedIndicator[], LinkedIndicatorTemplate[]] => {
  const associatedIndicators = getAssociatedIndicators(action);

  if (!action.actionTemplate) {
    return [associatedIndicators, []];
  }

  const missingIndicators =
    action.actionTemplate.actionTemplateIndicatorTemplates
      .map(
        (actionTemplateIndicatorTemplate) =>
          actionTemplateIndicatorTemplate.indicatorTemplate,
      )
      .filter(
        (indicatorTemplate) =>
          !associatedIndicators.find(
            (presentIndicator) =>
              presentIndicator.indicatorTemplate.id === indicatorTemplate.id,
          ),
      );

  return [associatedIndicators, missingIndicators];
};

export const exportActionsXlsx = async (
  entityId: string,
  entityName: string,
) => {
  const { data } = await client.query<
    ExportActionsXlsxQuery,
    ExportActionsXlsxQueryVariables
  >({
    query: ExportActionsXlsxDocument,
    variables: { entityId },
  });

  return downloadXlsxFileFromBinaryString(
    `${entityName}-actions.xlsx`,
    data.exportActions?.data,
  );
};
