import { useLoggedInAppContext } from 'business/AppBootstrapper';
import CampaignCard from 'business/campaigns/components/campaignCard';
import { useStrategyCampaignListFilters } from 'business/campaigns/services/table';
import TableFilters from 'business/common/components/tableFilters';
import Routes from 'business/router/routes';
import Restricted from 'business/user/components/Restricted';
import ConnectedLayout from 'business/user/layout/connected';
import { Permission } from 'business/user/types/user';
import { useCampaignListQuery } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'ui/button';
import EmptyState from 'ui/emptyState';
import Space from 'ui/space';
import Typography from 'ui/typography';

const CampaignsStrategyPage: React.FC = () => {
  const { t } = useTranslation();

  const { isAllowedTo } = useLoggedInAppContext();

  const { filtersValues, debouncedFiltersValues, setFiltersValues, noFilters } =
    useStrategyCampaignListFilters();

  const { data, previousData } = useCampaignListQuery({
    variables: {
      condition: {
        deletedAt: { _is_null: true },
        _or: [
          {
            name: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
          {
            description: { _ilike: `%${debouncedFiltersValues.search ?? ''}%` },
          },
        ],
        status: filtersValues.campaignStatus
          ? { _eq: filtersValues.campaignStatus }
          : {},
        campaignIndicators: {
          indicator: {
            indicatorTemplate: {
              indicatorTemplateStakes: {
                stake: {
                  id: filtersValues.stakeId
                    ? { _eq: filtersValues.stakeId }
                    : {},
                  themeId: filtersValues.themeId
                    ? { _eq: filtersValues.themeId }
                    : {},
                },
              },
            },
          },
        },
      },
    },
  });

  const campaigns = data?.campaign || previousData?.campaign || [];

  return (
    <ConnectedLayout>
      <Space direction="vertical" size="middle">
        <Space justify="space-between">
          <Typography.Title level={3}>
            {t('campaigns.strategy.title')}
          </Typography.Title>
          <Restricted permission={Permission.CampaignAdd}>
            <Link to={Routes.CampaignsCatalog}>
              <Button type="primary">
                {t('campaigns.strategy.goToCatalog')}
              </Button>
            </Link>
          </Restricted>
        </Space>
        <Typography.Paragraph>
          {t('campaigns.strategy.info')}
        </Typography.Paragraph>
        <TableFilters
          filtersValue={filtersValues}
          setFilters={(newFilters) =>
            setFiltersValues({ ...newFilters, page: 1 })
          }
          searchPlaceholder={t('tableFilters.search.campaigns')}
        />
        {campaigns.length === 0 && (
          <EmptyState
            title={t(
              noFilters
                ? 'campaigns.strategy.empty.titleNoData'
                : 'campaigns.strategy.empty.titleNoResult',
            )}
            description={t('campaigns.strategy.empty.description')}
            dispose={t('campaigns.strategy.empty.dispose')}
            cta={
              isAllowedTo(Permission.CampaignAdd)
                ? {
                    label: t('campaigns.strategy.empty.cta'),
                    to: Routes.CampaignsCatalog,
                  }
                : undefined
            }
          />
        )}
        {campaigns.map((campaign) => (
          <CampaignCard key={campaign.id} campaign={campaign} />
        ))}
      </Space>
    </ConnectedLayout>
  );
};

export default CampaignsStrategyPage;
