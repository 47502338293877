import { Layout as AntdLayout } from 'antd';
import React from 'react';
import AppLogo from 'ui/appLogo';
import Nav from 'ui/nav';
import Typography from 'ui/typography';
import Space from 'ui/space';
import { useTranslation } from 'react-i18next';
import images from 'ui/images';
import classnames from 'classnames';
import styles from './index.module.scss';

const { Content } = AntdLayout;

type Mode = 'dark' | 'light';

interface LayoutProps {
  isMenuVisible: boolean;
  isPaddingActive?: boolean;
  menuFooter?: React.ReactElement;
  menuContent?: React.ReactElement;
  mode?: Mode;
}

const NavHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Space className={styles.header} justify="space-between">
      <AppLogo mode="dark" clickable />
      <Space size="extra-small" align="center">
        <Typography.Text className={styles.by} soften>
          {t('common.by').toUpperCase()}
        </Typography.Text>
        <a
          href="https://www.greenaffair.com/"
          target="_blank"
          rel="noopener norefferer noreferrer"
        >
          <img src={images.logoGreenAffair} alt={t('common.companyName')} />
        </a>
      </Space>
    </Space>
  );
};

const Layout: React.FC<LayoutProps> = ({
  isMenuVisible,
  isPaddingActive = true,
  children,
  menuFooter,
  menuContent,
  mode = 'light',
}) => {
  return (
    <AntdLayout
      className={classnames(styles.layout, { [styles.dark]: mode === 'dark' })}
    >
      {isMenuVisible ? (
        <Nav header={<NavHeader />} footer={menuFooter}>
          {menuContent}
        </Nav>
      ) : null}
      <Content
        className={classnames(styles.content, {
          [styles.isPaddingActive]: isPaddingActive,
        })}
      >
        {children}
      </Content>
    </AntdLayout>
  );
};

export default Layout;
