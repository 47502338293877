import { useAppContext } from 'business/AppBootstrapper';
import { Mode } from 'business/indicators/services/type';
import { getUnitDisplay } from 'business/indicators/services/units';
import {
  ensurePlotDataIsSimplePlotData,
  IndicatorType,
} from 'business/indicators/types';
import { Permission } from 'business/user/types/user';
import { startOfYear } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'technical/date/formatter';
import { isNotNullOrUndefined } from 'technical/type';
import Space from 'ui/space';
import Typography from 'ui/typography';
import styles from './index.module.scss';

interface KeyValueProps {
  mode: Mode;
  indicatorType: IndicatorType;
  unit: string;
  keyValue: {
    value: number;
    date: Date;
  };
}
const KeyValue: React.FC<KeyValueProps> = ({
  mode,
  indicatorType,
  unit,
  keyValue,
}) => {
  const { t } = useTranslation();
  const { isAllowedTo } = useAppContext();
  return (
    <Space
      size="extra-small"
      align="baseline"
      justify={mode === Mode.Vertical ? 'end' : 'start'}
    >
      <Typography.Text
        data-test-id="indicator-card-key-value"
        className={styles.value}
      >
        {getUnitDisplay(
          unit,
          isAllowedTo(Permission.IndicatorsAggregate),
        )(keyValue.value || 0)}
      </Typography.Text>
      <Typography.Text type="secondary">
        {indicatorType === IndicatorType.Stock
          ? t('indicators.strategy.card.dateStock', {
              date: formatDate(startOfYear(keyValue.date)),
            })
          : t('indicators.strategy.card.date', {
              date: formatDate(keyValue.date),
            })}
      </Typography.Text>
    </Space>
  );
};

interface KeyValueResolverProps {
  keyValues: any;
  mode: Mode;
  unit: string;
  indicatorType: IndicatorType;
}
const KeyValueResolver: React.FC<KeyValueResolverProps> = ({
  keyValues,
  mode,
  unit,
  indicatorType,
}) => {
  switch (true) {
    case keyValues.currentYear &&
      ensurePlotDataIsSimplePlotData(keyValues.currentYear) &&
      isNotNullOrUndefined(keyValues.currentYear.value):
      return (
        <KeyValue
          mode={mode}
          unit={unit}
          indicatorType={indicatorType}
          keyValue={keyValues.currentYear}
        />
      );
    case keyValues.lastYear &&
      ensurePlotDataIsSimplePlotData(keyValues.lastYear) &&
      isNotNullOrUndefined(keyValues.lastYear.value):
      return (
        <KeyValue
          mode={mode}
          unit={unit}
          indicatorType={indicatorType}
          keyValue={keyValues.lastYear}
        />
      );
    default:
      return <Typography.Text type="secondary">---</Typography.Text>;
  }
};

export default KeyValueResolver;
