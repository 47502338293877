import { UserPermission } from 'generated/graphql';
import { isAllowedToSelectChild } from '../permissions/permissions';
import {
  Roles,
  EntityTypes,
  UserWithPrivateInfosAndPermissions,
} from '../types/user';

import { formatPermission } from './user';

export enum PermissionType {
  USER_PERMISSION = 'userPermission',
  SUPERVISOR_IMPERSONATE_PERMISSION = 'supervisorImpersonatePermission',
}

export function addPermissionOnStorage(entityId: string, role: string) {
  localStorage.setItem(PermissionType.USER_PERMISSION, `${entityId}/${role}`);
}

export function addSelectedEntityIdInStorage(selectedEntityId: string) {
  localStorage.setItem(
    PermissionType.SUPERVISOR_IMPERSONATE_PERMISSION,
    selectedEntityId,
  );
}

export function removePermissionFromStorage(permission?: PermissionType) {
  if (!permission) {
    Object.values(PermissionType).forEach((p) => localStorage.removeItem(p));
  } else {
    localStorage.removeItem(permission);
  }
}

export function getPermissionFromStorage(getRootEntity = false) {
  let entityId;
  let role;

  const permission = localStorage.getItem(PermissionType.USER_PERMISSION);

  if (permission) {
    [entityId, role] = permission.split('/');

    if (!getRootEntity && isAllowedToSelectChild(role as Roles)) {
      const childEntityId = localStorage.getItem(
        PermissionType.SUPERVISOR_IMPERSONATE_PERMISSION,
      );
      if (childEntityId) {
        entityId = childEntityId;
      }
    }
  }

  return {
    entityId,
    role,
  };
}

export const findPermission = (
  entityId: string | undefined,
  role: string | undefined,
  userPermissions: UserPermission[],
) => {
  return userPermissions.find(
    (userPermission) =>
      userPermission.entityId === entityId && userPermission.role === role,
  );
};

export const getSelectedEntityTypeFromLocalStorage = (
  user: UserWithPrivateInfosAndPermissions,
) => {
  const localstorageEntityType = localStorage.getItem(
    'userEntityTypes',
  ) as EntityTypes;

  return (
    user.userPermissions.find(
      (permission) => permission.entity.type === localstorageEntityType,
    )?.entity.type ?? null
  );
};

export const getSelectedPermissionsFromLocalstorage = (
  user: UserWithPrivateInfosAndPermissions,
) => {
  const storedPermission = getPermissionFromStorage(true);
  const permission =
    findPermission(
      storedPermission.entityId,
      storedPermission.role,
      user.userPermissions,
    ) ?? null;
  return permission ? formatPermission(permission) : null;
};
