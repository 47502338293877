import React, { useState, useEffect } from 'react';
import {
  AppContextAttributes,
  AppContext,
  useAppContext,
} from 'business/AppBootstrapper';
import useEntitySelection from '../entitySelection/useEntitySelection';
import {
  FormattedUserPermission,
  Roles,
  UserWithPrivateInfosAndPermissions,
} from '../types/user';
import useIsAllowedTo from '../permissions/usePermission';

export type SelectedPermissionContextAttributes = Pick<
  AppContextAttributes,
  | 'shouldDisplayOnBoarding'
  | 'setShouldDisplayOnBoarding'
  | 'selectedEntity'
  | 'entitySelection'
  | 'isAllowedTo'
>;

interface SelectedPermissionProps {
  user: UserWithPrivateInfosAndPermissions;
  selectedPermission: FormattedUserPermission;
}

export const useSelectedPermission = ({
  user,
  selectedPermission,
}: SelectedPermissionProps): SelectedPermissionContextAttributes => {
  const [shouldDisplayOnBoarding, setShouldDisplayOnBoarding] = useState(false);

  const entitySelection = useEntitySelection(selectedPermission);
  const selectedEntity = entitySelection.selectedEntity ?? selectedPermission;

  const { isAllowedTo } = useIsAllowedTo({
    selectedPermission,
    selectedEntity,
  });

  useEffect(() => {
    if (selectedPermission.role === Roles.CsrManager) {
      setShouldDisplayOnBoarding(user.onboardingDoneAt === null);
    } else {
      setShouldDisplayOnBoarding(false);
    }
  }, [user, selectedPermission]);

  return {
    shouldDisplayOnBoarding,
    setShouldDisplayOnBoarding,
    selectedEntity,
    entitySelection,
    isAllowedTo,
  };
};

export const SelectedPermissionContextProvider: React.FC<
  { children: React.ReactElement } & SelectedPermissionProps
> = ({ children, ...selectedEntityProps }) => {
  const appContext = useAppContext();
  const value = useSelectedPermission(selectedEntityProps);

  return (
    <AppContext.Provider value={{ ...appContext, ...value }}>
      {children}
    </AppContext.Provider>
  );
};
