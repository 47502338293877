import React from 'react';
import { useHistory } from 'react-router';
import Table from 'ui/table';
import { actionCatalogDetailsRoute } from 'business/router/routes';
import { AssociatedActionTemplate } from 'business/indicators/types';
import {
  associatedActionTemplatesColumns,
  associatedActionTemplatesDataSource,
} from '../../services/table';

interface AssociatedActionTemplatesTableProps {
  actionTemplates: AssociatedActionTemplate[];
}

const AssociatedActionTemplatesTable: React.FC<
  AssociatedActionTemplatesTableProps
> = ({ actionTemplates }) => {
  const history = useHistory();
  return (
    <Table
      data-test-id="associated-action-templates-table"
      data-onboarding-id="associated-action-templates-table"
      dataSource={associatedActionTemplatesDataSource(actionTemplates)}
      columns={associatedActionTemplatesColumns()}
      onRow={(record) => ({
        onClick: () => history.push(actionCatalogDetailsRoute(record.key)),
      })}
    />
  );
};

export default AssociatedActionTemplatesTable;
