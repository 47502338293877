import React from 'react';
import InformationDetails from 'business/common/components/informationDetails';
import { indicatorStrategyDetailsRoute } from 'business/router/routes';
import { CampaignFragment } from 'generated/graphql';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from 'ui/table';
import {
  linkedIndicatorsDataSource,
  linkedIndicatorsColumns,
} from 'business/indicators/services/table';

interface CampaignLinkedIndicatorsProps {
  campaignIndicators: CampaignFragment['campaignIndicators'];
}

function CampaignLinkedIndicators({
  campaignIndicators,
}: CampaignLinkedIndicatorsProps) {
  const { t } = useTranslation();

  if (campaignIndicators.length === 0) {
    return null;
  }

  const indicators = campaignIndicators.map((e) => e.indicator);

  const history = useHistory();

  return (
    <InformationDetails
      title={t('campaigns.strategy.details.indicators.title')}
    >
      <Table
        data-test-id="linked-indicators-table"
        dataSource={linkedIndicatorsDataSource(indicators)}
        columns={linkedIndicatorsColumns()}
        onRow={(record) => ({
          onClick: () =>
            history.push(indicatorStrategyDetailsRoute(record.key)),
        })}
      />
    </InformationDetails>
  );
}

export default CampaignLinkedIndicators;
