import logger from 'technical/logger';
import config from 'config/index';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import authService from 'business/user/services/authentication';
import { setContext } from '@apollo/client/link/context';
import { getPermissionFromStorage } from 'business/user/services/permission';
import { Roles } from 'business/user/types/user';
import { getImpersonateToken } from 'business/user/services/impersonate';

const authLink = setContext(
  (
    _,
    { sendWithoutJwt, headers, sendAsUser = false, getRootEntity = false }: any,
  ) => {
    // get the authentication token from auth service if it exists
    const token = authService.getAccessToken();
    const impersonateToken = getImpersonateToken();
    // get selected permission
    const { entityId, role } = getPermissionFromStorage(getRootEntity);
    // return the headers to the context so httpLink can read them
    const isTokenNeeded = !!token && !sendWithoutJwt;

    return {
      headers: {
        ...headers,
        // if token does not exist do not set authorization header
        ...(isTokenNeeded && {
          authorization: `Bearer ${token}`,
        }),
        // if there is a permission selected, set context
        ...(entityId && role
          ? {
              'X-Hasura-Role': sendAsUser ? Roles.User : role,
              'X-Hasura-Entity-Id': entityId,
              // if there is a impersonification going on
              ...(impersonateToken
                ? {
                    'impersonate-token': impersonateToken,
                  }
                : {}),
            }
          : {}),
      },
    };
  },
);

const httpLink = new HttpLink({
  uri: `${config.graphqlUri}/v1/graphql`,
  credentials: 'include',
  // Custom fetch to handle reconnection on jwt expired
  fetch: (input, init) =>
    fetch(input, init).then(async (response) => {
      const json = await response.json();
      if (json.errors && json.errors[0]?.extensions?.code === 'access-denied') {
        logger.info('Renewing token');
        try {
          await authService.renewToken();
        } catch (error) {
          if (error instanceof Error) {
            logger.info('Token renewal failed: logout');
            await authService.logout();
          }
        }
        const newToken = authService.getAccessToken();
        logger.info('Token renewed!');
        // Updating headers with new token
        return fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            authorization: `Bearer ${newToken}`,
          },
        });
      }

      // Recreating json ad text method that ca be called only one beefore forwardig
      return {
        ...response,
        json: () => Promise.resolve(json),
        text: () => Promise.resolve(JSON.stringify(json)),
      };
    }),
});

const client = new SubscriptionClient(`${config.graphqlWsUri}/v1/graphql`, {
  reconnect: true,
  timeout: 30000,
  connectionParams: () => {
    const token = localStorage.getItem('token');

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
});

const wsLink = new WebSocketLink(client);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = ApolloLink.split(
  // split based on operation type
  ({ query }) => {
    // @ts-ignore https://hasura.io/blog/moving-from-apollo-boost-to-graphql-subscriptions-with-apollo-client-cc0373e0adb0/
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});

export default apolloClient;
