import { Form as AntdForm } from 'antd';
import {
  createActionToStrategy,
  getAlreadyInstanciedIndicatorTemplates,
  getNotInstanciedIndicatorTemplates,
} from 'business/actions/services';
import { ActionStatus, FormikAction } from 'business/actions/types';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import AddMissingIndicatorsModal, {
  AddMissingIndicatorsModalContext,
} from 'business/indicators/components/addMissingIndicatorsModal';
import { MissingIndicator } from 'business/indicators/types';
import navBack from 'business/router/navBack';
import Routes, { actionStrategyDetailsRoute } from 'business/router/routes';
import SubPageLayout from 'business/user/layout/subPage';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Button from 'ui/button';
import Modal from 'ui/modal';
import Space from 'ui/space';
import Typography from 'ui/typography';
import * as yup from 'yup';
import ActionForm from '../../../components/actionForm';
import * as actionValidation from '../../../services/validation';

interface CreationActionPageParams {
  indicatorTemplateId?: string;
}
const CreateActionPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedEntity: { entityId },
  } = useLoggedInAppContext();
  const history = useHistory();
  const [missingIndicators, setMissingIndicators] = useState<
    MissingIndicator[]
  >([]);
  const { indicatorTemplateId } = useParams<CreationActionPageParams>();
  const [
    isAddMissingIndicatorsModalVisible,
    setIsAddMissingIndicatorsModalVisible,
  ] = useState(false);

  const handleAddActionToStrategy = async (formik: FormikAction) => {
    // 1. Add action, actionIndicators and indicators if necessary.
    const { data: actionData } = await createActionToStrategy(
      formik.values.label,
      formik.values.description,
      formik.values.status,
      formik.values.linkedIndicators,
      entityId,
      formik.values.dueDate,
      formik.values.comment,
      formik.values.cost,
      formik.values.impact,
      formik.values.responsibleId,
    );
    // 2. Show success modal
    Modal.confirm({
      title: t('actions.strategy.customAction.successModal.successTitle'),
      content: (
        <Typography.Paragraph>
          {t('actions.strategy.customAction.successModal.successContent')}
        </Typography.Paragraph>
      ),
      okText: t('actions.strategy.customAction.successModal.successOkCta'),
      cancelText: t(
        'actions.strategy.customAction.successModal.successCancelCta',
      ),
      onOk: () =>
        history.replace(
          actionStrategyDetailsRoute(actionData?.insert_action_one?.id),
        ),
      onCancel: () => navBack(Routes.AddActionStrategy),
      okButtonProps: {
        id: 'see-my-action',
      },
    });
  };

  const formik = useFormik<FormikAction['values']>({
    initialValues: {
      label: '',
      description: '',
      comment: undefined,
      status: ActionStatus.Incoming,
      dueDate: undefined,
      cost: undefined,
      impact: undefined,
      linkedIndicators: [],
      responsibleId: undefined,
    },
    validationSchema: yup.object().shape({
      label: actionValidation.label,
      description: actionValidation.description,
      comment: actionValidation.comment,
      status: actionValidation.status,
      dueDate: actionValidation.dueDate,
      cost: actionValidation.cost,
      impact: actionValidation.impact,
      linkedIndicators: actionValidation.linkedIndicators,
      responsibleId: actionValidation.responsibleId,
    }),
    onSubmit: async (values) => {
      const alreadyInstanciedIndicatorTemplates =
        getAlreadyInstanciedIndicatorTemplates(values.linkedIndicators);
      const notInstanciedIndicatorTemplates =
        getNotInstanciedIndicatorTemplates(values.linkedIndicators);

      setMissingIndicators(notInstanciedIndicatorTemplates);

      if (
        alreadyInstanciedIndicatorTemplates.length === 0 &&
        notInstanciedIndicatorTemplates.length !== 0
      ) {
        setIsAddMissingIndicatorsModalVisible(true);
      } else {
        await handleAddActionToStrategy(formik);
      }
    },
  });

  return (
    <SubPageLayout goBack={Routes.AddActionStrategy}>
      <AntdForm onFinish={formik.handleSubmit}>
        <Space direction="vertical">
          <Space justify="space-between">
            <Typography.Title level={2}>
              {t('actions.strategy.customAction.title')}
            </Typography.Title>
            <Button
              data-test-id="add-custom-action"
              type="primary"
              onClick={() => formik.handleSubmit()}
            >
              {t('common.save')}
            </Button>
          </Space>
          <ActionForm
            formik={formik}
            prefilledIndicatorTemplateId={indicatorTemplateId}
            shouldDisplayIndicatorTemplateLinkSelector
          />
        </Space>
        <AddMissingIndicatorsModal
          editable={false}
          missingIndicators={missingIndicators}
          handleSubmit={() => handleAddActionToStrategy(formik)}
          context={AddMissingIndicatorsModalContext.Actions}
          isVisible={isAddMissingIndicatorsModalVisible}
          handleClose={() => setIsAddMissingIndicatorsModalVisible(false)}
        />
      </AntdForm>
    </SubPageLayout>
  );
};

export default CreateActionPage;
