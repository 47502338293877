import { useAppContext } from 'business/AppBootstrapper';
import Routes, {
  actionCatalogDetailsRoute,
  indicatorCatalogDetailsRoute,
} from 'business/router/routes';
import config from 'config';
import { useMarkOnBoardingDoneMutation } from 'generated/graphql';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Joyride, {
  CallBackProps,
  Step,
  ACTIONS,
  EVENTS,
  STATUS,
} from 'react-joyride';
import elementIsReady from 'technical/elementReady';
import history from 'technical/history';
import AppLogo from 'ui/appLogo';
import List, { Item } from 'ui/list';
import Space from 'ui/space';
import Typography from 'ui/typography';
import variables from 'ui/variables.module.scss';
import styles from './index.module.scss';

const OnBoarding: React.FC = () => {
  const { t } = useTranslation();
  const { user, shouldDisplayOnBoarding, setShouldDisplayOnBoarding } =
    useAppContext();
  const steps: Step[] = [
    {
      target: 'body',
      content: (
        <Space direction="vertical">
          <Typography.Title level={2}>
            {t('onboarding.content.welcome.title')}
          </Typography.Title>
          <Typography.Paragraph>
            <Trans t={t} i18nKey="onboarding.content.welcome.content">
              a<Typography.Text strong>b</Typography.Text>c
            </Trans>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Trans t={t} i18nKey="onboarding.content.welcome.tool">
              a<Typography.Text strong>b</Typography.Text>c
            </Trans>
          </Typography.Paragraph>
        </Space>
      ),
      title: <AppLogo className={styles.innerTitleLogo} mode="light" />,
      placement: 'center',
      styles: {
        tooltip: {
          width: '800px',
        },
      },
    },
    {
      target: 'body',
      content: (
        <Space direction="vertical">
          <Typography.Title level={2}>
            <Trans t={t} i18nKey="onboarding.content.explanation.title">
              a<AppLogo mode="light" />b
            </Trans>
          </Typography.Title>
          <List className={styles.list}>
            <Item>{t('onboarding.content.explanation.item1')}</Item>
            <Item>{t('onboarding.content.explanation.item2')}</Item>
            <Item>{t('onboarding.content.explanation.item3')}</Item>
            <Item>{t('onboarding.content.explanation.item4')}</Item>
            <Item>{t('onboarding.content.explanation.item5')}</Item>
          </List>
        </Space>
      ),
      placement: 'center',
      styles: {
        tooltip: {
          width: '800px',
        },
      },
    },
    {
      target: '[data-onboarding-id="menu-strategy"]',
      disableBeacon: true,
      placement: 'right',
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.menu-strategy-indicators')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="strategy-add-indicator"]',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.add-indicator')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="menu-catalog"]',
      disableBeacon: true,
      placement: 'right',
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.menu-catalog-indicators')}
        </Typography.Paragraph>
      ),
    },
    {
      target:
        '[data-onboarding-id="catalog-indicators-table"] tbody tr.ant-table-row:first-child',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-indicator')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="indicator-template-tabs"] .ant-tabs-nav',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-indicator-detail-tabs')}
        </Typography.Paragraph>
      ),
    },
    {
      target:
        '[data-onboarding-id="associated-action-templates-table"] tbody tr:first-child',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-indicator-detail-actions')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="add-indicator"]',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-indicator-detail-add')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="menu-catalog-actions"]',
      disableBeacon: true,
      placement: 'right',
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-actions')}
        </Typography.Paragraph>
      ),
    },
    {
      target:
        '[data-onboarding-id="catalog-actions-table"] tbody tr.ant-table-row:first-child',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-actions-table')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="catalog-actions-detail"]',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-actions-detail')}
        </Typography.Paragraph>
      ),
    },
    {
      target: '[data-onboarding-id="catalog-actions-detail-add"]',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.catalog-actions-detail-add')}
        </Typography.Paragraph>
      ),
    },
    {
      target: 'body',
      placement: 'center',
      disableBeacon: true,
      content: (
        <Typography.Paragraph>
          {t('onboarding.content.end')}
        </Typography.Paragraph>
      ),
      styles: {
        tooltip: {
          width: '600px',
        },
      },
    },
  ];

  const [updateUser] = useMarkOnBoardingDoneMutation({
    variables: {
      idUser: user?.id,
    },
  });

  const [stepIndex, setStepIndex] = useState(0);

  const callback = async (data: CallBackProps) => {
    const { action, index, status, type } = data;
    switch (true) {
      case action === ACTIONS.START &&
        index === 0 &&
        status === STATUS.RUNNING &&
        type === EVENTS.TOUR_START:
        history.push(Routes.Home);
        break;
      case action === ACTIONS.CLOSE &&
        index === 3 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.push(Routes.IndicatorsCatalog);
        elementIsReady(steps[index + 1].target as string).then(() => {
          setStepIndex(index + 1);
        });
        break;
      case action === ACTIONS.PREV &&
        index === 4 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.goBack();
        elementIsReady(steps[index - 1].target as string).then(() => {
          setStepIndex(index - 1);
        });
        break;
      case action === ACTIONS.CLOSE &&
        index === 5 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER: {
        const indicatorIdToCheck =
          document
            .querySelector(
              '[data-onboarding-id="catalog-indicators-table"] tbody tr.ant-table-row:first-child',
            )
            ?.getAttribute('data-row-key') || null;
        if (!indicatorIdToCheck) {
          throw new Error('No indicator in the catalog are available');
        }
        history.push(indicatorCatalogDetailsRoute(indicatorIdToCheck));
        elementIsReady(steps[index + 1].target as string).then(() => {
          setStepIndex(index + 1);
        });
        break;
      }
      case action === ACTIONS.PREV &&
        index === 6 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.goBack();
        elementIsReady(steps[index - 1].target as string).then(() => {
          setStepIndex(index - 1);
        });
        break;
      case action === ACTIONS.CLOSE &&
        index === 6 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.push(
          history.location.pathname.replace('description', 'actions'),
        );
        elementIsReady(steps[index + 1].target as string).then(() => {
          setStepIndex(index + 1);
        });
        break;
      case action === ACTIONS.CLOSE &&
        index === 8 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.push(Routes.ActionsCatalog);
        elementIsReady(steps[index + 1].target as string).then(() => {
          setStepIndex(index + 1);
        });
        break;
      case action === ACTIONS.PREV &&
        index === 9 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.goBack();
        elementIsReady(steps[index - 1].target as string).then(() => {
          setStepIndex(index - 1);
        });
        break;
      case action === ACTIONS.CLOSE &&
        index === 10 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER: {
        const actionIdToCheck =
          document
            .querySelector(
              '[data-onboarding-id="catalog-actions-table"] tbody tr.ant-table-row:first-child',
            )
            ?.getAttribute('data-row-key') || null;
        if (!actionIdToCheck) {
          throw new Error('No indicator in the catalog are available');
        }
        history.push(actionCatalogDetailsRoute(actionIdToCheck));
        elementIsReady(steps[index + 1].target as string).then(() => {
          setStepIndex(index + 1);
        });
        break;
      }
      case action === ACTIONS.PREV &&
        index === 11 &&
        status === STATUS.RUNNING &&
        type === EVENTS.STEP_AFTER:
        history.goBack();
        elementIsReady(steps[index - 1].target as string).then(() => {
          setStepIndex(index - 1);
        });
        break;
      case type === EVENTS.TOUR_END:
        await updateUser();
        setStepIndex(0);
        setShouldDisplayOnBoarding(false);
        history.push(Routes.Home);
        break;
      case type === EVENTS.STEP_AFTER:
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        break;
      default:
        break;
    }
  };

  return (
    <Joyride
      stepIndex={stepIndex}
      steps={steps}
      disableScrolling
      showSkipButton
      run={shouldDisplayOnBoarding}
      styles={{
        tooltip: {
          borderRadius: variables.radiusMd,
        },
        buttonBack: {
          color: variables.purpleLight,
          background: 'transparent',
        },
        buttonNext: {
          background: variables.purpleLight,
        },
        buttonClose: {
          background: 'transparent',
        },
        buttonSkip: {
          color: variables.purpleLight,
          background: 'transparent',
        },
      }}
      locale={{
        back: t('onboarding.controls.back'),
        close: t('onboarding.controls.close'),
        last: t('onboarding.controls.last'),
        next: t('onboarding.controls.next'),
        open: t('onboarding.controls.open'),
        skip: t('onboarding.controls.skip'),
      }}
      callback={callback}
    />
  );
};

export default OnBoarding;
